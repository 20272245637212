import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import Swal from 'sweetalert2';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
declare var $;

@Component({
  selector: 'app-linha-vt',
  templateUrl: './linha-vt.component.html',
  styleUrls: ['./linha-vt.component.scss']
})
export class LinhaVTComponent implements OnInit {

  gridData = [];
  empresasList: Array<object> = [];
  empresasSelected = [];
  operadoraGridData = [];
  dropdownSettings = {};

  isEdit = false;

  linhaVTIdSelected = '';

  operadoraIdSelected = '';

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('modalOperadoraGrid', { static: true }) modalOperadoraGrid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroLinhaVTService,
    private operadoraService: CadastroOperadoraVTService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtValor") as any).maskMoney({ prefix: 'R$ ', thousands: '.', decimal: ',' });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Limpar todos',
      searchPlaceholderText: 'Pesquisar',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.empresasSelected);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtOperadora: {
          required: true,
          operadoraCheck: true
        },
        txtValor: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome da Linha"
        },
        txtOperadora: {
          required: "Informe a Operadora"
        },
        txtValor: {
          required: "Informe o Valor da Linha"
        }
      },
    });

    $.validator.addMethod("operadoraCheck", function (value, element) {
      return self.operadoraIdSelected || false;
    }, 'Informe a Operadora');

    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
  this.getSelectData();
  }
  async getSelectData() {
    this.empresasList = await this.getEmpresas();
  }

  getEmpresas(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisar().subscribe(
      res => {
        this.empresasList = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  getEmpresasByLinhaVTId(linhaVTId): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisarPorLinhaVTId(linhaVTId).subscribe(
      res => {
          this.empresasSelected = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  openModal() {
    if ($('#txtOperadora').val().toString().length > 0) {
      this.operadoraService.pesquisar({ Nome: $('#txtOperadora').val().toString() }).subscribe(
        res => {
          this.operadoraGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
            })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTableOperadora(this.operadoraGridData);

          ($('#modalOperadora') as any).modal('show');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.NomeLinha,
            valor: val.ValorLinha,
            operadora: val.OperadoraVT.Nome,
            operadoraId: val.OperadoraVTId,
            dataCadastro: val.DataCadastro,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtNome').val('');
    $('#txtValor').val('');
    $('#txtOperadora').val('');
    this.operadoraIdSelected = '';
  }

  getValuesFromInput() {
    return {
      NomeLinha: $('#txtNome').val().toString().trim(),
      ValorLinha: this.utilService.maskMoneyToNumber($('#txtValor').val().toString().trim()),
      OperadoraVTId: this.operadoraIdSelected,
      EmpresasSelected: this.empresasSelected.map(val => val.item_id),
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        console.log(this.linhaVTIdSelected);
        obj.Id = this.linhaVTIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);

    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": 'valor', "orderable": true, "render": function (data) {
            return 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          }
        },
        {
          "data": "operadora"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[4]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          ;
          $('#txtNome').val(data.nome);
          $('#txtValor').val(self.utilService.numberToMaskMoney(data.valor));
          $('#txtOperadora').val(data.operadora);
          self.operadoraIdSelected = data.operadoraId;
          self.linhaVTIdSelected = data.id;
          self.getEmpresasByLinhaVTId(data.id);
        });
        $($(row).children()[5]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableOperadora(data) {
    let self = this;
    this.dataTable = $(this.modalOperadoraGrid.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.operadoraIdSelected = data.id;
          $('#txtOperadora').val(data.nome);
          ($('#modalOperadora') as any).modal('hide');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": true,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
