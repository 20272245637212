import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { UtilsService } from "../../utils/utils";
import { environment } from "src/environments/environment";

@Injectable()
export class ImportacaoHoleriteService {
  routePrefix: string = "Holerite";
  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) {}

  pesquisar(colaboradorId = "", mes = "", ano = "", tipo = "", empresaId = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorColaboradorIdMesAno?colaboradorId=${colaboradorId}&Mes=${mes}&Ano=${ano}&empresaId=${empresaId}&tipo=${tipo}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarImportacaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  importar(data = {}): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/Importar${this.routePrefix}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  excluirPorId(id = ""): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}PorId?id=${id}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  excluirPorMultiplosIds(ids = []): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/ExcluirHoleritesSelecionados`,
        ids
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
}
