<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Alteração Cadastral
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Solicitação</li>
      <li class="active">Alteração Cadastral</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nome</label>
                  <input type="text" placeholder="Nome" class="form-control" id="txtNome" name="txtNome">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Endereço</label>
                  <input type="text" placeholder="Endereço" class="form-control" id="txtEndereco" name="txtEndereco">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Número</label>
                  <input type="text" placeholder="Número" class="form-control" id="txtNumero" name="txtNumero">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Complemento</label>
                  <input type="text" placeholder="Complemente" class="form-control" id="txtComplemento"
                    name="txtComplemento">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-group">
                    <label>Bairro</label>
                    <input type="text" placeholder="Bairro" class="form-control" id="txtBairro" name="txtBairro">
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>CEP</label>
                  <input mask="00000-000" placeholder="CEP" type="text" class="form-control" id="txtCEP" name="txtCEP">
                </div>
              </div>
              <div class="col-md-3">
                <label>UF</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="ufList" IdSelect="selUF"
                  [Value]="ufSelected">
                </select-component>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Cidade</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Disabled]="!ufSelected ? true : false"
                    [Data]="cidadeList" IdSelect="selCidade" [Value]="cidadeSelected">
                  </select-component>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" placeholder="Email" class="form-control" id="txtEmail" name="txtEmail">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Telefone 1</label>
                  <input mask="(00) 0000-00009" placeholder="Telefone 1" type="text" class="form-control"
                    id="txtTelefone1" name="txtTelefone1">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Telefone 2</label>
                  <input mask="(00) 0000-00009" placeholder="Telefone 2" type="text" class="form-control"
                    id="txtTelefone2" name="txtTelefone2">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Telefone 3</label>
                  <input mask="(00) 0000-00009" placeholder="Telefone 3" type="text" class="form-control"
                    id="txtTelefone3" name="txtTelefone3">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Categoria Habilitação</label>
                  <input type="text" placeholder="Categoria Habilitação" class="form-control" id="txtCategoria"
                    name="txtCategoria">
                </div>
              </div>
              <div class="col-md-3">
                <label>Vencimento Habilitação</label>
                <div class="input-group date">
                  <input mask="00/00/0000" placeholder="dd/mm/aaaa" type="text" class="form-control pull-right"
                    id="txtVencimentoHabilitacao" name="txtVencimentoHabilitacao">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Carteira de Habilitação</label>
                  <div class="input-group date">
                    <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                      id="txtCNHAnexo" name="txtCNHAnexo" placeholder="Escolher Arquivo">
                    <div class="input-group-addon" style="padding: 0px;">
                      <div class="fa fa-upload btn btn-file">
                        <input class="ignore" type="file" name="inputFoto" #CNHInput type="file"
                          (change)="processFile('cnh', CNHInput)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Comprovante de Residência</label>
                  <div class="input-group date">
                    <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                      id="txtComprovanteResidenciaAnexo" name="txtComprovanteResidenciaAnexo"
                      placeholder="Escolher Arquivo">
                    <div class="input-group-addon" style="padding: 0px;">
                      <div class="fa fa-upload btn btn-file">
                        <input class="ignore" type="file" name="inputFoto" #comprovanteInput type="file"
                          (change)="processFile('comprovante', comprovanteInput)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Banco</label>
                  <!-- BUSCAR UM COMPONENTE COM OS BANCO EXISTENTES -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="bancoList" IdSelect="selBanco"
                    [Value]="bancoSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Agência</label>
                  <input type="text" placeholder="Agência" class="form-control" id="txtAgencia" name="txtAgencia">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Conta</label>
                  <input type="text" placeholder="Conta" class="form-control" id="txtConta" name="txtConta">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Tipo de Conta</label>
                  <!-- BUSCAR NA TABELA TIPO DE CONTA -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoContaList"
                    IdSelect="selTipoConta" [Value]="tipoContaSelected">
                  </select-component>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Foto</label>
                  <div class="input-group date">
                    <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                      id="txtFotoAnexo" name="txtFotoAnexo" placeholder="Escolher Arquivo">
                    <div class="input-group-addon" style="padding: 0px;">
                      <div class="fa fa-upload btn btn-file">
                        <input class="ignore" type="file" name="inputFoto" #fotoInput type="file"
                          (change)="processFile('foto', fotoInput)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input value="Solicitar" readonly="readonly" (click)="solicitar()"
                    class="btn btn-info pull-right btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important; text-align: center;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Nome</th>
                  <th style="width: 15% !important">Endereço</th>
                  <th style="width: 15% !important">Número</th>
                  <th style="width: 15% !important">Complemento</th>
                  <th style="width: 15% !important">Bairro</th>
                  <th style="width: 15% !important">CEP</th>
                  <th style="width: 15% !important">UF</th>
                  <th style="width: 15% !important">Cidade</th>
                  <th style="width: 15% !important">Telefone 1</th>
                  <th style="width: 15% !important">Telefone 2</th>
                  <th style="width: 15% !important">Telefone 3</th>
                  <th style="width: 15% !important">Email</th>
                  <th style="width: 15% !important">Categoria Habilitação</th>
                  <th style="width: 15% !important">Vencimento Habilitação</th>
                  <th style="width: 15% !important">Banco</th>
                  <th style="width: 15% !important">Agência</th>
                  <th style="width: 15% !important">Conta</th>
                  <th style="width: 15% !important">Tipo de Conta</th>
                  <th style="width: 15% !important">Foto</th>
                  <th style="width: 15% !important">Comprovante de Residência</th>
                  <th style="width: 15% !important">Carteira de Habilitação</th>
                  <th style="width: 15% !important">Status</th>
                  <th style="width: 15% !important">Data da Solicitação</th>
                  <th style="width: 15% !important">Data da Conclusão</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>