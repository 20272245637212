import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoAlteracaoCadastralService } from 'src/app/@core/@service/solicitacao/alteracao-cadastral/alteracao-cadastral.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';

@Component({
  selector: 'app-solicitacao-alteracao-cadastral',
  templateUrl: './alteracao-cadastral.component.html',
  styleUrls: ['./alteracao-cadastral.component.scss']
})
export class SolicitacaoAlteracaoCadastralComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  colaboradorList: Array<any> = [];

  tipoSelected = ''

  tipoList: Array<any> = [];

  ufSelected = ''
  ufList: Array<any> = [];
  cidadeSelected = ''
  cidadeList: Array<any> = [];
  bancoSelected = ''
  bancoList: Array<any> = [];
  tipoContaSelected = ''
  tipoContaList: Array<any> = [];

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  fotoBase64 = '';

  cnhBase64 = '';

  comprovanteResidenciaBase64 = '';

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoAlteracaoCadastralService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.validarCampos();
    this.getDataGrid();
    this.getInitialData();
    //Date picker
    ($("#txtVencimentoHabilitacao") as any).datepicker({
      autoclose: true
    });
  }


  async getInitialData() {
    this.cidadeList = [];
    this.colaboradorService.BuscarTudoBanco().subscribe(
      res => {
        this.bancoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoTipoConta().subscribe(
      res => {
        this.tipoContaList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.ufList = await this.utilService.getEstados();
    this.moduleService.pesquisarPorColaboradorId(this.colaboradorSelected).subscribe(
      res => {
        setTimeout(async () => {
          this.ufSelected = res.UF;
          this.cidadeList = res.UF ? await this.utilService.getCidades(res.UF) : [];
          this.cidadeSelected = res.Cidade;
          this.bancoSelected = res.BancoId;
          this.tipoContaSelected = res.ContaBancariaTipoId;

          $('#txtNome').val(res.Nome);
          $('#txtEndereco').val(res.Endereco);
          $('#txtNumero').val(res.Numero);
          $('#txtComplemento').val(res.Complemento);
          $('#txtBairro').val(res.Bairro);
          $('#txtCEP').val(res.CEP);
          $('#txtTelefone1').val(res.Telefone1);
          $('#txtTelefone2').val(res.Telefone2);
          $('#txtTelefone3').val(res.Telefone3);
          $('#txtEmail').val(res.Email);
          $('#txtCategoria').val(res.CategoriaHabilitacao);
          ($("#txtVencimentoHabilitacao") as any).datepicker('setDate', res.DataVencimentoHabilitacao ? new Date(res.DataVencimentoHabilitacao) : '');
          $('#txtAgencia').val(res.Agencia);
          $('#txtConta').val(res.Conta);
        }, 2000);
      },
      err => {
        console.log(err);
      }
    );

  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtEndereco: {
          required: true
        },
        txtNumero: {
          required: true
        },
        txtBairro: {
          required: true
        },
        txtCEP: {
          required: true
        },
        txtTelefone1: {
          required: true
        },
        txtEmail: {
          required: true
        },
        txtAgencia: {
          required: true
        },
        txtConta: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtEndereco: {
          required: "Informe o Endereço"
        },
        txtNumero: {
          required: "Informe o Número"
        },
        txtBairro: {
          required: "Informe o Bairro"
        },
        txtCEP: {
          required: "Informe o CEP"
        },
        txtTelefone1: {
          required: "Informe o Telefone 1"
        },
        txtEmail: {
          required: "Informe o Email"
        },
        txtAgencia: {
          required: "Informe a Agência"
        },
        txtConta: {
          required: "Informe a Conta"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  processFile(id = '', imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      if (this.typeImagesAccepted.includes(file.type)) {
        if (id == 'foto') {
          this.fotoBase64 = event.target.result;
          $('#txtFotoAnexo').val(file.name);
        } else if (id == 'cnh') {
          this.cnhBase64 = event.target.result;
          $('#txtCNHAnexo').val(file.name);
        } else if (id == 'comprovante') {
          this.comprovanteResidenciaBase64 = event.target.result;
          $('#txtComprovanteResidenciaAnexo').val(file.name);
        }
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selUF') {
      this.ufSelected = event.target.value;
      this.cidadeSelected = '';
      this.cidadeList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selCidade') {
      this.cidadeSelected = event.target.value;
    } else if (event.srcElement.id == 'selBanco') {
      this.bancoSelected = event.target.value;
    } else if (event.srcElement.id == 'selTipoConta') {
      this.tipoContaSelected = event.target.value;
    }
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.colaboradorSelected).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Colaborador ? val.Colaborador.Nome : '-',
            endereco: val.Endereco || '-',
            numero: val.Numero || '-',
            complemento: val.Complemento || '-',
            bairro: val.Bairro || '-',
            cep: val.CEP || '-',
            estado: val.UF || '-',
            cidade: val.Cidade || '-',
            tel1: val.Telefone1 || '-',
            tel2: val.Telefone2 || '-',
            tel3: val.Telefone3 || '-',
            email: val.Email || '-',
            categoriaCNH: val.CategoriaHabilitacao || '-',
            vencimentoCNH: val.DataVencimentoHabilitacao || '-',
            banco: val.Banco ? val.Banco.Nome : '-',
            agencia: val.Agencia || '-',
            conta: val.Conta || '-',
            tipoConta: val.ContaBancariaTipo ? val.ContaBancariaTipo.Nome : '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }
  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();

    // Zera os campos
    this.ufSelected = '';
    this.cidadeSelected = '';
    this.bancoSelected = '';
    this.tipoContaSelected = '';
    this.fotoBase64 = '';
    $('#txtFotoAnexo').val('');
    this.cnhBase64 = '';
    $('#txtCNHAnexo').val('');
    this.comprovanteResidenciaBase64 = '';
    $('#txtComprovanteResidenciaAnexo').val('');

    $('#txtNome').val('');
    $('#txtEndereco').val('');
    $('#txtNumero').val('');
    $('#txtComplemento').val('');
    $('#txtBairro').val('');
    $('#txtCEP').val('');
    $('#txtTelefone1').val('');
    $('#txtTelefone2').val('');
    $('#txtTelefone3').val('');
    $('#txtEmail').val('');
    $('#txtCategoria').val('');
    $('#txtVencimentoHabilitacao').val('');
    $('#txtAgencia').val('');
    $('#txtConta').val('');
  }

  getValuesFromInput() {
    const data = $('#txtVencimentoHabilitacao').val().toString().trim().split('/');
    return {
      ColaboradorId: this.colaboradorSelected,
      Nome: $('#txtNome').val().toString().trim(),
      Endereco: $('#txtEndereco').val().toString().trim(),
      Numero: $('#txtNumero').val().toString().trim(),
      Complemento: $('#txtComplemento').val().toString().trim(),
      Bairro: $('#txtBairro').val().toString().trim(),
      CEP: $('#txtCEP').val().toString().trim(),
      UF: this.ufSelected,
      Cidade: this.cidadeSelected,
      Telefone1: $('#txtTelefone1').val().toString().trim(),
      Telefone2: $('#txtTelefone2').val().toString().trim(),
      Telefone3: $('#txtTelefone3').val().toString().trim(),
      Email: $('#txtEmail').val().toString().trim(),
      CategoriaHabilitacao: $('#txtCategoria').val().toString().trim(),
      DataVencimentoHabilitacao: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      BancoId: this.bancoSelected,
      Agencia: $('#txtAgencia').val().toString().trim(),
      Conta: $('#txtConta').val().toString().trim(),
      ContaBancariaTipoId: this.tipoContaSelected,
      CarteiraHabilitacaoBase64: this.cnhBase64,
      ComprovanteResidenciaBase64: this.comprovanteResidenciaBase64,
      FotoBase64: this.fotoBase64,
    }
  }

  solicitar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      this.moduleService.solicitar(obj).subscribe(
        res => {
          if (res.Codigo && res.Codigo == '600') {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: res.Descricao || 'Ocorreu um erro',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            this.getDataGrid();
            this.utilService.alertSwal('add-success');
          }
        },
        err => {
          console.log(err);
          this.utilService.alertSwal('add-error');
        }
      );
    }
  }

  exibirFoto(data: any = {}, type = '') {
    if (type == 'compResidencia') {
      this.moduleService.buscarFotoComprovanteResidencia(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Comprovante de Residência não encontrado!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar o Comprovante de Residência!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    } else if (type == 'cnh') {
      this.moduleService.buscarFotoCarteiraHabilitacao(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Carteira de Habilitação não encontrada!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar Carteira de Habilitação!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    } else if (type == 'foto') {
      this.moduleService.buscarFotoSolicitacaoAlteracao(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Foto não encontrada!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar a foto!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }


  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "endereco"
        },
        {
          "data": "numero"
        },
        {
          "data": "complemento"
        },
        {
          "data": "bairro"
        },
        {
          "data": "cep"
        },
        {
          "data": "estado"
        },
        {
          "data": "cidade"
        },
        {
          "data": "tel1"
        },
        {
          "data": "tel2"
        },
        {
          "data": "tel3"
        },
        {
          "data": "email"
        },
        {
          "data": "categoriaCNH"
        },
        {
          "data": 'vencimentoCNH', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": "banco"
        },
        {
          "data": "agencia"
        },
        {
          "data": "conta"
        },
        {
          "data": "tipoConta"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.FotoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Foto"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Foto"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.ComprovanteResidenciaNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Comprovante de Residência"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Comprovante de Residência"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.CarteiraHabilitacaoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Carteira de Habilitação"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Carteira de Habilitação"></button></div>';
            }

          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[18]).on("click", function (e) {
          if (data.FotoNome) {
            self.exibirFoto(data, 'foto');
          }
        });
        $($(row).children()[19]).on("click", function (e) {
          if (data.ComprovanteResidenciaNome) {
            self.exibirFoto(data, 'compResidencia');
          }
        });
        $($(row).children()[20]).on("click", function (e) {
          if (data.CarteiraHabilitacaoNome) {
            self.exibirFoto(data, 'cnh');
          }
        });

      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
