<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Informe de Rendimento
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Importação</li>
      <li class="active">Informe de Rendimento</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Anexar</label>
                  <div class="input-group date">
                    <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                      id="txtArquivo" name="txtArquivo" placeholder="Escolher Arquivo">
                    <div class="input-group-addon" style="padding: 0px;">
                      <div class="fa fa-upload btn btn-file">
                        <input class="ignore" type="file" name="inputFoto" #imageInput type="file"
                          (change)="processFile(imageInput)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Ano</label>
                  <input type="text" mask="0000" class="form-control" id="txtAno" name="txtAno"
                    placeholder="yyyy">
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input value="Importar" readonly="readonly" (click)="importar()"
                    class="btn btn-info pull-right btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div id="tableResult" class="box box-body" style="visibility: hidden;">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">CPF do Colaborador</th>
                  <th style="width: 15% !important">Ano</th>
                  <th style="width: 15% !important">Erro</th>
                  <th style="width: 15% !important">Data da Importação</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>