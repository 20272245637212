import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from '../@service/utils/loader-service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];
  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      sessionStorage.removeItem('currentUser')
      this.router.navigate(['Login']);
      return of(err.message);
    }
    return throwError(err);
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const notShowLoading = ['notificacao'];
    const checkerNotShowLoading = value => !notShowLoading.some(element => value.includes(element));
    if (checkerNotShowLoading(req.url.toString().toLowerCase()))
      this.loaderService.isLoading.next(true);
    this.requests.push(req);
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    let clonedreq: any;
    if (req.headers.getAll('Content-Type')) {
      clonedreq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${user ? user.token : ''}`)
      });
    } else {
      clonedreq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${user ? user.token : ''}`)
          .set('Content-Type', 'application/json')
      });
    }

    return Observable.create(observer => {
      const subscription = next.handle(clonedreq)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            this.handleAuthError(err);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
