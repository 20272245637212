<form id="formularioPagamento">
  <div style="margin-left: 0px;">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeColaboradorPagamento" name="txtNomeColaboradorPagamento"
                  placeholder="Nome do Colaborador">
                <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaboradorPagamento')"
                    title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <input type="text" id="txtEmpresaPagamento" name="txtEmpresaPagamento" class="form-control" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px;">
    <div class="box box-primary">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Banco</label>
              <!-- BUSCAR UM COMPONENTE COM OS BANCO EXISTENTES -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="bancoList" IdSelect="selBanco"
              [Value]="bancoSelected">
            </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Agência</label>
              <input type="text" class="form-control" id="txtAgencia" name="txtAgencia" placeholder="Agência">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Conta</label>
              <input type="text" class="form-control" id="txtConta" name="txtConta" placeholder="Conta">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Tipo</label>
              <!-- BUSCAR NA TABELA TIPO DE CONTA -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoContaList" IdSelect="selTipoConta"
              [Value]="tipoContaSelected">
            </select-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Chave Pix</label>
              <input type="text" class="form-control" id="txtChavePix" name="txtChavePix" placeholder="Chave Pix">
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                class="btn btn-primary btn-margin" />
              <input name="Salvar" id="btnSalvarPagamento" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar()"
                class="btn btn-info pull-right btn-margin" />
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</form>
<div class="box box-body">
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome do Colaborador</th>
            <th>Nome da Empresa</th>
            <th>Banco</th>
            <th>Agência</th>
            <th>Conta</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalColaboradorPagamento" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Colaborador</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Status</th>
              <th>Data Admissão</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
