import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroLotacaoPlanoService } from 'src/app/@core/@service/cadastro/lotacao/plano/plano.service';
import Swal from 'sweetalert2';
import { CadastroLotacaoUnidadeService } from 'src/app/@core/@service/cadastro/lotacao/unidade/unidade.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';

declare var $;
@Component({
  selector: 'app-lotacao-plano',
  templateUrl: './plano.component.html',
  styleUrls: ['./plano.component.scss']
})
export class LotacaoPlanoComponent implements OnInit {

  gridData = [];

  gridModalUnidadeData = [];

  empresaGridData = [];

  isEdit = false;

  planoIdSelected = '';

  empresaIdSelected = '';

  dataTable: any;

  dataTableModalUnidade: any;

  dataTableModalEmpresa: any;

  lotacaoUnidades = [];


  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalUnidade', { static: true }) gridModalUnidade: ElementRef;

  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroLotacaoPlanoService,
    private unidadeService: CadastroLotacaoUnidadeService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNomeEmpresa: {
          required: true,
          empresaCheck: true,
        },
        txtCodigoPlano: {
          required: true
        },
        txtNomePlano: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeEmpresa: {
          required: "Informe o Nome da Empresa"
        },
        txtCodigoPlano: {
          required: "Informe o Código do Plano"
        },
        txtNomePlano: {
          required: "Informe o Nome do Plano"
        }
      },
    });
    $.validator.addMethod("empresaCheck", function (value, element) {
      return self.empresaIdSelected ? true : false;
    }, 'Selecione a empresa');
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            empresaId: val.Empresa.Id,
            empresaNome: val.Empresa.Nome,
            codigo: val.Codigo,
            nome: val.Nome,
            dataCadastro: val.DataCadastro,
            lotacaoUnidades: val.LotacaoPlanosUnidades,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  getDataGridModalUnidade() {
    this.unidadeService.pesquisarLotacaoUnidade().subscribe(
      res => {
        this.gridModalUnidadeData = res.map(val =>
          ({
            id: val.Id,
            codigo: val.Codigo,
            nome: val.Nome,
            dataCadastro: val.DataCadastro,
            isPlanoUnidade: this.lotacaoUnidades.filter(x => x.LotacaoUnidadeId == val.Id),
          })
        ).sort(this.utilService.dynamicSort('nome'));

        this.loadDataTableModalUnidade(this.gridModalUnidadeData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtNomeEmpresa').val('');
    $('#txtCodigoPlano').val('');
    $('#txtNomePlano').val('');
    this.empresaIdSelected = '';
    this.lotacaoUnidades = [];
  }

  getValuesFromInput() {
    return {
      Nome: $('#txtNomePlano').val().toString().trim(),
      Codigo: $('#txtCodigoPlano').val().toString().trim(),
      EmpresaId: this.empresaIdSelected,
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;

    if (idModal == 'modalUnidade') {
      this.getDataGridModalUnidade();
      ($("#formularioUnidade") as any).on("submit", function () {
        return false;
      });
      ($(`#${idModal}`) as any).modal('show');
    } else {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.planoIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
            this.clearInputvalues();
            this.getDataGrid();
            this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
            this.clearInputvalues();
            this.getDataGrid();
            this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  salvarPlanoUnidade() {
    if (this.lotacaoUnidades.length > 0) {
      this.lotacaoUnidades.forEach(val => {
        delete val.LotacaoUnidade
      });
      this.moduleService.adicionarPlanoUnidade(this.lotacaoUnidades).subscribe(
        res => {
          if (res.Codigo && res.Codigo == '600') {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: res.Descricao || 'Ocorreu um erro',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            this.utilService.alertSwal('add-success');
            this.lotacaoUnidades = [];
            this.closeModal('modalUnidade');
            this.getDataGrid();
          }
        },
        err => {
          this.utilService.alertSwal('add-error');
        }
      );
    } else {
      this.utilService.alertSwal('add-error-plano-unidade');
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "empresaNome"
        },
        {
          "data": "codigo"
        },
        {
          "data": "nome"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-gears" style="color:rgb(5, 90, 5); border: 0px; background-color: transparent;" title="Selecionar Unidade"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[4]).on("click", function (e) {
          self.openModal('modalUnidade', data);
          self.planoIdSelected = data.id;
          self.lotacaoUnidades = data.lotacaoUnidades;
        });
        $($(row).children()[5]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          $('#txtNomeEmpresa').val(data.empresaNome);
          $('#txtCodigoPlano').val(data.codigo);
          $('#txtNomePlano').val(data.nome);
          self.planoIdSelected = data.id;
          self.empresaIdSelected = data.empresaId;
        });
        $($(row).children()[6]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalUnidade(data) {

    let self = this;
    this.dataTableModalUnidade = $(this.gridModalUnidade.nativeElement);
    this.dataTableModalUnidade.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [

        {
          "data": '', "orderable": false, "render": function (data, type, row) {

            if (row.isPlanoUnidade.length > 0) {
              return '<input checked type="checkbox"/>';
            }
            return '<input type="checkbox"/>';
          }
        },
        {
          "data": "codigo"
        },
        {
          "data": "nome"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).find("input")).on("click", function (e) {
          const lotacaoUnidades = self.lotacaoUnidades.filter(val => val.LotacaoUnidadeId == data.id);
          if (lotacaoUnidades.length > 0) {
            self.lotacaoUnidades.splice(self.lotacaoUnidades.findIndex(val => val.LotacaoUnidadeId == data.id), 1);
          } else {
            self.lotacaoUnidades.push(
              {
                LotacaoPlanoId: self.planoIdSelected,
                LotacaoUnidadeId: data.id,
              }
            )
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $('.tableOperadora tbody').css('cursor', 'pointer');
        $($(row).children()).on("click", function (e) {
          self.empresaIdSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }

}
