import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoBeneficioService } from 'src/app/@core/@service/solicitacao/beneficio/beneficio.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import { ImportacaoFeriasService } from 'src/app/@core/@service/importacao/ferias/ferias.service';
import { SolicitacaoStatusEnum, UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

declare var $;

@Component({
  selector: 'app-programcao-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.scss']
})

export class ProgramacaoFeriasComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  operadoraVTSelected = ''
  operadoraVTList: Array<any> = [];
  linhaVTSelected = ''
  linhaVTList: Array<any> = [];
  utilizacaoSelected = ''
  utilizacaoList: Array<any> = [];
  situacaoSelected = ''
  situacaoList: Array<any> = [];

  dataTable: any;
  currUser: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoFeriasService,
  ) { }

  ngOnInit() {
    this.currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorSelected = this.currUser ? this.currUser.UsuarioColaboradorId : '';
    }
    this.getDataGrid();
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return 'Em Análise';
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return 'Aprovado';
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return 'Reprovado';
    } else {
      return '';
    }
  }

  mapGrid(data) {
    const arrData = [];
    data.Periodo.forEach(element => {
      if (element.Concessao.length > 0) {
        if (element.Concessao.length == 1) {
          arrData.push(
            {
              id: element.Id,
              colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
              matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
              empresa: data.Empresa ? data.Empresa.Nome : '-',
              inicioPeriodo: element.InicioPeriodo || '-',
              fimPeriodo: element.FimPeriodo || '-',
              situacao: element.Situacao || '-',
              diasDireito: element.DiasDireito || '-',
              diasConcedidos: element.DiasConcedidos || '-',
              diasProgramados: element.DiasProgramados || '-',
              inicioFerias: element.Concessao[0].InicioFerias || '-',
              diasGozo: element.Concessao[0].DiasGozo || '-',
              diasAbono: element.Concessao[0].DiasAbono || '-',
              diasDeLicenca: element.Concessao[0].DiasLicenca || '-',
              saldo: element.Saldo || '-',
              status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
              dataSolicitacao: element.DataSolicitacao,
              dataConclusao: element.DataConclusao,
              isConcessao: false,
              ...element
            });
        } else {
          element.Concessao.forEach((element2, index) => {
            if (index == 0) {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
                  matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
                  empresa: data.Empresa ? data.Empresa.Nome : '-',
                  inicioPeriodo: element.InicioPeriodo || '-',
                  fimPeriodo: element.FimPeriodo || '-',
                  situacao: element.Situacao || '-',
                  diasDireito: element.DiasDireito || '-',
                  diasConcedidos: element.DiasConcedidos || '-',
                  diasProgramados: element.DiasProgramados || '-',
                  inicioFerias: element.Concessao[index].InicioFerias || '-',
                  diasGozo: element.Concessao[index].DiasGozo || '-',
                  diasAbono: element.Concessao[index].DiasAbono || '-',
                  diasDeLicenca: element.Concessao[index].DiasLicenca || '-',
                  saldo: element.Saldo || '-',
                  status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
                  dataSolicitacao: element.DataSolicitacao,
                  dataConclusao: element.DataConclusao,
                  isConcessao: false,
                  ...element
                });
            } else {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: '',
                  matricula: '',
                  empresa: '',
                  inicioPeriodo: '',
                  fimPeriodo: '',
                  situacao: '',
                  diasDireito: '',
                  diasConcedidos: '',
                  diasProgramados: '',
                  inicioFerias: element2.InicioFerias || '-',
                  diasGozo: element2.DiasGozo || '-',
                  diasAbono: element2.DiasAbono || '-',
                  diasDeLicenca: element2.DiasLicenca || '-',
                  saldo: '',
                  status: '',
                  dataSolicitacao: '',
                  dataConclusao: '',
                  isConcessao: true,
                  ...element
                });
            }
          });
        }
      } else {
        arrData.push(
          {
            id: element.Id,
            colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
            matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
            empresa: data.Empresa ? data.Empresa.Nome : '-',
            inicioPeriodo: element.InicioPeriodo || '-',
            fimPeriodo: element.FimPeriodo || '-',
            situacao: element.Situacao || '-',
            diasDireito: element.DiasDireito || '-',
            diasConcedidos: element.DiasConcedidos || '-',
            diasProgramados: element.DiasProgramados || '-',
            inicioFerias: '-',
            diasGozo: '-',
            diasAbono: '-',
            diasDeLicenca: '-',
            saldo: element.Saldo || '-',
            status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
            dataSolicitacao: element.DataSolicitacao,
            dataConclusao: element.DataConclusao,
            isConcessao: false,
            ...element
          });
      }
    });
    return arrData;
  }

  getDataGrid() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.moduleService.pesquisarFeriasPorColaboradorId(this.colaboradorSelected).subscribe(
      res => {
        let grid = [];
        res.forEach(element => {
          grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
        });
        this.loadDataTable(grid);
      },
      err => {
        console.log(err);
      }
    );
    }else{
      this.moduleService.pesquisarFeriasPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
        res => {
          let grid = [];
          res.forEach(element => {
            grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
          });
          this.loadDataTable(grid);
        },
        err => {
          console.log(err);
        }
      );
    }
  }



  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "inicioPeriodo"
        },
        {
          "data": "fimPeriodo"
        },
        {
          "data": "situacao"
        },
        {
          "data": "diasDireito"
        },
        {
          "data": "diasConcedidos"
        },
        {
          "data": "diasProgramados"
        },
        {
          "data": "inicioFerias"
        },
        {
          "data": "diasAbono"
        },
        {
          "data": "diasGozo"
        },
        {
          "data": "diasDeLicenca"
        },
        {
          "data": "saldo"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": 'dataConclusao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": false,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
