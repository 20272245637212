<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Banco de Horas
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Banco de Horas</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" *ngIf="colaboradorType == 'gestor'; else rh">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="colaboradorList"
                    IdSelect="selColaborador" [Value]="colaboradorSelected">
                  </select-component>
                </div>
                <ng-template #rh>
                  <div class="form-group">
                    <label>Nome da Empresa</label>
                    <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                    <div class="input-group">
                      <input type="text" class="form-control" id="txtNomeEmpresa" name="txtNomeEmpresa"
                        placeholder="Nome da Empresa">
                      <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalEmpresa')"
                          title="Pesquisar Empresa"><i class="fa fa-search"></i></a></span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Matrícula do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>Horas Positivas</th>
                  <th>Horas Negativas</th>
                  <th>Saldo</th>
                  <th>Data da Importação</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalEmpresa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel"><b>Empresa</b></h4>
    </div>
    <div class="modal-body">
      <table #gridModalEmpresa style="width: 100% !important; cursor: pointer;" class="hover tableOperadora table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cnpj</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
    </div>
  </div>
</div>
</div>