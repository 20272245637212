import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoFeriasAvisoService } from 'src/app/@core/@service/importacao/ferias-aviso/ferias-aviso.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { UsuarioColaboradorTipoEnum, ColaboradorTipoEnum } from 'src/app/@core/@enum/enum';


declare var $;

@Component({
  selector: 'app-relatorio-ferias-aviso',
  templateUrl: './ferias-aviso.component.html',
  styleUrls: ['./ferias-aviso.component.scss']
})

export class RelatorioAvisoFeriasComponent implements OnInit {

  gridData = [];

  colaboradorType = '';

  colaboradorGridData = [];
  colaboradorSelected = '';

  modalRelatoriogridData: any = {};

  modalRelatorioData: any = {
    cpf: '',
    colaborador: '',
    empresa: '',
    ano: '',
    texto: '',
    localData: '',
  };

  colaboradorLogged: any;

  dataTable: any;

  userLogged: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoFeriasAvisoService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.validarCampos();
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO  || this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
        this.colaboradorType = 'rh';
      else
        this.colaboradorType = 'funcionario';
    }
    this.loadDataTable([]);
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe o ano"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalRelatorio') {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        res => {
          if (res) {
            this.modalRelatorioData = {
              cpf: res.CPF || '',
              colaborador: res.Nome || '',
              empresa: res.Empresa ? res.Empresa.Nome : '',
              ano: res.Ano || '',
              texto: res.Texto || '',
              localData: res.LocalData || '',
            };
            ($(`#${idModal}`) as any).modal('show');
          }

        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Ocorreu um erro ao buscar a os dados da importação!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  getDataGrid() {
    if (($("#formulario") as any).valid()) {
      if (this.colaboradorType == 'funcionario') {
        this.moduleService.pesquisar(this.colaboradorLogged, $('#txtAno').val()).subscribe(
          res => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map(val =>
                ({
                  id: val.Id,
                  colaborador: val.Nome || '',
                  matricula: val.Matricula || '',
                  empresa: val.Colaborador ? (val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '') : '',
                  ano: val.Ano || '',
                  dataImportacao: val.DataCadastro,
                  ...val
                })
              );
              this.loadDataTable(this.gridData);
            }
          },
          err => {
            console.log(err);
          }
        );
      } else {
        if (this.colaboradorSelected) {
          this.moduleService.pesquisar(this.colaboradorSelected, $('#txtAno').val()).subscribe(
            res => {
              if (res.length == 1 && res[0].Erro) {
                this.loadDataTable([]);
              } else {
                this.gridData = res.map(val =>
                  ({
                    id: val.Id,
                    colaborador: val.Nome || '',
                    matricula: val.Matricula || '',
                    empresa: val.Colaborador ? (val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '') : '',
                    ano: val.Ano || '',
                    dataImportacao: val.DataCadastro,
                    ...val
                  })
                );
                this.loadDataTable(this.gridData);
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      }
    }
  }

  pesquisar() {
    ($("#formulario") as any).valid();
    if (this.colaboradorType == 'rh' && this.colaboradorSelected) {
      this.getDataGrid();
    } else if (this.colaboradorType == 'funcionario') {
      this.getDataGrid();
    } else {
      $('#txtNomeColaborador').addClass('element-invalid');
    }
  }

  clearInputvalues(type = '') {
    this.colaboradorSelected = '';
    $('#txtAno').val('');
    $('#txtNomeColaborador').val('');
    $('#txtNomeColaborador').addClass('element-invalid');
    $('#txtNomeEmpresa').addClass('element-invalid');
    $('#selColaborador').addClass('element-invalid');
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "ano"
        },
        {
          "data": 'dataImportacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[5]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(data.DataCadastro).toLocaleDateString();
          self.openModal('modalRelatorio', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          $('#txtNomeColaborador').removeClass('element-invalid');
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
