import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/@core/@service/utils/loader-service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      setTimeout(() => {
        if (v) {
          $('app-dashboard').css("pointer-events", "none");
        } else {
          $('app-dashboard').css("pointer-events", "all");
        }
      }, 1000);

      this.loading = v;
    });

  }
  ngOnInit() {
  }

}
