import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoDivergenciaService } from 'src/app/@core/@service/importacao/divergencia/divergencia.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroJustificativaDeDivergenciaService } from "src/app/@core/@service/cadastro/justificativa-de-divergencia/justificativa-de-divergencia.service";
import { SolicitacaoStatusEnum } from 'src/app/@core/@enum/enum';



@Component({
  selector: 'app-aprovacao-divergencia',
  templateUrl: './divergencia.component.html',
  styleUrls: ['./divergencia.component.scss']
})
export class AprovacaoDivergenciaComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  justificativasDivergencias = [];

  modalDetalheGridData = [];

  colaboradorSelected = '';

  colaboradorList = [];

  detalheSelected: any;

  gestorId

  statusSelected = '';

  statusList: Array<any>;

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  @ViewChild('gridModalGestor', { static: true }) gridModalGestor: ElementRef;

  @ViewChild('gridModalDetalhe', { static: true }) gridModalDetalhe: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoDivergenciaService,
    private colaboradorService: CadastroColaboradorService,
    private justificativaDivergenciaService: CadastroJustificativaDeDivergenciaService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.listarTodasJustificativasDivergencias();
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selColaborador') {
      $('#selColaborador').removeClass('element-invalid');
      this.colaboradorSelected = event.target.value;
    }
  }

  aprovar(data, obj) {
    Swal.fire({
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        'aria-label': 'Informe o motivo da aprovação'
      },
      inputLabel: 'Motivo da Aprovação',
      inputPlaceholder: 'Informe o motivo da aprovação...',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.aprovar({ Id: data.id, MotivoAprovacao: result.value, JustificativaDivergenciaId: data.JustificativaDivergenciaId }).subscribe(
          res => {
            this.utilService.alertSwal('approve-success');
            this.closeModal("modalDetalhe");
            this.getDataGrid();
            setTimeout(() => {
              this.openModal("modalDetalhe", { Id: obj.id });
            }, 500);
          },
          err => {
            this.utilService.alertSwal('approve-error');
            this.closeModal("modalDetalhe");
            this.getDataGrid();
            setTimeout(() => {
              this.openModal("modalDetalhe", { Id: obj.id });
            }, 500);
          }
        );
      }
    });
  }


  getIntitialsData() {
    const userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (userLogged)
      this.gestorId = userLogged.UsuarioColaboradorId;

    this.loadDataTable([]);
    this.colaboradorService.buscarColaboradorPorGestorId(this.gestorId).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id || '',
            Description: val.Nome || '',
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
  }

  getValuesFromInput() {
    return {
      GestorId: this.gestorId,
      ColaboradorId: this.colaboradorSelected,
      SolicitacaoStatusId: this.statusSelected,
    }
  }

  getDataGrid() {
    this.moduleService.buscarDivergenciaPorColaboradorId(this.colaboradorSelected).subscribe(
      res => {
        if (res.length == 1 && res[0].Erro) {
          this.loadDataTable([]);
        } 
        this.gridData = res.map(val =>
          ({
            id: val.Id || '',
            colaborador: val.Colaborador ? val.Colaborador.Nome : '',
            matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '',
            empresa: val.Empresa ? val.Empresa.Nome : '',
            motivo: val.Motivo,
            data: val.DataCadastro,
            ...val,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {
    // Zera os campos
    this.colaboradorSelected = '';
    this.statusSelected = '';
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return 'Em Análise';
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return 'Aprovado';
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return 'Reprovado';
    } else {
      return '';
    }
  }

  getDetalhesModal(data, idModal = '', type = '') {
    this.moduleService.buscarDivergenciaNaoJustificada(data.Id).subscribe(
      res => {
        this.modalDetalheGridData = res.Detalhes.map(val =>
          ({
            diaSemana: val.DiaSemana || '-',
            dia: val.Dia ? new Date(val.Dia).toLocaleDateString('pt-BR') : '-',
            horario1: val.Horario1 || '-',
            horario2: val.Horario2 || '-',
            horario3: val.Horario3 || '-',
            horario4: val.Horario4 || '-',
            inicioJornada: val.InicioJornada || '-',
            terminoJornada: val.FimJornada || '-',
            diferencaHoras: val.DiferencaHoras || '-',
            descricaoDivergencia: val.Descricao || '-',
            status: this.getStatusDescricao(val.SolicitacaoStatusId),
            dataSolicitacao: res.DataCadastro,
            dataConclusao: val.DataConclusao,
            ...val
          })
        );
        if(this.modalDetalheGridData.length) {
          if (type != 'aprovar')
            ($(`#${idModal}`) as any).modal('show');
          this.loadDataTableModalDetalhe(this.modalDetalheGridData);
        }
      },
      err => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Ocorreu um erro ao buscar a os dados da importação!',
          showConfirmButton: false,
          timer: 2000
        });
        console.log(err);
      }
    );
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalDetalhe') {
      this.getDetalhesModal(data, idModal);
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  pesquisar() {
      this.getDataGrid();
  }

  listarTodasJustificativasDivergencias(){
    this.justificativaDivergenciaService.trazerTodas()
      .subscribe(
        (res) => {
        {
          this.justificativasDivergencias = res.map((val) => ({
            id: val.Id,
            tipo: val.Tipo ? val.Tipo : "",
            ...val,
          }));
        }
      },
      (err) => {
        console.log(err);
      });
  }

  getCamposJustificativa(id){
    let obj = {
      id: id,
      JustificativaDivergenciaId: $("#selectDivergencias_" + id).val(),
    };
    return obj;
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "motivo"
        },
        {
          "data": 'data', "orderable": false, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Ver Detalhes"> </button></div>';

          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[5]).on("click", function (e) {
          self.detalheSelected = data;
          self.openModal('modalDetalhe', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalDetalhe(data) {
    let self = this;
    let optionsJustificativas: string;
    this.justificativasDivergencias.forEach(element => {
      optionsJustificativas += "<option value=" + element.id.toString() + ">"+ element.tipo.toString() +"</option>" 
    });
    
    this.dataTable = $(this.gridModalDetalhe.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "diaSemana"
        },
        {
          "data": "dia"
        },
        {
          "data": "horario1"
        },
        {
          "data": "horario2"
        },
        {
          "data": "horario3"
        },
        {
          "data": "horario4"
        },
        {
          "data": "inicioJornada"
        },
        {
          "data": "terminoJornada"
        },
        {
          "data": "diferencaHoras"
        },
        {
          "data": "descricaoDivergencia"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": 'dataConclusao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><select id="selectDivergencias_' + row.Id + '" class="form-control select2"><option value="0">---</option>' + optionsJustificativas + '</select></div>';
          },
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.status && row.status.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[14]).on("click", function (e) {
          if (data.status && data.status.toString().toLowerCase().includes('análise')) {
            $("#selectDivergencias_" + data.Id).removeAttr("style");
            
            let valid = true;
            let justificativaModel = self.getCamposJustificativa(data.Id);
  
            if(justificativaModel.JustificativaDivergenciaId === '0'){
              valid = false;
              $("#selectDivergencias_" + data.Id).css("border-color", "red");
            }
            var obj = {
                id: data.DivergenciaId
            }
            self.aprovar(justificativaModel, obj);
          }
        });
      },
    })
  }
}
