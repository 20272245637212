import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroUsuarioService } from 'src/app/@core/@service/cadastro/usuario/usuario.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import Swal from 'sweetalert2';

declare var $;

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  gridData = [];
  empresasList: Array<object> = [];
  empresasSelected = [];
  dropdownSettings = {};

  isEdit = false;

  sindicatoIdSelected = '';

  estadoSelected = '';
  tipoUsuarioSelected = '';

  cidadeSelected = '';


  cidadesList: Array<object> = [];
  estadosList: Array<object> = [];
  tipoUsuarioList: Array<object> = [];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroUsuarioService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Limpar todos',
      searchPlaceholderText: 'Pesquisar',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.empresasSelected);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectEstados') {
      this.cidadeSelected = '';
      this.estadoSelected = event.target.value;
      this.cidadesList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidades') {
      this.cidadeSelected = event.target.value;
    } else if (event.srcElement.id == 'selectTipoUsuario') {
      this.tipoUsuarioSelected = event.target.value;
    }
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtCPF: {
          required: true, minlength: 14
        },
        txtEmail: {
          required: true, email: true
        },
        txtTelRes: {
          minlength: 14
        },
        txtTelCom: {
          minlength: 14
        },
        txtTelCel: {
          minlength: 14
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtCPF: {
          required: "Informe o Cpf",
          minlength: "Informe o Cpf corretamente",
          cpfCheck: true
        },
        txtEmail: {
          required: "Informe o Email",
          email: "Informme o Email corretamente"
        },
        txtTelRes: {
          minlength: "Informe o Telefone Residencial corretamente"
        },
        txtTelCom: {
          minlength: "Informe o Telefone Comercial corretamente"
        },
        txtTelCel: {
          minlength: "Informe o Telefone Celular corretamente"
        }
      },
    });
    $.validator.addMethod("cpfCheck", function (value, element) {
      return UtilsService.verificarCPF(value);
    }, 'Cpf inválido');
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  async getSelectData() {
    this.estadosList = await this.utilService.getEstados();
    this.cidadesList = [];
    this.tipoUsuarioList = this.utilService.getTipoUsuario();
    this.empresasList = await this.getEmpresas();
  }

  getEmpresas(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisar().subscribe(
      res => {
          this.empresasList = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  getEmpresasByUsuarioId(usuarioId): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisarPorUsuarioId(usuarioId).subscribe(
      res => {
        this.empresasSelected = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            bairro: val.Bairro,
            telRes: val.TelefoneResidencial,
            cpf: val.CPF,
            cep: val.CEP,
            telCel: val.TelefoneCelular,
            endereco: val.Endereco,
            enderecoComplemento: val.Complemento,
            estadoId: val.UF,
            cidadeId: val.Cidade,
            estado: this.utilService.getEstadoDescription(val.UF),
            cidade: val.Cidade,
            telCom: val.TelefoneComercial,
            email: val.Email,
            dataCadastro: val.dataCadastro,
            senha: val.Senha,
            admin: val.Admin
        })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    $('#txtNome').val('');
    $('#txtBairro').val('');
    $('#txtTelRes').val('');
    $('#txtCPF').val('');
    $('#txtCep').val('');
    $('#txtTelCel').val('');
    $('#txtEnd').val('');
    this.estadoSelected = '';
    $('#txtTelCom').val('');
    $('#txtEndComp').val('');
    this.cidadeSelected = '';
    $('#txtEmail').val('');
    this.tipoUsuarioSelected = '';
    this.empresasSelected = [];
  }

  getValuesFromInput() {
    return {
      Nome: $('#txtNome').val().toString().trim(),
      CPF: $('#txtCPF').val().toString().trim(),
      Endereco: $('#txtEnd').val().toString().trim(),
      Complemento: $('#txtEndComp').val().toString().trim(),
      Bairro: $('#txtBairro').val().toString().trim(),
      CEP: $('#txtCep').val().toString().trim(),
      UF: this.estadoSelected,
      Cidade: this.cidadeSelected,
      TelefoneResidencial: $('#txtTelRes').val().toString().trim(),
      TelefoneCelular: $('#txtTelCel').val().toString().trim(),
      TelefoneComercial: $('#txtTelCom').val().toString().trim(),
      Email: $('#txtEmail').val().toString().trim(),
      Admin: this.tipoUsuarioSelected,
      EmpresasSelected: this.empresasSelected.map(val => val.item_id),
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.sindicatoIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
        {
          "data": "endereco"
        },
        {
          "data": "enderecoComplemento"
        },
        {
          "data": "bairro"
        },
        {
          "data": "cep"
        },
        {
          "data": "estadoId"
        },
        {
          "data": "cidade"
        },
        {
          "data": "telRes"
        },
        {
          "data": 'telCel', "orderable": false, "render": function (data, type, row) {
            return data ? data : '<div style="text-align: center">-</div>';
          }
        },
        {
          "data": "telCom"
        },
        {
          "data": "email"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "senha"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[14]).on("click", async function (e) {
          // Seta modo edição
          self.isEdit = true;
          // Preencher campos, com a linha selecionada
          $('#txtNome').val(data.nome);
          $('#txtBairro').val(data.bairro);
          $('#txtTelRes').val(data.telRes);
          $('#txtCPF').val(data.cpf);
          $('#txtCep').val(data.cep);
          $('#txtTelCel').val(data.telCel);
          $('#txtEnd').val(data.endereco);
          self.estadoSelected = data.estadoId;
          $('#txtTelCom').val(data.telCom);
          $('#txtEndComp').val(data.enderecoComplemento);
          self.cidadesList = await self.utilService.getCidades(data.estadoId);
          self.cidadeSelected = data.cidadeId;
          $('#txtEmail').val(data.email);

          self.sindicatoIdSelected = data.id;
          self.tipoUsuarioSelected = data.admin;
          console.log(data.id);
          self.getEmpresasByUsuarioId(data.id);
        });
        $($(row).children()[15]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
