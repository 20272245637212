import { Injectable, EventEmitter } from '@angular/core';
import { ComponentsService } from './components.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { retry, catchError } from 'rxjs/operators';
import { cidades } from 'src/app/@core/@service/utils/cidades';
import { estados } from 'src/app/@core/@service/utils/estados';
import { environment } from 'src/environments/environment';
import { PerfilComponent } from 'src/app/dashboard/body/perfil/perfil.component';
import { Router } from '@angular/router';

@Injectable()
export class UtilsService {

    constructor(
        private componentsService: ComponentsService,
        private httpClient: HttpClient,
        private router: Router,
    ) { }


    static verificarCPF(cpf: string) {
        cpf = cpf.replace(/\D/g, '');
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function (j) {
            var soma = 0, r;
            cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                soma += parseInt(e) * ((j + 2) - (i + 1));
            });
            r = soma % 11;
            r = (r < 2) ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;
        });
        return result;
    }

    getMesList() {
        return [
            {
                Id: '1',
                Description: 'Janeiro'
            },
            {
                Id: '2',
                Description: 'Fevereiro'
            },
            {
                Id: '3',
                Description: 'Março'
            },
            {
                Id: '4',
                Description: 'Abril'
            },
            {
                Id: '5',
                Description: 'Maio'
            },
            {
                Id: '6',
                Description: 'Junho'
            },
            {
                Id: '7',
                Description: 'Julho'
            },
            {
                Id: '8',
                Description: 'Agosto'
            },
            {
                Id: '9',
                Description: 'Setembro'
            },
            {
                Id: '10',
                Description: 'Outubro'
            },
            {
                Id: '11',
                Description: 'Novembro'
            },
            {
                Id: '12',
                Description: 'Dezembro'
            },
            {
              Id: '13º',
              Description: 'Décimo Terceiro'
          },
      ];
    }
    getTipoList() {
      return [
          {
              Id: 'Mensal',
              Description: 'Mensal'
          },
          {
              Id: 'Adiantamento',
              Description: 'Adiantamento'
          }
      ];
  }
  getEtiniaList() {
    return [
        {
            Id: 'Branca',
            Description: 'Branca'
        },
        {
            Id: 'Preta',
            Description: 'Preta'
        },
        {
            Id: 'Parda',
            Description: 'Parda'
        },
        {
            Id: 'Amarela',
            Description: 'Amarela'
        },
        {
            Id: 'Indígena',
            Description: 'Indígena'
        }
    ];
}
  getTipoImportacaoList() {
    return [
        {
            Id: 'Falta',
            Description: 'Faltas'
        },
        {
            Id: 'Ocorrencia',
            Description: 'Ocorrências'
        },
        {
            Id: 'DataSul',
            Description: 'Data Sul'
        }
    ];
}
  getFerramentaList() {
    return [
        {
            Id: 'DataSul',
            Description: 'Data Sul'
        },
        {
            Id: 'PontoMais',
            Description: 'Ponto Mais'
        }
    ];
}
  getTipoUsuario() {
    return [
        {
            Id: 'false',
            Description: 'User'
        },
        {
            Id: 'true',
            Description: 'Admin'
        }
    ];
}
  verificarHora(hora) {
        if (!hora.includes(':')) {
            return false;
        }
        const horaFormatted = hora.split(':');
        if (horaFormatted[1].length < 2) {
            return false;
        }
        if (horaFormatted[0] > 24) {
            return false;
        }
        if (horaFormatted[1] > 59) {
            return false;
        }
        return true;
    }

    static verificarCNPJ(cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return false;

        if (cnpj.length != 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;

    }

    buscarEstados(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}UF/BuscarTudoUF`)
            .pipe(
                retry(1),
                catchError(this.handleError));
    }

    buscarCidadesPorUF(uf = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}Cidade/BuscarCidadePorUF?uf=${uf}`)
            .pipe(
                retry(1),
                catchError(this.handleError));
    }

    async getEstados(): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
            this.buscarEstados().subscribe(
                res => {
                    resolve(res.map(val => ({ Id: val.Sigla, Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() })));
                },
                err => {
                    console.log(err);
                    reject([]);
                }
            );
        })

    }

    getEstadoDescription(uf = '') {
        const state = estados.filter(val => val.uf == uf)[0];
        return state ? state.nome : '';
    }

    async getCidades(uf = ''): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
            this.buscarCidadesPorUF(uf).subscribe(
                res => {
                    resolve(res.map(val => ({ Id: val.Nome, Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() })));
                },
                err => {
                    console.log(err);
                    resolve([]);
                }
            );
        })
    }

    findInMenuStorage(idMenu) {
        const menu = JSON.parse(sessionStorage.getItem('menu'));
        if (menu) {
           const filtered = menu.filter(val => val.Id == idMenu);
           if(filtered.length > 0) {
               return true;
           }
        }
        return false;
    };

    getComponente(pai, description, isMenu = false) {
        if (isMenu) {
            const comp = this.componentsService.componentes[pai].filter(val => val.id == description);
            const retorno = comp ? comp[0].componente : false;
            if (retorno) {
                const url = location.href.split("/")[0] + "//" + location.href.split("/")[2]
                history.pushState({
                    id: ''
                }, '', `${url}/${pai.charAt(0).toUpperCase() + pai.slice(1)}/${comp[0].prop.charAt(0).toUpperCase() + comp[0].prop.slice(1)}`);
            }
            return retorno;
        } else {
            if (description) {
                if (this.componentsService.componentes.hasOwnProperty(pai) && this.componentsService.componentes[pai].filter(val => val.prop == description).length > 0) {
                    const comp = this.componentsService.componentes[pai].filter(val => val.prop == description);
                    if (comp.length > 0) {
                        if(this.findInMenuStorage(comp[0].id)) {
                            return comp ? comp[0].componente : false;
                        }
                    }

                }
            } else {
                if (this.componentsService.componentes.hasOwnProperty(pai) && typeof this.componentsService.componentes[pai].componente == 'function') {
                    const comp = this.componentsService.componentes[pai];
                    return comp ? comp.componente : false;
                }
            }
        }
        this.router.navigate(['Perfil']);
        return PerfilComponent;


    }

    numberToMaskMoney(valor) {
        return "R$ " + valor.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    // Manipulação de erros
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };

    getDatatableTranslate() {
        return {
            "emptyTable": "Nenhum registro encontrado",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 até 0 de 0 registros",
            "infoFiltered": "(Filtrados de _MAX_ registros)",
            "infoThousands": ".",
            "loadingRecords": "Carregando...",
            "processing": "Processando...",
            "zeroRecords": "Nenhum registro encontrado",
            "search": "Pesquisar",
            "paginate": {
                "next": "Próximo",
                "previous": "Anterior",
                "first": "Primeiro",
                "last": "Último"
            },
            "aria": {
                "sortAscending": ": Ordenar colunas de forma ascendente",
                "sortDescending": ": Ordenar colunas de forma descendente"
            },
            "select": {
                "rows": {
                    "_": "Selecionado %d linhas",
                    "0": "Nenhuma linha selecionada",
                    "1": "Selecionado 1 linha"
                },
                "1": "%d linha selecionada",
                "_": "%d linhas selecionadas",
                "cells": {
                    "1": "1 célula selecionada",
                    "_": "%d células selecionadas"
                },
                "columns": {
                    "1": "1 coluna selecionada",
                    "_": "%d colunas selecionadas"
                }
            },
            "buttons": {
                "copySuccess": {
                    "1": "Uma linha copiada com sucesso",
                    "_": "%d linhas copiadas com sucesso"
                },
                "collection": "Coleção  <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
                "colvis": "Visibilidade da Coluna",
                "colvisRestore": "Restaurar Visibilidade",
                "copy": "Copiar",
                "copyKeys": "Pressione ctrl ou u2318 + C para copiar os dados da tabela para a área de transferência do sistema. Para cancelar, clique nesta mensagem ou pressione Esc..",
                "copyTitle": "Copiar para a Área de Transferência",
                "csv": "CSV",
                "excel": "Excel",
                "pageLength": {
                    "-1": "Mostrar todos os registros",
                    "1": "Mostrar 1 registro",
                    "_": "Mostrar %d registros"
                },
                "pdf": "PDF",
                "print": "Imprimir"
            },
            "autoFill": {
                "cancel": "Cancelar",
                "fill": "Preencher todas as células com",
                "fillHorizontal": "Preencher células horizontalmente",
                "fillVertical": "Preencher células verticalmente"
            },
            "lengthMenu": "Exibir _MENU_ resultados por página",
            "searchBuilder": {
                "add": "Adicionar Condição",
                "button": {
                    "0": "Construtor de Pesquisa",
                    "_": "Construtor de Pesquisa (%d)"
                },
                "clearAll": "Limpar Tudo",
                "condition": "Condição",
                "conditions": {
                    "date": {
                        "after": "Depois",
                        "before": "Antes",
                        "between": "Entre",
                        "empty": "Vazio",
                        "equals": "Igual",
                        "not": "Não",
                        "notBetween": "Não Entre",
                        "notEmpty": "Não Vazio"
                    },
                    "moment": {
                        "after": "Depois",
                        "before": "Antes",
                        "between": "Entre",
                        "empty": "Vazio",
                        "equals": "Igual",
                        "not": "Não",
                        "notBetween": "Não Entre",
                        "notEmpty": "Não Vazio"
                    },
                    "number": {
                        "between": "Entre",
                        "empty": "Vazio",
                        "equals": "Igual",
                        "gt": "Maior Que",
                        "gte": "Maior ou Igual a",
                        "lt": "Menor Que",
                        "lte": "Menor ou Igual a",
                        "not": "Não",
                        "notBetween": "Não Entre",
                        "notEmpty": "Não Vazio"
                    },
                    "string": {
                        "contains": "Contém",
                        "empty": "Vazio",
                        "endsWith": "Termina Com",
                        "equals": "Igual",
                        "not": "Não",
                        "notEmpty": "Não Vazio",
                        "startsWith": "Começa Com"
                    }
                },
                "data": "Data",
                "deleteTitle": "Excluir regra de filtragem",
                "logicAnd": "E",
                "logicOr": "Ou",
                "title": {
                    "0": "Construtor de Pesquisa",
                    "_": "Construtor de Pesquisa (%d)"
                },
                "value": "Valor"
            },
            "searchPanes": {
                "clearMessage": "Limpar Tudo",
                "collapse": {
                    "0": "Painéis de Pesquisa",
                    "_": "Painéis de Pesquisa (%d)"
                },
                "count": "{total}",
                "countFiltered": "{shown} ({total})",
                "emptyPanes": "Nenhum Painel de Pesquisa",
                "loadMessage": "Carregando Painéis de Pesquisa...",
                "title": "Filtros Ativos"
            },
            "searchPlaceholder": "Pesquise por algo",
            "thousands": "."
        };

    }

    dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a, b) => {
            const result = (
                a[property] ? a[property].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
                    < b[property] ? b[property].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
            ) ? -1 : (
                a[property] ? a[property].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
                    > b[property] ? b[property].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
            )
                    ? 1 : 0;
            return result * sortOrder;
        };
    }

    maskMoneyToNumber(valor) {
        return parseFloat(valor.replace(/[^0-9,]/g, '').replace(',', '.'));
    }

    alertSwal(type) {
        if (type == 'remove-success') {
            Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Registro removido!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'reprove-success') {
            Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Solicitação reprovada!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'remove-error') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao reprovar registro!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'reprove-error') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao reprovar Solicitação!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'edit-success') {
            Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Registro alterado!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'approve-success') {
            Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Solicitação aprovada!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'approve-error') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao aprovar solicitação!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'edit-error') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao alterar registro!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'add-success') {
            Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Registro adicionado!',
                showConfirmButton: false,
                timer: 2000
            });

        } else if (type == 'add-error') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao adicionar registro!',
                showConfirmButton: false,
                timer: 2000
            });
        } else if (type == 'add-error-plano-unidade') {
            Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Necessário escolher pelo menos uma unidade!',
                showConfirmButton: false,
                timer: 2000
            });
        }
    }
}
