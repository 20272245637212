import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoBancoDeHorasService } from 'src/app/@core/@service/importacao/banco-de-horas/banco-de-horas.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { UsuarioColaboradorTipoEnum, ColaboradorTipoEnum } from 'src/app/@core/@enum/enum';


declare var $;

@Component({
  selector: 'app-relatorio-banco-de-horas',
  templateUrl: './banco-de-horas.component.html',
  styleUrls: ['./banco-de-horas.component.scss']
})

export class RelatorioBancoDeHorasComponent implements OnInit {

  gridData = [];

  colaboradorType = '';

  empresaGridData = [];
  empresaSelected = '';
  modalRelatoriogridData: any = {};

  colaboradorList: any;
  colaboradorSelected = '';

  modalRelatorioData: any = {
    colaborador: '',
    matricula: '',
    empresa: '',
  };

  colaboradorLogged: any;

  dataTable: any;

  userLogged: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoBancoDeHorasService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO  || this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
        this.colaboradorType = 'rh';
      else if (this.userLogged.ColaboradorTipo.Id == ColaboradorTipoEnum.GESTOR) {
        this.getColaboradorList();
        this.colaboradorType = 'gestor';
      }
    }
    this.loadDataTable([]);
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selColaborador') {
      this.colaboradorSelected = event.target.value;
      $('#selColaborador').removeClass('element-invalid');
    }
  }

  getColaboradorList() {
    this.colaboradorService.buscarColaboradorPorGestorId(this.colaboradorLogged).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
  }


  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  getDataGrid() {
    if (this.colaboradorType == 'gestor') {
      if (this.colaboradorSelected) {
        this.moduleService.pesquisarPorColaboradorId(this.colaboradorSelected).subscribe(
          res => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map(val =>
                ({
                  id: val.Id,
                  colaborador: val.Colaborador ? val.Colaborador.Nome : '',
                  matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '',
                  empresa: val.Colaborador ? (val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '') : '',
                  horasPositivas: val.HorasPositivas || '',
                  horasNegativas: val.HorasNegativas || '',
                  saldo: val.HorasSaldo || '',
                  dataConclusao: val.DataCadastro,
                  ...val
                })
              );
              this.loadDataTable(this.gridData);
            }
          },
          err => {
            console.log(err);
          }
        );
      } else {
        this.moduleService.pesquisarPorGestorId(this.colaboradorLogged).subscribe(
          res => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map(val =>
                ({
                  id: val.Id,
                  colaborador: val.Colaborador ? val.Colaborador.Nome : '',
                  matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '',
                  empresa: val.Empresa ? val.Empresa.Nome : '',
                  horasPositivas: val.HorasPositivas || '',
                  horasNegativas: val.HorasNegativas || '',
                  saldo: val.HorasSaldo || '',
                  dataImportacao: val.DataImportacao,
                  ...val
                })
              );
              this.loadDataTable(this.gridData);
            }
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (this.colaboradorType == 'rh') {
      this.moduleService.pesquisarPorEmpresaId(this.empresaSelected).subscribe(
        res => {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            this.gridData = res.map(val =>
              ({
                id: val.Id,
                colaborador: val.Colaborador ? val.Colaborador.Nome : '',
                matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '',
                empresa: val.Empresa ? val.Empresa.Nome : '',
                horasPositivas: val.HorasPositivas || '',
                horasNegativas: val.HorasNegativas || '',
                saldo: val.HorasSaldo || '',
                dataImportacao: val.DataImportacao,
                ...val
              })
            );
            this.loadDataTable(this.gridData);
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  pesquisar() {
    if (this.colaboradorType == 'rh' && this.empresaSelected) {
      this.getDataGrid();
    } else if (this.colaboradorType == 'gestor') {
      this.getDataGrid();
    } else {
      $('#txtNomeColaborador').addClass('element-invalid');
      $('#selColaborador').addClass('element-invalid');
      $('#txtNomeEmpresa').addClass('element-invalid');
    }
  }

  clearInputvalues(type = '') {
    this.colaboradorSelected = '';
    this.empresaSelected = '';
    $('#txtDataInicial').val('');
    $('#txtDataFinal').val('');
    $('#txtNomeColaborador').val('');
    $('#txtNomeEmpresa').val('');
    $('#txtNomeColaborador').addClass('element-invalid');
    $('#txtNomeEmpresa').addClass('element-invalid');
    $('#selColaborador').addClass('element-invalid');
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "horasPositivas"
        },
        {
          "data": "horasNegativas"
        },
        {
          "data": "saldo"
        },
        {
          "data": 'dataImportacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.empresaSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }
}
