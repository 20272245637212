import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoAbsenteismoService } from 'src/app/@core/@service/importacao/absenteismo/absenteismo.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { UsuarioColaboradorTipoEnum, ColaboradorTipoEnum } from 'src/app/@core/@enum/enum';
import { Router } from '@angular/router';


declare var $;

@Component({
  selector: 'app-relatorio-absenteismo',
  templateUrl: './absenteismo.component.html',
  styleUrls: ['./absenteismo.component.scss']
})

export class RelatorioAbsenteismoComponent implements OnInit {

  gridData = [];

  colaboradorType = '';

  mesSelected = '';
  mesList = [];

  modalRelatorioGridData: any = {};

  colaboradorLogged: any;

  dataTable: any;

  userLogged: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoAbsenteismoService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.validarCampos();
    this.mesList = this.utilService.getMesList();
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO || this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
        this.colaboradorType = 'rh';
      else if (this.userLogged.ColaboradorTipo.Id == ColaboradorTipoEnum.GESTOR)
        this.colaboradorType = 'gestor';
      else
        location.href = location.origin + '/Perfil';
    }
    this.loadDataTable([]);
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe o ano"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;
      $('#selMes').removeClass('element-invalid');
    }
  }

  getDataGrid() {
    if (this.colaboradorType == 'gestor') {
      this.moduleService.pesquisarporGestorIdAnoMes(this.colaboradorLogged, this.mesSelected, $('#txtAno').val()).subscribe(
        res => {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            this.gridData = res.map(val =>
              ({
                id: val.Id,
                colaborador: val.Nome || '',
                cpf: val.Cpf || '',
                horasTotais: val.HorasTotais || '',
                horasNaoTrabalhadas: val.HorasNaoTrabalhadas || '',
                absenteismo: val.Absenteismo || '',
                mes: val.Mes || '',
                ano: val.Ano || '',
                dataImportacao: val.DataCadastro,
                ...val
              })
            );
            this.loadDataTable(this.gridData);
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.moduleService.pesquisarporAnoMes(this.mesSelected, $('#txtAno').val()).subscribe(
        res => {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            this.gridData = res.map(val =>
              ({
                id: val.Id,
                colaborador: val.Nome || '',
                cpf: val.Cpf || '',
                horasTotais: val.HorasTotais || '',
                horasNaoTrabalhadas: val.HorasNaoTrabalhadas || '',
                absenteismo: val.Absenteismo || '',
                mes: val.Mes || '',
                ano: val.Ano || '',
                dataImportacao: val.DataCadastro,
                ...val
              })
            );
            this.loadDataTable(this.gridData);
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  pesquisar() {
    if (($("#formulario") as any).valid()) {
      if (!this.mesSelected) {
        $('#selMes').addClass('element-invalid');
      } else {
        this.getDataGrid();
      }
    }
  }

  clearInputvalues(type = '') {
    this.mesSelected = '';
    $('#txtAno').val('');
    $('#selMes').addClass('element-invalid');
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "cpf"
        },
        {
          "data": "horasTotais"
        },
        {
          "data": "horasNaoTrabalhadas"
        },
        {
          "data": "absenteismo"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": 'dataImportacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
