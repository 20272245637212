import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../../utils/utils';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClickOutsideDirective } from 'ng-multiselect-dropdown/click-outside.directive';

@Injectable()
export class CadastroColaboradorDadosPrincipaisService {

    routePrefix: string = 'ColaboradorDadoPrincipal'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data: any = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}Colaborador/BuscarDadoPrincipalColaborador`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    pesquisarPorUsuarioId(data: any = {}, usuarioId): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}Colaborador/BuscarDadoPrincipalColaboradorPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    pesquisarPreAdmissao(data: any = {}, isPreAdmissao = false): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}Colaborador/BuscarDadoPrincipalColaboradorPreAdmissao`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPreAdmissaoPorUsuarioId(data: any = {},usuarioId: number, isPreAdmissao = false): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}Colaborador/BuscarDadoPrincipalColaboradorPreAdmissaoPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    adicionar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/AdicionarDadoPrincipalColaborador`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    adicionarPreAdmissao(data = {},usuarioId: number): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/AdicionarDadoPrincipalColaboradorPreAdmissao?usuarioRequisitanteId=${usuarioId}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovarColaboradorPreAdmissao(data): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/aprovarColaboradorPreAdmissao?colaboradorId=${data.id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {}, concluir?: boolean, usuarioId?: number): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/EditarDadoPrincipalColaborador?concluir=${concluir}&usuarioRequisitanteId=${usuarioId}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    remover(data = {}): Observable<any> {
        console.log(data);
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/ExcluirDadoPrincipalColaborador`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    adicionarPlanoUnidade(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Colaborador/Adicionar${this.routePrefix}Unidade`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}
