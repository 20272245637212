<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Desligamento
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Aprovação</li>
      <li class="active">Desligamento</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Nome do Gestor</label>
                <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                <div class="input-group">
                  <input type="text" class="form-control" id="txtNomeGestor" name="txtNomeGestor"
                    placeholder="Nome do Gestor">
                  <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalGestor')"
                      title="Pesquisar gestor"><i class="fa fa-search"></i></a></span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Nome do Colaborador</label>
                <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                <div class="input-group">
                  <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                    placeholder="Nome do Colaborador">
                  <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                      title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Status</label>
                <!-- BUSCAR OS DADOS DA TABELA DESLIGAMENTO TIPO -->
                <select-component (changeValue)="changeSelectValues($event)" [Data]="statusList" IdSelect="selStatus"
                  [Value]="statusSelected">
                </select-component>
              </div>
            </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
              </td>
            </tr>
          </table>
        </div>

      </form>
    </div>

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 15%">Nome do Gestor</th>
                <th style="width: 15%">Nome do Colaborador</th>
                <th style="width: 15%">Data Sugerida</th>
                <th style="width: 15%">Tipo</th>
                <th style="width: 15%">Motivo</th>
                <th style="width: 15%">Substituir?</th>
                <th style="width: 15%">Recontrataria?</th>
                <th style="width: 15%">Status</th>
                <th style="width: 15%">Data da Solicitação</th>
                <th style="width: 15%">Data da Conclusão</th>
                <th style="width: 15%">Data do Desligamento</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>
  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalGestor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Gestor</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalGestor style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CONFIRMAÇÃO -->
<div class="modal fade" id="modalConfirmacao" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Confirmação de Aprovação</b></h4>
      </div>
      <div class="modal-header">
        <label>Data do desligamento</label>
        <div class="input-group date">
          <input mask="00/00/0000" placeholder="dd/mm/aaaa" type="text" class="form-control pull-right"
            id="txtDataDesligamentoModal" name="txtDataDesligamentoModal">
          <div class="input-group-addon">
            <i class="fa fa-calendar"></i>
          </div>
        </div>
      </div>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Você tem certeza?</h5>
      </div>
      <table border=0 align="center" width="90%">
        <tr style="height: 15px;">
          <td></td>
        </tr>
        <tr width="100%">
          <td align="center">
            <button type="button" class="btn btn-primary" (click)="aprovar()">Sim</button>
            <button style="margin-left: 10px;" data-dismiss="modal" type="button" (click)="closeModal('modalConfirmacao')" class="btn btn-danger">Não</button>
          </td>
        </tr>
        <tr style="height: 15px;">
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</div>