import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoAbsenteismoService } from 'src/app/@core/@service/importacao/absenteismo/absenteismo.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-importacao-absenteismo',
  templateUrl: './absenteismo.component.html',
  styleUrls: ['./absenteismo.component.scss']
})

export class ImportacaoAbsenteismoComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  arquivoAnexoSelected = '';

  mesSelected = '';
  mesList = [];

  typeDocAccepted = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoAbsenteismoService,
    private router: Router,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.mesList = this.utilService.getMesList();
    this.validarCampos();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;
      $('#selMes').removeClass('element-invalid');
    }
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe a Data"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }


  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      if (this.typeDocAccepted.includes(file.type)) {
        const base64partes = event.target.result.split('base64,');
        this.arquivoAnexoSelected = base64partes.length == 2 ? base64partes[1] : '';
        $('#txtArquivo').val(file.name);
        $('#txtArquivo').removeClass('element-invalid');
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();
    if (type == 'importar') {
      $('#selMes').removeClass('element-invalid');
      $('#txtArquivo').removeClass('element-invalid');
    }
    this.arquivoAnexoSelected = '';
    this.mesSelected = '';
    $('#txtAno').val('');
    $('#txtArquivo').val('');
    $('#tableResult').css("visibility", "hidden");
    this.router.navigate(['Importacao/Absenteismo']);
  }

  getValuesFromInput() {
    return {
      mes: this.mesSelected,
      ano: $('#txtAno').val().toString(),
      excelBase64: this.arquivoAnexoSelected,
    }
  }

  importar() {
    if (!this.arquivoAnexoSelected || !this.mesSelected) {
      ($("#formulario") as any).valid()
      if (!this.mesSelected) {
        $('#selMes').addClass('element-invalid');
      }
      if (!this.arquivoAnexoSelected) {
        $('#txtArquivo').addClass('element-invalid');
      }
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.importar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              const inputBkp = {
                mes: this.mesSelected || '',
                ano: $('#txtAno').val().toString() || ''
              };
              this.clearInputvalues('importar');
              if (res.length > 0) {
                this.gridData = res.map(val =>
                  ({
                    id: val.Id,
                    cpf: val.Cpf || '',
                    mes: inputBkp.mes,
                    ano: inputBkp.ano,
                    erro: val.LogErro || '',
                    dataConclusao: val.DataCadastro,
                    ...val
                  })
                );
                this.loadDataTable(this.gridData);
                $('#tableResult').css("visibility", "unset");
              } else {
                this.loadDataTable([]);
                $('#tableResult').css("visibility", "hidden");
              }
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Arquivo importado!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          },
          err => {
            console.log(err);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Erro ao importar arquivo!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "cpf"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": 'erro', "orderable": false, "render": function (data, type, row) {
            return `<div style="color: red;">${data}</div>`;
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
