import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MenuService } from 'src/app/@core/@service/menu/menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menuData: any = {
    cadastro: {},
    importacao: {},
    relatorio: {},
    solicitacao: {},
    programacao: {},
    lancamento: {},
    aprovacao: {},
  }
  constructor(
    private router: Router,
  ) { }
  @Output() changePageMenu = new EventEmitter();
  ngOnInit() {
    const menu = JSON.parse(sessionStorage.getItem('menu'));
    if (menu) {
      this.menuData = {
        cadastro: menu.filter(val => val.Titulo == 'Cadastro'),
        importacao: menu.filter(val => val.Titulo == 'Importação'),
        relatorio: menu.filter(val => val.Titulo == 'Relatório'),
        solicitacao: menu.filter(val => val.Titulo == 'Solicitação'),
        programacao: menu.filter(val => val.Titulo == 'Programação'),
        lancamento: menu.filter(val => val.Titulo == 'Lançamento'),
        aprovacao: menu.filter(val => val.Titulo == 'Aprovação'),
      }
      Object.keys(this.menuData).forEach(x1 => {
        this.menuData[x1].forEach((x2, i) => {
          if (x2.SubTituloNivel1.toString().toLowerCase() == 's') {
            const filter = this.menuData[x1].filter(f1 => f1.SubTitulo == x2.SubTitulo && f1.SubTituloNivel1.toString().toLowerCase() != 's');
            x2.subMenus = filter;
            filter.forEach(x3 => {
              this.menuData[x1].splice(this.menuData[x1].findIndex(idx => idx.Id == x3.Id), 1);
            });
          }
        });
      });
    }
    else {
      this.router.navigate(['Login']);
    }
  }

  selectItemMenu(el, idPai) {
    $('a').removeClass("item-active");
    $('a').removeClass("item-active");
    $(`#${idPai}`).addClass("item-active");
    $(el.target).addClass("item-active");

    if (window.innerWidth < 768) {
      if ($('body').hasClass('sidebar-open')) {
        $('body').removeClass('sidebar-open');
      }
    } else {
      if ($('body').hasClass('sidebar-collapse')) {
        $('body').removeClass('sidebar-collapse');
      }
    }
  }
}
