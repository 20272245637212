<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Usuário
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Usuário</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome do Usuário">
              </div>
              <div class="form-group">
                <label>Bairro</label>
                <input type="text" class="form-control" id="txtBairro" name="txtBairro" placeholder="Bairro">
              </div>
              <div class="form-group">
                <label>Telefone (Residencial)</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTelRes" name="txtTelRes"
                  placeholder="(__) ____-____">
              </div>
              <div class="form-group">
                <label>Tipo do Usuário</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoUsuarioList"
                  IdSelect="selectTipoUsuario" [Value]="tipoUsuarioSelected">
                </select-component>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>CPF</label>
                <input type="text" mask="000.000.000-00" class="form-control" id="txtCPF" name="txtCPF"
                  placeholder="CPF">
              </div>
              <div class="form-group">
                <label>CEP</label>
                <input type="text" mask="00000-000" class="form-control" id="txtCep" name="txtCep" placeholder="CEP">
              </div>
              <div class="form-group">
                <label>Telefone (Celular)</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTelCel" name="txtTelCel"
                  placeholder="(__) ____-____">
              </div>
              <div class="form-group">
                <label>Empresas</label>
                <ng-multiselect-dropdown [placeholder]="'Selecione uma ou mais empresas...'" [settings]="dropdownSettings"
                  [data]="empresasList" [(ngModel)]="empresasSelected" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Endereço</label>
                <input type="text" class="form-control" id="txtEnd" name="txtEnd" placeholder="Endereço">
              </div>
              <div class="form-group">
                <label>UF</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                  IdSelect="selectEstados" [Value]="estadoSelected">
                </select-component>
              </div>
              <div class="form-group">
                <label>Telefone (Comercial)</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTelCom" name="txtTelCom"
                  placeholder="(__) ____-____">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Complemento</label>
                <input type="text" class="form-control" id="txtEndComp" name="txtEndComp"
                  placeholder="Endereço Complemento">
              </div>
              <div class="form-group">
                <label>Cidade</label>
                <select-component (changeValue)="changeSelectValues($event)" [Disabled]="!estadoSelected ? true : false"
                  [Data]="cidadesList" IdSelect="selectCidades" [Value]="cidadeSelected">
                </select-component>
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" id="txtEmail" name="txtEmail" placeholder="Email">
              </div>
            </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>
      </form>
    </div>

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>CPF</th>
                <th>Endereço</th>
                <th>Compl.</th>
                <th>Bairro</th>
                <th>CEP</th>
                <th>UF</th>
                <th>Cidade</th>
                <th>Telefone Res.</th>
                <th>Telefone Cel.</th>
                <th>Telefone Com.</th>
                <th>Email</th>
                <th>Data de Cadastro</th>
                <th>Senha</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>

  <div class="control-sidebar-bg"></div>
</div>
