import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class AprovacaoDesligamentoService {

    routePrefix: string = 'Aprovacao'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}Desligamento/BuscarSolicitacaoDesligamento`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    adicionar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Adicionar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Editar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovar(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Desligamento/ReprovarSolicitacaoDesligamento?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovar(data: any = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}Desligamento/AprovarSolicitacaoDesligamento?id=${data.Id}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarStatus(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}Solicitacao/BuscarTudoStatusSolicitacao`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }


}