import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";
import { UtilsService } from "../../../../@core/@service/utils/utils";
import { ImportacaoDivergenciaService } from "src/app/@core/@service/importacao/divergencia/divergencia.service";
import Swal from "sweetalert2";
import { CadastroColaboradorService } from "src/app/@core/@service/cadastro/colaborador/colaborador.service";
import { CadastroJustificativaDeDivergenciaService } from "src/app/@core/@service/cadastro/justificativa-de-divergencia/justificativa-de-divergencia.service"
//import * as jsPDF from "jspdf";
import jsPDF from 'jspdf';
//import "jspdf-autotable";
import autoTable from 'jspdf-autotable'
import { SelectorFlags } from "@angular/compiler/src/core";
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';

declare var $;

@Component({
  selector: "app-relatorio-divergencia",
  templateUrl: "./divergencia.component.html",
  styleUrls: ["./divergencia.component.scss"],
})
export class RelatorioDivergenciaComponent implements OnInit {
  public get moduleService(): ImportacaoDivergenciaService {
    return this._moduleService;
  }
  public set moduleService(value: ImportacaoDivergenciaService) {
    this._moduleService = value;
  }
  empresaGridData = [];
  gridData = [];

  colaboradorGridData = [];
  empresaIdSelected = '';

  justificativasDivergencias = [];

  verificado: boolean = true;

  modalRelatoriogridData: any = {};

  modalRelatorioData: any = {
    colaborador: "",
    matricula: "",
    empresa: "",
  };

  colaboradorSelected = "";

  dataTable: any;

  @ViewChild("grid", { static: true }) grid: ElementRef;
  @ViewChild("gridModalColaborador", { static: true }) gridModalColaborador: ElementRef;
  @ViewChild("gridModalRelatorio", { static: true }) gridModalRelatorio: ElementRef;
  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;
  constructor(
    private justificativaDivergenciaService: CadastroJustificativaDeDivergenciaService,
    private utilService: UtilsService,
    private _moduleService: ImportacaoDivergenciaService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.validarCampos();
    this.loadDataTable([]);
    this.listarTodasJustificativasDivergencias();
    //Date picker
    ($("#txtDataInicial") as any).datepicker({
      autoclose: true,
    });
    ($("#txtDataFinal") as any).datepicker({
      autoclose: true,
    });
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtDataInicial: {
          required: false,
        },
        txtDataFinal: {
          required: false,
          //dataCheck: true,
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtDataInicial: {
          required: "Informe a Data Inicial",
        },
        txtDataFinal: {
          required: "Informe a Data Final",
        },
      },
    });
    $.validator.addMethod(
      "dataCheck",
      function (value, element) {
        const dataInicial = $("#txtDataInicial").val();
        const dataFinal = $("#txtDataFinal").val();
        if (dataInicial && dataFinal) {
          var partesDataInicial = dataInicial.split("/");
          var partesDataFinal = dataFinal.split("/");
          var data1 = Number(
            partesDataInicial[2] + partesDataInicial[1] + partesDataInicial[0]
          );
          var data2 = Number(
            partesDataFinal[2] + partesDataFinal[1] + partesDataFinal[0]
          );
          if (data1 > data2) return false;
          return true;
        }
        return false;
      },
      "A data final deve ser posterior a inicial"
    );
    ($("#formulario") as any).on("submit", function () {
      return false;
    });
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            console.log(res);
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
    if (idModal == "modalColaborador") {
      if ($("#txtNomeColaborador").val().toString().length > 0) {
        this.colaboradorService
          .pesquisarColaboradorPorNome(
            $("#txtNomeColaborador").val().toString()
          )
          .subscribe(
            (res) => {
              this.colaboradorGridData = res
                .map((val) => ({
                  id: val.Id,
                  nome: val.Nome,
                  cpf: val.CPF,
                  ...val,
                }))
                .sort(this.utilService.dynamicSort("nome"));
              this.loadDataTableModalColaborador(this.colaboradorGridData);

              ($(`#${idModal}`) as any).modal("show");
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Ocorreu um erro ao buscar a lista de \n colaboradores!",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(err);
            }
          );
      }
    } else if (idModal == "modalRelatorio") {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        (res) => {
          this.modalRelatoriogridData = res.Detalhes.map((val) => ({
            id: val.Id,
            diaSemana: val.DiaSemana || "-",
            dia: val.Dia ? new Date(val.Dia).toLocaleDateString("pt-BR") : "-",
            horario1: val.Horario1 || "-",
            horario2: val.Horario2 || "-",
            horario3: val.Horario3 || "-",
            horario4: val.Horario4 || "-",
            inicioJornada: val.InicioJornada || "-",
            terminoJornada: val.FimJornada || "-",
            diferencaHoras: val.DiferencaHoras || "-",
            descricaoDivergencia: val.Descricao || "-",
            justificativaDivergencia: val.JustificativaDivergencia?.Tipo ?? "-",
            motivoAprovacao: val.MotivoAprovacao ?? "-",
            ...val,
          }));
          this.loadDataTableModalRelatorio(this.modalRelatoriogridData);
          ($(`#${idModal}`) as any).modal("show");
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Ocorreu um erro ao buscar a os dados da importação!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal("hide");
  }

  exportarPDF() {
    const data = [];
    const doc = new jsPDF("p", "pt", "a4");

    this.modalRelatoriogridData.forEach((val, i) => {
      const arr = [];
      Object.keys(val).forEach((x) => {
        arr.push(val[x]);
      });
      data.push(arr);
    });
    doc.text(
      `Relatório de Divergência - ${new Date().toLocaleDateString("pt-BR")}`,
      150,
      30
    );
    doc.setFontSize(11);
    doc.text(`Estabelecimento: ${this.modalRelatorioData.empresa}`, 40, 60);
    doc.text(`Matrícula: ${this.modalRelatorioData.matricula}`, 40, 90);
    doc.text(`Nome: ${this.modalRelatorioData.colaborador}`, 170, 90);

    autoTable(doc,{
      margin: { top: 120 },
      didDrawPage: function (data) {
        data.settings.margin.top = 25;
      },
      head: [
        [
          "Dia da Semana",
          "Dia",
          "Horário 1",
          "Horário 2",
          "Horário 3",
          "Horário 4",
          "Início Jornada",
          "Termino Jornada",
          "Diferença Horas",
          "Descrição Divergência",
        ],
      ],
      body: data,
      styles: {
        valign: "middle",
        halign: "center",
        fontSize: 9,
      },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
    });

    doc.save(
      `relatorio-divergencia-${new Date().toLocaleDateString("pt-BR")}.pdf`
    );
  }

  getDataGrid() {
    if (($("#formulario") as any).valid()) {
      this.moduleService
        .pesquisar(
          this.colaboradorSelected,
          $("#txtDataInicial").val(),
          $("#txtDataFinal").val(),
          this.empresaIdSelected,
        )
        .subscribe(
          (res) => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map((val) => ({
                id: val.Id,
                colaborador: val.Colaborador ? val.Colaborador.Nome : "",
                matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : "",
                empresa: val.Empresa ? val.Empresa.Nome : "",
                motivo: val.Motivo || "-",
                dataConclusao: val.DataCadastro,
                tipoImportacao: val.tipoImportacao || "-",
                ferramenta: val.ferramenta || "-",
                ...val,
              }));
              this.loadDataTable(this.gridData);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  pesquisar() {
    this.getDataGrid();
  }
  exportToExcel() {
    if (($("#formulario") as any).valid()) {
      this.moduleService
        .Exportar(
          this.colaboradorSelected,
          $("#txtDataInicial").val(),
          $("#txtDataFinal").val(),
          this.empresaIdSelected,
        )
      .subscribe(
        (res) => {
          if (res) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
selecionarTodosParaExclusao(event) {
  let value = event.target.checked;

  $("[data-delete-check]").prop("checked", value);
  $("[data-delete-check]").trigger("change");
}
get itensSelecionadosParaExclusao() {
  return this.gridData.filter((x) => x.selecionadoParaExclusao);
}
  clearInputvalues(type = "") {
    this.colaboradorSelected = "";
    this.empresaIdSelected = "";
    $("#txtDataInicial").val("");
    $("#txtDataFinal").val("");
    $("#txtNomeColaborador").val("");
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "colaborador",
        },
        {
          data: "matricula",
        },
        {
          data: "empresa",
        },
        {
          data: "motivo",
        },
        {
          data: "dataConclusao",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "";
          },
        },
        {
          data: "TipoImportacao",
        },
        {
          data: "Ferramenta",
        },
        {
          data: "DataOcorrencia",
        },
        {
          data: "Acao",
        },
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          },
        },
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><input type="checkbox" data-delete-check=""></div>';
          },
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()[9]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(
            data.DataCadastro
          ).toLocaleDateString();
          self.openModal("modalRelatorio", data);
        });
        $($(row).children()[10]).on("click", async function (e) {
          data.selecionadoParaExclusao = !data.selecionadoParaExclusao;
        });

        $($(row).children()[10]).on("change", async function (e) {
          data.selecionadoParaExclusao = $(e.target).prop("checked");
          if (
            $("[data-delete-check]").length ==
            self.itensSelecionadosParaExclusao.length
          ) {
            $("[data-select-all]").prop("checked", true);
          }

          if (self.itensSelecionadosParaExclusao.length == 0) {
            $("[data-select-all]").prop("checked", false);
          }
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: true,
      searching: true,
      ordering: true,
      info: true,
      autoWidth: true,
      language: this.utilService.getDatatableTranslate(),
    });
  }
  exluir() {
    Swal.fire({
      position: "top",
      title: `Você tem certeza que deseja remover ${this.itensSelecionadosParaExclusao.length} Divergências?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3c8dbc",
      cancelButtonColor: "#DD4B39",
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService
          .excluirPorMultiplosIds(
            this.itensSelecionadosParaExclusao.map((x) => x.id)
          )
          .subscribe(
            (res) => {
              Swal.fire({
                position: "top",
                icon: "success",
                title: "Divergências removidas!",
                showConfirmButton: false,
                timer: 2000,
              });
              this.pesquisar();
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Ocorreu um erro ao remover as Divergências!",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(err);
            }
          );
      }
    });
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cpf",
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $("#txtNomeColaborador").val(data.nome);
          $("#txtNomeColaborador").removeClass("element-invalid");
          self.closeModal("modalColaborador");
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  listarTodasJustificativasDivergencias() {
    this.justificativaDivergenciaService.trazerTodas()
      .subscribe(
        (res) => {
          {
            this.justificativasDivergencias = res.map((val) => ({
              id: val.Id,
              tipo: val.Tipo ? val.Tipo : "",
              ...val,
            }));
          }
        },
        (err) => {
          console.log(err);
        });
  }
  getCamposJustificativa(id) {
    let obj = {
      id: id,
      JustificativaDivergenciaId: $("#selectDivergencias_" + id).val(),
      MotivoAprovacao: $("#observacaoDivergencias_" + id).val()
    };
    return obj;
  }

  salvar(data) {
    this._moduleService.salvar(data).subscribe(
      res => {
        Swal.fire({
          position: 'top',
          icon: "success",
          title: 'Divergência Verificada com Sucesso!',
          showConfirmButton: false,
          timer: 2000
        });
        this.closeModal("modalRelatorio");
        this.getDataGrid();
        setTimeout(() => {
          this.openModal("modalRelatorio", { id: data.DivergenciaId });
        }, 500);
      },
      err => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Ocorreu um erro ao aprovar a divergência!',
          showConfirmButton: false,
          timer: 2000
        });
        console.log(err);
        this.closeModal("modalRelatorio");
        this.getDataGrid();
        setTimeout(() => {
          this.openModal("modalRelatorio", { id: data.DivergenciaId });
        }, 500);
      }
    )
  };

  loadDataTableModalRelatorio(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "diaSemana",
        },
        {
          data: "dia",
        },
        {
          data: "horario1",
        },
        {
          data: "horario2",
        },
        {
          data: "horario3",
        },
        {
          data: "horario4",
        },
        {
          data: "inicioJornada",
        },
        {
          data: "terminoJornada",
        },
        {
          data: "diferencaHoras",
        },
        {
          data: "descricaoDivergencia",
        },
        {
          data: "justificativaDivergencia",
        },
        {
          data: "motivoAprovacao",
        },
        {
          data: "AcaoPendente",
        },
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button"class="fa fa-check" style="color: #100F5C; border: 0px; background-color: transparent;" title="Verificar"></button></div>';
          },
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[13]).on("click", function (e) {
          self.salvar(data);
        });
      },
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    console.log(this.gridModalEmpresa);
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.empresaIdSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
