import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class CadastroSindicatoService {

    routePrefix: string = 'Sindicato'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    adicionar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Adicionar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Editar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    remover(data): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}?id=${data.id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}