import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/@core/@service/login/login.service';

declare var $;
@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  @ViewChild('inputCpf', { static: true }) inputCpf: ElementRef;
  @ViewChild('inputSenhaAtual', { static: true }) inputSenhaAtual: ElementRef;
  @ViewChild('inputSenhaNova', { static: true }) inputSenhaNova: ElementRef;
  @ViewChild('inputSenhaNovaConfirmacao', { static: true }) inputSenhaNovaConfirmacao: ElementRef;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    document.body.className = 'hold-transition login-page';
    $(() => {
      $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%' /* optional */
      });
    });
    this.validarCampos();
  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        inputCpf: {
          required: true,
          minlength: 11,
          cpfCheck: true,
        },
        inputSenhaAtual: {
          required: true
        },
        inputSenhaNova: {
          required: true
        },
        inputSenhaNovaConfirmacao: {
          required: true,
          equalTo: "#inputSenhaNova"
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        inputCpf: {
          required: "Informe o CPF",
          minlength: "Informe o CPF corretamente"
        },
        inputSenhaAtual: {
          required: "Informe a senha"
        },
        inputSenhaNova: {
          required: "Informe a nova senha"
        },
        inputSenhaNovaConfirmacao: {
          required: "Informe a confirmação da nova senha",
          equalTo: "Senha de confirmação diferente"
        }
      }
    });

    ($("#formulario") as any).on("submit", function () {
      return false;
    })

    $.validator.addMethod("cpfCheck", function (value, element) {
      return UtilsService.verificarCPF(value);
    }, 'Cpf inválido');
  }

  alterarSenha() {
    if (($("#formulario") as any).valid()) {
      const data = {
        user: this.inputCpf.nativeElement.value,
        senha: this.inputSenhaAtual.nativeElement.value,
        novaSenha: this.inputSenhaNova.nativeElement.value,
      };

      if(this.inputSenhaNova.nativeElement.value == this.inputSenhaNovaConfirmacao.nativeElement.value) {
        this.loginService.alterarSenha(data).subscribe(
          resultado => {
            if (!resultado.Erro) {
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'A senha foi alterada com sucesso!',
                showConfirmButton: false,
                timer: 2000
              });
              this.voltarAoLogin();
            } else {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: resultado.Erro.Descricao,
                showConfirmButton: false,
                timer: 2000
              });
            }
  
          },
          erro => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao alterar a senha!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
  
    }

  }

  voltarAoLogin() {
    this.router.navigate(['Login']);
  }

}
