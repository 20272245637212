<div class="box box-primary">
  <form id="formulario">
    <div class="box-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Nome da Empresa</label>
            <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
            <div class="input-group">
              <input type="text" class="form-control" id="txtNomeEmpresa" name="txtNomeEmpresa"
                placeholder="Nome da Empresa">
              <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalEmpresa')"
                  title="Pesquisar Empresa"><i class="fa fa-search"></i></a></span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Código do Plano</label>
            <input type="text" class="form-control" id="txtCodigoPlano" name="txtCodigoPlano"
              placeholder="Código do Plano">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Nome do Plano</label>
            <input type="text" class="form-control" id="txtNomePlano" name="txtNomePlano" placeholder="Nome do Plano">
          </div>
        </div>
      </div>
    </div>

    <div class="box-footer">
      <table border=0 width="100%">
        <tr width="100%">
          <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
          </td>
          <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
          </td>
          <td align="right">
            <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
              class="btn btn-primary btn-margin" />
            <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
              class="btn btn-info pull-right btn-margin" />
          </td>
        </tr>
      </table>
    </div>
  </form>
</div>
<div class="box box-body">
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome da Empresa</th>
            <th>Código do Plano</th>
            <th>Nome do Plano</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
            <th style="width: 5% !important;"></th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEmpresa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel"><b>Empresa</b></h4>
    </div>
    <div class="modal-body">
      <table #gridModalEmpresa style="width: 100% !important; cursor: pointer;" class="hover tableOperadora table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cnpj</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
    </div>
  </div>
</div>
</div>

<div class="modal fade" id="modalUnidade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><b>Selecionar Unidade</b></h4><br>
        <h5 class="modal-title">Plano: {{dadosModal.nome || '-'}}</h5>
        <h5 class="modal-title">Empresa: {{dadosModal.empresaNome || '-'}}</h5>
      </div>
      <div class="modal-body">
        <form id="formularioUnidade">
          <div style="overflow-y: auto; padding-left: 10px; padding-right: 10px;">
            <div style="width: 99%;">
              <table #gridModalUnidade tyle="width: 100% !important;" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Código da Unidade</th>
                    <th>Nome da Unidade</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <input id="SalvarUnidade" name="SalvarUnidade" value="Salvar" (click)="salvarPlanoUnidade()"
          class="btn btn-info pull-right btn-margin" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>