import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils/utils';
import { Observable } from 'rxjs';

@Injectable()
export class MenuService {

    constructor() { }

    static menuData: any;

}