import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoHoraExtraService } from 'src/app/@core/@service/solicitacao/hora-extra/hora-extra.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import { UsuarioColaboradorTipoEnum, ValeTransporteUtilizacaoEnum } from 'src/app/@core/@enum/enum';

declare var $;

@Component({
  selector: 'app-solicitacao-hora-extra',
  templateUrl: './hora-extra.component.html',
  styleUrls: ['./hora-extra.component.scss']
})

export class SolicitacaoHoraExtraComponent implements OnInit {

  gridData = [];

  gridModalConvocacaoData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  solicitacaoSelectedId = '';

  operadoraVTSelected = ''
  operadoraVTList: Array<any> = [];
  linhaVTSelected = ''
  linhaVTList: Array<any> = [];
  utilizacaoSelected = ''
  utilizacaoList: Array<any> = [];
  situacaoSelected = ''
  situacaoList: Array<any> = [];

  utilziacaoEnum: any = ValeTransporteUtilizacaoEnum;

  diasSemana = [];

  dataTable: any;
  currUser: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalConvocacao', { static: true }) gridModalConvocacao: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoHoraExtraService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
    private linhaVTService: CadastroLinhaVTService,
    private operadoraVTService: CadastroOperadoraVTService,
  ) { }

  ngOnInit() {
    this.validarCampos();
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
    this.currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
      this.gestorId = this.currUser ? this.currUser.UsuarioColaboradorId : '';
    }
    this.loadDataTable([]);
    this.getDataGrid();
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtData: {
          required: true
        },
        txtHoraInicial: {
          required: true,
        },
        txtHoraFinal: {
          required: true,
        },
        txtMotivo: {
          required: true,
        },

      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtData: {
          required: "Informe a Data"
        },
        txtHoraInicial: {
          required: "Informe a Hora Inicial"
        },
        txtHoraFinal: {
          required: "Informe a Hora Final"
        },
        txtMotivo: {
          required: "Informe o Motivo"
        },

      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }



  getDataGrid() {
    const data = this.getValuesFromInput();
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
          this.moduleService.buscarPorGestorId(this.gestorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            data: val.Data || '-',
            horarioInicial: val.HoraInicio || '-',
            horarioFinal: val.HoraFim || '-',
            motivo: val.Motivo || '-',
            cafeManha: val.TemCafeManha ? 'Sim' : 'Não',
            refeicao: val.Temrefeicao ? 'Sim' : 'Não',
            marmitex: val.TemMarmitex ? 'Sim' : 'Não',
            dataSolicitacao: val.DataSolicitacao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
    }else{
      this.moduleService.buscarPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
        res => {
          this.gridData = res.map(val =>
            ({
              id: val.Id,
              data: val.Data || '-',
              horarioInicial: val.HoraInicio || '-',
              horarioFinal: val.HoraFim || '-',
              motivo: val.Motivo || '-',
              cafeManha: val.TemCafeManha ? 'Sim' : 'Não',
              refeicao: val.Temrefeicao ? 'Sim' : 'Não',
              marmitex: val.TemMarmitex ? 'Sim' : 'Não',
              dataSolicitacao: val.DataSolicitacao,
              ...val
            })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTable(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);

    if (idModal == 'modalConvocacao') {
      this.getDataGridModalConvocacao(data);
      ($("#formularioConvocacao") as any).on("submit", function () {
        return false;
      });
      ($(`#${idModal}`) as any).modal('show');
    }
  }

  getDataGridModalConvocacao(dadosSolicitacao: any = {}) {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService.buscarColaboradorPorGestorId(this.currUser.UsuarioColaboradorId).subscribe(
      res => {
        console.log('caiu 1', res);
        console.log('caiu 2', dadosSolicitacao);
        this.gridModalConvocacaoData = res.map(val =>
          ({
            id: val.Id,
            cpf: val.CPF,
            matricula: val.MatriculaInterna || '-',
            nome: val.Nome,
            isChecked: dadosSolicitacao.SolicitacaoHoraExtraColaborador ? dadosSolicitacao.SolicitacaoHoraExtraColaborador.filter(x1 => x1.ColaboradorId == val.Id).length > 0 : false,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        console.log('caiu 3', this.gridModalConvocacaoData);

        console.log('caiu 4', this.gridModalConvocacaoData)
        this.loadDataTableModalConvocacao(this.gridModalConvocacaoData);
      },
      err => {
        console.log(err);
      }
    );
    }else{
      this.colaboradorService.buscarColaboradorPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
        res => {
          console.log('caiu 1', res);
          console.log('caiu 2', dadosSolicitacao);
          this.gridModalConvocacaoData = res.map(val =>
            ({
              id: val.Id,
              cpf: val.CPF,
              matricula: val.MatriculaInterna || '-',
              nome: val.Nome,
              isChecked: dadosSolicitacao.SolicitacaoHoraExtraColaborador ? dadosSolicitacao.SolicitacaoHoraExtraColaborador.filter(x1 => x1.ColaboradorId == val.Id).length > 0 : false,
            })
          ).sort(this.utilService.dynamicSort('nome'));
          console.log('caiu 3', this.gridModalConvocacaoData);

          console.log('caiu 4', this.gridModalConvocacaoData)
          this.loadDataTableModalConvocacao(this.gridModalConvocacaoData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  clearInputvalues(type = '') {
    $('#formulario').trigger("reset");
  }

  getValuesFromInput() {
    const data = $('#txtData').val() ? $('#txtData').val().toString().trim().split('/') : '';
    return {
      gestorId: this.gestorId,
      data: data.length >= 3 ? new Date(`${data[2]}-${data[1]}-${data[0]}`) : '',
      horaInicio: $('#txtHoraInicial').val(),
      horaFim: $('#txtHoraFinal').val(),
      motivo: $('#txtMotivo').val(),
      temCafeManha: $('#checkCafe').is(':checked'),
      temRefeicao: $('#radioRefeicao').is(':checked'),
      temMarmitex: $('#radioMarmitex').is(':checked'),
      marmitexId: null
    }
  }

  solicitar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      this.moduleService.solicitar(obj).subscribe(
        res => {
          if (res.Codigo && res.Codigo == '600') {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: res.Descricao || 'Ocorreu um erro',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            this.clearInputvalues('solicitar');
            this.getDataGrid();
            this.utilService.alertSwal('add-success');
          }
        },
        err => {
          console.log(err);
          this.utilService.alertSwal('add-error');
        }
      );
    }
  }

  checkAllConvocacao() {
    this.gridModalConvocacaoData = this.gridModalConvocacaoData.map(val =>
      ({
        id: val.id,
        cpf: val.cpf,
        matricula: val.matricula,
        nome: val.nome,
        isChecked: $('#checkAll').is(':checked'),
      })
    );
    this.loadDataTableModalConvocacao(this.gridModalConvocacaoData);
  }

  convocarEquipe() {
    const funcChecked = this.gridModalConvocacaoData.filter(val => val.isChecked);
    if (funcChecked.length == 0) {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'Necessário ao menos um funcionário!',
        showConfirmButton: false,
        timer: 2000
      });
    } else {
      console.log(this.gridModalConvocacaoData);
      const data = {
        Id: this.solicitacaoSelectedId,
        SolicitacaoHoraExtraColaborador: this.gridModalConvocacaoData.filter(val => val.isChecked).map(val =>
          ({
            ColaboradorId: val.id,
          })
        ),
      }
      Swal.fire({
        position: 'top',
        title: 'Você tem certeza que deseja convocar os funcionários selecionados?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3c8dbc',
        cancelButtonColor: '#DD4B39',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.moduleService.convocar(data).subscribe(
            res => {
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Funcionários convocados!',
                showConfirmButton: false,
                timer: 2000
              });
              this.closeModal('modalConvocacao');
              this.getDataGrid();
            },
            err => {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Erro ao convocar funcionários!',
                showConfirmButton: false,
                timer: 2000
              });
              console.log(err);
            }
          );
        }
      })
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'data', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "horarioInicial"
        },
        {
          "data": "horarioFinal"
        },
        {
          "data": "motivo"
        },
        {
          "data": "cafeManha"
        },
        {
          "data": "refeicao"
        },
        {
          "data": "marmitex"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-user-plus" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Convocar Equipe"> </button></div>';

          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[8]).on("click", function (e) {
          self.solicitacaoSelectedId = data.Id;
          self.openModal('modalConvocacao', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalConvocacao(data) {
    let self = this;
    const datatable = $(this.gridModalConvocacao.nativeElement);
    datatable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'isChecked', "orderable": false, "render": function (data, type, row) {
            if (data) {
              return '<div style="text-align: center;"><input disabled  checked type="checkbox"/></div>';
            } else {
              return '<div style="text-align: center;"><input type="checkbox"/></div>';
            }

          }
        },
        {
          "data": 'matricula', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'cpf', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'nome', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[0]).find('input[type=checkbox]').each(function () {
          $(this).change(function () {
            self.gridModalConvocacaoData[index].isChecked = !data.isChecked;
          });
        })
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": true,
      "ordering": true,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
