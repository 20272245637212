import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class AprovacaoAtestadoService {

    routePrefix: string = 'Atestado'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarLancamentoAtestado`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovar(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/ReprovarLancamentoAtestado?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovar(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AprovarLancamentoAtestado?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarStatus(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}Lancamento/BuscarTudoStatusLancamento`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoLancamentoAtestado(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarImagem${this.routePrefix}?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }


}