import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { AprovacaoMovimentacaoContratualService } from 'src/app/@core/@service/aprovacao/movimentacao-contratual/movimentacao-contratual.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';



@Component({
  selector: 'app-aprovacao-movimentacao-contratual',
  templateUrl: './movimentacao-contratual.component.html',
  styleUrls: ['./movimentacao-contratual.component.scss']
})
export class AprovacaoMovimentacaoContratualComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  gestorGridData = [];

  colaboradorSelected = '';

  gestorSelected

  statusSelected = '';

  statusList: Array<any>;

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  @ViewChild('gridModalGestor', { static: true }) gridModalGestor: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: AprovacaoMovimentacaoContratualService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selStatus') {
      this.statusSelected = event.target.value;
    }
  }

  reprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja reprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.reprovar(data.id).subscribe(
          res => {
            this.utilService.alertSwal('reprove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('reprove-error');
          }
        );
      }
    })
  }

  aprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja aprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.aprovar(data.id).subscribe(
          res => {
            this.utilService.alertSwal('approve-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('approve-error');
          }
        );
      }
    })
  }


  getIntitialsData() {
    this.loadDataTable([]);
    this.moduleService.buscarStatus().subscribe(
      res => {
        this.statusList = res.map(val =>
          ({
            Id: val.Id || '',
            Description: val.Nome || '',
          })
        ).sort(this.utilService.dynamicSort('nome'));
      },
      err => {
        console.log(err);
      }
    );
  }

  getValuesFromInput() {
    return {
      GestorId: this.gestorSelected,
      ColaboradorId: this.colaboradorSelected,
      SolicitacaoStatusId: this.statusSelected,
    }
  }


  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id || '',
            gestor: val.Gestor ? val.Gestor.Nome : '',
            colaborador: val.Colaborador ? val.Colaborador.Nome : '',
            empresa: val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '-',
            lotacao: val.LotacaoUnidade ? val.LotacaoUnidade.Nome : '-',
            cargo: val.CargoUnidade ? val.CargoUnidade.Nome : '-',
            centroCusto: val.CentroCustoUnidade ? val.CentroCustoUnidade.Nome : '-',
            tipoMaoObra: val.TipoMaoObra ? val.TipoMaoObra.Nome : '-',
            turno: val.Turno ? val.Turno.Descricao : '-',
            unidadeNegocio: val.UnidadeNegocio ? val.UnidadeNegocio.Nome : '-',
            cartaoPonto: val.NumeroCartaoPonto || '-',
            salario: val.Salario || '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {
    // Zera os campos
    this.gestorSelected = '';
    this.colaboradorSelected = '';
    this.statusSelected = '';
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalGestor') {
      if ($('#txtNomeGestor').val().toString().length > 0) {
        this.colaboradorService.pesquisarGestorPorNome($('#txtNomeGestor').val().toString()).subscribe(
          res => {
            this.gestorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalGestor(this.gestorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n gestores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  pesquisar() {
    this.getDataGrid();
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "gestor"
        },
        {
          "data": "colaborador"
        },
        {
          "data": "empresa"
        },
        {
          "data": "lotacao"
        },
        {
          "data": "centroCusto"
        },
        {
          "data": "tipoMaoObra"
        },
        {
          "data": "turno"
        },
        {
          "data": "unidadeNegocio"
        },
        {
          "data": "cartaoPonto"
        },
        {
          "data": "cargo"
        },
        {
          "data": 'salario', "orderable": true, "render": function (data) {
            return 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            }
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[14]).on("click", function (e) {
          // aprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.aprovar(data);
          }
        });
        $($(row).children()[15]).on("click", async function (e) {
          // reprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.reprovar(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalGestor(data) {
    let self = this;
    this.dataTable = $(this.gridModalGestor.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.gestorSelected = data.id;
          $('#txtNomeGestor').val(data.nome);
          self.closeModal('modalGestor');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
