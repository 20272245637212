<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Cartão de Ponto
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Cartão de Ponto</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                      placeholder="Nome do Colaborador">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Mês</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="mesList" IdSelect="selMes"
                    [Value]="mesSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Ano</label>
                  <input type="text" mask="0000" class="form-control" id="txtAno" name="txtAno" placeholder="yyyy">
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Nome do Colaborador</th>
                  <th style="width: 15% !important">Matrícula do Colaborador</th>
                  <th style="width: 15% !important">Empresa</th>
                  <th style="width: 15% !important">Mês</th>
                  <th style="width: 15% !important">Ano</th>
                  <th style="width: 15% !important">Data da Importação</th>
                  <th style="width: 5% !important;"></th>
                  <th style="width: 5% !important;"></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 95%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Relatório de Cartão de Ponto</b></h4>
      </div>
      <div class="modal-header" style="padding-left: 15px;">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-4">
            <b>Estabelecimento:</b> {{modalRelatorioData.empresa}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-4">
                <b>Matrícula:</b> {{modalRelatorioData.matricula}}
              </div>
              <div class="col-md-8">
                <b>Nome:</b> {{modalRelatorioData.colaborador}}
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <b>Mês:</b> {{modalRelatorioData.mes}}
          </div>
          <div class="col-md-1">
            <b>Ano:</b> {{modalRelatorioData.ano}}
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-6">
                <b>Data de Início Período:</b> {{modalRelatorioData.dataInicio}}
              </div>
              <div class="col-md-6">
                <b>Data Termino Período:</b> {{modalRelatorioData.dataFim}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6" style="padding-top: 15px;">
            <div style="overflow-y: auto;">
              <div style="width: 99%; padding-left: 15px;">
                <table #gridModalRelatorio1 style="width: 100% !important; cursor: pointer; text-align: center;"
                  class="hover table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Período</th>
                      <th>Horas Positivas</th>
                      <th>Horas Negativas</th>
                      <th>Saldo Mês</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="padding-top: 15px;">
            <div style="overflow-y: auto;">
              <div style="width: 99%; padding-left: 15px;">
                <table #gridModalRelatorio2 style="width: 100% !important; cursor: pointer; text-align: center;"
                  class="hover table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Evt</th>
                      <th>Descrição</th>
                      <th>Qtd. Motivo</th>
                      <th>Saldo Banco Horas</th>
                      <th>Saldo Compensação Dia Ponte</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalRelatorio style="width: 100% !important; cursor: pointer; text-align: center;"
              class="hover table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Dia da Semana</th>
                  <th>Número Jornada</th>
                  <th>Tipo Dia</th>
                  <th>Marcação 1</th>
                  <th>Tipo Marcação 1</th>
                  <th>Marcação 2</th>
                  <th>Tipo Marcação 2</th>
                  <th>Marcação 3</th>
                  <th>Tipo Marcação 3</th>
                  <th>Marcação 4</th>
                  <th>Tipo Marcação 4</th>
                  <th>Marcação 5</th>
                  <th>Tipo Marcação 5</th>
                  <th>Marcação 6</th>
                  <th>Tipo Marcação 6</th>
                  <th>Marcação 7</th>
                  <th>Tipo Marcação 7</th>
                  <th>Marcação 8</th>
                  <th>Tipo Marcação 8</th>
                  <th>Início Jornada</th>
                  <th>Termino Jornada</th>
                  <th>Quant. Horas</th>
                  <th>Descrição Ocorrência</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>