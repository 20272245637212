import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorDadosPrincipaisService } from 'src/app/@core/@service/cadastro/colaborador/dados-principais/dados-principais.service';
import Swal from 'sweetalert2';
import { CadastroCargoUnidadeService } from 'src/app/@core/@service/cadastro/cargo/unidade/unidade.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

declare var $;
@Component({
  selector: 'app-colaborador-dados-principais',
  templateUrl: './dados-principais.component.html',
  styleUrls: ['./dados-principais.component.scss']
})
export class ColaboradorDadosPrincipaisComponent implements OnInit {

  gridData = [];

  gridModalUnidadeData = [];

  empresaGridData = [];

  equipeGridData = [];

  isEdit = false;

  planoIdSelected = '';

  empresaIdSelected = '';

  colaboradorSelected = '';

  dadoPrincipalIdSelected = '';

  dadoPrincipalEstadoSelected = '';
  dadoPrincipalCidadeSelected = '';
  cidadesList: Array<object> = [];
  estadosList: Array<object> = [];
  statusList: Array<object> = [];
  colaboradorTipoList: Array<object> = [];
  grauInstrucaoList: Array<object> = [];
  estadoCivilList: Array<object> = [];
  etiniaList: Array<object> = [];
  tipoList: Array<object> = [];
  sexoList: Array<object> = [];
  modalEquipeLotacaoList: Array<object> = [];
  equipeLotacaoList: Array<any> = [];
  modalEquipeCodigoInicialIdSelected = '';
  modalEquipeCodigoFinalIdSelected = '';
  empresasTerceiraList: Array<object> = [];
  empresaTerceiraSelected = [];
  empresasTerceiraSettings = {};
  fotoColaborador: any = {
    nome: '',
    base64: '',
  };
  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];
  colaboradorStatusIdSelected = '';
  grauInstrucaoSelected = '';
  estadoCivilSelected = '';
  etiniaSelected = '';
  colaboradorTipoSelected = '';
  sexoIdSelected = '';
  dataTable: any;
  dataTableModalUnidade: any;
  dataTableModalEmpresa: any;
  cargoUnidades = [];
  userLogged: any;
  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalUnidade', { static: true }) gridModalUnidade: ElementRef;

  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;

  @ViewChild('gridModalEquipe', { static: true }) gridModalEquipe: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorDadosPrincipaisService,
    private unidadeService: CadastroCargoUnidadeService,
    private empresaService: CadastroEmpresaService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO){
      ($("#btnSalvar") as any).hide();
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtDataNasc") as any).datepicker({
      autoclose: true
    });
    ($("#formularioDadosPrincipaisDetalhe") as any).on("submit", function () {
      return false;
    })

    this.empresasTerceiraSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      //selectAllText: 'Selecionar todos',
      //unSelectAllText: 'Limpar todos',
      searchPlaceholderText: 'Pesquisar',
      itemsShowLimit: 4,
      badgeShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async removerEquipe(id) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.colaboradorService.removerEquipeGestor(id).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getEquipeGestorDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    this.estadosList = await this.utilService.getEstados();
    this.etiniaList = await this.utilService.getEtiniaList();
    this.cidadesList = [];
    this.sexoList = [
      {
        Id: 'Masculino',
        Description: 'MASCULINO'
      },
      {
        Id: 'Feminino',
        Description: 'FEMININO'
      },
    ]
    this.colaboradorService.BuscarTudoColaboradorTipo().subscribe(
      res => {
        this.colaboradorTipoList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.pesquisarColaboradorStatus().subscribe(
      res => {
        this.statusList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );this.colaboradorService.BuscarTudoGrauInstrucao().subscribe(
      res => {
        this.grauInstrucaoList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoEstadoCivil().subscribe(
      res => {
        this.estadoCivilList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoColaboradorTipo().subscribe(
      res => {
        this.tipoList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectEstados') {
      this.dadoPrincipalCidadeSelected = '';
      this.dadoPrincipalEstadoSelected = event.target.value;
      this.cidadesList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidades') {
      this.dadoPrincipalCidadeSelected = event.target.value;
    } else if (event.srcElement.id == 'selectStatus') {
      this.colaboradorStatusIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selectGrauInstrucao') {
      this.grauInstrucaoSelected = event.target.value;
    } else if (event.srcElement.id == 'selectEstadoCivil') {
      this.estadoCivilSelected = event.target.value;
    } else if (event.srcElement.id == 'selectTipo') {
      this.colaboradorTipoSelected = event.target.value;
      $('#selectTipo').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selectSexo') {
      this.sexoIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selCodigoInicial') {
      this.modalEquipeCodigoInicialIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selCodigoFinal') {
      this.modalEquipeCodigoFinalIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selectEtinia') {
      this.etiniaSelected = event.target.value;
    }
  }

  validarCampos() {
    const self = this;
    ($("#formularioDadosPrincipais") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtCPF: {
          required: true, minlength: 14
        },
        txtEmail: {
          required: true, email: true
        },
        txtTel1: {
          minlength: 14
        },
        txtTel3: {
          minlength: 14
        },
        txtTel2: {
          minlength: 14
        },
        txtNomeEmpresa: {
          required: true,
          empresaCheck: true,
        },
        selectTipo: {
          required: true
        },
        selectEtinia: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtCPF: {
          required: "Informe o Cpf",
          minlength: "Informe o Cpf corretamente"
        },
        txtEmail: {
          required: "Informe o Email",
          email: "Informme o Email corretamente"
        },
        txtTel1: {
          minlength: "Informe o Telefone Residencial corretamente"
        },
        txtTel3: {
          minlength: "Informe o Telefone Celular corretamente"
        },
        txtTel2: {
          minlength: "Informe o Telefone Comercial corretamente"
        },
        txtNomeEmpresa: {
          required: "Informe a Empresa"
        },
        selectTipo: {
          required: "Selecione o Tipo"
        },
        selectEtinia: {
          required: "Declare a Etinia"
        }
      },
    });
    $.validator.addMethod("empresaCheck", function (value, element) {
      return self.empresaIdSelected ? true : false;
    }, 'Selecione a empresa');
    ($("#formularioDadosPrincipais") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
    this.getEmpresas();
  }

  getEmpresas(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisar().subscribe(
      res => {
          this.empresasTerceiraList = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }
  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.empresasSelected);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisarPorUsuarioId(data,this.userLogged.UsuarioColaboradorId).subscribe(
      res => {
        console.log(res);
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            cpf: val.CPF,
            tipo: val.ColaboradorTipo.Nome,
            empresaId: val.EmpresaId,
            empresaNome: val.Empresa ? val.Empresa.Nome : '',
            uf: val.UF,
            cidade: val.Cidade,
            telRes: val.Telefone1,
            telCel: val.Telefone2,
            telCom: val.Telefone3,
            Email: val.Email,
            statusId: val.ColaboradorStatusId,
            status: val.ColaboradorStatus ? val.ColaboradorStatus.Nome : '',
            senha: this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO? '-' : val.Senha || '-',
            dataCadastro: val.DataCadastro,
            empresaTerceira: val.EmpresaTerceiraId? val.EmpresaTerceira.Nome: '',
            etinia: val.Etinia,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearModalEquipeInputvalues() {
    this.modalEquipeCodigoInicialIdSelected = '';
    this.modalEquipeCodigoFinalIdSelected = '';
  }

  clearInputvalues(type = '') {

    if (type == 'salvar') {
      $('#selectTipo').removeClass('element-invalid');
      $("#formularioDadosPrincipais").valid();
    }
    // Desabilita modo edição
    this.isEdit = false;
    // Zera os campos
    this.dadoPrincipalIdSelected = '';
    this.empresaIdSelected = '';
    this.colaboradorTipoSelected = '';
    this.sexoIdSelected = '';
    this.fotoColaborador = {
      nome: '',
      base64: '',
    };
    this.grauInstrucaoSelected = ''; //ver depois
    this.estadoCivilSelected = ''; //ver depois
    this.etiniaSelected = ''; //ver depois
    this.colaboradorStatusIdSelected = '';
    this.dadoPrincipalCidadeSelected = '';
    this.dadoPrincipalEstadoSelected = '';
    $('#txtNome').val('');
    $('#txtCPF').val('');
    $('#txtEnd').val('');
    $('#txtEndComp').val('');
    $('#txtBairro').val('');
    $('#txtCep').val('');
    $('#txtDataNasc').val('');
    $('#txtTel1').val('');
    $('#txtTel2').val('');
    $('#txtTel3').val('');
    $('#txtEmail').val('');
    $('#txtNomePai').val('');
    $('#txtNomeMae').val('');
    $('#txtMatriculaInterna').val('');
    $('#txtMatriculaESocial').val('');
    $('#txtPaisNasc').val('');
    $('#txtUFNasc').val('');
    $('#txtCidadeNasc').val('');
    $('#txtNumeroEnd').val('');
    $('#txtNomeEmpresa').val('');
    $('#selectEtinia').val('');
    this.empresaTerceiraSelected = [];
  }

  getValuesFromInput(type = '') {
    const data = $('#txtDataNasc').val().toString().trim().split('/');
    if (type == 'salvar') {
      return {
        Nome: $('#txtNome').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CPF: $('#txtCPF').val().toString().trim(),
        EmpresaId: this.empresaIdSelected || '',
        ColaboradorTipoId: this.colaboradorTipoSelected || '',
        Sexo: this.sexoIdSelected || '',
        Endereco: $('#txtEnd').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Complemento: $('#txtEndComp').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Bairro: $('#txtBairro').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CEP: $('#txtCep').val().toString().trim(),
        UF: this.dadoPrincipalEstadoSelected || '',
        Cidade: this.dadoPrincipalCidadeSelected || '',
        FotoBase64: this.fotoColaborador.base64,
        DataNascimento: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
        Telefone1: $('#txtTel1').val().toString().trim(),
        Telefone2: $('#txtTel2').val().toString().trim(),
        Telefone3: $('#txtTel3').val().toString().trim(),
        Email: $('#txtEmail').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        NomePai: $('#txtNomePai').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        NomeMae: $('#txtNomeMae').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Numero: $('#txtNumeroEnd').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        MatriculaInterna: $('#txtMatriculaInterna').val().toString().trim(),
        MatriculaeSocial: $('#txtMatriculaESocial').val().toString().trim(),
        PaisNascimento: $('#txtPaisNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        UFNascimento: $('#txtUFNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CidadeNascimento: $('#txtCidadeNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        GrauInstrucaoId: this.grauInstrucaoSelected || '',
        EstadoCivilId: this.estadoCivilSelected || '',
        ColaboradorStatusId: this.colaboradorStatusIdSelected,
        EmpresaTerceiraId: this.empresaTerceiraSelected.map(val => val.item_id)[0],
        Etinia: this.etiniaSelected || '',
      }
    }
    return {
      Nome: $('#txtNome').val().toString().trim(),
      CPF: $('#txtCPF').val().toString().trim(),
      EmpresaId: this.empresaIdSelected || 0,
      ColaboradorTipoId: this.colaboradorTipoSelected || 0,
      Sexo: this.sexoIdSelected || '',
      Endereco: $('#txtEnd').val().toString().trim(),
      Complemento: $('#txtEndComp').val().toString().trim(),
      Bairro: $('#txtBairro').val().toString().trim(),
      CEP: $('#txtCep').val().toString().trim(),
      UF: this.dadoPrincipalEstadoSelected || '',
      Cidade: this.dadoPrincipalCidadeSelected || '',
      FotoBase64: this.fotoColaborador.base64,
      DataNascimento: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      Telefone1: $('#txtTel1').val().toString().trim(),
      Telefone2: $('#txtTel2').val().toString().trim(),
      Telefone3: $('#txtTel3').val().toString().trim(),
      Email: $('#txtEmail').val().toString().trim(),
      NomePai: $('#txtNomePai').val().toString().trim(),
      NomeMae: $('#txtNomeMae').val().toString().trim(),
      Numero: $('#txtNumeroEnd').val().toString().trim(),
      MatriculaInterna: $('#txtMatriculaInterna').val().toString().trim(),
      MatriculaeSocial: $('#txtMatriculaESocial').val().toString().trim(),
      PaisNascimento: $('#txtPaisNasc').val().toString().trim(),
      UFNascimento: $('#txtUFNasc').val().toString().trim(),
      CidadeNascimento: $('#txtCidadeNasc').val().toString().trim(),
      GrauInstrucaoId: this.grauInstrucaoSelected || 0,
      EstadoCivilId: this.estadoCivilSelected || 0,
      ColaboradorStatusId: this.colaboradorStatusIdSelected,
      EmpresaTerceiraId: this.empresaTerceiraSelected.map(val => val.item_id)[0],
      Etinia: this.etiniaSelected || '',
    }

  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      if (this.typeImagesAccepted.includes(file.type)) {
        this.fotoColaborador.base64 = event.target.result;
        this.fotoColaborador.nome = file.name;
        $('#txtFoto').val(file.name);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;

    if (idModal == 'modalEmpresa') {
      console.log(this.userLogged);
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNomeUsuarioId($('#txtNomeEmpresa').val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalEquipe') {
      this.getEquipeGestorDataGrid(idModal);
      this.colaboradorService.BuscarTudoLotacao(this.empresaIdSelected).subscribe(
        res => {
          this.equipeLotacaoList = res;
          this.modalEquipeLotacaoList = res.map(val =>
            ({
              Id: val.Id,
              Description: val.Codigo + ' - ' + val.Nome,
            })
          );
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  getEquipeGestorDataGrid(idModal = '') {
    this.colaboradorService.buscarEquipeGestor({ ColaboradorId: this.colaboradorSelected }).subscribe(
      res => {
        console.log(res);
        this.equipeGridData = res.map(val =>
          ({
            id: val.Id,
            codigoInicial: val.LotacaoUnidadeInicial.Codigo + ' - ' + val.LotacaoUnidadeInicial.Nome,
            codigoFinal: val.LotacaoUnidadeFinal.Codigo + ' - ' + val.LotacaoUnidadeFinal.Nome,
            ...val,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTableModalEquipe(this.equipeGridData);
        if (!($(`#${idModal}`) as any).hasClass('in')) {
          ($(`#${idModal}`) as any).modal('show');
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (!this.colaboradorTipoSelected) {
      ($("#formularioDadosPrincipais") as any).valid();
      $('#selectTipo').addClass('element-invalid');
    } else {
      $('#selectTipo').removeClass('element-invalid');
      if (($("#formularioDadosPrincipais") as any).valid()) {
        const obj: any = this.getValuesFromInput('salvar');
        console.log(obj);
        if (this.isEdit) {
          obj.Id = this.dadoPrincipalIdSelected.toString();
          this.moduleService.editar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else if (res == 'Registro não encontrado') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res,
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  salvarEquipe() {
    if (this.modalEquipeCodigoInicialIdSelected && this.modalEquipeCodigoFinalIdSelected) {
      let codInicial = this.equipeLotacaoList.filter(val => val.Id == this.modalEquipeCodigoInicialIdSelected);
      codInicial = codInicial.length > 0 ? codInicial[0].Codigo : 0;
      let codFinal = this.equipeLotacaoList.filter(val => val.Id == this.modalEquipeCodigoFinalIdSelected);
      codFinal = codFinal.length > 0 ? codFinal[0].Codigo : 0;
      if (codInicial > codFinal) {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'O código inicial não pode ser maior que o código final!',
          showConfirmButton: false,
          timer: 4000
        });
      } else {
        const obj = {
          ColaboradorId: this.colaboradorSelected,
          LotacaoUnidadeInicialId: this.modalEquipeCodigoInicialIdSelected,
          LotacaoUnidadeFinalId: this.modalEquipeCodigoFinalIdSelected,
        }
        this.colaboradorService.adicionarEquipeGestor(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearModalEquipeInputvalues();
              this.getEquipeGestorDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {

    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
        {
          "data": "tipo"
        },
        {
          "data": "empresaNome"
        },
        {
          "data": "uf"
        },
        {
          "data": "cidade"
        },
        {
          "data": "telRes"
        },
        {
          "data": "telCel"
        },
        {
          "data": "telCom"
        },
        {
          "data": "Email"
        },
        {
          "data": "status"
        },
        {
          "data": "senha"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "empresaTerceira"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.ColaboradorTipo.Nome.toString().toLowerCase().includes('gestor')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-user-plus" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Minha Equipe"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-user-plus" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Minha Equipe"> </button></div>';
            }
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[14]).on("click", async function (e) {
          if (data.ColaboradorTipo.Nome.toString().toLowerCase().includes('gestor')) {
            self.empresaIdSelected = data.EmpresaId;
            self.colaboradorSelected = data.id;
            self.empresaTerceiraSelected = data.EmpresaTerceiraId;
            self.openModal('modalEquipe');
          }
        });
        $($(row).children()[15]).on("click", async function (e) {
          // Seta modo edição
          self.isEdit = true;
          // Preencher campos, com a linha selecionada
          ($("#txtDataNasc") as any).datepicker('setDate', data.DataNascimento ? new Date(data.DataNascimento) : '');
          self.dadoPrincipalIdSelected = data.id;
          self.empresaIdSelected = data.empresaId;
          self.colaboradorTipoSelected = data.ColaboradorTipoId;
          self.sexoIdSelected = data.Sexo;
          self.fotoColaborador = {
            nome: '',
            base64: '',
          };
          self.grauInstrucaoSelected = data.GrauInstrucaoId;
          self.estadoCivilSelected = data.EstadoCivilId;
          self.etiniaSelected = data.Etinia;
          self.colaboradorStatusIdSelected = data.ColaboradorStatusId;
          self.dadoPrincipalEstadoSelected = data.UF;
          self.cidadesList = data.UF ? await self.utilService.getCidades(data.UF) : [];
          self.dadoPrincipalCidadeSelected = data.Cidade;
          data.EmpresaTerceiraId ? self.getEmpresaTerceiraPorId(data.EmpresaTerceiraId): self.empresaTerceiraSelected = [];
          $('#txtNomeEmpresa').val(data.Empresa.Nome);
          $('#txtNome').val(data.Nome);
          $('#txtCPF').val(data.CPF);
          $('#txtEnd').val(data.Endereco);
          $('#txtEndComp').val(data.Complemento);
          $('#txtBairro').val(data.Bairro);
          $('#txtNumeroEnd').val(data.Numero);
          $('#txtCep').val(data.CEP);
          $('#txtTel1').val(data.Telefone1);
          $('#txtTel2').val(data.Telefone2);
          $('#txtTel3').val(data.Telefone3);
          $('#txtEmail').val(data.Email);
          $('#txtNomePai').val(data.NomePai);
          $('#txtNomeMae').val(data.NomeMae);
          $('#txtMatriculaInterna').val(data.MatriculaInterna);
          $('#txtMatriculaESocial').val(data.MatriculaeSocial);
          $('#txtPaisNasc').val(data.PaisNascimento);
          $('#txtUFNasc').val(data.UFNascimento);
          $('#txtCidadeNasc').val(data.CidadeNascimento);
          $("#formularioDadosPrincipais").valid();
          if (!self.colaboradorTipoSelected) {
            $('#selectTipo').addClass('element-invalid');
          } else {
            $('#selectTipo').removeClass('element-invalid');
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  getEmpresaTerceiraPorId(id): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisarPorId(id).subscribe(
      res => {
          this.empresaTerceiraSelected = [{
            item_id: res.Id,
            item_text: res.Nome,
          }]
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  loadDataTableModalEquipe(data) {
    let self = this;
    this.dataTable = $(this.gridModalEquipe.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "codigoInicial"
        },
        {
          "data": "codigoFinal"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.removerEquipe(data.id);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.empresaIdSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
