<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Linha Vale Transporte
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Linha Vale Transporte</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome da Linha</label>
                <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome da Linha">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Valor da Linha (R$)</label>
                <input type="text" class="form-control" id="txtValor" name="txtValor" placeholder="0,00">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome da Operadora </label>
                <!-- BUSCAR OS DADOS DA TABELA OperadoraVT -->
                <div class="input-group">
                  <input type="text" class="form-control" id="txtOperadora" name="txtOperadora"
                    placeholder="Nome da Operadora">
                  <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal()"
                      title="Pesquisar Operadora"><i class="fa fa-search"></i></a></span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
            <div class="form-group">
              <label>Empresas</label>
              <ng-multiselect-dropdown [placeholder]="'Selecione uma ou mais empresas...'" [settings]="dropdownSettings"
                [data]="empresasList" [(ngModel)]="empresasSelected" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>

      </form>
    </div>

    <!-- MODAL OPERADORA -->
    <div class="modal fade" id="modalOperadora" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel"><b>Operadora</b></h4>
          </div>
          <div class="modal-body">
            <table #modalOperadoraGrid style="width: 100% !important;" style="cursor:pointer" class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- FIM MODAL OPERADORA -->

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome da Linha</th>
                <th>Valor da Linha (R$)</th>
                <th>Nome da Operadora</th>
                <th>Data de Cadastro</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>

  <div class="control-sidebar-bg"></div>
</div>
