import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoHoraExtraService } from 'src/app/@core/@service/solicitacao/hora-extra/hora-extra.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { UsuarioColaboradorTipoEnum, ColaboradorTipoEnum } from 'src/app/@core/@enum/enum';


declare var $;

@Component({
  selector: 'app-relatorio-hora-extra',
  templateUrl: './hora-extra.component.html',
  styleUrls: ['./hora-extra.component.scss']
})

export class RelatorioHoraExtraComponent implements OnInit {

  gridData = [];

  colaboradorType = '';

  empresaGridData = [];
  empresaSelected = '';
  modalRelatoriogridData: any = {};

  modalRelatorioData: any = {
    colaborador: '',
    matricula: '',
    empresa: '',
  };

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoHoraExtraService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
    this.validarCampos();
    this.loadDataTable([]);    
  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtData: {
          required: true,
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtData: {
          required: "Informe a Data",
        },
      }
    });

    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalConvocados') {
      this.moduleService.buscarConvocadosPorSolicitacaoId(data.Id).subscribe(
        res => {
          this.modalRelatoriogridData = res.map(val =>
            ({
              id: val.Id,
              colaborador: val.Colaborador ? val.Colaborador.Nome : '-',
              matricula: val.Colaborador ? (val.Colaborador.MatriculaInterna ? val.Colaborador.MatriculaInterna : '-') : '-',
              temRefeicao: data.TemRefeicao ? 'Sim' : 'Não',
              temMarmitex: data.TemMarmitex ? 'Sim' : 'Não',
              marmitex: data.Marmitex ? data.Marmitex.Tipo : '-',
            })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTableModalRelatorio(this.modalRelatoriogridData);

          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  getDataGrid() {
    if (!this.empresaSelected) {
      ($("#formulario") as any).valid();
      $('#txtNomeEmpresa').addClass('element-invalid');
    } else {
      if (($("#formulario") as any).valid()) {
        const data = $('#txtData').val().toString().trim().split('/');
        this.moduleService.buscarPorEmpresaIdData(this.empresaSelected, `${data[2]}-${data[1]}-${data[0]}`).subscribe(
          res => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map(val =>
                ({
                  id: val.Id,
                  gestor: val.Gestor ? val.Gestor.Nome : '',
                  matricula: val.Gestor ? val.Gestor.MatriculaInterna : '',
                  data: val.Data || '',
                  horaInicio: val.HoraInicio || '',
                  horaFim: val.HoraFim || '',
                  motivo: val.Motivo || '',
                  cafeManha: val.TemCafeManha ? 'Sim' : 'Não',
                  refeicao: val.TemRefeicao ? 'Sim' : 'Não',
                  marmitex: val.TemMarmitex ? 'Sim' : 'Não',
                  dataSolicitacao: val.DataSolicitacao,
                  ...val
                })
              );
              this.loadDataTable(this.gridData);
            }
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  clearInputvalues(type = '') {
    this.empresaSelected = '';
    $('#txtData').val('');
    $('#txtNomeEmpresa').addClass('element-invalid');
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "gestor"
        },
        {
          "data": "matricula"
        },
        {
          "data": 'data', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": "horaInicio"
        },
        {
          "data": "horaFim"
        },
        {
          "data": "motivo"
        },
        {
          "data": "cafeManha"
        },
        {
          "data": "refeicao"
        },
        {
          "data": "marmitex"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-users" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Convocados"> </button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[10]).on("click", async function (e) {
          self.openModal('modalConvocados', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.empresaSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data) {
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "temRefeicao"
        },
        {
          "data": "temMarmitex"
        },
        {
          "data": "marmitex"
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": true,
      "ordering": true,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
