<div class="box box-primary">
  <form id="formulario">
    <div class="box-body">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Código da Unidade</label>
            <input type="text" class="form-control" id="txtCodigo" name="txtCodigo" placeholder="Código da Unidade">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nome da Unidade</label>
            <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome da Unidade">
          </div>
        </div>
      </div>
    </div>

    <div class="box-footer">
      <table border=0 width="100%">
        <tr width="100%">
          <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
          </td>
          <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
          </td>
          <td align="right">
            <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
              class="btn btn-primary btn-margin" />
            <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
              class="btn btn-info pull-right btn-margin" />
          </td>
        </tr>
      </table>
    </div>
    
  </form>
</div>

<div class="box box-body">
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Código da Unidade</th>
            <th>Nome da Unidade</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>