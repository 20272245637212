import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "src/app/@core/core.module";
import { BeneficioComponent } from "./beneficio/beneficio.component";
import { EmpresaComponent } from "./empresa/empresa.component";
import { CadastroRoutingModule } from "./cadastro-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalComponent } from "../../../@core/components/modal/modal.component";
import { NgxMaskModule } from "ngx-mask";
import { TipoMaoDeObraComponent } from "./tipo-mao-de-obra/tipo-mao-de-obra.component";
import { MarmitexComponent } from "./marmitex/marmitex.component";
import { TipoDeRegistroComponent } from "./tipo-de-registro/tipo-de-registro.component";
import { MotivoHoraExtraComponent } from "./motivo-hora-extra/motivo-hora-extra.component";
import { JustificativaDeDivergenciaComponent } from "./justificativa-de-divergencia/justificativa-de-divergencia.component";
import { CategoriaSalarialComponent } from "./categoria-salarial/categoria-salarial.component";
import { UnidadeDeNegocioComponent } from "./unidade-de-negocio/unidade-de-negocio.component";
import { CentroDeCustoComponent } from "./centro-de-custo/centro-de-custo.component";
import { DocumentoAdmissionalComponent } from "./documento-admissional/documento-admissional.component";
import { DependenteComponent } from "./dependente/dependente.component";
import { TurnoComponent } from "./turno/turno.component";
import { OperadoraVTComponent } from "./operadora-vt/operadora-vt.component";
import { LinhaVTComponent } from "./linha-vt/linha-vt.component";
import { SindicatoComponent } from "./sindicato/sindicato.component";
import { UsuarioComponent } from "./usuario/usuario.component";
import { LotacaoComponent } from "./lotacao/lotacao.component";
import { LotacaoUnidadeComponent } from "./lotacao/unidade/unidade.component";
import { LotacaoPlanoComponent } from "./lotacao/plano/plano.component";
import { CargoComponent } from "./cargo/cargo.component";
import { CargoUnidadeComponent } from "./cargo/unidade/unidade.component";
import { CargoPlanoComponent } from "./cargo/plano/plano.component";
import { CentroDeCustoUnidadeComponent } from "./centro-de-custo/unidade/unidade.component";
import { CentroDeCustoPlanoComponent } from "./centro-de-custo/plano/plano.component";
import { ColaboradorComponent } from "./colaborador/colaborador.component";
import { ColaboradorDadosPrincipaisComponent } from "./colaborador/dados-principais/dados-principais.component";
import { ColaboradorDocumentacaoComponent } from "./colaborador/documentacao/documentacao.component";
import { ColaboradorEmpregadorComponent } from "./colaborador/empregador/empregador.component";
import { ColaboradorPagamentoComponent } from "./colaborador/pagamento/pagamento.component";
import { ColaboradorEstrangeiroComponent } from "./colaborador/estrangeiro/estrangeiro.component";
import { ColaboradorAnexarDocumentoComponent } from "./colaborador/anexar-documento/anexar-documento.component";
import { PreAdmissaoComponent } from "./pre-admissao/pre-admissao.component";
import { PreAdmissaoDadosPrincipaisComponent } from "./pre-admissao/dados-principais/dados-principais.component";
import { PreAdmissaoDocumentacaoComponent } from "./pre-admissao/documentacao/documentacao.component";
import { PreAdmissaoEmpregadorComponent } from "./pre-admissao/empregador/empregador.component";
import { PreAdmissaoPagamentoComponent } from "./pre-admissao/pagamento/pagamento.component";
import { PreAdmissaoEstrangeiroComponent } from "./pre-admissao/estrangeiro/estrangeiro.component";
import { PreAdmissaoAnexarDocumentoComponent } from "./pre-admissao/anexar-documento/anexar-documento.component";
import { BandeiraCartaoVTComponent } from "./bandeira-cartao-vt/bandeira-cartao-vt.component";
import { NgbAccordionModule, NgbTimepicker, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BeneficioComponent,
    EmpresaComponent,
    ModalComponent,
    TipoMaoDeObraComponent,
    MarmitexComponent,
    TipoDeRegistroComponent,
    MotivoHoraExtraComponent,
    JustificativaDeDivergenciaComponent,
    CategoriaSalarialComponent,
    UnidadeDeNegocioComponent,
    CentroDeCustoComponent,
    DocumentoAdmissionalComponent,
    DependenteComponent,
    TurnoComponent,
    OperadoraVTComponent,
    LinhaVTComponent,
    SindicatoComponent,
    UsuarioComponent,
    LotacaoComponent,
    LotacaoUnidadeComponent,
    LotacaoPlanoComponent,
    CargoComponent,
    CargoUnidadeComponent,
    CargoPlanoComponent,
    CentroDeCustoComponent,
    CentroDeCustoUnidadeComponent,
    CentroDeCustoPlanoComponent,
    ColaboradorComponent,
    ColaboradorDadosPrincipaisComponent,
    ColaboradorDocumentacaoComponent,
    ColaboradorEmpregadorComponent,
    ColaboradorPagamentoComponent,
    ColaboradorEstrangeiroComponent,
    ColaboradorAnexarDocumentoComponent,
    PreAdmissaoComponent,
    PreAdmissaoDadosPrincipaisComponent,
    PreAdmissaoDocumentacaoComponent,
    PreAdmissaoEmpregadorComponent,
    PreAdmissaoPagamentoComponent,
    PreAdmissaoEstrangeiroComponent,
    PreAdmissaoAnexarDocumentoComponent,
    BandeiraCartaoVTComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    CadastroRoutingModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    NgbAccordionModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    NgbTimepickerModule,
  ],
  entryComponents: [
    ModalComponent,
    EmpresaComponent,
    BeneficioComponent,
    TipoMaoDeObraComponent,
    MarmitexComponent,
    TipoDeRegistroComponent,
    MotivoHoraExtraComponent,
    JustificativaDeDivergenciaComponent,
    CategoriaSalarialComponent,
    UnidadeDeNegocioComponent,
    CentroDeCustoComponent,
    DocumentoAdmissionalComponent,
    DependenteComponent,
    TurnoComponent,
    OperadoraVTComponent,
    LinhaVTComponent,
    SindicatoComponent,
    UsuarioComponent,
    LotacaoComponent,
    LotacaoUnidadeComponent,
    LotacaoPlanoComponent,
    CargoComponent,
    CargoUnidadeComponent,
    CargoPlanoComponent,
    CentroDeCustoComponent,
    CentroDeCustoUnidadeComponent,
    CentroDeCustoPlanoComponent,
    ColaboradorComponent,
    ColaboradorDadosPrincipaisComponent,
    ColaboradorDocumentacaoComponent,
    ColaboradorEmpregadorComponent,
    ColaboradorPagamentoComponent,
    ColaboradorEstrangeiroComponent,
    ColaboradorAnexarDocumentoComponent,
    PreAdmissaoComponent,
    PreAdmissaoDadosPrincipaisComponent,
    PreAdmissaoDocumentacaoComponent,
    PreAdmissaoEmpregadorComponent,
    PreAdmissaoPagamentoComponent,
    PreAdmissaoEstrangeiroComponent,
    PreAdmissaoAnexarDocumentoComponent,
    BandeiraCartaoVTComponent,
  ],
})
export class CadastroModule {}
