import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/*Custom Files*/
import { RecuperarSenhaRoutingModule } from './recuperar-senha-routing.module';
import { RecuperarSenhaComponent } from './recuperar-senha.component';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [RecuperarSenhaComponent],
  imports: [
    CommonModule,
    RecuperarSenhaRoutingModule,
    NgxMaskModule.forChild()
  ],
})
export class RecuperarSenhaModule { }
