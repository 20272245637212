import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorDocumentacaoService } from 'src/app/@core/@service/cadastro/colaborador/documentacao/documentacao.service';
import Swal from 'sweetalert2';
import { CadastroCargoUnidadeService } from 'src/app/@core/@service/cadastro/cargo/unidade/unidade.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from 'src/app/@core/@enum/enum';

declare var $;
@Component({
  selector: 'app-pre-admissao-documentacao',
  templateUrl: './documentacao.component.html',
  styleUrls: ['./documentacao.component.scss']
})
export class PreAdmissaoDocumentacaoComponent implements OnInit {

  gridData = [];

  gridModalUnidadeData = [];

  colaboradorGridData = [];

  isEdit = false;

  planoIdSelected = '';

  empresaIdSelected = '';

  documentacaoIdSelected = '';

  colaboradorTipoIdSelected = '';

  empresaSelected = '';

  colaboradorSelected = '';

  estadoEmissaoRGSelected = '';
  cidadesList: Array<object> = [];
  estadosList: Array<object> = [];
  statusList: Array<object> = [];
  cidadesEmissaoRICList: Array<object> = [];
  estadoEmissaoRICSelected = '';
  cidadeEmissaoRICSelected = '';
  cidadesEleitoralList: Array<object> = [];
  estadoEleitoralSelected = '';
  cidadeEleitoralSelected = '';
  estadoCTPSSelected = '';
  cidadeCTPSSelected = '';


  fotoColaborador: any = {
    nome: '',
    base64: '',
  };

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  colaboradorStatusIdSelected = '';

  grauInstrucaoSelected = '';

  estadoCivilSelected = '';

  sexoIdSelected = '';

  dataTable: any;

  dataTableModalUnidade: any;

  dataTableModalColaborador: any;

  cargoUnidades = [];


  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };

  userLogged: any;
  colaboradorType = '';

  colaboradorLogged = '';

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalUnidade', { static: true }) gridModalUnidade: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorDocumentacaoService,
    private unidadeService: CadastroCargoUnidadeService,
    private empresaService: CadastroEmpresaService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
     /*$("#txtRG").mask("999.999.999-W", {
      translation: {
        'W': {
          pattern: /[X0-9]/
        }
      },
      reverse: true
    });*/
     this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      /*if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO){
        ($("#btnSalvarDocumentacao") as any).hide();
      }*/

      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && this.userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO) {
        this.colaboradorType = 'pre-admissao';
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.getDataGrid();
      } else
        this.colaboradorType = 'rh';
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtDataEmissaoRG") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataExpRIC") as any).datepicker({
      autoclose: true
    });
    ($("#txtVencimentoHabilitacao") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataEmissaoCTPS") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataEmissaoPIS") as any).datepicker({
      autoclose: true
    });



  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    this.estadosList = await this.utilService.getEstados();
    this.cidadesList = [];
    this.colaboradorService.pesquisarColaboradorStatus().subscribe(
      res => {
        this.statusList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectEstadoEmissaoRG') {
      this.estadoEmissaoRGSelected = event.target.value;
      //this.cidadesList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectEstadoEmissaoRIC') {
      this.estadoEmissaoRICSelected = event.target.value;
      this.cidadesEmissaoRICList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidadeEmissaoRIC') {
      this.cidadeEmissaoRICSelected = event.target.value;
    } else if (event.srcElement.id == 'selectEstadoEleitoral') {
      this.estadoEleitoralSelected = event.target.value;
      this.cidadesEleitoralList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidadeEleitoral') {
      this.cidadeEleitoralSelected = event.target.value;
    } else if (event.srcElement.id == 'selectEstadoCTPS') {
      this.estadoCTPSSelected = event.target.value;
    }
    ($("#formularioDocumentacao") as any).valid();
  }

  validarCampos() {
    const self = this;
    ($("#formularioDocumentacao") as any).validate({
      // Define as regras
      rules: {
        txtNomeColaboradorDocumentacao: {
          required: true,
          colaboradorCheck: true,
        },
        txtRG: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeColaboradorDocumentacao: {
          required: "Informe o Nome do Colaborador"
        },
        txtRG: {
          required: "Informe o RG"
        }
      },
    });
    $.validator.addMethod("colaboradorCheck", function (value, element) {
      return self.colaboradorSelected ? true : false;
    }, 'Selecione o colaborador');
    ($("#formularioDocumentacao") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    if (this.colaboradorType == 'pre-admissao') {
      this.colaboradorService.BuscarDocumentacaoColaboradorPreAdmissaoPorId(this.colaboradorLogged).subscribe(
        async res => {
          // Seta modo edição
          this.isEdit = true;
          this.documentacaoIdSelected = res.Id;
          // Preencher campos, com a linha selecionada
          ($("#txtDataEmissaoRG") as any).datepicker('setDate', res.DataEmissaoRG ? new Date(res.DataEmissaoRG) : '');
          ($("#txtDataExpRIC") as any).datepicker('setDate', res.DataExpedicaoRIC ? new Date(res.DataExpedicaoRIC) : '');
          ($("#txtVencimentoHabilitacao") as any).datepicker('setDate', res.DataVencimentoHabilitacao ? new Date(res.DataVencimentoHabilitacao) : '');
          ($("#txtDataEmissaoCTPS") as any).datepicker('setDate', res.DataEmissaoCTPS ? new Date(res.DataEmissaoCTPS) : '');
          ($("#txtDataEmissaoPIS") as any).datepicker('setDate', res.DataEmissaoPISNITNIS ? new Date(res.DataEmissaoPISNITNIS) : '');

          this.empresaIdSelected = res.empresaId;
          this.estadoCTPSSelected = res.UFCTPS;
          this.estadoEleitoralSelected = res.UFEleitoral;
          this.cidadesEleitoralList = await this.utilService.getCidades(res.UFEleitoral);
          this.cidadeEleitoralSelected = res.CidadeEleitoral;
          this.estadoEmissaoRICSelected = res.UFEmissaoRIC;
          this.cidadesEmissaoRICList = await this.utilService.getCidades(res.UFEmissaoRIC);
          this.cidadeEmissaoRICSelected = res.CidadeEmissaoRIC;
          this.estadoEmissaoRGSelected = res.UFEmissaoRG;
          this.colaboradorSelected = res.ColaboradorId;
          $('#txtEmpresaDocumentacao').val(res.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorDocumentacao').val(res.Colaborador.Nome);
          $('#txtRG').val(res.RG);
          $('#txtOrgaoEmissorRG').val(res.OrgaoEmissorRG);
          $('#txtRIC').val(res.RIC);
          $('#txtOrgaoEmissorRIC').val(res.OrgaoEmissorRIC);
          $('#txtCartaoSaude').val(res.CartaoNacionalSaude);
          $('#txtNumeroReservista').val(res.NumeroReservista);
          $('#txtTituloEleitor').val(res.TituloEleitor);
          $('#txtZonaEleitoral').val(res.ZonaEleitoral);
          $('#txtSecaoEleitoral').val(res.SecaoEleitoral);
          $('#txtCarteiraHabilitacao').val(res.CarteiraHabilitacao);
          $('#txtCategoria').val(res.CategoriaHabilitacao);
          $('#txtNumeroCTPS').val(res.NumeroCTPS);
          $('#txtSerieCTPS').val(res.SerieCTPS);
          $('#txtPIS').val(res.PISNITNIS);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.moduleService.pesquisarPreAdmissaoPorUsuarioId(data, this.userLogged.UsuarioColaboradorId).subscribe(
        res => {
          this.gridData = res.map(val =>
            ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              rg: val.RG,
              ric: val.RIC,
              cartaoNacionalSaude: val.CartaoNacionalSaude,
              reservista: val.NumeroReservista,
              tituloEleitor: val.TituloEleitor,
              habilitacao: val.CarteiraHabilitacao,
              ctps: val.NumeroCTPS,
              pis: val.PISNITNIS,
              dataCadastro: val.DataCadastro,
              ...val
            })
          ).sort(this.utilService.dynamicSort('nomeColaborador'));
          this.loadDataTable(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  clearInputvalues() {
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos

    this.estadoCTPSSelected = '';
    this.estadoEleitoralSelected = '';
    this.cidadeEleitoralSelected = '';
    this.estadoEmissaoRICSelected = '';
    this.cidadeEmissaoRICSelected = '';
    this.estadoEmissaoRGSelected = '';
    this.colaboradorSelected = '';
    this.empresaIdSelected = '';
    $('#txtEmpresaDocumentacao').val('');
    $('#txtNomeColaboradorDocumentacao').val('');


    $('#txtRG').val('');
    $('#txtOrgaoEmissorRG').val('');
    $('#txtDataEmissaoRG').val('');
    $('#txtRIC').val('');
    $('#txtOrgaoEmissorRIC').val('');
    $('#txtDataExpRIC').val('');
    $('#txtCartaoSaude').val('');
    $('#txtNumeroReservista').val('');
    $('#txtTituloEleitor').val('');
    $('#txtZonaEleitoral').val('');
    $('#txtSecaoEleitoral').val('');
    $('#txtCarteiraHabilitacao').val('');
    $('#txtCategoria').val('');
    $('#txtVencimentoHabilitacao').val('');
    $('#txtNumeroCTPS').val('');
    $('#txtSerieCTPS').val('');
    $('#txtDataEmissaoCTPS').val('');
    $('#txtPIS').val('');
    $('#txtDataEmissaoPIS').val('');

  }

  getValuesFromInput() {
    const dataEmissaoRG = $('#txtDataEmissaoRG').val().toString().trim().split('/');
    const dataExpedicaoRIC = $('#txtDataExpRIC').val().toString().trim().split('/');
    const dataVencimentoCNH = $('#txtVencimentoHabilitacao').val().toString().trim().split('/');
    const dataEmissaoCTPS = $('#txtDataEmissaoCTPS').val().toString().trim().split('/');
    const dataEmissaoPISNITNIS = $('#txtDataEmissaoPIS').val().toString().trim().split('/');

    return {
      ColaboradorId: this.colaboradorSelected || '',
      RG: $('#txtRG').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      OrgaoEmissorRG: $('#txtOrgaoEmissorRG').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      UFEmissaoRG: this.estadoEmissaoRGSelected || '',
      DataEmissaoRG: dataEmissaoRG.length >= 3 ? `${dataEmissaoRG[2]}-${dataEmissaoRG[1]}-${dataEmissaoRG[0]}` : '',
      RIC: $('#txtRIC').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      UFEmissaoRIC: this.estadoEmissaoRICSelected || '',
      CidadeEmissaoRIC: this.cidadeEmissaoRICSelected || '',
      OrgaoEmissorRIC: $('#txtOrgaoEmissorRIC').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      DataExpedicaoRIC: dataExpedicaoRIC.length >= 3 ? `${dataExpedicaoRIC[2]}-${dataExpedicaoRIC[1]}-${dataExpedicaoRIC[0]}` : '',
      CartaoNacionalSaude: $('#txtCartaoSaude').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      NumeroReservista: $('#txtNumeroReservista').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      TituloEleitor: $('#txtTituloEleitor').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      ZonaEleitoral: $('#txtZonaEleitoral').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      SecaoEleitoral: $('#txtSecaoEleitoral').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      UFEleitoral: this.estadoEleitoralSelected || '',
      CidadeEleitoral: this.cidadeEleitoralSelected || '',
      CarteiraHabilitacao: $('#txtCarteiraHabilitacao').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      CategoriaHabilitacao: $('#txtCategoria').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      DataVencimentoHabilitacao: dataVencimentoCNH.length >= 3 ? `${dataVencimentoCNH[2]}-${dataVencimentoCNH[1]}-${dataVencimentoCNH[0]}` : '',
      NumeroCTPS: $('#txtNumeroCTPS').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      SerieCTPS: $('#txtSerieCTPS').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      UFCTPS: this.estadoCTPSSelected || '',
      DataEmissaoCTPS: dataEmissaoCTPS.length >= 3 ? `${dataEmissaoCTPS[2]}-${dataEmissaoCTPS[1]}-${dataEmissaoCTPS[0]}` : '',
      PISNITNIS: $('#txtPIS').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase() || '',
      DataEmissaoPISNITNIS: dataEmissaoPISNITNIS.length >= 3 ? `${dataEmissaoPISNITNIS[2]}-${dataEmissaoPISNITNIS[1]}-${dataEmissaoPISNITNIS[0]}` : '',
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;
    if (idModal == 'modalColaboradorDocumentacao') {
      if ($('#txtNomeColaboradorDocumentacao').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPreAdmissaoPorNomePorUsuarioId($('#txtNomeColaboradorDocumentacao').val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar(concluir:boolean) {
    if (($("#formularioDocumentacao") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.documentacaoIdSelected;
        this.moduleService.editar(obj,concluir).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj,concluir).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    ;
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nomeColaborador"
        },
        {
          "data": "nomeEmpresa"
        },
        {
          "data": "rg"
        },
        {
          "data": "ric"
        },
        {
          "data": "cartaoNacionalSaude"
        },
        {
          "data": "reservista"
        },
        {
          "data": "tituloEleitor"
        },
        {
          "data": "habilitacao"
        },
        {
          "data": "ctps"
        },
        {
          "data": "pis"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[11]).on("click", async function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          ($("#txtDataEmissaoRG") as any).datepicker('setDate', data.DataEmissaoRG ? new Date(data.DataEmissaoRG) : '');
          ($("#txtDataExpRIC") as any).datepicker('setDate', data.DataExpedicaoRIC ? new Date(data.DataExpedicaoRIC) : '');
          ($("#txtVencimentoHabilitacao") as any).datepicker('setDate', data.DataVencimentoHabilitacao ? new Date(data.DataVencimentoHabilitacao) : '');
          ($("#txtDataEmissaoCTPS") as any).datepicker('setDate', data.DataEmissaoCTPS ? new Date(data.DataEmissaoCTPS) : '');
          ($("#txtDataEmissaoPIS") as any).datepicker('setDate', data.DataEmissaoPISNITNIS ? new Date(data.DataEmissaoPISNITNIS) : '');

          self.documentacaoIdSelected = data.id;
          self.empresaIdSelected = data.empresaId;
          self.estadoCTPSSelected = data.UFCTPS;
          self.estadoEleitoralSelected = data.UFEleitoral;
          self.cidadesEleitoralList = await self.utilService.getCidades(data.UFEleitoral);
          self.cidadeEleitoralSelected = data.CidadeEleitoral;
          self.estadoEmissaoRICSelected = data.UFEmissaoRIC;
          self.cidadesEmissaoRICList = await self.utilService.getCidades(data.UFEmissaoRIC);
          self.cidadeEmissaoRICSelected = data.CidadeEmissaoRIC;
          self.estadoEmissaoRGSelected = data.UFEmissaoRG;
          self.colaboradorSelected = data.ColaboradorId;
          $('#txtEmpresaDocumentacao').val(data.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorDocumentacao').val(data.Colaborador.Nome);
          $('#txtRG').val(data.RG);
          $('#txtOrgaoEmissorRG').val(data.OrgaoEmissorRG);
          $('#txtRIC').val(data.RIC);
          $('#txtOrgaoEmissorRIC').val(data.OrgaoEmissorRIC);
          $('#txtCartaoSaude').val(data.CartaoNacionalSaude);
          $('#txtNumeroReservista').val(data.NumeroReservista);
          $('#txtTituloEleitor').val(data.TituloEleitor);
          $('#txtZonaEleitoral').val(data.ZonaEleitoral);
          $('#txtSecaoEleitoral').val(data.SecaoEleitoral);
          $('#txtCarteiraHabilitacao').val(data.CarteiraHabilitacao);
          $('#txtCategoria').val(data.CategoriaHabilitacao);
          $('#txtNumeroCTPS').val(data.NumeroCTPS);
          $('#txtSerieCTPS').val(data.SerieCTPS);
          $('#txtPIS').val(data.PISNITNIS);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          self.empresaIdSelected = data.Empresa.Id;
          $('#txtEmpresaDocumentacao').val(data.Empresa.Nome);
          $('#txtNomeColaboradorDocumentacao').val(data.nome);
          self.closeModal('modalColaboradorDocumentacao');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }

}
