import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoBeneficioService } from 'src/app/@core/@service/solicitacao/beneficio/beneficio.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';

declare var $;

@Component({
  selector: 'app-solicitacao-beneficio',
  templateUrl: './beneficio.component.html',
  styleUrls: ['./beneficio.component.scss']
})

export class SolicitacaoBeneficioComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  operadoraVTSelected = ''
  operadoraVTList: Array<any> = [];
  linhaVTSelected = ''
  linhaVTList: Array<any> = [];
  utilizacaoSelected = ''
  utilizacaoList: Array<any> = [];
  situacaoSelected = ''
  situacaoList: Array<any> = [];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoBeneficioService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
    private linhaVTService: CadastroLinhaVTService,
    private operadoraVTService: CadastroOperadoraVTService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.getDataGrid();
  }

  getDataGrid() {
    this.moduleService.pesquisarPorColaboradorId(this.colaboradorSelected).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Beneficio ? val.Beneficio.Nome : '',
            regraDesconto: val.Beneficio ? val.Beneficio.RegraDesconto : '',
            tipo: val.Beneficio ? (val.Beneficio.BeneficioTipo ? val.Beneficio.BeneficioTipo.Nome : '') : '',
            custo: val.Beneficio ? val.Beneficio.CustoBeneficio : '',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '',
            dataSolicitacao: val.DataSolicitacao ? (val.DataSolicitacao == '0001-01-01T00:00:00' ? '' : val.DataSolicitacao) : '',
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  solicitar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja solicitar esse benefício?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.solicitar(
          {
            ColaboradorId: this.colaboradorSelected,
            BeneficioId: data.Beneficio.Id
          }).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.getDataGrid();
                Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Benefício solicitado!',
                  showConfirmButton: false,
                  timer: 2000
                });
              }
            },
            err => {
              console.log(err);
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Ocorreu um erro ao solicitar benefício!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          );
      }
    });

  }

  cancelar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja cancelar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.cancelarSolicitacao(data.id).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.getDataGrid();
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Solicitação de benfício cancelada!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          },
          err => {
            console.log(err);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao cancelar a solicitação do benefício!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
    });

  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "regraDesconto"
        },
        {
          "data": "tipo"
        },
        {
          "data": 'custo', "orderable": true, "render": function (data) {
            return 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (!row.SolicitacaoStatusId || row.SolicitacaoStatusId == 0) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-plus" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Solicitar"> </button></div>';
            } else if (row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('aprovado')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Cancelar"> </button></div>';
            } else {
              return '';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[7]).on("click", function (e) {
          if (!data.SolicitacaoStatusId || data.SolicitacaoStatusId == 0) {
            //Solicitar
            self.solicitar(data);
          } else if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('aprovado')) {
            //Cancelar
            self.cancelar(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
