import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { AprovacaoAlteracaoCadastralService } from 'src/app/@core/@service/aprovacao/alteracao-cadastral/alteracao-cadastral.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';



@Component({
  selector: 'app-aprovacao-alteracao-cadastral',
  templateUrl: './alteracao-cadastral.component.html',
  styleUrls: ['./alteracao-cadastral.component.scss']
})
export class AprovacaoAlteracaoCadastralComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  colaboradorSelected = '';

  gestorSelected

  statusSelected = '';

  statusList: Array<any>;

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  @ViewChild('gridModalGestor', { static: true }) gridModalGestor: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: AprovacaoAlteracaoCadastralService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selStatus') {
      this.statusSelected = event.target.value;
    }
  }

  reprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja reprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.reprovar(data.id).subscribe(
          res => {
            this.utilService.alertSwal('reprove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('reprove-error');
          }
        );
      }
    })
  }

  aprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja aprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.aprovar(data.id).subscribe(
          res => {
            this.utilService.alertSwal('approve-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('approve-error');
          }
        );
      }
    })
  }


  getIntitialsData() {
    this.loadDataTable([]);
    this.moduleService.buscarStatus().subscribe(
      res => {
        this.statusList = res.map(val =>
        ({
          Id: val.Id || '',
          Description: val.Nome || '',
        })
        ).sort(this.utilService.dynamicSort('nome'));
      },
      err => {
        console.log(err);
      }
    );
  }

  getValuesFromInput() {
    return {
      GestorId: this.gestorSelected,
      ColaboradorId: this.colaboradorSelected,
      SolicitacaoStatusId: this.statusSelected,
    }
  }


  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
        ({
          id: val.Id || '',
          colaborador: val.Colaborador ? val.Colaborador.Nome : '',
          centroCusto: val.CentroCustoUnidade ? val.CentroCustoUnidade.Nome : '-',
          endereco: val.Endereco || '-',
          numero: val.Numero || '-',
          complemento: val.Complemento || '-',
          bairro: val.Bairro || '-',
          cep: val.CEP || '-',
          estado: val.UF || '-',
          cidade: val.Cidade || '-',
          pais: val.Pais || '-',
          tel1: val.Telefone1 || '-',
          tel2: val.Telefone2 || '-',
          tel3: val.Telefone3 || '-',
          email: val.Email || '-',
          categoriaCNH: val.CategoriaHabilitacao || '-',
          vencimentoCNH: val.DataVencimentoHabilitacao || '-',
          banco: val.Banco ? val.Banco.Nome : '-',
          agencia: val.Agencia || '-',
          conta: val.Conta || '-',
          tipoConta: val.ContaBancariaTipo ? val.ContaBancariaTipo.Nome : '-',
          status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
          dataSolicitacao: val.DataSolicitacao,
          dataConclusao: val.DataConclusao,
          ...val,
        })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {
    // Zera os campos
    this.colaboradorSelected = '';
    this.statusSelected = '';
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cpf: val.CPF,
              ...val
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  exibirFoto(data: any = {}, type = '') {
    if (type == 'compResidencia') {
      this.moduleService.buscarFotoComprovanteResidencia(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Comprovante de Residência não encontrado!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar o Comprovante de Residência!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    } else if (type == 'cnh') {
      this.moduleService.buscarFotoCarteiraHabilitacao(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Carteira de Habilitação não encontrada!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar Carteira de Habilitação!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    } else if (type == 'foto') {
      this.moduleService.buscarFotoSolicitacaoAlteracao(data.id).subscribe(
        res => {
          if (res == "Documento não encontrado") {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Foto não encontrada!',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            if (res.includes('base64')) {
              if (!res.includes('base64,')) {
                res = res.replace('base64', 'base64,');
              }
              Swal.fire({
                confirmButtonColor: '#3C8DBC',
                position: 'top',
                title: "",
                text: "",
                imageUrl: res,
              });
            }
          }
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao buscar a foto!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "centroCusto"
        },
        {
          "data": "endereco"
        },
        {
          "data": "numero"
        },
        {
          "data": "complemento"
        },
        {
          "data": "bairro"
        },
        {
          "data": "cep"
        },
        {
          "data": "estado"
        },
        {
          "data": "cidade"
        },
        {
          "data": "pais"
        },
        {
          "data": "tel1"
        },
        {
          "data": "tel2"
        },
        {
          "data": "tel3"
        },
        {
          "data": "email"
        },
        {
          "data": "categoriaCNH"
        },
        {
          "data": "vencimentoCNH"
        },
        {
          "data": "banco"
        },
        {
          "data": "agencia"
        },
        {
          "data": "conta"
        },
        {
          "data": "tipoConta"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.FotoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Foto"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Foto"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.ComprovanteResidenciaNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Comprovante de Residência"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Comprovante de Residência"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.CarteiraHabilitacaoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Carteira de Habilitação"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Carteira de Habilitação"></button></div>';
            }

          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            }
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[20]).on("click", function (e) {
          if (data.FotoNome) {
            self.exibirFoto(data, 'foto');
          }
        });
        $($(row).children()[21]).on("click", function (e) {
          if (data.ComprovanteResidenciaNome) {
            self.exibirFoto(data, 'compResidencia');
          }
        });
        $($(row).children()[22]).on("click", function (e) {
          if (data.CarteiraHabilitacaoNome) {
            self.exibirFoto(data, 'cnh');
          }
        });
        $($(row).children()[26]).on("click", function (e) {
          // aprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.aprovar(data);
          }
        });
        $($(row).children()[27]).on("click", async function (e) {
          // reprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.reprovar(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalGestor(data) {
    let self = this;
    this.dataTable = $(this.gridModalGestor.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.gestorSelected = data.id;
          $('#txtNomeGestor').val(data.nome);
          self.closeModal('modalGestor');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
