<form id="formularioEstrangeiro">
  <div style="margin-left: 0px;">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeColaboradorEstrangeiro" name="txtNomeColaboradorEstrangeiro"
                  placeholder="Nome do Colaborador">
                <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaboradorEstrangeiro')"
                    title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <input type="text" id="txtEmpresaEstrangeiro" name="txtEmpresaEstrangeiro" class="form-control" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px;">
    <div class="box box-primary">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Origem</label>
              <input type="text" class="form-control" id="txtOrigem" name="txtOrigem" placeholder="Origem">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Tipo Visto Estrangeiro</label>
              <!-- BUSCAR OS DADOS DA TABELA VISTO TIPO -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoVistoEstrangeiroList"
              IdSelect="selVistoEstrangeiro" [Value]="tipoVistoEstrangeiroSelected">
            </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Passaporte</label>
              <input type="text" id="txtPassaporte" name="txtPassaporte" class="form-control" placeholder="Passaporte">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Orgão Emissor Passaporte</label>
              <input type="text" id="txtOrgaoPassaporte" name="txtOrgaoPassaporte" class="form-control"
                placeholder="Orgão Emissor Passaporte">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>País Emissor Passaporte (Sigla)</label>
              <input type="text" class="form-control" id="txtPaisPassaporte" name="txtPaisPassaporte"
                placeholder="País Emissor Passaporte Sigla">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF Passporte</label>
              <input type="text" class="form-control" id="txtUFPassaporte" name="txtUFPassaporte"
                placeholder="UF Passaporte">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data Emissão Passaporte</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right" id="txtDataEmissaoPassaporte"
                  name="txtDataEmissaoPassaporte" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Portaria Naturalização</label>
              <input type="text" id="txtPortariaNaturalizazao" name="txtPortariaNaturalizazao" class="form-control"
                placeholder="Portaria Naturalização">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Identidade Estrangeira</label>
              <input type="text" class="form-control" id="txtIdentEstrangeira" name="txtIdentEstrangeira"
                placeholder="Identidade Estrangeira">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Validade Identidade Estrangeira</label>
              <input type="text" class="form-control" id="txtValidadeIdentEstrangeira"
                name="txtValidadeIdentEstrangeira" placeholder="Validade Identidade Estrangeira">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Ano Chegada</label>
              <input type="text" class="form-control" mask="0000" id="txtAnoChegada" name="txtAnoChegada" placeholder="yyyy">
            </div>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                class="btn btn-primary btn-margin" />
              <input name="Salvar" id="btnSalvarEstrangeiro" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar()"
                class="btn btn-info pull-right btn-margin" />
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</form>
<div class="box box-body">
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome do Colaborador</th>
            <th>Nome da Empresa</th>
            <th>Origem</th>
            <th>Tipo Visto</th>
            <th>Passaporte</th>
            <th>Orgão Emissor</th>
            <th>País Emissor (Sigla)</th>
            <th>UF</th>
            <th>Data Emissão</th>
            <th>Portaria Nat.</th>
            <th>Identidade Est.</th>
            <th>Valiade Ident Est.</th>
            <th>Ano Chegada</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalColaboradorEstrangeiro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Colaborador</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Status</th>
              <th>Data Admissão</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
