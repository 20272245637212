import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  private isAuthenticated = true;
  constructor(private router: Router) { }

  canActivate() {

    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.isAuthenticated = user ? true : false;
    if (!this.isAuthenticated) {
      this.router.navigate(['/Login']);
    }
    return this.isAuthenticated;
  }
}
