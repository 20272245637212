import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { LancamentoAtestadoService } from 'src/app/@core/@service/lancamento/atestado/atestado.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';

declare var $;

@Component({
  selector: 'app-lancamento-atestado',
  templateUrl: './atestado.component.html',
  styleUrls: ['./atestado.component.scss']
})

export class LancamentoAtestadoComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  fotoSelected = '';

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: LancamentoAtestadoService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
    private linhaVTService: CadastroLinhaVTService,
    private operadoraVTService: CadastroOperadoraVTService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.validarCampos();
    this.getDataGrid();
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtData: {
          required: true
        },
        txtHoraChegada: {
          required: true,
          horaCheck: true,
        },
        txtHoraSaida: {
          required: true,
          horaCheck: true,
        },
        txtQtdDias: {
          required: true
        },
        txtFoto: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtData: {
          required: "Informe a Data"
        },
        txtHoraChegada: {
          required: "Informe o Horário de Chegada"
        },
        txtHoraSaida: {
          required: "Informe o Horário de Saída"
        },
        txtQtdDias: {
          required: "Informe a Quantidade de Dias"
        },
        txtFoto: {
          required: "Selecione o Atestado"
        }
      },
    });
    $.validator.addMethod("horaCheck", function (value, element) {
      return self.utilService.verificarHora(value);
    }, 'Hora inválida');
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      if (this.typeImagesAccepted.includes(file.type)) {
        this.fotoSelected = event.target.result;
        $('#txtFoto').val(file.name);
        $('#txtFoto').removeClass('element-invalid');
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.colaboradorSelected).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            data: val.DataAtestado || '-',
            horarioChegada: val.HorarioChegada || '-',
            horarioSaida: val.HorarioSaida || '-',
            quantidadeDias: val.QuantidadeDias || '-',
            CID: val.CID || '-',
            status: val.LancamentoStatus ? val.LancamentoStatus.Nome : '-',
            dataConclusao: val.DataConclusao,
            ...val
          })
        );
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }
  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();

    this.fotoSelected = '';
    $('#txtData').val('');
    $('#txtHoraChegada').val('');
    $('#txtHoraSaida').val('');
    $('#txtQtdDias').val('');
    $('#txtCID').val('');
    $('#txtFoto').val('');
  }

  getValuesFromInput() {
    const data = $('#txtData').val().toString().trim().split('/');
    return {
      ColaboradorId: this.colaboradorSelected,
      DataAtestado: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      HorarioChegada: $('#txtHoraChegada').val().toString().trim(),
      HorarioSaida: $('#txtHoraSaida').val().toString().trim(),
      QuantidadeDias: $('#txtQtdDias').val().toString().trim(),
      CID: $('#txtCID').val().toString().trim(),
      AtestadoBase64: this.fotoSelected,
    }
  }

  lancar() {
    if (!this.fotoSelected) {
      ($("#formulario") as any).valid()
      $('#txtFoto').addClass('element-invalid');
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.lancar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  exibirDocumento(data: any = {}) {
    this.moduleService.buscarFotoLancamentoAtestado(data.id).subscribe(
      res => {
        if (res.includes('base64')) {
          res = res.replace('base64', 'base64,');
          Swal.fire({
            confirmButtonColor: '#3C8DBC',
            position: 'top',
            title: "",
            text: "",
            imageUrl: res,
          });
        }
      },
      err => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Erro ao buscar imagem',
          showConfirmButton: false,
          timer: 2000
        });
        console.log(err);
      }
    );
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'data', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "horarioChegada"
        },
        {
          "data": "horarioSaida"
        },
        {
          "data": "quantidadeDias"
        },
        {
          "data": "CID"
        },

        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.AtestadoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Atestado"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Atestado"></button></div>';
            }

          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[5]).on("click", function (e) {
          if (data.AtestadoNome) {
            self.exibirDocumento(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
