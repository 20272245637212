<div class="login-box">
  <div class="login-logo">
    <img src="../../../assets/Logotipo Meu RH Online em fundo Transparente - Principal.png" style="max-width: -webkit-fill-available; max-height: -webkit-fill-available; 
    height: 150px;
    width: 240px;">
  </div>
  <!-- /.login-logo -->
  <div class="login-box-body">
    <form id="formulario">
      <div class="form-group has-feedback">
        <span class="glyphicon glyphicon-user form-control-feedback"></span>
        <input #user id="inputCpf" mask="000.000.000-00" name="inputCpf" type="text" class="form-control" placeholder="Cpf">
      </div>
      <div class="form-group has-feedback">
        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        <input #password id="inputPassword" name="inputPassword" type="password" class="form-control" placeholder="Senha">
      </div>
      <div class="row">
        <div class="col-xs-8">
          <div class="checkbox icheck">
            <label>
              <input type="checkbox"> Lembrar-me
            </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-xs-4">
          <input type="submit" id="Enviar" name="Enviar" value="Entrar" (click)="login()" class="btn btn-primary btn-block btn-flat" />
        </div>
        <!-- /.col -->
      </div>
    </form>
    <a (click)="alterarSenha()">Alterar senha</a><br>
    <a (click)="recuperarSenha()">Recuperar minha senha</a><br>
    <!--<a href="register.html" class="text-center">Registrar um novo membro</a>-->

  </div>
  <!-- /.login-box-body -->
</div>