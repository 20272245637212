<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Movimentação Contratual
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Solicitação</li>
      <li class="active">Movimentação Contratual</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-info">
          <div class="box-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="colaboradorList"
                    IdSelect="selColaborador" [Value]="colaboradorSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nome da Empresa</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="empresaList"
                    [Disabled]="!colaboradorSelected ? true : false" IdSelect="selEmpresa" [Value]="empresaSelected">
                  </select-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Lotação</label>
                  <!-- BUSCAR OS DADOS DA TABELA LOTAÇÃO -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="lotacaoList"
                    [Disabled]="!empresaSelected ? true : false" IdSelect="selPlanoLotacao" [Value]="lotacaoSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Centro de Custo</label>
                  <!-- BUSCAR OS DADOS DA TABELA CENTRO DE CUSTO -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="centroCustoList"
                    [Disabled]="!empresaSelected ? true : false" IdSelect="selCentroCusto"
                    [Value]="centroCustoSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Cargo</label>
                  <!-- BUSCAR OS DADOS DA TABELA CARGO -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="cargoList" IdSelect="selCargo"
                    [Disabled]="!empresaSelected ? true : false" [Value]="cargoSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Turno</label>
                  <!-- BUSCAR OS DADOS DA TABELA TURNO -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="turnoList" IdSelect="selTurno"
                    [Value]="turnoSelected">
                  </select-component>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Unidade de Negócio</label>
                  <!-- BUSCAR OS DADOS DA TABELA UNIDADE DE NEGÓCIO -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="unidadeNegocioList"
                    IdSelect="selUnidade" [Value]="unidadeNegocioSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Cartão de Ponto</label>
                  <input type="text" class="form-control" id="txtCartaoPonto" name="txtCartaoPonto"
                    placeholder="Número do Cartão de Ponto">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Tipo de Mão de Obra</label>
                  <!-- BUSCAR OS DADOS DA TABELA TIPO MÃO DE OBRA -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoMaoObraList"
                    IdSelect="selTipoMaoObra" [Value]="tipoMaoObraSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Salário (R$)</label>
                  <input type="text" class="form-control" id="txtSalario" name="txtSalario" placeholder="Custo">
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input value="Solicitar" readonly="readonly" (click)="solicitar()" class="btn btn-info pull-right btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Nome do Colaborador</th>
                  <th style="width: 15% !important">Nome da Empresa</th>
                  <th style="width: 15% !important">Lotação</th>
                  <th style="width: 15% !important">Centro de Custo</th>
                  <th style="width: 15% !important">Tipo de Mão de Obra</th>
                  <th style="width: 15% !important">Turno</th>
                  <th style="width: 15% !important">Unidade de Negócio</th>
                  <th style="width: 15% !important">Cartão de Ponto</th>
                  <th style="width: 15% !important">Cargo</th>
                  <th style="width: 15% !important">Salário</th>
                  <th style="width: 15% !important">Status</th>
                  <th style="width: 15% !important">Data da Solicitação</th>
                  <th style="width: 15% !important">Data da Conclusão</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>