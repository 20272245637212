import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoInformeRendimentoService } from 'src/app/@core/@service/importacao/informe-de-rendimentos/informe-de-rendimentos.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

declare var $;

@Component({
  selector: 'app-relatorio-informe-rendimentos',
  templateUrl: './informe-rendimentos.component.html',
  styleUrls: ['./informe-rendimentos.component.scss']
})

export class RelatorioInformeRendimentosComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  modalRelatorioData: any = {
    cpf: '',
    colaborador: '',
    empresa: '',
    ano: '',
    mes: '',
    totalProventos: '',
    totalDescontos: '',
    liquido: '',
    salarioBase: '',
    salarioInss: '',
    baseCalcFgts: '',
    fgtsMes: '',
    baseCalcIRRF: '',
  };

  relatorioData: any;

  colaboradorSelected = ''

  arquivoAnexoSelected = '';

  typeImagesAccepted = ['text/plain'];

  dataTable: any;
  dataTableExport: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;
  @ViewChild('gridModalRelatorio1', { static: true }) gridModalRelatorio1: ElementRef;
  @ViewChild('gridModalRelatorio2', { static: true }) gridModalRelatorio2: ElementRef;
  @ViewChild('gridModalRelatorio3', { static: true }) gridModalRelatorio3: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoInformeRendimentoService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.validarCampos();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe a Data"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalRelatorio') {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        res => {
          this.modalRelatorioData = {
            matricula: res.Colaborador ? res.Colaborador.MatriculaInterna : '',
            colaborador: res.Colaborador ? res.Colaborador.Nome : '',
            empresa: data.Colaborador ? (data.Colaborador.Empresa ? data.Colaborador.Empresa.Nome : '') : '',
            ano: res.Ano || '',
            ...res,
          };
          this.loadDataTableModalRelatorio(res.TipoRendimentosTributaveis, $(this.gridModalRelatorio1.nativeElement));
          this.loadDataTableModalRelatorio(res.TipoRendimentosIsentos, $(this.gridModalRelatorio2.nativeElement));
          this.loadDataTableModalRelatorio(res.TipoRendimentosSujeitosTrib, $(this.gridModalRelatorio3.nativeElement));
          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Ocorreu um erro ao buscar a os dados da importação!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  getDataGrid() {
    if (($("#formulario") as any).valid()) {
      this.moduleService.pesquisar(this.colaboradorSelected, $('#txtAno').val().toString()).subscribe(
        res => {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            if(typeof res == 'object') {
              res = [res];
            }
            this.gridData = res.map(val =>
              ({
                id: val.Id,
                colaborador: val.Colaborador ? val.Colaborador.Nome : '-',
                matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '-',
                empresa: val.Empresa ? val.Empresa.Nome : '-',
                ano: val.Ano || '-',
                dataConclusao: val.DataCadastro,
                ...val
              })
            );
            this.loadDataTable(this.gridData);
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
    }
  }

  pesquisar() {
    ($("#formulario") as any).valid();
    if (this.colaboradorSelected) {
      this.getDataGrid()
    } else {
      $('#txtNomeColaborador').addClass('element-invalid');
    }
  }

  clearInputvalues(type = '') {
    this.colaboradorSelected = '';
    $('#txtAno').val('');
    $('#txtNomeColaborador').val('');
    //$('#txtNomeColaborador').addClass('element-invalid');
    // $('#selMes').addClass('element-invalid');
  }

  getValuesFromInput() {
    return {
      Ano: $('#txtAno').val().toString(),
      ColaboradorId: this.colaboradorSelected,
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "ano"
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[5]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(data.DataCadastro).toLocaleDateString();
          self.openModal('modalRelatorio', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          $('#txtNomeColaborador').removeClass('element-invalid');
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data , grid: any) {
    grid.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "DescricaoTipoEvento"
        },
        {
          "data": "Valor"
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }


}
