import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils/utils';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {

    token: string = '';
    loginRetorno: any;
    constructor(
        private router: Router,
        private authService: AuthService,
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    static menuData: any;

    login(user = '', pass = ''): Observable<any> {
        return this.httpClient.get(`${environment.urlBase}/Login/BuscarLogin?cpf=${user}&senha=${pass}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    recuperarSenha(user = ''): Observable<any> {
        return this.httpClient.get(`${environment.urlBase}/Login/RecuperarSenha?cpf=${user}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    alterarSenha(dados: any = {}): Observable<any> {
        return this.httpClient.get(`${environment.urlBase}/Login/AlterarSenha?cpf=${dados.user}&senha=${dados.senha}&novaSenha=${dados.novaSenha}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    logout() {
        sessionStorage.clear();
        this.router.navigate(['Login']);
    }

}
