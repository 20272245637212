import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { LoginService } from 'src/app/@core/@service/login/login.service';
import Swal from 'sweetalert2';

declare var $;
@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {

  @ViewChild('user', { static: true }) user: ElementRef;

  constructor(
    private router: Router,
    private recuperarSenhaService: LoginService
  ) { }

  ngOnInit() {
    document.body.className = 'hold-transition recuperar-senha-page';
    $(() => {
      $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%' /* optional */
      });
    });
    this.validarCampos();
  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        inputCpf: {
          required: true,
          minlength: 11,
          cpfCheck: true,
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        inputCpf: {
          required: "Informe o CPF",
          minlength: "Informe o CPF corretamente"
        },
      }
    });

    ($("#formulario") as any).on("submit", function () {
      return false;
    })

    $.validator.addMethod("cpfCheck", function (value, element) {
      return UtilsService.verificarCPF(value);
    }, 'Cpf inválido');
  }

  recuperarSenha() {
    if (($("#formulario") as any).valid()) {
      this.recuperarSenhaService.recuperarSenha(this.user.nativeElement.value).subscribe(
        resultado => {
          if (!resultado.Erro) {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Foi enviado um e-mail com o link de redefinição',
              showConfirmButton: false,
              timer: 2000
            });
            this.voltarAoLogin();
          } else {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: resultado.Erro.Descricao,
              showConfirmButton: false,
              timer: 2000
            });
          }

        },
        erro => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Ocorreu um erro ao redefinir a senha!',
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
    }

  }

  voltarAoLogin() {
    this.router.navigate(['Login']);
  }

}
