import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroDependenteService } from 'src/app/@core/@service/cadastro/dependente/dependente.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var $;

@Component({
  selector: 'app-dependente',
  templateUrl: './dependente.component.html',
  styleUrls: ['./dependente.component.scss']
})
export class DependenteComponent implements OnInit {

  gridData = [];

  titularGridData = [];

  isEdit = false;

  sexosData = [
    {
      Id: 'Masculino',
      Description: 'MASCULINO',
    }, {
      Id: 'Feminino',
      Description: 'FEMININO',
    }
  ];

  grausData = [
    {
      Id: 'Filho',
      Description: 'FILHO',
    }, {
      Id: 'Conjuge',
      Description: 'CONJUGE',
    }
  ];

  sexoSelected = '';

  grauSelected = '';

  titularSelected = '';

  fotoDependente: any = {
    nome: '',
    base64: '',
  };

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  dependenteIdSelected = '';

  userLogged: any;

  colaboradorLogged = '';

  colaboradorType = ''

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalTitular', { static: true }) gridModalTitular: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroDependenteService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO || this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
        this.colaboradorType = 'rh';
      else
        this.colaboradorType = 'colaborador';
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selGrau') {
      this.grauSelected = event.target.value;
    } else if (event.srcElement.id == 'selSexo') {
      $('#selSexo').removeClass('element-invalid');
      this.sexoSelected = event.target.value;
    }
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNomeColaborador: {
          required: true,
          titularCheck: true,
        },
        txtCPFDependente: {
          required: true, minlength: 14
        },
        txtNome: {
          required: true
        },
        txtNomeMae: {
          required: true
        },
        selGrau: {
          required: true
        },
        selSexo: {
          required: true
        },
        txtData: {
          required: true, minlength: 10
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeColaborador: {
          required: "Informe o Titular"
        },
        txtCPFDependente: {
          required: "Informe o CPF",
          minlength: "Informe o Cpf corretamente"
        },
        txtNome: {
          required: "Informe o Nome"
        },
        txtNomeMae: {
          required: "Informe o Nome da Mãe"
        },
        selGrau: {
          required: "Selecione o Grau de Dependência"
        },
        selSexo: {
          required: "Selecione o Sexo"
        },
        txtData: {
          required: "Informe a Data de Nascimento",
          minlength: "Informe a Data de Nascimento corretamente"
        }
      },
    });
    $.validator.addMethod("titularCheck", function (value, element) {
      return self.titularSelected ? true : false;
    }, 'Selecione a empresa');
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
        ({
          id: val.Id || '',
          nome: val.Nome || '',
          cpf: val.CPF || '',
          sexo: val.Sexo || '',
          dataNasc: val.DataNascimento || '',
          nomeMae: val.NomeMae || '',
          grauDependencia: val.GrauDependencia || '',
          nomeTitular: val.TitularNome || '',
          dataCadastro: val.DataCadastro,
          ...val,
        })
        ).sort(this.utilService.dynamicSort('nome'));
        console.log(this.gridData);
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtNome').val('');
    $('#txtNomeMae').val('');
    $('#txtCPFDependente').val('');
    this.grauSelected = '';
    this.sexoSelected = '';
    this.fotoDependente = {};
    $('#txtFoto').val('');
    $('#txtData').val('');
    if (this.colaboradorType == 'rh') {
      this.titularSelected = '';
      $('#txtNomeColaborador').val('');
    }

  }

  getValuesFromInput() {
    const data = $('#txtData').val().toString().trim().split('/');
    return {
      Nome: $('#txtNome').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      CPF: $('#txtCPFDependente').val().toString().trim(),
      Sexo: this.sexoSelected,
      DataNascimento: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      NomeMae: $('#txtNomeMae').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      GrauDependencia: this.grauSelected,
      DocumentoBase64: this.fotoDependente.base64,
      ColaboradorId: this.colaboradorType == 'rh' ? this.titularSelected : this.colaboradorLogged,
    }
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      if (this.typeImagesAccepted.includes(file.type)) {
        this.fotoDependente.base64 = event.target.result;
        this.fotoDependente.nome = file.name;
        $('#txtFoto').val(file.name);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalTitular') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.titularGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cpf: val.CPF,
              ...val
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalTitular(this.titularGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (!this.sexoSelected) {
      ($("#formulario") as any).valid();
      $('#selSexo').addClass('element-invalid');
    } else {
      $('#selSexo').removeClass('element-invalid');
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.dependenteIdSelected;
          this.moduleService.editar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  downloadDocumento(data) {
    this.moduleService.buscarArquivo(data.id).subscribe(
      res => {
        if (res.includes('base64')) {
          res = res.replace('base64', 'base64,');
          const downloadLink = document.createElement("a");
          const fileName = `documento-${data.nome.replaceAll(' ', '-')}-${new Date().toLocaleDateString('pt-br')}.png`;

          downloadLink.href = res;
          downloadLink.download = fileName;
          downloadLink.click();
        } else if (res == 'Documento não encontrado') {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'O dependente não possui um documento anexado!',
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Erro ao fazer downlaod do documento!',
            showConfirmButton: false,
            timer: 2000
          });
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  exibirDocumento(data: any = {}) {
    this.moduleService.buscarArquivo(data.id).subscribe(
      res => {
        if (res.includes('base64')) {
          res = res.replace('base64', 'base64,');
          Swal.fire({
            confirmButtonColor: '#3C8DBC',
            position: 'top',
            title: "",
            text: "",
            imageUrl: res,
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
        {
          "data": "sexo"
        },
        {
          "data": 'dataNasc', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "nomeMae"
        },
        {
          "data": "grauDependencia"
        },
        {
          "data": "nomeTitular"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.DocumentoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="color: rgb(16, 15, 92); border: 0px; background-color: transparent;" title="Visualizar Documento"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-file-image-o" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Visualizar Documento"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.DocumentoNome) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-cloud-download" style="color: #3c8dbc; border: 0px; background-color: transparent;" title="Download Documento"></button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-cloud-download" style="pointer-events: none; color: gray; border: 0px; background-color: transparent;" title="Download Documento"></button></div>';
            }

          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[8]).on("click", function (e) {
          if (data.DocumentoNome) {
            self.exibirDocumento(data);
          }
        });
        $($(row).children()[9]).on("click", function (e) {
          if (data.DocumentoNome) {
            self.downloadDocumento(data);
          }
        });
        $($(row).children()[10]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          $('#txtNome').val(data.nome);
          $('#txtCPFDependente').val(data.cpf);
          self.dependenteIdSelected = data.id;
          self.sexoSelected = data.sexo;
          self.grauSelected = data.grauDependencia;
          self.titularSelected = data.TitularId;
          ($("#txtData") as any).datepicker('setDate', data.dataNasc ? new Date(data.dataNasc) : '');
          $('#txtNomeMae').val(data.nomeMae);
          $('#txtNomeColaborador').val(data.nomeTitular);
        });
        $($(row).children()[11]).on("click", async function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalTitular(data) {
    let self = this;
    this.dataTable = $(this.gridModalTitular.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.titularSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          self.closeModal('modalTitular');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }

}
