import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import Swal from 'sweetalert2';

declare var $;
@Component({
  selector: 'app-operadora-vt',
  templateUrl: './operadora-vt.component.html',
  styleUrls: ['./operadora-vt.component.scss']
})
export class OperadoraVTComponent implements OnInit {

  gridData = [];

  bandeirasCartao = [];

  tarifaTipos = [];

  tarifaTipoSelected = '';

  bandeirasCartaoSelected = '';

  isEdit = false;

  operadoraVTIdSelected = '';

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroOperadoraVTService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selBandeira') {
      this.bandeirasCartaoSelected = event.target.value;
    } else if (event.srcElement.id == 'selTarifa') {
      this.tarifaTipoSelected = event.target.value;
    }
  }


  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNomeOperadora: {
          required: true
        },
        selBandeira: {
          required: true
        },
        selTarifa: {
          required: true,
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeOperadora: {
          required: "Informe o Nome da Operadora"
        },
        selBandeira: {
          required: "Selecione a Bandeira do Cartão"
        },
        selTarifa: {
          required: "Selecione o Tipo de Tarifa"
        }
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.moduleService.buscarBandeiraCartao().subscribe(
      res => {
        this.bandeirasCartao = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.moduleService.buscarTarifaTipo().subscribe(
      res => {
        this.tarifaTipos = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            bandeiraCartao: val.OperadoraVTBandeiraCartao.Nome,
            bandeiraCartaoId: val.OperadoraVTBandeiraCartaoId,
            tipoTarifa: val.OperadoraVTTarifaTipo.Nome,
            tipoTarifaId: val.OperadoraVTTarifaTipoId,
            dataCadastro: val.DataCadastro,
          })
        ).sort(this.utilService.dynamicSort('descricao'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtNomeOperadora').val('');
    this.bandeirasCartaoSelected = '';
    this.tarifaTipoSelected = '';
  }

  getValuesFromInput() {
    return {
      Nome: $('#txtNomeOperadora').val().toString().trim(),
      OperadoraVTBandeiraCartaoId: this.bandeirasCartaoSelected,
      OperadoraVTTarifaTipoId: this.tarifaTipoSelected
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      if (this.tarifaTipoSelected) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.operadoraVTIdSelected;
          this.moduleService.editar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      } else {
        setTimeout(() => {
          $('#selTarifa').trigger('click');
        }, 500);
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "bandeiraCartao"
        },
        {
          "data": "tipoTarifa"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[4]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          $('#txtNomeOperadora').val(data.nome);
          self.bandeirasCartaoSelected = data.bandeiraCartaoId;
          self.tarifaTipoSelected = data.tipoTarifaId
          self.operadoraVTIdSelected = data.id;
        });
        $($(row).children()[5]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
