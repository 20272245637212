<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Férias
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Solicitação</li>
      <li class="active">Férias</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="colaboradorList"
                    IdSelect="selectColaborador" [Value]="colaboradorSelected">
                  </select-component>
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input value="Pesquisar" readonly="readonly" (click)="pesquisar()" style="width: 90px !important;" class="btn btn-info pull-right btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Matrícula</th>
                  <th>Empresa</th>
                  <th>Início Período</th>
                  <th>Fim Período</th>
                  <th>Situação</th>
                  <th>Dias Direito</th>
                  <th>Dias Concedidos</th>
                  <th>Dias Programados</th>
                  <th>Início Férias</th>
                  <th>Dias Abono</th>
                  <th>Dias Gozo</th>
                  <th>Dias de Licença</th>
                  <th>Saldo</th>
                  <th>Status</th>
                  <th>Data da Solicitação</th>
                  <th>Data da Conclusão</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>