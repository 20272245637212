enum UsuarioColaboradorTipoEnum {
    USUARIO = 1,
    COLABORADOR = 2,
    ADMINISTRADOR = 3
}

enum ColaboradorTipoEnum {
    GESTOR = 1,
    FUNCIONARIO = 2
}

enum ColaboradorStatusEnum {
    PRE_ADMISSAO = 1,
    ATIVO = 2,
    AFASTADO = 3,
    DESLIGADO = 4
}

enum SolicitacaoBeneficioDependenteStatusEnum {
    EM_ANALISE_APROVACAO = 1,
    APROVADO = 2,
    REPROVADO = 3,
    EM_ANALISE_CANCELAMENTO = 4,
}
enum SolicitacaoStatusEnum {
    EM_ANALISE = 1,
    APROVADO = 2,
    REPROVADO = 3,
}

enum ValeTransporteDiasSemanaEnum {
    DOMINGO = 1,
    SEGUNDA = 2,
    TERCA = 3,
    QUARTA = 4,
    QUINTA = 5,
    SEXTA = 6,
    SABADO = 7,
}

enum ValeTransporteUtilizacaoEnum {
    IDA = 1,
    VOLTA = 2,
    IDA_E_VOLTA = 3,
    DIAS_DA_SEMANA = 4,
}

enum FornecedorHolerite {
    DATASUL = 1,
    DOMINIO = 2
}

enum TipoRegistroEnum {
    PJ = 1,
    CLT = 2,
    Temporario = 3,
    Voluntario = 4,
    Freelancer = 5,
    Domestico = 6,
    Estagiario = 7,
    SocioDiretor = 8
}

export {
    UsuarioColaboradorTipoEnum,
    ColaboradorTipoEnum,
    ColaboradorStatusEnum,
    SolicitacaoBeneficioDependenteStatusEnum,
    ValeTransporteDiasSemanaEnum,
    ValeTransporteUtilizacaoEnum,
    SolicitacaoStatusEnum,
    FornecedorHolerite,
    TipoRegistroEnum
}
