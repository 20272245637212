import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";
import { UtilsService } from "src/app/@core/@service/utils/utils";
import Swal from "sweetalert2";
import { CadastroColaboradorService } from "src/app/@core/@service/cadastro/colaborador/colaborador.service";
import { CadastroDocumentoAdmissionalService } from "src/app/@core/@service/cadastro/documento-admissional/documento-admissional.service";
import {
  UsuarioColaboradorTipoEnum,
  ColaboradorStatusEnum,
} from "src/app/@core/@enum/enum";
import { concat, Observable } from "rxjs";

declare var $;
@Component({
  selector: "app-pre-admissao-anexar-documento",
  templateUrl: "./anexar-documento.component.html",
  styleUrls: ["./anexar-documento.component.scss"],
})
export class PreAdmissaoAnexarDocumentoComponent implements OnInit {
  colaboradorGridData = [];

  isEdit = false;

  urlDocumento = "";

  empresaIdSelected = "";

  anexarDocumentoIdSelected = "";

  colaboradorSelected = "";

  colaboradorSelectedPreAdmissao = "";

  tipoVistoEstrangeiroList: Array<object> = [];
  tipoVistoEstrangeiroSelected = "";

  dataTable: any;

  documentosList: any;

  dadosModal: any = {
    nome: "",
    empresaNome: "",
  };

  colaboradorLogged = "";
  colaboradorType = "";
  usuarioType = "";
  userLogged: any;
  typeImagesAccepted = ["image/png", "image/jpeg", "image/jpg", "image/bmp", "application/pdf"];

  @ViewChild("gridModalColaborador", { static: true })
  gridModalColaborador: ElementRef;

  constructor(
    private utilService: UtilsService,
    private colaboradorService: CadastroColaboradorService,
    private documentoAdmissionalService: CadastroDocumentoAdmissionalService
  ) {}

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem("currentUser"));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO){
        this.usuarioType = "user";
      }

      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && this.userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO)
      {
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.colaboradorType = "pre-admissao";
        this.getInitialsDataByColaboradorId(this.userLogged.UsuarioColaboradorId);
        //this.getInitialsData();
      }
      else
      {
        this.colaboradorType = "rh";
        this.colaboradorSelected = "";
        //this.getInitialsData();
      }
    }
    ($("#formularioAnexarDoc") as any).on("submit", function () {
      return false;
    });
  }

  escondeDocumentos(){
    this.documentosList.forEach(element => {
      if(element.PermiteVisualizar){
        $(`#documeto_${element.id}`).attr("style", "display:none");
      }
    });
  }
  getInitialsDataByColaboradorId(colaboradorId) {
    if(this.colaboradorType == "pre-admissao"){
      this.documentoAdmissionalService.pesquisarByColaboradorId(colaboradorId).subscribe(
        (res) => {
          this.documentosList = res
            .map((val) => ({
              id: val.Id,
              nome: val.Nome,
              obrigatorio: val.Obrigatorio,
              documents: [],
              documentsToSave: [],
              ...val,
            }))
            .filter(x => x.PermiteVisualizar);
            if (this.colaboradorType == "pre-admissao")
          this.loadDocumentosColaborador();
        },
        (err) => {
          console.log(err);
        }
      );
    }
    else{
      this.documentoAdmissionalService.pesquisarByColaboradorId(colaboradorId).subscribe(
        (res) => {
          this.documentosList = res
            .map((val) => ({
              id: val.Id,
              nome: val.Nome,
              obrigatorio: val.Obrigatorio,
              documents: [],
              documentsToSave: [],
              ...val,
            }))
        .sort(this.utilService.dynamicSort("nome"));
        this.loadDocumentosColaborador();
      },
        (err) => {
          console.log(err);
        }
      );
    }

  }
  getInitialsData() {
    if(this.colaboradorType == "pre-admissao"){
      this.documentoAdmissionalService.pesquisar().subscribe(
        (res) => {
        this.documentosList = res
            .map((val) => ({
              id: val.Id,
              nome: val.Nome,
              obrigatorio: val.Obrigatorio,
              documents: [],
              documentsToSave: [],
              ...val,
            }))
            .filter(x => x.PermiteVisualizar);
          if (this.colaboradorType == "pre-admissao")
            this.loadDocumentosColaborador();
        },
        (err) => {
          console.log(err);
        }
      );
    }
    else{
      this.documentoAdmissionalService.pesquisar().subscribe(
        (res) => {
          this.documentosList = res
            .map((val) => ({
              id: val.Id,
              nome: val.Nome,
              obrigatorio: val.Obrigatorio,
              documents: [],
              documentsToSave: [],
              ...val,
            }))
            .sort(this.utilService.dynamicSort("nome"));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }


  abrirDocumento(data: any = {}){
    this.colaboradorService
    .buscarArquivoDocumentoAnexoPreAdmissaoColaborador(data.Id)
    .subscribe(
      (res) => {
        if (res.includes("base64")) {
          if (!res.includes("base64,")) {
            res = res.replace("base64", "base64,");
          }
          this.openFile(res);
        } else if (res == "Documento não encontrado") {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "O documento não foi encontrado!",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Erro ao fazer abrir o documento!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );;
  }

  downloadDocumento(data: any = {}) {
    this.colaboradorService
      .buscarArquivoDocumentoAnexoPreAdmissaoColaborador(data.Id)
      .subscribe(
        (res) => {
          if (res.includes("base64")) {
            if (!res.includes("base64,")) {
              res = res.replace("base64", "base64,");
            }
            const downloadLink = document.createElement("a");
            const fileName = `documento-${new Date().toLocaleDateString(
              "pt-br"
            )}-${data.DocumentoNome}`;
            downloadLink.href = res;
            downloadLink.download = fileName;
            downloadLink.click();
          } else if (res == "Documento não encontrado") {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "O documento não foi encontrado!",
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "Erro ao fazer downlaod do documento!",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  removerDocumento(data: any = {}) {
    Swal.fire({
      position: "top",
      title: "Você tem certeza que deseja remover esse documento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3c8dbc",
      cancelButtonColor: "#DD4B39",
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((result) => {
      if (result.isConfirmed) {
        this.colaboradorService
          .removerArquivoDocumentoAnexoPreAdmissaoColaborador(data.Id)
          .subscribe(
            (res) => {
              Swal.fire({
                position: "top",
                icon: "success",
                title: "Documentos removido com sucesso!",
                showConfirmButton: false,
                timer: 2000,
              });

              this.loadDocumentosColaborador();
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Falha ao remover o documento!",
                showConfirmButton: false,
                timer: 2000,
              });

              this.loadDocumentosColaborador();
            }
          );
      }
    });
  }

  verificaTamanhoArquivo(tamanho){
    if(tamanho > 26214400){
      return false;
    }
    else{
      return true;
    }
  }

  processFile(imageInput: HTMLInputElement, idDocumento = "") {
    if (this.colaboradorSelected) {
      if(this.colaboradorType == "pre-admissao"){
        this.colaboradorSelectedPreAdmissao = this.colaboradorLogged;
      };
      const file: File = imageInput.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (event: any) => {

        if(!this.verificaTamanhoArquivo(file.size)){
          Swal.fire({
            position: "top",
            icon: "error",
            title:
              "Tamanho do arquivo inválido! \n\n O arquivo deve ser menor que 20MB",
            showConfirmButton: true,
            timer: 4000,
          });
          imageInput.value = "";
        }
        else{
          if (this.typeImagesAccepted.includes(file.type)) {
            $(`#txtNome-${idDocumento}`).val(file.name);
            const filter = this.documentosList.filter(
              (val) => val.id == idDocumento
            );

            if (filter.length > 0) {
              const tipoDocumentoAdimissional = filter[0];

              console.log(file);


              tipoDocumentoAdimissional.documentsToSave.push({
                Id: 0,
                ColaboradorId: this.colaboradorSelectedPreAdmissao,
                DocumentoAdmissionalId: idDocumento,
                DocumentoBase64: event.target.result,
                DocumentoNome: file.name,
              });
            }

            imageInput.value = "";
          } else {
            Swal.fire({
              position: "top",
              icon: "error",
              title:
                "Formato de arquivo inválido! \n\n Formatos aceito: (png, jpeg, jpg, pdf e bmp)",
              showConfirmButton: true,
              timer: 4000,
            });
          }
        }
      });

      reader.readAsDataURL(file);
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        title:
          "Para anexar um documento, o colaborador precisa estar selecionado!",
        showConfirmButton: true,
        timer: 4000,
      });
    }
  }

  clearInputvalues() {
    // Desabilita modo edição
    this.isEdit = false;

    this.colaboradorSelected = "";
    this.empresaIdSelected = "";

    this.documentosList.forEach((element) => {
      element.documents = [];
      element.documentsToSave = [];
    });

    $("#txtEmpresaAnexarDocumento").val("");
    $("#txtNomeColaboradorAnexarDocumento").val("");
    // Zera os campos;
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    let self = this;
    this.dadosModal = data;
    if (idModal == "modalColaboradorAnexarDocumento") {
      if ($("#txtNomeColaboradorAnexarDocumento").val().toString().length > 0) {
        this.colaboradorService
          .pesquisarColaboradorPreAdmissaoPorNomePorUsuarioId(
            $("#txtNomeColaboradorAnexarDocumento").val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
            (res) => {
              this.colaboradorGridData = res
                .map((val) => ({
                  id: val.Id,
                  nome: val.Nome,
                  cpf: val.CPF,
                  ...val,
                }))
                .sort(this.utilService.dynamicSort("nome"));
              this.loadDataTableModalColaborador(this.colaboradorGridData);
              ($(`#${idModal}`) as any).modal("show");
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Ocorreu um erro ao buscar a lista de \n colaboradores!",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(err);
            }
          );
      }
    }
  }

  loadDocumentosColaborador() {
    $('[id^="txtNome-"]').val("");
    this.documentosList.forEach((val) => {
      val.documents = [];
      val.documentsToSave = [];
    });

    this.colaboradorService
      .buscarDocumentoAnexoColaboradorPreAdmissao(this.colaboradorSelected)
      .subscribe(
        (res) => {
          res.forEach((val) => {
            const filter = this.documentosList.filter(
              (x) => x.Id == val.DocumentoAdmissionalId
            );
            if (filter.length > 0) {
              filter[0].documents.push({
                Id: val.Id,
                DocumentoNome: val.DocumentoNome,
                DocumentoBase64: val.DocumentoBase64,
                DocumentoAdmissionalId: val.DocumentoAdmissionalId,
                ColaboradorId: val.ColaboradorId,
                DocumentoAdmissional: val.DocumentoAdmissional,
              });
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal("hide");
  }

  async salvar(concluir:boolean) {
    var documents = [].concat(
      ...this.documentosList.map((x) => x.documentsToSave)
    );

    console.log(documents);
    /* if (this.colaboradorSelected && documents.length > 0) { */
      if (this.colaboradorSelected) {
      const errors = [];
      let count = 0;

      var observables = documents.map((val, i) => {
        return this.colaboradorService.salvarDocumentoAnexoColaborador(val,concluir);
      });

      concat(...observables).subscribe(
        (res) => {
          count++;

          if (count == observables.length) {
            this.concluirSave(errors);
          }
        },
        (err) => {
          errors.push(err);
          count++;

          if (count == observables.length) {
            this.concluirSave(errors);
          }
        }
      );
    }
  }

  async concluir() {
   if (this.colaboradorSelected) {
        this.colaboradorService
        .concluirPreAdmissaoColaborador(this.colaboradorSelected)
        .subscribe(
          (res) => {
            console.log(res);
            if(res.Erro == null){
              Swal.fire({
                position: "top",
                icon: "success",
                title: "Cadastro concluído com sucesso!",
                showConfirmButton: false,
                timer: 2000,
              });
            }else{
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Erro.Descricao || 'Ocorreu um erro',
                showConfirmButton: true,
                timer: 10000
              });
            }
      })
    }
  }


  concluirSave(errors) {
    setTimeout(() => {
      if (errors.length > 0) {
        Swal.fire({
          position: "top",
          icon: "error",
          title: `Erro ao salvar os documentos: \n\n${errors
            .toString()
            .replaceAll(",", ", ")}`,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "top",
          icon: "success",
          title: "Documentos salvo com sucesso!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.loadDocumentosColaborador();
    }, 1000);
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cpf",
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          self.empresaIdSelected = data.Empresa.Id;
          self.colaboradorSelectedPreAdmissao = data.id;
          $("#txtEmpresaAnexarDocumento").val(data.Empresa.Nome);
          $("#txtNomeColaboradorAnexarDocumento").val(data.nome);
          self.getInitialsDataByColaboradorId(data.Id);
          self.closeModal("modalColaboradorAnexarDocumento");
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  openFile(fileBase64: string) {
    var iframe = "<iframe width='100%' height='100%' src='" + fileBase64 + "'></iframe>"
    var x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();
  }
}
