import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/@core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from '../../../@core/components/modal/modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { SolicitacaoDesligamentoComponent } from './desligamento/desligamento.component';
import { SolicitacaoMovimentacaoContratualComponent } from './movimentacao-contratual/movimentacao-contratual.component';
import { SolicitacaoAlteracaoCadastralComponent } from './alteracao-cadastral/alteracao-cadastral.component';
import { SolicitacaoValeTransporteComponent } from './vale-transporte/vale-transporte.component';
import { SolicitacaoBeneficioComponent } from './beneficio/beneficio.component';
import { SolicitacaoBeneficioDependenteComponent } from './beneficio-dependente/beneficio-dependente.component';
import { SolicitacaoFeriasComponent } from './ferias/ferias.component';
import { SolicitacaoHoraExtraComponent } from './hora-extra/hora-extra.component';


@NgModule({
  declarations: [
    SolicitacaoDesligamentoComponent,
    SolicitacaoMovimentacaoContratualComponent,
    SolicitacaoAlteracaoCadastralComponent,
    SolicitacaoValeTransporteComponent,
    SolicitacaoBeneficioComponent,
    SolicitacaoBeneficioDependenteComponent,
    SolicitacaoFeriasComponent,
    SolicitacaoHoraExtraComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild()
  ],
  entryComponents: [
    SolicitacaoDesligamentoComponent,
    SolicitacaoMovimentacaoContratualComponent,
    SolicitacaoAlteracaoCadastralComponent,
    SolicitacaoValeTransporteComponent,
    SolicitacaoBeneficioComponent,
    SolicitacaoBeneficioDependenteComponent,
    SolicitacaoFeriasComponent,
    SolicitacaoHoraExtraComponent
  ]
})
export class SolicitacaoModule { }
