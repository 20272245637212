import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { LoginService } from 'src/app/@core/@service/login/login.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
declare var $;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('user', { static: true }) user: ElementRef;

  @ViewChild('password', { static: true }) password: ElementRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.loginService.logout();
    document.body.className = 'hold-transition login-page';
    $(() => {
      $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%' /* optional */
      });
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
    this.validarCampos()
  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        inputCpf: {
          required: true,
          minlength: 11,
          cpfCheck: false,
        },
        inputPassword: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        inputCpf: {
          required: "Informe o CPF",
          minlength: "Informe o CPF corretamente"
        },
        inputPassword: {
          required: "Informe a senha"
        },
      }
    });

    $.validator.addMethod("cpfCheck", function (value, element) {
      return UtilsService.verificarCPF(value);
    }, 'Cpf inválido');
  }

  mapResultado(resultado) {
    return {
      token: resultado.Token.access_token,
      cargo: 'Analista',
      nome: 'Caique Delpino',
      colaboradorId: resultado.UsuarioColaboradorId,
      colaboradorTipo: resultado.ColaboradorTipo,
      cpf: resultado.Cpf,
      dataCadastro: resultado.DataCadastro,
      ...resultado,
    }
  }

  login() {
    if (($("#formulario") as any).valid()) {
      const user = this.user.nativeElement.value;
      const pass = this.password.nativeElement.value;

      this.loginService.login(user, pass).subscribe(
        resultado => {
          if (!resultado.Erro && !resultado.Token.error) {

            sessionStorage.setItem('currentUser', JSON.stringify(this.mapResultado(resultado)));

            // //TODO: Remover
            // resultado.Menu.push(
            //   {
            //     "Id": 69,
            //     "Titulo": "Aprovação",
            //     "SubTitulo": "Hora Extra",
            //     "SubTituloNivel1": "",
            //     "UsuarioPermissao": "N",
            //     "GestorPermissao": "S",
            //     "FuncionarioPermissao": "S",
            //     "PreAdmissaoPermissao": "N",
            //     "Order": 3,
            //     "MenuStatusId": 1,
            //     "MenuStatus": {
            //       "Id": 1,
            //       "Nome": "Ativo"
            //     }
            //   }
            // )
            sessionStorage.setItem('menu', JSON.stringify(resultado.Menu));
            this.router.navigate(['Perfil']);
          } else {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: resultado.Erro.Descricao, //'Usuário/Senha incorretos!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        },
        erro => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Usuário/Senha incorreta!',
            showConfirmButton: false,
            timer: 2000
          });
        }
      );
    } else {
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: ($("#formulario") as any).validate().errorMap.inputCpf,
        showConfirmButton: false,
        timer: 2000
      });
    }
  }

  recuperarSenha() {
    this.router.navigate(['RecuperarSenha']);
  }

  alterarSenha() {
    this.router.navigate(['AlterarSenha']);
  }

}
