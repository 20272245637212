import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoMovimentacaoContratualService } from 'src/app/@core/@service/solicitacao/movimentacao-contratual/movimentacao-contratual.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

@Component({
  selector: 'app-solicitacao-movimentacao-contratual',
  templateUrl: './movimentacao-contratual.component.html',
  styleUrls: ['./movimentacao-contratual.component.scss']
})
export class SolicitacaoMovimentacaoContratualComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  colaboradorList: Array<any> = [];

  tipoSelected = ''

  tipoList: Array<any> = [];

  empresaSelected = ''
  empresaList: Array<any> = [];
  lotacaoSelected = ''
  lotacaoList: Array<any> = [];
  centroCustoSelected = ''
  centroCustoList: Array<any> = [];
  cargoSelected = ''
  cargoList: Array<any> = [];
  turnoSelected = ''
  turnoList: Array<any> = [];
  unidadeNegocioSelected = ''
  unidadeNegocioList: Array<any> = [];
  tipoMaoObraSelected = ''
  tipoMaoObraList: Array<any> = [];
  currUser: any;

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoMovimentacaoContratualService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
      this.gestorId = this.currUser ? this.currUser.UsuarioColaboradorId : '';
    }
    this.validarCampos();
    this.getDataGrid();
    this.getInitialData();
    //Date picker
    ($("#txtDataSugerida") as any).datepicker({
      autoclose: true
    });
    ($("#txtSalario") as any).maskMoney({ prefix: 'R$ ', thousands: '.', decimal: ',' });
  }

  getDataFromEmpresa() {
    this.colaboradorService.BuscarTudoCargo(this.empresaSelected).subscribe(
      res => {
        this.cargoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoCentroCusto(this.empresaSelected).subscribe(
      res => {
        this.centroCustoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoLotacao(this.empresaSelected).subscribe(
      res => {
        this.lotacaoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.preencherCampos();
  }

  preencherCampos() {

    this.moduleService.buscarDadosPorColaboradorId(this.colaboradorSelected).subscribe(
      res => {
        if (res) {
          this.lotacaoSelected = res.LotacaoUnidadeId || '';
          this.centroCustoSelected = res.CentroCustoUnidadeId || '';
          this.cargoSelected = res.CargoUnidadeId || '';
          this.turnoSelected = res.TurnoId || '';
          this.unidadeNegocioSelected = res.UnidadeNegocioId || '';
          this.tipoMaoObraSelected = res.TipoMaoObraId || '';
          $('#txtCartaoPonto').val(res.NumeroCartaoPonto);
          $('#txtSalario').val(this.utilService.numberToMaskMoney(res.Salario));
        }
        console.log(res);
      },
      err => {
        console.log(err);
      }
    );
  }

  getInitialData() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService.buscarColaboradorPorGestorId(this.gestorId).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
  }else{
    this.colaboradorService.buscarColaboradorPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
  }
    this.colaboradorService.BuscarTudoTipoMaoDeObra().subscribe(
      res => {
        this.tipoMaoObraList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoUnidadeDeNegocio().subscribe(
      res => {
        this.unidadeNegocioList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoTurno().subscribe(
      res => {
        this.turnoList = res.filter(val => val.Descricao != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Descricao
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );

  }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        selColaborador: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        selColaborador: {
          required: "Selecione o Nome do Colaborador"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selColaborador') {
      this.colaboradorSelected = event.target.value;
      $('#selColaborador').removeClass('element-invalid');
      this.colaboradorService.buscarColaboradorPorId(event.target.value).subscribe(
        res => {
          this.empresaServie.buscarEmpresaMatrizFilialPorEmpresaId(res.EmpresaId).subscribe(
            res2 => {
              this.empresaList = res2.map(val =>
                ({
                  Id: val.Id,
                  Description: val.Nome
                })
              ).sort(this.utilService.dynamicSort('Description'));
              if (res2.filter(val => val.Id == res.EmpresaId).length > 0) {
                this.empresaSelected = res.EmpresaId;
                this.getDataFromEmpresa();
              }
            },
            err2 => {
              console.log(err2);
            }
          );

        },
        err => {
          console.log(err);
        }
      );
    } else if (event.srcElement.id == 'selEmpresa') {
      this.empresaSelected = event.target.value;
      this.getDataFromEmpresa();
    } else if (event.srcElement.id == 'selPlanoLotacao') {
      this.lotacaoSelected = event.target.value;
    } else if (event.srcElement.id == 'selCentroCusto') {
      this.centroCustoSelected = event.target.value;
    } else if (event.srcElement.id == 'selCargo') {
      this.cargoSelected = event.target.value;
    } else if (event.srcElement.id == 'selTurno') {
      this.turnoSelected = event.target.value;
    } else if (event.srcElement.id == 'selUnidade') {
      this.unidadeNegocioSelected = event.target.value;
    } else if (event.srcElement.id == 'selTipoMaoObra') {
      this.tipoMaoObraSelected = event.target.value;
    }

  }

  getDataGrid() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.gestorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Colaborador ? val.Colaborador.Nome : '-',
            empresa: val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '-',
            lotacao: val.LotacaoUnidade ? val.LotacaoUnidade.Nome : '-',
            cargo: val.CargoUnidade ? val.CargoUnidade.Nome : '-',
            centroCusto: val.CentroCustoUnidade ? val.CentroCustoUnidade.Nome : '-',
            tipoMaoObra: val.TipoMaoObra ? val.TipoMaoObra.Nome : '-',
            turno: val.Turno ? val.Turno.Descricao : '-',
            unidadeNegocio: val.UnidadeNegocio ? val.UnidadeNegocio.Nome : '-',
            cartaoPonto: val.NumeroCartaoPonto || '-',
            salario: val.Salario || '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }else{
    this.moduleService.pesquisarPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Colaborador ? val.Colaborador.Nome : '-',
            empresa: val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '-',
            lotacao: val.LotacaoUnidade ? val.LotacaoUnidade.Nome : '-',
            cargo: val.CargoUnidade ? val.CargoUnidade.Nome : '-',
            centroCusto: val.CentroCustoUnidade ? val.CentroCustoUnidade.Nome : '-',
            tipoMaoObra: val.TipoMaoObra ? val.TipoMaoObra.Nome : '-',
            turno: val.Turno ? val.Turno.Descricao : '-',
            unidadeNegocio: val.UnidadeNegocio ? val.UnidadeNegocio.Nome : '-',
            cartaoPonto: val.NumeroCartaoPonto || '-',
            salario: val.Salario || '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }}
  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();
    if (type == 'solicitar') {
      $('#selColaborador').removeClass('element-invalid');
    }
    // Zera os campos
    this.gestorSelected = '';
    this.colaboradorSelected = '';
    this.empresaSelected = '';
    this.lotacaoSelected = '';
    this.centroCustoSelected = '';
    this.cargoSelected = '';
    this.turnoSelected = '';
    this.unidadeNegocioSelected = '';
    this.tipoMaoObraSelected = '';
    $('#txtCartaoPonto').val('');
    $('#txtSalario').val('');
  }

  getValuesFromInput() {
    return {
      GestorId: this.gestorId,
      ColaboradorId: this.colaboradorSelected,
      EmpresaId: this.empresaSelected,
      LotacaoUnidadeId: this.lotacaoSelected,
      CentroCustoUnidadeId: this.centroCustoSelected,
      CargoUnidadeId: this.cargoSelected,
      TurnoId: this.turnoSelected,
      UnidadeNegocioId: this.unidadeNegocioSelected,
      NumeroCartaoPonto: $('#txtCartaoPonto').val().toString().trim(),
      TipoMaoObraId: this.tipoMaoObraSelected,
      Salario: this.utilService.maskMoneyToNumber($('#txtSalario').val().toString().trim()),
    }
  }

  solicitar() {
    ($("#formulario") as any).valid();
    if (!this.colaboradorSelected) {
      $('#selColaborador').addClass('element-invalid');
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.solicitar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "empresa"
        },
        {
          "data": "lotacao"
        },
        {
          "data": "centroCusto"
        },
        {
          "data": "tipoMaoObra"
        },
        {
          "data": "turno"
        },
        {
          "data": "unidadeNegocio"
        },
        {
          "data": "cartaoPonto"
        },
        {
          "data": "cargo"
        },
        {
          "data": 'salario', "orderable": true, "render": function (data) {
            return 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
