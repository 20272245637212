import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImportacaoBancoDeHorasService {

    routePrefix: string = 'BancoHoras'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisarPorGestorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorGestorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorColaboradorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarImportacaoPorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorColaboradorIdMesAno?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorEmpresaId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorEmpresaId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    importar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Importar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    excluirPorId(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}PorId?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}