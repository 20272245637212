import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoBeneficioDependenteService } from 'src/app/@core/@service/solicitacao/beneficio-dependente/beneficio-dependente.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';

@Component({
  selector: 'app-solicitacao-beneficio-dependente',
  templateUrl: './beneficio-dependente.component.html',
  styleUrls: ['./beneficio-dependente.component.scss']
})
export class SolicitacaoBeneficioDependenteComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  colaboradorList: Array<any> = [];

  tipoSelected = ''

  tipoList: Array<any> = [];

  desligamentoIdSelected = '';

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoBeneficioDependenteService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.gestorId = currUser ? currUser.UsuarioColaboradorId : '';
    this.getInitialData();
    //Date picker
    ($("#txtDataSugerida") as any).datepicker({
      autoclose: true
    });
  }

  getInitialData() {
    this.colaboradorService.buscarDependentePorColaboradorId(this.gestorId).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
    this.loadDataTable([]);
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectColaborador') {
      this.colaboradorSelected = event.target.value;
      $('#selectColaborador').removeClass('element-invalid');
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  getDataGrid() {
    if(this.colaboradorSelected) {
    this.moduleService.pesquisar(this.colaboradorSelected).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nomeDependente: val.Dependente ? val.Dependente.Nome : '-',
            nomeTitular: val.Dependente ? val.Dependente.TitularNome : '-',
            beneficio: val.Beneficio ? val.Beneficio.Nome : '-',
            regra: val.Beneficio ? val.Beneficio.RegraDesconto : '-',
            tipo: val.Beneficio ? (val.Beneficio.BeneficioTipo ? val.Beneficio.BeneficioTipo.Nome : '-') : '-',
            custo: val.Beneficio ? val.Beneficio.CustoBeneficio : '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
    } else {
      $('#selectColaborador').addClass('element-invalid');
    }
  }
  clearInputvalues(type = '') {
    // Zera os campos
    this.colaboradorSelected = '';
    $('#selectColaborador').removeClass('element-invalid');
  }


  solicitar(data) {
    const obj = {
      DependenteId: data.DependenteId,
      BeneficioId: data.BeneficioId,
    };
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja solicitar esse benefício?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.solicitar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    });
  }

  cancelar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja cancelar esse benefício?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.cancelar(data).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.getDataGrid();
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Benefício Cancelado!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          },
          err => {
            console.log(err);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Erro ao cancelar Benefício!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
    })
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nomeDependente"
        },
        {
          "data": "nomeTitular"
        },
        {
          "data": "beneficio"
        },
        {
          "data": "regra"
        },
        {
          "data": "tipo"
        },
        {
          "data": 'custo', "orderable": true, "render": function (data) {
            return 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          }
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data == '0001-01-01T00:00:00' ? '-' : (data ? new Date(data).toLocaleDateString('pt-br') : '-');
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (!row.SolicitacaoStatusId || row.SolicitacaoStatusId == 0) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-plus" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Solicitar"> </button></div>';
            } else if (row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('aprovado')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Cancelar"> </button></div>';
            } else {
              return '';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[9]).on("click", function (e) {
          if (!data.SolicitacaoStatusId || data.SolicitacaoStatusId == 0) {
            self.solicitar(data);
          } else if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('aprovado')) {
            self.cancelar(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
