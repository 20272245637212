import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { CoreModule } from "./@core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { NgxMaskModule } from "ngx-mask";
import { NgbModule, NgbTimepicker, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./@core/interceptors/auth.interceptor";
import { AuthService } from "./@core/@service/auth.service";
import { LoaderComponent } from "./dashboard/loader/loader.component";
import localePt from "@angular/common/locales/pt";
import { CurrencyPipe, registerLocaleData } from "@angular/common";

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule,
    DashboardModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgbTimepickerModule,
],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "pt",
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
