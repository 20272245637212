import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoFeriasService {

    routePrefix: string = 'Ferias'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    solicitar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Solicitar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovar(data: any = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Aprovar${this.routePrefix}`, { Id: data.Id })
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovar(data: any = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Reprovar${this.routePrefix}`, { Id: data.Id })
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}