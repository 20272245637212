<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Sindicato
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Sindicato</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome do Sindicato">
              </div>
              <div class="form-group">
                <label>Representante</label>
                <input type="text" class="form-control" id="txtRepresentante" name="txtRepresentante"
                  placeholder="Nome do Representante">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Cnpj</label>
                <input type="text" class="form-control" mask="00.000.000/0000-00" id="txtCNPJ" name="txtCNPJ"
                  placeholder="Cnpj do Sindicato">
              </div>
              <div class="form-group">
                <label>Telefone Comercial</label>
                <input type="text" class="form-control" mask="(00) 0000-00009" id="txtTelCom" name="txtTelCom"
                  placeholder="(__) ____-____">
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label>Mês</label>
                    <select-component (changeValue)="changeSelectValues($event)" [Data]="mesList" IdSelect="selMes"
                      [Value]="mesSelected">
                    </select-component>
                  </div>
                  <div class="col-md-6">
                    <label>Ano</label>
                    <input type="text" class="form-control" mask="0000" id="txtAno" name="txtAno" placeholder="yyyy">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label>Telefone Celular</label>
                    <input type="text" class="form-control" mask="(00) 0000-00009" id="txtTelCel" name="txtTelCel"
                      placeholder="(__) ____-____">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>
      </form>
    </div>

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Cnpj</th>
                <th>Mês</th>,
                <th>Ano</th>
                <th>Representante</th>
                <th>Telefone Comercial</th>
                <th>Telefone Celular</th>
                <th>Data de Cadastro</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>

  <div class="control-sidebar-bg"></div>
</div>