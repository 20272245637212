import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../../utils/utils';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CadastroColaboradorPagamentoService {

    routePrefix: string = 'ColaboradorPagamento'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarPagamentoColaborador`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    pesquisarPorUsuarioId(data = {}, usuarioId): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarPagamentoColaboradorPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    pesquisarPreAdmissao(data: any = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarPagamentoColaboradorPreAdmissao`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPreAdmissaoPorUsuarioId(data: any = {},usuarioId: number): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarPagamentoColaboradorPreAdmissaoPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    adicionar(data = {},concluir?:boolean): Observable<any> {
      console.log(concluir);
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AdicionarPagamentoColaborador?concluir=${concluir}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {},concluir?:boolean): Observable<any> {
      console.log(concluir);
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/EditarPagamentoColaborador?concluir=${concluir}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    remover(data): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/ExcluirPagamentoColaborador?id=${data.id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}
