import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../@core/@service/utils/utils';
import { PerfilService } from 'src/app/@core/@service/perfil/perfil.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  @ViewChild('profileImage', { static: true }) profileImage: ElementRef;

  perfilDataSubscription: Subscription;
  perfilData = this.perfilService.perfilData;

  constructor(
    private utilService: UtilsService,
    private perfilService: PerfilService,
  ) {
    this.perfilDataSubscription = this.perfilService.getPerfilData().subscribe(res => {
      if (res) {
        this.perfilData = res;
        const nome = this.perfilData.nome;
        let nomeFormatted = nome ? nome.split(' ').map(name => name[0].toUpperCase()) : '';
        if (nomeFormatted) {
          if (nomeFormatted.length == 1) {
            nomeFormatted = nomeFormatted[0];
          } else {
            nomeFormatted = nomeFormatted[0] + nomeFormatted[nomeFormatted.length - 1];
          }
        }
        if (this.perfilData.fotoBase64) {
          // Foto
          $('#profileImage').removeClass('profileText-circle');
          $('#profileImage').addClass('profileImage-circle');
          $('#profileImage').css('background-image', 'url(' + this.perfilData.fotoBase64 + ')');
        } else {
          // Foto
          $('#profileImage').removeClass('profileImage-circle');
          $('#profileImage').addClass('profileText-circle');
          this.profileImage.nativeElement.innerHTML = nomeFormatted.includes('undefined') ? '--' : nomeFormatted;
        }
      } else {
        // clear perfilDatas when empty perfilData received
        this.perfilData = this.perfilService.perfilData;
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.perfilDataSubscription.unsubscribe();
  }

}
