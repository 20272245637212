import { Component, OnDestroy, OnInit, ComponentRef, ViewContainerRef, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BeneficioComponent } from './body/cadastro/beneficio/beneficio.component';
import { UtilsService } from '../@core/@service/utils/utils';
import { PerfilService } from '../@core/@service/perfil/perfil.service';
import { PerfilComponent } from './body/perfil/perfil.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private utilService: UtilsService,
    private perfilService: PerfilService
  ) { }
  @ViewChild('pageRouter', { static: true, read: ViewContainerRef }) pageRouter: ViewContainerRef;
  obj: any;

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    document.body.className = 'hold-transition skin-blue sidebar-mini';
    this.changePage();
    this.perfilService.getData();
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }

  changeUrl(pai, filho) {
    const url = location.href.split("/")[0] + "//" + location.href.split("/")[2]
    history.pushState({
      id: ''
    }, '', `${url}/${pai.charAt(0).toUpperCase() + pai.slice(1)}/${filho.charAt(0).toUpperCase() + filho.slice(1)}`);
    return '';
  }

  changePage(data = { pai: '', item: 'home', isMenu: false }) {
    let component = PerfilComponent;
    if (data.isMenu) {
      component = this.utilService.getComponente(data.pai, data.item, data.isMenu);
    } else {
      const urlArray = location.href.split('/');
      if (data && data.item) {
        if (data.item === 'home') {
          if (urlArray.length == 4) {
            component = this.utilService.getComponente(urlArray[urlArray.length - 1].toString().toLowerCase(), null);
          } else if (urlArray.length > 4) {
            const subModule = urlArray[urlArray.length - 1].toString();
            const module = urlArray[urlArray.length - 2].toString().toLowerCase();
            component = this.utilService.getComponente(module, subModule[0].toLowerCase() + subModule.slice(1));
          } else {
            this.router.navigate(['perfil']);
          }
        } else {
          component = this.utilService.getComponente(data.pai, data.item);
          this.changeUrl(data.pai, data.item);
        }
      }
    }
    if (typeof component == 'function') {
      const factory = this.resolver.resolveComponentFactory(component);
      this.pageRouter.clear();
      this.obj = this.pageRouter.createComponent(factory);
    }
  }
}
