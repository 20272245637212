<div class="login-box">
  <div class="login-logo">
    <b>Meu RH Online</b>
  </div>
  <div class="login-box-body">
    <form id="formulario">
      <div class="form-group has-feedback">
        <span class="glyphicon glyphicon-user form-control-feedback"></span>
        <input #user id="inputCpf" mask="000.000.000-00" name="inputCpf" type="text" class="form-control" placeholder="Cpf">
      </div>
      <div class="row">
        <div class="col-xs-4">
          <input type="submit" id="Voltar" name="Voltar" value="Voltar" (click)="voltarAoLogin()" class="btn btn-primary btn-block btn-flat" />
        </div>
        <div class="col-xs-4" style="float: right;">
          <input type="submit" id="Recuperar" name="Recuperar" value="Recuperar" (click)="recuperarSenha()" class="btn btn-primary btn-block btn-flat" />
        </div>
      </div>
    </form>
  </div>
  <!-- /.login-box-body -->
</div>