import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoCartaoPontoService } from 'src/app/@core/@service/importacao/cartao-de-ponto/cartao-de-ponto.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

declare var $;

@Component({
  selector: 'app-relatorio-cartao-de-ponto',
  templateUrl: './cartao-de-ponto.component.html',
  styleUrls: ['./cartao-de-ponto.component.scss']
})

export class RelatorioCartaoPontoComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  modalRelatorioData: any = {
    cpf: '',
    colaborador: '',
    empresa: '',
    ano: '',
    mes: '',
    dataInicio: '',
    dataFim: '',
  };

  relatorioData: any;

  colaboradorSelected = ''

  arquivoAnexoSelected = '';

  mesSelected = '';
  mesList = [];

  typeImagesAccepted = ['text/plain'];

  dataTable: any;
  dataTableExport: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;
  @ViewChild('gridModalRelatorio1', { static: true }) gridModalRelatorio1: ElementRef;
  @ViewChild('gridModalRelatorio2', { static: true }) gridModalRelatorio2: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoCartaoPontoService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.mesList = this.utilService.getMesList();
    this.validarCampos();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;

    }
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe a Data"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  async remover(id) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse cartão de ponto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.excluirPorId(id).subscribe(
          res => {
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Cartão de ponto removido!',
              showConfirmButton: false,
              timer: 2000
            });
            this.pesquisar();
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao remover o cartão de ponto!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    })
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cpf: val.CPF,
              ...val
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalRelatorio') {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        res => {
          this.modalRelatorioData = {
            matricula: res.Colaborador ? res.Colaborador.MatriculaInterna : '',
            colaborador: res.Colaborador ? res.Colaborador.Nome : '',
            empresa: data.Colaborador ? (data.Colaborador.Empresa ? data.Colaborador.Empresa.Nome : '') : '',
            ano: res.Ano || '',
            mes: res.Mes || '',
            dataInicio: res.CartaoPontoCabecalho.length > 0 ? res.CartaoPontoCabecalho[0].DataInicioPeriodo : '',
            dataFim: res.CartaoPontoCabecalho.length > 0 ? res.CartaoPontoCabecalho[0].DataTerminoPeriodo : '',
          };
          this.loadDataTableModalRelatorio(res.CartaoPontoDetalhe);

          if (res.CartaoPontoCabecalho.length > 0) {
            // Rel1
            const rel1 = [];
            rel1.push({
              descricao: res.CartaoPontoCabecalho[0].PeriodoBancoHoras.split(':')[0],
              periodo: res.CartaoPontoCabecalho[0].PeriodoBancoHoras.split(':')[1],
              horasPositivas: res.CartaoPontoCabecalho[0].HorasPositivas1,
              horasNegativas: res.CartaoPontoCabecalho[0].HorasNegativas1,
              saldoMes: res.CartaoPontoCabecalho[0].SaldoMes1
            });
            rel1.push({
              descricao: res.CartaoPontoCabecalho[0].PeriodoDiaPonte.split(':')[0],
              periodo: res.CartaoPontoCabecalho[0].PeriodoDiaPonte.split(':')[1],
              horasPositivas: res.CartaoPontoCabecalho[0].HorasPositivas2,
              horasNegativas: res.CartaoPontoCabecalho[0].HorasNegativas2,
              saldoMes: res.CartaoPontoCabecalho[0].SaldoMes2
            });
            // Rel2
            const rel2 = [];
            rel2.push({
              evt: res.CartaoPontoCabecalho[0].CodigoEvento1,
              descricao: res.CartaoPontoCabecalho[0].DescricaoEvento1,
              qtdMotivos: res.CartaoPontoCabecalho[0].QuantidadeHoras1,
              saldoBanco: '<b>Horas Positivas:  </b>' + res.CartaoPontoCabecalho[0].HorasPositivasBancoHoras,
              saldoCompensacao: '<b>Horas Positivas:  </b>' + res.CartaoPontoCabecalho[0].HorasPositivasDiaPonte
            });
            rel2.push({
              evt: res.CartaoPontoCabecalho[0].CodigoEvento2,
              descricao: res.CartaoPontoCabecalho[0].DescricaoEvento2,
              qtdMotivos: res.CartaoPontoCabecalho[0].QuantidadeHoras2,
              saldoBanco: '<b>Horas Negativas:  </b>' + res.CartaoPontoCabecalho[0].HorasNegativasBancoHoras,
              saldoCompensacao: '<b>Horas Negativas:  </b>' + res.CartaoPontoCabecalho[0].HorasNegativasDiaPonte
            });
            rel2.push({
              evt: res.CartaoPontoCabecalho[0].CodigoEvento3,
              descricao: res.CartaoPontoCabecalho[0].DescricaoEvento3,
              qtdMotivos: res.CartaoPontoCabecalho[0].QuantidadeHoras3,
              saldoBanco: '<b>Saldo: </b>' + res.CartaoPontoCabecalho[0].SaldoBancoHoras,
              saldoCompensacao: '<b>Saldo:  </b>' + res.CartaoPontoCabecalho[0].SaldoDiaPonte
            });
            rel2.push({
              evt: '',
              descricao:'',
              qtdMotivos: '',
              saldoBanco: '<b>Pagas:  </b>' + res.CartaoPontoCabecalho[0].HrsPagasBancoHoras,
              saldoCompensacao: '<b>Pagas:  </b>' + res.CartaoPontoCabecalho[0].HrsPagasDiaPonte
            });
            rel2.push({
              evt: '',
              descricao: '',
              qtdMotivos: '',
              saldoBanco: '<b>Descontadas:  </b>' + res.CartaoPontoCabecalho[0].HrsDescontadasBancoHoras,
              saldoCompensacao: '<b>Descontadas:  </b>' + res.CartaoPontoCabecalho[0].HrsDescontadasDiaPonte
            });
            rel2.push({
              evt: '',
              descricao: '',
              qtdMotivos: '',
              saldoBanco: '<b>Compensadas:  </b>' + res.CartaoPontoCabecalho[0].HrsCompensadasBancoHoras,
              saldoCompensacao: '<b>Compensadas:  </b>' + res.CartaoPontoCabecalho[0].HrsCompensadasDiaPonte
            });

            this.loadDataTableModalRelatorio1(rel1);
            this.loadDataTableModalRelatorio2(rel2);
          }
          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Ocorreu um erro ao buscar a os dados da importação!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.colaboradorSelected, this.mesSelected, $('#txtAno').val().toString()).subscribe(
      res => {
        if (res.length == 1 && res[0].Erro) {
          this.loadDataTable([]);
        } else {
          this.gridData = res.map(val =>
          ({
            id: val.Id,
            colaborador: val.Colaborador ? val.Colaborador.Nome : '',
            matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '',
            empresa: val.Colaborador ? (val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '') : '',
            mes: val.Mes || '',
            ano: val.Ano || '',
            dataConclusao: val.DataCadastro,
            ...val
          })
          );
          this.loadDataTable(this.gridData);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  pesquisar() {
    if (this.colaboradorSelected) {
      this.getDataGrid()
    } else {
      $('#txtNomeColaborador').addClass('element-invalid');
    }
  }

  clearInputvalues(type = '') {
    this.colaboradorSelected = '';
    this.mesSelected = '';
    $('#txtAno').val('');
    $('#txtNomeColaborador').addClass('element-invalid');
  }

  getValuesFromInput() {
    return {
      Mes: this.mesSelected,
      Ano: $('#txtAno').val().toString(),
      ColaboradorId: this.colaboradorSelected,
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[6]).on("click", async function (e) {
          self.remover(data.id);
        });
        $($(row).children()[7]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(data.DataCadastro).toLocaleDateString();
          self.openModal('modalRelatorio', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          $('#txtNomeColaborador').removeClass('element-invalid');
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "Dia"
        },
        {
          "data": "DiaSemana"
        },
        {
          "data": "NumeroJornada"
        },
        {
          "data": "TipoDia"
        },
        {
          "data": "Marcacao1"
        },
        {
          "data": "TipoMarcacao1"
        },
        {
          "data": "Marcacao2"
        },
        {
          "data": "TipoMarcacao2"
        },
        {
          "data": "Marcacao3"
        },
        {
          "data": "TipoMarcacao3"
        },
        {
          "data": "Marcacao4"
        },
        {
          "data": "TipoMarcacao4"
        },
        {
          "data": "Marcacao5"
        },
        {
          "data": "TipoMarcacao5"
        },
        {
          "data": "Marcacao6"
        },
        {
          "data": "TipoMarcacao6"
        },
        {
          "data": "Marcacao7"
        },
        {
          "data": "TipoMarcacao7"
        },
        {
          "data": "Marcacao8"
        },
        {
          "data": "TipoMarcacao8"
        },
        {
          "data": "InicioJornada"
        },
        {
          "data": "TerminoJornada"
        },
        {
          "data": "QuantHoras"
        },
        {
          "data": "DescricaoOcorrencia"
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }


  loadDataTableModalRelatorio1(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio1.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "descricao"
        },
        {
          "data": "periodo"
        },
        {
          "data": "horasPositivas"
        },
        {
          "data": "horasNegativas"
        },
        {
          "data": "saldoMes"
        },
      ],
      "paging": false,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio2(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio2.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "evt"
        },
        {
          "data": "descricao"
        },
        {
          "data": "qtdMotivos"
        },
        {
          "data": "saldoBanco"
        },
        {
          "data": "saldoCompensacao"
        },
      ],
      "paging": false,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }


}
