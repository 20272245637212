import { retry, catchError } from "rxjs/operators";
import { UtilsService } from "../../utils/utils";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class CadastroColaboradorService {
  routePrefix: string = "Colaborador";
  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) {}

  pesquisar(data = {}): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  adicionar(data = {}): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/Adicionar${this.routePrefix}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  salvarDocumentoAnexoColaborador(data = {},concluir?:boolean): Observable<any> {
    console.log(concluir);
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/SalvarDocumentoAnexo${this.routePrefix}?concluir=${concluir}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarDocumentoAnexoColaborador(colaboradorId = ""): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/BuscarDocumentoAnexo${this.routePrefix}`,
        { ColaboradorId: colaboradorId }
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  concluirPreAdmissaoColaborador(colaboradorId = ""): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/ConcluirPreAdmissaoColaborador`,
        { Id: colaboradorId }
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarDependentePorNome(nome = ""): Observable<any> {
    return this.httpClient
      .post<Array<any>>(`${environment.urlBase}Dependente/BuscarDependente`, {
        Nome: nome,
        CPF: "",
        Sexo: "",
        DataNascimento: "",
        NomeMae: "",
        GrauDependencia: "",
        ColaboradorId: 0,
      })
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarFotoPorColaboradorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/BuscarFoto${this.routePrefix}?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarDocumentoAnexoColaboradorPreAdmissao(
    colaboradorId = ""
  ): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/BuscarDocumentoAdmissional${this.routePrefix}PreAdmissao`,
        { ColaboradorId: colaboradorId }
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarArquivoDocumentoAnexoColaborador(arquivoId = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/BuscarArquivoDocumentoAnexo${this.routePrefix}?id=${arquivoId}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarArquivoDocumentoAnexoPreAdmissaoColaborador(
    arquivoId = ""
  ): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/BuscarArquivoDocumentoAnexoPreAdmissao${this.routePrefix}?id=${arquivoId}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  removerArquivoDocumentoAnexoPreAdmissaoColaborador(
    arquivoId = ""
  ): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/RemoverDocumentoAnexo${this.routePrefix}PreAdmissional?id=${arquivoId}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  removerArquivoDocumentoAnexoColaborador(
    arquivoId = ""
  ): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}${this.routePrefix}DocumentoAdmissional/RemoverDocumentoAnexo${this.routePrefix}?id=${arquivoId}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  editar(data = {}): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/Editar${this.routePrefix}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  remover(data): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}?id=${data.id}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarDocumentacao(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarColaboradorStatus(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}Status`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarColaboradorPorNome(nome: string = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorNome?nome=${nome}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  pesquisarColaboradorPorNomeAtivo(nome: string = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorNomeAtivo?nome=${nome}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  pesquisarColaboradorPorNomePorUsuarioId(nome: string = "",usuarioId): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorNomePorUsuarioId?nome=${nome}&usuarioId=${usuarioId}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarColaboradorPreAdmissaoPorNome(nome: string = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PreAdmissaoPorNome?nome=${nome}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  pesquisarColaboradorPreAdmissaoPorNomePorUsuarioId(nome: string = "",usuarioId): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PreAdmissaoPorNomePorUsuarioId?nome=${nome}&usuarioId=${usuarioId}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  pesquisarGestorPorNome(nome: string = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/BuscarGestorPorNome?nome=${nome}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoColaboradorTipo(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}Tipo`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoGrauInstrucao(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}GrauInstrucao/BuscarTudoGrauInstrucao`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoEstadoCivil(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}EstadoCivil/BuscarTudoEstadoCivil`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoCargo(idEmpresa = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}CargoUnidade/BuscarCargoUnidadePorIdEmpresa?id=${idEmpresa}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoCentroCusto(idEmpresa = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}CentroCustoUnidade/BuscarCentroCustoUnidadePorIdEmpresa?id=${idEmpresa}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoLotacao(idEmpresa = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}LotacaoUnidade/BuscarLotacaoUnidadePorIdEmpresa?id=${idEmpresa}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTipoRegistro(): Observable<any> {
    //this.concluirPreAdmissaoColaborador.log
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}TipoRegistro/BuscarTudoTipoRegistro`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTipoMaoDeObra(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}TipoMaoObra/BuscarTudoTipoMaoObra`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoUnidadeDeNegocio(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}UnidadeNegocio/BuscarTudoUnidadeNegocio`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTurno(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(`${environment.urlBase}Turno/BuscarTudoTurno`)
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTurnoPorEmpresaId(id:number): Observable<any> {
    return this.httpClient
      .get<Array<any>>(`${environment.urlBase}Turno/BuscarTudoTurnoPorEmpresaId?empresaId=${id}`)
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  BuscarTudoCategoriaSalarial(): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}CategoriaSalarial/BuscarCategoriaSalarial`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoSindicato(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(`${environment.urlBase}Sindicato/BuscarTudoSindicato`)
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoBanco(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(`${environment.urlBase}Pagamento/BuscarTudoBanco`)
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTipoConta(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(`${environment.urlBase}Pagamento/BuscarTudoTipoConta`)
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarTudoTipoVisto(): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorEstrangeiro/BuscarTudoEstrangeiroColaboradorTipoVisto`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  salvarDocumentoAnexo(data = {}): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}DocumentoAnexo/SalvarDocumentoAnexo${this.routePrefix}`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarColaboradorPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarColaboradorPorIdOuTipoRegistro(
    id = "",
    tipoRegistro = "",
    selectStatus = ""
  ): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorIdOuTipoDeRegistro?id=${id}&tipoRegistro=${tipoRegistro}&selectStatus=${selectStatus}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarColaboradorPorIdOuTipoRegistroPorUsuarioId(
    tipoRegistro = "",
    usuarioId: number,
    selectStatus = ""
  ): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorTipoDeRegistroPorUsuarioId?tipoRegistro=${tipoRegistro}&usuarioId=${usuarioId}&selectStatus=${selectStatus}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarEquipeGestor(data = {}): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}ColaboradorEquipeGestor/BuscarEquipeGestor`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  adicionarEquipeGestor(data = {}): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}ColaboradorEquipeGestor/AdicionarEquipeGestor`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  removerEquipeGestor(id = ""): Observable<any> {
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}ColaboradorEquipeGestor/ExcluirEquipeGestor?id=${id}`,
        {}
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarColaboradorPorGestorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}Colaborador/BuscarColaboradorPorGestorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarColaboradorPorUsuarioId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}Colaborador/BuscarColaboradorPorUsuarioId?usuarioId=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  buscarDependentePorColaboradorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}Dependente/BuscarDependentePorColaboradorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarDadoPrincipalColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}Colaborador/BuscarDadoPrincipalColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarDocumentacaoColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorDocumentacao/BuscarDocumentacaoColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarEmpregadorColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorEmpregador/BuscarEmpregadorColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarPagamentoColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorPagamento/BuscarPagamentoColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarEstrangeiroColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorEstrangeiro/BuscarEstrangeiroColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  BuscarDocumentoAnexoColaboradorPreAdmissaoPorId(id = ""): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}ColaboradorDocumentoAdmissional/BuscarDocumentoAnexoColaboradorPreAdmissaoPorId?id=${id}`
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  ExportarColaboradorPorIdOuTipoRegistro(
    id = "",
    tipoRegistro = ""
  ): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Exportar${this.routePrefix}PorIdOuTipoDeRegistro?id=${id}&tipoRegistro=${tipoRegistro}`,
        {
          //@ts-ignore
          responseType: "arraybuffer",
        }
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  ExportarColaboradorPorIdOuTipoRegistroPorUsuarioId(
    id = "",
    tipoRegistro = "",
    usuarioId = "",
  ): Observable<any> {
    return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Exportar${this.routePrefix}PorIdOuTipoDeRegistroPorUsuarioId?id=${id}&tipoRegistro=${tipoRegistro}&usuarioId=${usuarioId}`,
        {
          //@ts-ignore
          responseType: "arraybuffer",
        }
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }

  EnviarEmail(data): Observable<any> {
    console.log(data);
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}Colaborador/ReenviarEmailColaborador/`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
  EnviarEmailConclusao(data): Observable<any> {
    console.log(data);
    return this.httpClient
      .post<Array<any>>(
        `${environment.urlBase}Colaborador/EnviarEmailConclusao/`,
        data
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
}
