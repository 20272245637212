import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoHoraExtraService } from 'src/app/@core/@service/solicitacao/hora-extra/hora-extra.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum, ColaboradorTipoEnum, SolicitacaoStatusEnum } from 'src/app/@core/@enum/enum';
import { Router } from '@angular/router';



@Component({
  selector: 'app-aprovacao-hora-extra',
  templateUrl: './hora-extra.component.html',
  styleUrls: ['./hora-extra.component.scss']
})
export class AprovacaoHoraExtraComponent implements OnInit {

  gridData = [];

  motivoReprovacao = '';

  dataTable: any;

  colaboradorLogged = ''

  colaboradorType = '';

  userLogged: any;

  solicitacaoSelectedId = '';

  isMarmitex = false;

  gridModalConvocacaoData = [];

  marmitexAprovacaoSelected = '';
  marmitexAprovacaoList: any = [];

  dataModalConvocados: any;

  modalRelatorioData: any = {
    cpf: '',
    colaborador: '',
    empresa: '',
    ano: '',
    mes: '',
    dataInicio: '',
    dataFim: '',
  };

  @ViewChild('gridGestor', { static: true }) gridGestor: ElementRef;
  @ViewChild('gridFuncionario', { static: true }) gridFuncionario: ElementRef;
  @ViewChild('gridModalConvocacao', { static: true }) gridModalConvocacao: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoHoraExtraService,
    private router: Router
  ) { }

  ngOnInit() {
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
    const userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (userLogged) {
      this.colaboradorLogged = userLogged.UsuarioColaboradorId;
      if (userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR) {
        console.log()
        if (userLogged.ColaboradorTipo.Id == ColaboradorTipoEnum.FUNCIONARIO) {
          this.colaboradorType = 'funcionario';
          this.getDataGrid();
        } else if (userLogged.ColaboradorTipo.Id == ColaboradorTipoEnum.GESTOR) {
          this.colaboradorType = 'gestor';
          this.loadDataTableGestor([]);
        }
      } else {
        location.href = location.origin + '/Perfil';
      }
    }
  }

  changeRadioAprovacao() {
    this.isMarmitex = $('#radioMarmitex').is(':checked');
    if (this.isMarmitex && this.marmitexAprovacaoList.length == 0) {
      this.moduleService.buscarMarmitex().subscribe(
        res => {
          this.marmitexAprovacaoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Tipo,
          })
          );
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMarmitexAprovacao') {
      this.marmitexAprovacaoSelected = event.target.value;
    }
  }

  reprovar() {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja reprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = {
          Id: this.solicitacaoSelectedId,
          SolicitacaoHoraExtraColaborador: [
            {
              ColaboradorId: this.colaboradorLogged,
            }
          ]
        }
        this.moduleService.reprovar(obj).subscribe(
          res => {
            this.utilService.alertSwal('reprove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('reprove-error');
          }
        );
      }
    });
  }

  aprovar() {
    let data;
    if (this.colaboradorType == 'funcionario') {
      data = {
        Id: this.solicitacaoSelectedId,
        temRefeicao: $('#radioRefeicao').is(':checked'),
        temMarmitex: $('#radioMarmitex').is(':checked'),
        marmitexId: this.marmitexAprovacaoSelected,
        SolicitacaoHoraExtraColaborador: [
          {
            ColaboradorId: this.colaboradorLogged,
          }
        ]
      }
      this.moduleService.aprovar(data).subscribe(
        res => {
          if (res == 'Registro não encontrado') {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: res,
              showConfirmButton: false,
              timer: 2000
            });
            return;
          }
          this.utilService.alertSwal('approve-success');
          this.closeModal('modalAprovacao');
          this.getDataGrid();
        },
        err => {
          this.utilService.alertSwal('approve-error');
        }
      );
    } else {
      const funcChecked = this.gridModalConvocacaoData.filter(val => val.isChecked);
      if (funcChecked.length == 0) {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Necessário ao menos um funcionário!',
          showConfirmButton: false,
          timer: 2000
        });
      } else if ($('#radioMarmitex').is(':checked') && !this.marmitexAprovacaoSelected) {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'O campo "Marmitex" é obrigatório!',
          showConfirmButton: false,
          timer: 2000
        });
      } else {
        data = {
          Id: this.solicitacaoSelectedId,
          temRefeicao: $('#radioRefeicao').is(':checked'),
          temMarmitex: $('#radioMarmitex').is(':checked'),
          marmitexId: this.marmitexAprovacaoSelected,
          SolicitacaoHoraExtraColaborador: funcChecked.map(val =>
          ({
            ColaboradorId: val.id,
          })
          ),
        }
        this.moduleService.aprovar(data).subscribe(
          res => {
            if (res == 'Registro não encontrado') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res,
                showConfirmButton: false,
                timer: 2000
              });
              return;
            }
            this.utilService.alertSwal('approve-success');
            this.closeModal('modalAprovacao');
            this.closeModal('modalAprovacaoConvocados');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('approve-error');
          }
        );
      }
    }
  }

  getDataGridModalConvocados() {
    this.moduleService.buscarConvocadosPorHoraExtraId(this.solicitacaoSelectedId).subscribe(
      res => {
        this.gridModalConvocacaoData = res.map(val =>
        ({
          id: val.ColaboradorId,
          cpf: val.Colaborador.CPF,
          matricula: val.Colaborador.MatriculaInterna || '-',
          nome: val.Colaborador.Nome,
          status: val.SolicitacaoStatusId ? this.getStatusDescricao(val.SolicitacaoStatusId) : '',
          dataConclusao: val.DataConclusao || '',
          isChecked: false,
          ...val,
        })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTableModalConvocacao(this.gridModalConvocacaoData);
      },
      err => {
        console.log(err);
      }
    );
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalAprovacaoConvocados') {
      $("#checkAll").prop("checked", false);
      this.dataModalConvocados = data;
      this.getDataGridModalConvocados();
      ($("#formularioConvocacao") as any).on("submit", function () {
        return false;
      });
      ($(`#${idModal}`) as any).modal('show');
    } else if (idModal == 'modalAprovacao') {

      // limpar dados do modal
      $("#radioRefeicao").prop("checked", false);
      $("#radioMarmitex").prop("checked", false);
      this.marmitexAprovacaoSelected = '';
      this.isMarmitex = false;

      let obj;
      if (this.colaboradorType == 'funcionario')
        obj = data;
      if (this.colaboradorType == 'gestor') {
        obj = this.dataModalConvocados;
        const funcChecked = this.gridModalConvocacaoData.filter(val => val.isChecked);
        if (funcChecked.length == 0) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Necessário ao menos um funcionário!',
            showConfirmButton: false,
            timer: 2000
          });
          return;
        }
      }
      $("#radioRefeicao").prop("checked", obj.TemRefeicao);
      $("#radioMarmitex").prop("checked", obj.TemMarmitex);
      if (obj.TemMarmitex) {
        this.marmitexAprovacaoSelected = obj.MarmitexId ? obj.MarmitexId : '';
        this.changeRadioAprovacao();
      }
      ($(`#${idModal}`) as any).modal('show');
    }
  }

  clearInputvalues() {
    // Zera os campos
    $('#txtData').val('');
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  checkAllConvocacao() {
    this.gridModalConvocacaoData = this.gridModalConvocacaoData.map(val =>
    ({
      id: val.id,
      cpf: val.cpf,
      matricula: val.matricula,
      nome: val.nome,
      isChecked: $('#checkAll').is(':checked'),
    })
    );
    this.loadDataTableModalConvocacao(this.gridModalConvocacaoData);
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return 'Em Análise';
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return 'Aprovado';
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return 'Reprovado';
    } else {
      return '';
    }
  }

  getDataGrid() {
    if (this.colaboradorType == 'funcionario') {
      this.moduleService.buscarPorColaboradorId(this.colaboradorLogged).subscribe(
        res => {
          this.gridData = res.map(val =>
          ({
            id: val.Id || '',
            data: val.Data || '',
            horarioInicial: val.HoraInicio || '-',
            horarioFinal: val.HoraFim || '-',
            motivo: val.Motivo || '-',
            hasCafeManha: val.TemCafeManha ? 'Sim' : 'Não',
            hasRefeicao: val.TemRefeicao ? 'Sim' : 'Não',
            hasMarmitex: val.TemMarmitex ? 'Sim' : 'Não',
            marmitex: val.Marmitex ? val.Marmitex.Tipo : '-',
            status: val.SolicitacaoHoraExtraColaborador.length > 0 ? val.SolicitacaoHoraExtraColaborador[0].SolicitacaoStatus.Nome : this.getStatusDescricao(val.SolicitacaoHoraExtraColaborador[0].SolicitacaoStatusId),
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val,
          })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTableFuncionario(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      const data = $('#txtData').val() ? $('#txtData').val().toString().trim().split('/') : '';
      this.moduleService.buscarPorGestorId(this.colaboradorLogged, $('#txtData').val() ? `${data[2]}-${data[1]}-${data[0]}` : '').subscribe(
        res => {
          this.gridData = res.map(val =>
          ({
            id: val.Id || '',
            data: val.Data || '',
            horarioInicial: val.HoraInicio || '-',
            horarioFinal: val.HoraFim || '-',
            motivo: val.Motivo || '-',
            hasCafeManha: val.TemCafeManha ? 'Sim' : 'Não',
            hasRefeicao: val.TemRefeicao ? 'Sim' : 'Não',
            hasMarmitex: val.TemMarmitex ? 'Sim' : 'Não',
            marmitex: val.Marmitex ? val.Marmitex.Tipo : '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : this.getStatusDescricao(val.SolicitacaoStatusId),
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val,
          })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTableGestor(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  loadDataTableGestor(data) {
    let self = this;
    this.dataTable = $(this.gridGestor.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'data', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "horarioInicial"
        },
        {
          "data": "horarioFinal"
        },
        {
          "data": "motivo"
        },
        {
          "data": "hasCafeManha"
        },
        {
          "data": "hasRefeicao"
        },
        {
          "data": "hasMarmitex"
        },
        {
          "data": "marmitex"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-users" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Convocados"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[9]).on("click", async function (e) {
          // detalhe
          self.solicitacaoSelectedId = data.Id;
          self.openModal('modalAprovacaoConvocados', data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableFuncionario(data) {
    let self = this;
    this.dataTable = $(this.gridFuncionario.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'data', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "horarioInicial"
        },
        {
          "data": "horarioFinal"
        },
        {
          "data": "motivo"
        },
        {
          "data": "hasCafeManha"
        },
        {
          "data": "hasRefeicao"
        },
        {
          "data": "hasMarmitex"
        },
        {
          "data": "marmitex"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.status.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            }
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.status.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[9]).on("click", function (e) {
          // aprovar
          if (data.status.toString().toLowerCase().includes('análise')) {
            self.solicitacaoSelectedId = data.Id;
            self.openModal('modalAprovacao', data);
          }
        });
        $($(row).children()[10]).on("click", async function (e) {
          // reprovar
          if (data.status.toString().toLowerCase().includes('análise')) {
            self.solicitacaoSelectedId = data.Id;
            self.reprovar();
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalConvocacao(data) {
    let self = this;
    const datatable: any = $(this.gridModalConvocacao.nativeElement);
    datatable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": 'SolicitacaoStatusId', "orderable": false, "render": function (data, type, row) {
            if (data == SolicitacaoStatusEnum.EM_ANALISE) {
              return '<div style="text-align: center;"><input type="checkbox"/></div>';
            } else {
              return '<div style="text-align: center;"><input disabled checked type="checkbox"/></div>';
            }

          }
        },
        {
          "data": 'matricula', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'cpf', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'nome', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'status', "orderable": true, "render": function (data, type, row) {
            return `<div style="text-align: center;">${data}</div>`;
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[0]).find('input[type=checkbox]').each(function () {
          $(this).change(function () {
            self.gridModalConvocacaoData[index].isChecked = !data.isChecked;
          });
        })
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": true,
      "ordering": true,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
