<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Colaborador
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Colaborador</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li id="dadosPrincipais" class="active"><a (click)="changeTab('dadosPrincipais')">Dados Principais</a></li>
        <li id="documentacao" ><a (click)="changeTab('documentacao')">Documentação</a></li>
        <li id="empregador" ><a (click)="changeTab('empregador')">Empregador</a></li>
        <li id="pagamento" ><a (click)="changeTab('pagamento')">Pagamento</a></li>
        <li id="estrangeiro" ><a (click)="changeTab('estrangeiro')">Estrangeiro</a></li>
        <!-- <li id="anexarDocumentos"><a (click)="changeTab('anexarDocumentos')">Anexar Documento</a></li> -->
        <li id="anexarDocumentos" *ngIf="ehRH"><a (click)="changeTab('anexarDocumentos')">Anexar Documento</a></li>
      </ul>
    </div>
    <div #tabRouterDadoPrincipalDiv>
      <ng-template #tabRouterDadoPrincipal></ng-template>
    </div>
    <div #tabRouterDocumentacaoDiv>
      <ng-template #tabRouterDocumentacao></ng-template>
    </div>
    <div #tabRouterEmpregadorDiv>
      <ng-template #tabRouterEmpregador></ng-template>
    </div>
    <div #tabRouterPagamentoDiv>
      <ng-template #tabRouterPagamento></ng-template>
    </div>
    <div #tabRouterEstrangeiroDiv>
      <ng-template #tabRouterEstrangeiro></ng-template>
    </div>
    <div #tabRouterAnexarDocumentosDiv>
      <ng-template #tabRouterAnexarDocumentos></ng-template>
    </div>

    <!-- FIM DIV INTERNA PRINCIPAL -->
  </section>

  <div class="control-sidebar-bg"></div>
</div>