import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroMarmitexService } from 'src/app/@core/@service/cadastro/marmitex/marmitex.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-marmitex',
  templateUrl: './marmitex.component.html',
  styleUrls: ['./marmitex.component.scss']
})
export class MarmitexComponent implements OnInit {

  gridData = [];

  isEdit = false;

  marmitexIdSelected = '';

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroMarmitexService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtTipo: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtTipo: {
          required: "Informe o Tipo"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            tipo: val.Tipo,
            dataCadastro: val.DataCadastro,
          })
        ).sort(this.utilService.dynamicSort('tipo'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtTipo').val('');
  }

  getValuesFromInput() {
    return {
      Tipo: $('#txtTipo').val().toString().trim(),
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.marmitexIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "tipo"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[2]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          $('#txtTipo').val(data.tipo);
          self.marmitexIdSelected = data.id;
        });
        $($(row).children()[3]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
