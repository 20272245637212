<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Cartão de Ponto
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Aprovação</li>
      <li class="active">Cartão de Ponto</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">
    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 15%">Nome do Colaborador</th>
                <th style="width: 15%">Matrícula</th>
                <th style="width: 15%">Empresa</th>
                <th style="width: 15%">Mês</th>
                <th style="width: 15%">Ano</th>
                <th style="width: 15%">Status</th>
                <th style="width: 15%">Data da Conclusão</th>
                <th style="width: 15%">Motivo Reprovação</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>
  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 95%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Aprovação de Cartão de Ponto</b></h4>
      </div>
      <div class="modal-header" style="padding-left: 15px;">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-4">
            <b>Estabelecimento:</b> {{modalRelatorioData.empresa}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Matrícula:</b> {{modalRelatorioData.matricula}}
          </div>
          <div class="col-md-9">
            <b>Nome:</b> {{modalRelatorioData.colaborador}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Mês:</b> {{modalRelatorioData.mes}}
          </div>
          <div class="col-md-2">
            <b>Ano:</b> {{modalRelatorioData.ano}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Data de Início Período:</b> {{modalRelatorioData.dataInicio}}
          </div>
          <div class="col-md-4">
            <b>Data Termino Período:</b> {{modalRelatorioData.dataInicio}}
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalRelatorio style="width: 100% !important; cursor: pointer;"
              class="hover table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Dia da Semana</th>
                  <th>Número Jornada</th>
                  <th>Tipo Dia</th>
                  <th>Marcação 1</th>
                  <th>Tipo Marcação 1</th>
                  <th>Marcação 2</th>
                  <th>Tipo Marcação 2</th>
                  <th>Marcação 3</th>
                  <th>Tipo Marcação 3</th>
                  <th>Marcação 4</th>
                  <th>Tipo Marcação 4</th>
                  <th>Marcação 5</th>
                  <th>Tipo Marcação 5</th>
                  <th>Marcação 6</th>
                  <th>Tipo Marcação 6</th>
                  <th>Marcação 7</th>
                  <th>Tipo Marcação 7</th>
                  <th>Marcação 8</th>
                  <th>Tipo Marcação 8</th>
                  <th>Início Jornada</th>
                  <th>Termino Jornada</th>
                  <th>Quant. Horas</th>
                  <th>Descrição Ocorrência</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>