import { Injectable, EventEmitter } from '@angular/core';
import { CadastroColaboradorService } from '../cadastro/colaborador/colaborador.service';
import { Subject, Observable } from 'rxjs';
import { ColaboradorTipoEnum, UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from '../../@enum/enum';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../utils/utils';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class PerfilService {

    constructor(
        private colaboradorService: CadastroColaboradorService,
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    perfilData: any = {
        nome: '',
        cargo: '',
        fotoBase64: '',
        empresa: '',
        cpf: '',
        endereco: '',
        complemento: '',
        bairro: '',
        cep: '',
        uf: '',
        cidade: '',
        telefoneResidencial: '',
        telefoneComercial: '',
        telefoneCelular: '',
        email: '',
        dataCadastro: '',
        tipoUsuario: '',
    }

    tipoUsuario = '';

    private perfilDataSubject = new Subject<any>();

    sendPerfilData(perfilData: any) {
        perfilData.tipoUsuario = this.tipoUsuario;
        this.perfilDataSubject.next(perfilData);
    }

    clearPerfilData() {
        this.perfilDataSubject.next(this.perfilData);
    }

    getPerfilData(): Observable<any> {
        return this.perfilDataSubject.asObservable();
    }

    getUsuarioById(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}Usuario/BuscarUsuarioPorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    mapPerfilData(data) {
        return {
            nome: data.Nome || '',
            cargo: data.ColaboradorTipo ? data.ColaboradorTipo.Nome : '',
            fotoBase64: data.FotoBase64,
            empresa: data.Empresa ? data.Empresa.Nome : '',
            cpf: data.CPF || '',
            endereco: data.Endereco || '',
            complemento: data.Complemento || '',
            bairro: data.Bairro || '',
            cep: data.CEP || '',
            uf: data.UF || '',
            cidade: data.Cidade || '',
            telefoneResidencial: data.Telefone1 || '',
            telefoneCelular: data.Telefone2 || '',
            telefoneComercial: data.Telefone3 || '',
            email: data.Email || '',
            tipoUsuario: this.tipoUsuario || '',
            dataCadastro: data.DataCadastro ? new Date(data.DataCadastro).toLocaleDateString('pt-BR') : '',
        }
    }

    mapPerfilRHData(data) {
        return {
            nome: data.Nome || '',
            cargo:  '',
            fotoBase64: '',
            empresa: '',
            cpf: data.CPF || '',
            endereco: data.Endereco || '',
            complemento: data.Complemento || '',
            bairro: data.Bairro || '',
            cep: data.CEP || '',
            uf: data.UF || '',
            cidade: data.Cidade || '',
            telefoneResidencial: data.TelefoneResidencial || '',
            telefoneCelular: data.TelefoneCelular || '',
            telefoneComercial: data.TelefoneComercial || '',
            email: data.Email || '',
            tipoUsuario: this.tipoUsuario || '',
            dataCadastro: data.DataCadastro ? new Date(data.DataCadastro).toLocaleDateString('pt-BR') : '',
        }
    }

    setColaboradorSessionStorage(data: any) {
        data.tipoUsuario = this.tipoUsuario;
        sessionStorage.setItem('currentColaborador', JSON.stringify(data));
    }

    getData() {
        const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const currColaborador = JSON.parse(sessionStorage.getItem('currentColaborador'));
        const currColaboradorPreAdmissao = JSON.parse(sessionStorage.getItem('currentColaboradorPreadmissao'));
        const currUserRH = JSON.parse(sessionStorage.getItem('currentUsuarioRH'));
        const colaboradorlogged = currUser ? currUser.UsuarioColaboradorId : '';
        //const usuarioRHlogged = currUser ? currUser.Id : '';
        const usuarioRHlogged = currUser ? currUser.UsuarioColaboradorId : '';
        if (!currUser.tipoUsuario) {
            if (currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO || currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
                this.tipoUsuario = 'RH';
            else {
                if (currUser.ColaboradorStatus) {
                    if (currUser.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO)
                        this.tipoUsuario = 'Pré-Admissão';
                    else {
                        if (currUser.ColaboradorTipo.Id == ColaboradorTipoEnum.FUNCIONARIO)
                            this.tipoUsuario = 'Funcionário';
                        if (currUser.ColaboradorTipo.Id == ColaboradorTipoEnum.GESTOR)
                            this.tipoUsuario = 'Gestor';
                    }
                } else {
                    if (currUser.ColaboradorTipo.Id == ColaboradorTipoEnum.FUNCIONARIO)
                        this.tipoUsuario = 'Funcionário';
                    if (currUser.ColaboradorTipo.Id == ColaboradorTipoEnum.GESTOR)
                        this.tipoUsuario = 'Gestor';
                }
            }
            currUser.tipoUsuario = this.tipoUsuario;
            sessionStorage.setItem('currentUser', JSON.stringify(currUser));
        } else {
            this.tipoUsuario = currUser.tipoUsuario;
        }

        if (this.tipoUsuario == 'RH') {
            if (!currUserRH) {
                this.getUsuarioById(usuarioRHlogged).subscribe(
                    res => {
                        sessionStorage.setItem('currentUsuarioRH', JSON.stringify(res));
                        this.sendPerfilData(this.mapPerfilRHData(res));
                    },
                    err => {
                        console.log(err)
                    }
                );
            } else {
                this.sendPerfilData(this.mapPerfilRHData(currUserRH));
            }
        } else if (this.tipoUsuario == 'Pré-Admissão') {
            if (!currColaboradorPreAdmissao) {
                this.colaboradorService.BuscarDadoPrincipalColaboradorPreAdmissaoPorId(colaboradorlogged).subscribe(
                    res => {
                        if (res.FotoNome) {
                            this.colaboradorService.buscarFotoPorColaboradorId(res.Id).subscribe(
                                res2 => {
                                    if (res2.includes('base64')) {
                                        if (!res2.includes('base64,')) {
                                            res2 = res2.replace('base64', 'base64,');
                                        }
                                        res.FotoBase64 = res2 != "Documento não encontrado" ? res2 : '';
                                        sessionStorage.setItem('currentColaboradorPreadmissao', JSON.stringify(res));
                                        this.sendPerfilData(this.mapPerfilData(res));
                                    }
                                    sessionStorage.setItem('currentColaboradorPreadmissao', JSON.stringify(res));
                                    this.sendPerfilData(this.mapPerfilData(res));
                                },
                                err2 => {
                                    sessionStorage.setItem('currentColaboradorPreadmissao', JSON.stringify(res));
                                    console.log(err2);
                                }
                            );
                        } else {
                            sessionStorage.setItem('currentColaboradorPreadmissao', JSON.stringify(res));
                            this.sendPerfilData(this.mapPerfilData(res));
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                this.sendPerfilData(this.mapPerfilData(currColaboradorPreAdmissao));
            }
        } else {
            if (!currColaborador) {
                this.colaboradorService.buscarColaboradorPorId(colaboradorlogged).subscribe(
                    res => {
                        if (res.FotoNome) {
                            this.colaboradorService.buscarFotoPorColaboradorId(res.Id).subscribe(
                                res2 => {
                                    if (res2.includes('base64')) {
                                        if (!res2.includes('base64,')) {
                                            res2 = res2.replace('base64', 'base64,');
                                        }
                                        res.FotoBase64 = res2 != "Documento não encontrado" ? res2 : '';
                                        this.setColaboradorSessionStorage(res);
                                        this.sendPerfilData(this.mapPerfilData(res));
                                    }
                                    this.setColaboradorSessionStorage(res);
                                    this.sendPerfilData(this.mapPerfilData(res));
                                },
                                err2 => {
                                    this.setColaboradorSessionStorage(res);
                                    console.log(err2);
                                }
                            );
                        } else {
                            this.setColaboradorSessionStorage(res);
                            this.sendPerfilData(this.mapPerfilData(res));
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );
            } else {
                this.sendPerfilData(this.mapPerfilData(currColaborador));
            }
        }
    }

}
