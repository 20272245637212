<header class="main-header">

  <!-- Logo -->
  <span id="titulo" class="logo" style="background: #1D3A69;">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">MRO</span>
    <!-- logo for regular state and mobile devices -->
    <!-- <span class="logo-lg"><b>Meu RH Online</b></span> -->
    <img src="../../../assets/Logotipo Meu RH Online em fundo Transparente - Principal.png" style="height: 40px; width: 70px;">
  </span>

  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top" style="background: #1D3A69;">
    <!-- Sidebar toggle button-->
    <a style="cursor: pointer;" (click)="menuCollapse()" class="sidebar-toggle" data-toggle="control-sidebar"
      role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>


    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li id="liLink">
          <a href={{linkSite}}><i class="fa fa-hand-pointer-o" aria-hidden="true"></i> Acesse aqui o Portal de RH</a>
        </li>

        <!-- Messages: style can be found in dropdown.less-->
        <!-- Notifications: style can be found in dropdown.less -->

        <li class="dropdown notifications-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-bell-o"></i>
            <span class="label label-warning">{{notificacaoList.length}}</span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <!-- inner menu: contains the actual data -->
              <ul class="menu" *ngIf="notificacaoList.length > 0; else semNotificacoes">
                <li *ngFor="let item of notificacaoList" (click)="openNotificacao(item)">
                  <a style="cursor: pointer;">
                    <i class="fa {{item.NotificacaoDetalhe.IconeClass}}"
                      style="color: #{{item.NotificacaoDetalhe.ExadecimalColor}}"></i>
                    {{item.NotificacaoDetalhe.Mensagem}}
                  </a>
                </li>
              </ul>
              <ng-template #semNotificacoes>
                <ul class="menu">
                  <div style="text-align: center; padding-top: 30%; font-weight: 700;">Sem notificações </div>
                </ul>
              </ng-template>
            </li>
          </ul>
        </li>
        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <!-- <img src="assets/dist/img/user2-160x160.jpg" class="user-image" alt="User Image"> -->
            <div #profileImageSmall id="profileImageSmall" class="profileText"></div>
            <span class="hidden-xs">{{ perfilData.nome }}</span>
          </a>
          <ul class="dropdown-menu">
            <!-- User image -->
            <li class="user-header">
              <div #profileImageBig id="profileImageBig"
                class="profile-user-img img-responsive img-circle profileText-circle"></div>

              <p style="font-size: 15px;">
                {{ perfilData.nome }}<br> {{ perfilData.tipoUsuario }}
              </p>
            </li>
            <!-- Menu Body -->
            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-left">
                <a href="/Perfil" class="btn btn-default btn-flat">Perfil</a>
              </div>
              <div class="pull-right">
                <a style="cursor: pointer;" (click)="logout()" class="btn btn-default btn-flat">Sair</a>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>
