<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
    <section class="content-header">
        <h1>
            Perfil
        </h1>
        <ol class="breadcrumb">
            <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
            <li class="active">Perfil</li>
        </ol>
    </section>

    <section class="content">

        <div class="box box-primary">
            <div class="box-body box-profile">
                <div class="col-md-12" style="padding-bottom: 15px;">
                    <div #profileImage id="profileImage" class="profile-user-img img-responsive img-circle profileText-circle"></div>

                    <h3 class="profile-username text-center">{{ perfilData.nome || '-' }}</h3>

                    <p class="text-muted text-center">{{ perfilData.tipoUsuario || '-' }}</p>
                </div>
                <div class="col-md-6">
                    <ul class="list-group list-group-unbordered">
                        <li class="list-group-item">
                            <b>CPF:</b> <a class="pull-right">{{ perfilData.cpf || '-'}}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Endereço:</b> <a class="pull-right">{{ perfilData.endereco || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Complemento:</b> <a class="pull-right">{{ perfilData.complemento || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Bairro:</b> <a class="pull-right">{{ perfilData.bairro || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>CEP:</b> <a class="pull-right">{{ perfilData.cep || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>UF:</b> <a class="pull-right">{{ perfilData.uf || '-' }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul class="list-group list-group-unbordered">
                        <li class="list-group-item">
                            <b>Cidade:</b> <a class="pull-right">{{ perfilData.cidade || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Telefone (Residencial):</b> <a class="pull-right">{{ perfilData.telefoneResidencial || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Telefone (Comercial):</b> <a class="pull-right">{{ perfilData.telefoneComercial || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Telefone (Celular):</b> <a class="pull-right">{{ perfilData.telefoneCelular || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Email:</b> <a class="pull-right">{{ perfilData.email || '-' }}</a>
                        </li>
                        <li class="list-group-item">
                            <b>Data de Cadastro:</b> <a class="pull-right">{{ perfilData.dataCadastro || '-' }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /.box-body -->
        </div>

    </section>

    <div class="control-sidebar-bg"></div>
</div>