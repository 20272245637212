<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Centro de Custo
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Centro de Custo</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li id="liUnidade" class="active"><a (click)="changeTab('unidade')">Unidade</a></li>
        <li id="liPlano" ><a (click)="changeTab('plano')">Plano</a></li>
      </ul>
    </div>

    <div #tabRouterUnidadeDiv>
      <ng-template #tabRouterUnidade></ng-template>
    </div>
    <div #tabRouterPlanoDiv>
      <ng-template #tabRouterPlano></ng-template>
    </div>
    <!-- FIM DIV INTERNA PRINCIPAL -->
  </section>

  <div class="control-sidebar-bg"></div>
</div>