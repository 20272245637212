<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Vale Transporte
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Solicitação</li>
      <li class="active">Vale Transporte</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;" [style.display]="colaboradorType == 'colaborador' ? 'none' : 'block'">
        <div class="box box-info">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                      placeholder="Nome do Colaborador">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Operadora VT</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="operadoraVTList"
                  [Disabled]="isEdit ? true : false"IdSelect="selOperadoraVT" [Value]="operadoraVTSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Linha VT</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="linhaVTList"
                    [Disabled]="!operadoraVTSelected ? true : isEdit ? true : false" IdSelect="selLinhaVT" [Value]="linhaVTSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Situação</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="situacaoList"
                    IdSelect="selSituacao" [Value]="situacaoSelected">
                  </select-component>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Utilização</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="utilizacaoList"
                    IdSelect="selUtilizacao" [Value]="utilizacaoSelected">
                  </select-component>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12"
              [style.display]="utilizacaoSelected != utilziacaoEnum.DIAS_DA_SEMANA ? 'none' : 'block'">
              <div class="form-group">
                <label>Dias da Semana</label>
                <div class="row" style="font-size: 12px;">
                  <div class="col-md-6">
                    <div class="col-md-3">
                      <input type="checkbox" value="2" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Segunda-Feira</span>
                    </div>
                    <div class="col-md-3">
                      <input type="checkbox" value="3" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Terça-Feira</span>
                    </div>
                    <div class="col-md-3">
                      <input type="checkbox" value="4" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Quarta-Feira</span>
                    </div>
                    <div class="col-md-3">
                      <input type="checkbox" value="5" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Quinta-Feira</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="col-md-4">
                      <input type="checkbox" value="6" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Sexta-Feira</span>
                    </div>
                    <div class="col-md-4">
                      <input type="checkbox" value="7" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Sábado</span>
                    </div>
                    <div class="col-md-4">
                      <input type="checkbox" value="1" (click)="selectDiasSemana($event)">
                      <span style="padding-left: 5%;">Domingo</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input value="Solicitar" readonly="readonly" (click)="solicitar()" class="btn btn-info pull-right btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Operadora VT</th>
                  <th style="width: 15% !important">Linha VT</th>
                  <th style="width: 15% !important">Valor da Linha (R$)</th>
                  <th style="width: 15% !important">Utilização</th>
                  <th style="width: 15% !important">Situação</th>
                  <th style="width: 15% !important">Status</th>
                  <th style="width: 15% !important">Data da Solicitação</th>
                  <th style="width: 15% !important">Data da Conclusão</th>
                  <th style="width: 5% !important;"></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
