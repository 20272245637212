<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Hora Extra
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Aprovação</li>
      <li class="active">Hora Extra</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary" [style.display]="colaboradorType == 'gestor' ? 'block' : 'none'">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Data</label>
                <div class="input-group date">
                  <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right" id="txtData" name="txtData"
                    placeholder="dd/mm/yyyy">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
              </td>
            </tr>
          </table>
        </div>

      </form>
    </div>

    <div class="box box-body" [style.display]="colaboradorType == 'gestor' ? 'block' : 'none'">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #gridGestor [style.display]="colaboradorType == 'gestor' ? 'table' : 'none'"
            style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 15% !important">Data</th>
                <th style="width: 15% !important">Horário Inicial</th>
                <th style="width: 15% !important">Horário Final</th>
                <th style="width: 15% !important">Motivo</th>
                <th style="width: 15% !important">Café da Manhã?</th>
                <th style="width: 15% !important">Refeição?</th>
                <th style="width: 15% !important">Marmitex?</th>
                <th style="width: 15% !important">Marmitex</th>
                <th style="width: 15% !important">Data da Solicitação</th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <div class="box box-body" [style.display]="colaboradorType == 'funcionario' ? 'block' : 'none'">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #gridFuncionario [style.display]="colaboradorType == 'funcionario' ? 'table' : 'none'"
            style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 15% !important">Data</th>
                <th style="width: 15% !important">Horário Inicial</th>
                <th style="width: 15% !important">Horário Final</th>
                <th style="width: 15% !important">Motivo</th>
                <th style="width: 15% !important">Café da Manhã?</th>
                <th style="width: 15% !important">Refeição?</th>
                <th style="width: 15% !important">Marmitex?</th>
                <th style="width: 15% !important">Marmitex</th>
                <th style="width: 15% !important">Data da Solicitação</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>
  <div class="control-sidebar-bg"></div>
</div>



<div class="modal fade" id="modalAprovacaoConvocados" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><b>Aprovar Funcionários convocados</b></h4><br>
      </div>
      <div class="modal-body">
        <form id="formularioConvocacao">
          <div style="overflow-y: auto; padding-left: 10px; padding-right: 10px;">
            <div style="width: 99%;">
              <table #gridModalConvocacao tyle="width: 100% !important;" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center;"><input id="checkAll" type="checkbox"
                        (click)="checkAllConvocacao()" /></th>
                    <th style="text-align: center;">Matrícula</th>
                    <th style="text-align: center;">CPF</th>
                    <th style="text-align: center;">Nome</th>
                    <th style="text-align: center;">Status</th>
                    <th style="text-align: center;">Data da Conclusão</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <input id="aprovarConvocados" name="aprovarConvocados" value="Aprovar" (click)="openModal('modalAprovacao')"
          class="btn btn-info pull-right btn-margin" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalAprovacao" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 40%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><b>Confirmar aprovação</b></h4><br>
      </div>
      <div class="modal-body">
        <div class="row" style="padding-top: 15px;">
          <div class="col-md-12">
            <div class="col-md-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <label>Refeição?</label>
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-12" style="text-align: center;">
                    <input name="alimentacao" (change)="changeRadioAprovacao()" id="radioRefeicao" type="radio">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12" style="text-align: center;">
                    <label>Marmitex?</label>
                  </div>
                </div>
                <div class="row" style="padding-top: 10px;">
                  <div class="col-md-12" style="text-align: center;">
                    <input name="alimentacao" (change)="changeRadioAprovacao()" id="radioMarmitex" type="radio">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" [style.display]="isMarmitex ? 'block' : 'none'">
              <label>Marmitex?</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="marmitexAprovacaoList"
                IdSelect="selMarmitexAprovacao" [Value]="marmitexAprovacaoSelected">
              </select-component>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <input id="aprovar" name="aprovar" value="Aprovar" (click)="aprovar()"
          class="btn btn-info pull-right btn-margin" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>