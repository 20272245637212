import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";
import { UtilsService } from "../../../../@core/@service/utils/utils";
import { ImportacaoHoleriteService } from "src/app/@core/@service/importacao/holerite/holerite.service";
import { CadastroEmpresaService } from "src/app/@core/@service/cadastro/empresa/empresa.service";
import Swal from "sweetalert2";
import { CadastroColaboradorService } from "src/app/@core/@service/cadastro/colaborador/colaborador.service";
//import * as jsPDF from "jspdf";
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { UsuarioColaboradorTipoEnum } from "src/app/@core/@enum/enum";
import html2canvas from "html2canvas";
import { CurrencyPipe } from "@angular/common";

declare var $;

@Component({
  selector: "app-relatorio-holerite",
  templateUrl: "./holerite.component.html",
  styleUrls: ["./holerite.component.scss"],
})
export class RelatorioHoleriteComponent implements OnInit {
  gridData = [];

  colaboradorGridData = [];

  arrayMes = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro",
  };

  modalRelatorioData: any = {
    cpf: "",
    colaborador: "",
    empresa: "",
    ano: "",
    mes: "",
    totalProventos: "",
    totalDescontos: "",
    liquido: "",
    salarioBase: "",
    salarioInss: "",
    baseCalcFgts: "",
    fgtsMes: "",
    baseCalcIRRF: "",
  };

  colaboradorLogged = "";

  colaboradorType = "";

  relatorioData: any;

  colaboradorSelected = "";

  arquivoAnexoSelected = "";

  empresaIdSelected = "";

  mesSelected = "";
  mesList = [];
  tipoSelected = "";
  tipoList = [];

  empresaGridData = [];

  typeImagesAccepted = ["text/plain"];

  dataTable: any;
  dataTableExport: any;

  @ViewChild("grid", { static: true }) grid: ElementRef;
  @ViewChild("gridModalEmpresa", { static: true }) gridModalEmpresa: ElementRef;
  @ViewChild("gridModalColaborador", { static: true })
  gridModalColaborador: ElementRef;
  @ViewChild("gridModalRelatorio", { static: true })
  gridModalRelatorio: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoHoleriteService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    const userLogged = JSON.parse(sessionStorage.getItem("currentUser"));
    if (userLogged) {
      this.colaboradorLogged = userLogged.UsuarioColaboradorId;
      if (
        userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO  || userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR)
        this.colaboradorType = "rh";
      else this.colaboradorType = "colaborador";
    }
    this.mesList = this.utilService.getMesList();
    this.tipoList = this.utilService.getTipoList();
    this.validarCampos();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true,
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == "selMes") {
      this.mesSelected = event.target.value;
      $("#selMes").removeClass("element-invalid");
    }
    else if (event.srcElement.id == "selTipo") {
      this.tipoSelected = event.target.value;
      $("#selTipo").removeClass("element-invalid");
    }
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true,
        },
        selTipo: {
          required: true,
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe a Data",
        },
        selTipo: {
          required: "Informe o Tipo",
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    });
  }

  async remover(id) {
    Swal.fire({
      position: "top",
      title: "Você tem certeza que deseja remover esse Holerite?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3c8dbc",
      cancelButtonColor: "#DD4B39",
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.excluirPorId(id).subscribe(
          (res) => {
            Swal.fire({
              position: "top",
              icon: "success",
              title: "Holerite removido!",
              showConfirmButton: false,
              timer: 2000,
            });
            this.pesquisar();
          },
          (err) => {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "Ocorreu um erro ao remover o Holerite!",
              showConfirmButton: false,
              timer: 2000,
            });
            console.log(err);
          }
        );
      }
    });
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);

    if (idModal == "modalEmpresa") {
      if ($("#txtNomeEmpresa").val().toString().length > 0) {
        this.empresaService
          .buscarEmpresaPorNome($("#txtNomeEmpresa").val().toString())
          .subscribe(
            (res) => {
              this.empresaGridData = res
                .map((val) => ({
                  id: val.Id,
                  nome: val.Nome,
                  cnpj: val.CNPJ,
                }))
                .sort(this.utilService.dynamicSort("nome"));
              this.loadDataTableModalEmpresa(this.empresaGridData);

              ($(`#${idModal}`) as any).modal("show");
            },
            (err) => {
              console.log(err);
            }
          );
      }
    }

    if (idModal == "modalColaborador") {
      if ($("#txtNomeColaborador").val().toString().length > 0) {
        this.colaboradorService
          .pesquisarColaboradorPorNome(
            $("#txtNomeColaborador").val().toString()
          )
          .subscribe(
            (res) => {
              this.colaboradorGridData = res
                .map((val) => ({
                  id: val.Id,
                  nome: val.Nome,
                  cpf: val.CPF,
                  ...val,
                }))
                .sort(this.utilService.dynamicSort("nome"));
              this.loadDataTableModalColaborador(this.colaboradorGridData);

              ($(`#${idModal}`) as any).modal("show");
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Ocorreu um erro ao buscar a lista de \n colaboradores!",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(err);
            }
          );
      }
    } else if (idModal == "modalRelatorio") {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        (res) => {
          if (res.HoleriteEventos && res.HoleriteEventos.length) {
            res.HoleriteEventos.forEach((element) => {
              element.ValoresNegativos = this.currencyPipe.transform(
                element.ValoresNegativos || "",
                "BRL",
                ""
              );
              element.ValoresPositivos = this.currencyPipe.transform(
                element.ValoresPositivos || "",
                "BRL",
                ""
              );
            });
          }

          this.modalRelatorioData = {
            matricula: res.Colaborador ? res.Colaborador.MatriculaInterna : "",
            colaborador: res.Colaborador ? res.Colaborador.Nome : "",
            empresa: data.Colaborador
              ? data.Colaborador.Empresa
                ? data.Colaborador.Empresa.Nome
                : ""
              : "",
            empresaCNPJ: data.Colaborador
              ? data.Colaborador.Empresa
                ? data.Colaborador.Empresa.CNPJ
                : ""
              : "",
            tipo: res.Tipo || "",
            ano: res.Ano || "",
            mes: res.Mes || "",
            mesNome: res.Mes ? this.arrayMes[res.Mes] : "",
            totalProventos: this.currencyPipe.transform(
              res.TotalProventos || "",
              "BRL",
              ""
            ),
            totalDescontos: this.currencyPipe.transform(
              res.TotalDescontos || "",
              "BRL",
              ""
            ),
            liquido: this.currencyPipe.transform(res.Liquido || "", "BRL", ""),
            salarioBase: this.currencyPipe.transform(
              res.SalarioBase || "",
              "BRL",
              ""
            ),
            salarioInss: this.currencyPipe.transform(
              res.SalarioContrINSS || "",
              "BRL",
              ""
            ),
            baseCalcFgts: this.currencyPipe.transform(
              res.BaseCalcFGTS || "",
              "BRL",
              ""
            ),
            fgtsMes: this.currencyPipe.transform(res.FTGSMes || "", "BRL", ""),
            baseCalcIRRF: this.currencyPipe.transform(
              res.BaseCalcIRRF || "",
              "BRL",
              ""
            ),
            eventosPDF: [...res.HoleriteEventos] || [],
          };

          while (this.modalRelatorioData.eventosPDF.length < 15) {
            this.modalRelatorioData.eventosPDF.push({
              Evento: "   ",
              Descricao: "    ",
              Quantidade: "",
              ValoresNegativos: "",
              ValoresPositivos: "",
            });
          }

          this.loadDataTableModalRelatorio(res.HoleriteEventos);
          ($(`#${idModal}`) as any).modal("show");
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Ocorreu um erro ao buscar a os dados da importação!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );
    }
  }

  exportarPDF() {
    html2canvas(document.querySelector("#holeriteRelatorio")).then((canvas) => {
      var pdf = new jsPDF("l", "pt", [canvas.width, canvas.height]);

      var imgData = canvas.toDataURL();
      pdf.addImage(imgData, 0, 0, canvas.width, canvas.height);
      pdf.save(
        `${this.modalRelatorioData.matricula}-${this.modalRelatorioData.ano}-${this.modalRelatorioData.mes}.pdf`
      );
    });
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal("hide");
  }

  getDataGrid() {
    if (($("#formulario") as any).valid()) {
      this.moduleService
        .pesquisar(
          this.colaboradorType == "rh" ? this.colaboradorSelected ? this.colaboradorSelected : "0" : this.colaboradorLogged,
          this.mesSelected,
          $("#txtAno").val().toString(),
          this.tipoSelected,
          this.empresaIdSelected
        )
        .subscribe(
          (res) => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map((val) => ({
                id: val.Id,
                colaborador: val.Colaborador ? val.Colaborador.Nome : "-",
                matricula: val.Matricula || "-",
                empresa: val.Colaborador
                  ? val.Colaborador.Empresa
                    ? val.Colaborador.Empresa.Nome
                    : ""
                  : "-",
                mes: val.Mes || "-",
                ano: val.Ano || "-",
                tipo: val.Tipo || "-",
                dataConclusao: val.DataCadastro,
                selecionadoParaExclusao: false,
                ...val,
              }));
              this.loadDataTable(this.gridData);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
    }
  }

  pesquisar() {
    ($("#formulario") as any).valid();
    if (this.colaboradorType == "rh") {
      //if (this.colaboradorSelected && this.mesSelected) {
      if (this.mesSelected && this.tipoSelected) {
        this.getDataGrid();
      } else {
        //if (!this.colaboradorSelected && this.colaboradorType == 'rh')
        // $('#txtNomeColaborador').addClass('element-invalid');
        if (!this.mesSelected) $("#selMes").addClass("element-invalid");
        if (!this.tipoSelected) $("#selTipo").addClass("element-invalid");
      }
    } else {
      if (this.mesSelected && this.tipoSelected) {
        this.getDataGrid();
      } else {
        //$("#selMes").addClass("element-invalid");
        if (!this.mesSelected) $("#selMes").addClass("element-invalid");
        if (!this.tipoSelected) $("#selTipo").addClass("element-invalid");
      }
    }
  }

  exluir() {
    Swal.fire({
      position: "top",
      title: `Você tem certeza que deseja remover ${this.itensSelecionadosParaExclusao.length} Holerites?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3c8dbc",
      cancelButtonColor: "#DD4B39",
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService
          .excluirPorMultiplosIds(
            this.itensSelecionadosParaExclusao.map((x) => x.id)
          )
          .subscribe(
            (res) => {
              Swal.fire({
                position: "top",
                icon: "success",
                title: "Holerites removidos!",
                showConfirmButton: false,
                timer: 2000,
              });
              this.pesquisar();
            },
            (err) => {
              Swal.fire({
                position: "top",
                icon: "error",
                title: "Ocorreu um erro ao remover os Holerites!",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(err);
            }
          );
      }
    });
  }

  clearInputvalues(type = "") {
    this.colaboradorSelected = "";
    this.mesSelected = "";
    this.tipoSelected = "";
    $("#txtAno").val("");
    $("#txtNomeColaborador").val("");
    // $('#txtNomeColaborador').addClass('element-invalid');
    // $('#selMes').addClass('element-invalid');
  }

  getValuesFromInput() {
    return {
      Mes: this.mesSelected,
      Ano: $("#txtAno").val().toString(),
      Tipo: this.tipoSelected,
      ColaboradorId: this.colaboradorSelected,
    };
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><input type="checkbox" data-delete-check=""></div>';
          },
        },
        {
          data: "colaborador",
        },
        {
          data: "matricula",
        },
        {
          data: "empresa",
        },
        {
          data: "mes",
        },
        {
          data: "ano",
        },
        {
          data: "tipo",
        },
        {
          data: "dataConclusao",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "";
          },
        },
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          },
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()[0]).on("click", async function (e) {
          data.selecionadoParaExclusao = !data.selecionadoParaExclusao;
        });

        $($(row).children()[0]).on("change", async function (e) {
          data.selecionadoParaExclusao = $(e.target).prop("checked");

          if (
            $("[data-delete-check]").length ==
            self.itensSelecionadosParaExclusao.length
          ) {
            $("[data-select-all]").prop("checked", true);
          }

          if (self.itensSelecionadosParaExclusao.length == 0) {
            $("[data-select-all]").prop("checked", false);
          }
        });

        $($(row).children()[8]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(
            data.DataCadastro
          ).toLocaleDateString();
          self.openModal("modalRelatorio", data);
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: true,
      searching: true,
      ordering: true,
      info: true,
      autoWidth: true,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cpf",
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $("#txtNomeColaborador").val(data.nome);
          $("#txtNomeColaborador").removeClass("element-invalid");
          self.closeModal("modalColaborador");
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "Evento",
        },
        {
          data: "Descricao",
        },
        {
          data: "Quantidade",
        },
        {
          data: "ValoresPositivos",
        },
        {
          data: "ValoresNegativos",
        },
      ],
      createdRow: function (row, data, index) {},
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cnpj",
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          $("#txtNomeEmpresa").removeClass("element-invalid");
          self.empresaIdSelected = data.id;
          $("#txtNomeEmpresa").val(data.nome);
          self.closeModal("modalEmpresa");
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }


  selecionarTodosParaExclusao(event) {
    let value = event.target.checked;

    $("[data-delete-check]").prop("checked", value);
    $("[data-delete-check]").trigger("change");
  }

  get itensSelecionadosParaExclusao() {
    return this.gridData.filter((x) => x.selecionadoParaExclusao);
  }
}
