import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImportacaoAbsenteismoService {

    routePrefix: string = 'Absenteismo'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisarporAnoMes(mes = '', ano = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarAbsenteismoPorAnoMes?mes=${mes}&ano=${ano}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarporGestorIdAnoMes(gestorId = '', mes = '', ano = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarAbsenteismoPorGestorIdAnoMes?gestorId=${gestorId}&mes=${mes}&ano=${ano}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarImportacaoPorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    importar(data: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Importar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}