import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoFeriasService } from 'src/app/@core/@service/solicitacao/ferias/ferias.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { ImportacaoFeriasService } from 'src/app/@core/@service/importacao/ferias/ferias.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { SolicitacaoStatusEnum } from 'src/app/@core/@enum/enum';



@Component({
  selector: 'app-aprovacao-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.scss']
})
export class AprovacaoFeriasComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  empresaGridData = [];

  colaboradorSelected = '';

  gestorSelected

  statusSelected = '';

  empresaSelected = '';

  statusList: Array<any>;

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoFeriasService,
    private feriasService: ImportacaoFeriasService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.loadDataTable([]);
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selStatus') {
      this.statusSelected = event.target.value;
    }
  }

  reprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja reprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.reprovar(data).subscribe(
          res => {
            this.utilService.alertSwal('reprove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('reprove-error');
          }
        );
      }
    })
  }

  aprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja aprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.aprovar(data).subscribe(
          res => {
            this.utilService.alertSwal('approve-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('approve-error');
          }
        );
      }
    })
  }

  getValuesFromInput() {
    return {
      GestorId: this.gestorSelected,
      ColaboradorId: this.colaboradorSelected,
      SolicitacaoStatusId: this.statusSelected,
    }
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return 'Em Análise';
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return 'Aprovado';
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return 'Reprovado';
    } else {
      return '';
    }
  }


  mapGrid(data) {
    const arrData = [];
    data.Periodo.forEach(element => {
      if (element.Concessao.length > 0) {
        if (element.Concessao.length == 1) {
          arrData.push(
            {
              id: element.Id,
              colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
              matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
              empresa: data.Empresa ? data.Empresa.Nome : '-',
              inicioPeriodo: element.InicioPeriodo || '-',
              fimPeriodo: element.FimPeriodo || '-',
              situacao: element.Situacao || '-',
              diasDireito: element.DiasDireito || '-',
              diasConcedidos: element.DiasConcedidos || '-',
              diasProgramados: element.DiasProgramados || '-',
              inicioFerias: element.Concessao[0].InicioFerias || '-',
              diasGozo: element.Concessao[0].DiasGozo || '-',
              diasAbono: element.Concessao[0].DiasAbono || '-',
              diasDeLicenca: element.Concessao[0].DiasLicenca || '-',
              saldo: element.Saldo || '-',
              status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
              dataSolicitacao: element.DataSolicitacao,
              dataConclusao: element.DataConclusao,
              isConcessao: false,
              ...element
            });
        } else {
          element.Concessao.forEach((element2, index) => {
            if (index == 0) {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
                  matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
                  empresa: data.Empresa ? data.Empresa.Nome : '-',
                  inicioPeriodo: element.InicioPeriodo || '-',
                  fimPeriodo: element.FimPeriodo || '-',
                  situacao: element.Situacao || '-',
                  diasDireito: element.DiasDireito || '-',
                  diasConcedidos: element.DiasConcedidos || '-',
                  diasProgramados: element.DiasProgramados || '-',
                  inicioFerias: element.Concessao[index].InicioFerias || '-',
                  diasGozo: element.Concessao[index].DiasGozo || '-',
                  diasAbono: element.Concessao[index].DiasAbono || '-',
                  diasDeLicenca: element.Concessao[index].DiasLicenca || '-',
                  saldo: element.Saldo || '-',
                  status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
                  dataSolicitacao: element.DataSolicitacao,
                  dataConclusao: element.DataConclusao,
                  isConcessao: false,
                  ...element
                });
            } else {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: '',
                  matricula: '',
                  empresa: '',
                  inicioPeriodo: '',
                  fimPeriodo: '',
                  situacao: '',
                  diasDireito: '',
                  diasConcedidos: '',
                  diasProgramados: '',
                  inicioFerias: element2.InicioFerias || '-',
                  diasGozo: element2.DiasGozo || '-',
                  diasAbono: element2.DiasAbono || '-',
                  diasDeLicenca: element2.DiasLicenca || '-',
                  saldo: '',
                  status: '',
                  dataSolicitacao: '',
                  dataConclusao: '',
                  isConcessao: true,
                  ...element
                });
            }
          });
        }
      } else {
        arrData.push(
          {
            id: element.Id,
            colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
            matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
            empresa: data.Empresa ? data.Empresa.Nome : '-',
            inicioPeriodo: element.InicioPeriodo || '-',
            fimPeriodo: element.FimPeriodo || '-',
            situacao: element.Situacao || '-',
            diasDireito: element.DiasDireito || '-',
            diasConcedidos: element.DiasConcedidos || '-',
            diasProgramados: element.DiasProgramados || '-',
            inicioFerias: '-',
            diasGozo: '-',
            diasAbono: '-',
            diasDeLicenca: '-',
            saldo: element.Saldo || '-',
            status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
            dataSolicitacao: element.DataSolicitacao,
            dataConclusao: element.DataConclusao,
            isConcessao: false,
            ...element
          });
      }
    });
    return arrData;
  }


  getDataGrid() {
    const data = this.getValuesFromInput();
    if (this.empresaSelected) {
      this.feriasService.pesquisarFeriasPorEmpresaId(this.empresaSelected).subscribe(
        res => {
          let grid = [];
          res.forEach(element => {
            grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
          });
          this.loadDataTable(grid);
        },
        err => {
          console.log(err);
        }
      );
    } else if (this.colaboradorSelected) {
      this.feriasService.pesquisarFeriasPorColaboradorId(this.colaboradorSelected).subscribe(
        res => {
          let grid = [];
          res.forEach(element => {
            grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
          });
          this.loadDataTable(grid);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  clearInputvalues() {
    // Zera os campos
    this.colaboradorSelected = '';
    this.statusSelected = '';
    this.empresaSelected = '';
    $('#txtNomeColaborador').val('');
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNome($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cpf: val.CPF,
              ...val
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cnpj: val.CNPJ,
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }


  pesquisar() {
    this.getDataGrid();
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "inicioPeriodo"
        },
        {
          "data": "fimPeriodo"
        },
        {
          "data": "situacao"
        },
        {
          "data": "diasDireito"
        },
        {
          "data": "diasConcedidos"
        },
        {
          "data": "diasProgramados"
        },
        {
          "data": "inicioFerias"
        },
        {
          "data": "diasAbono"
        },
        {
          "data": "diasGozo"
        },
        {
          "data": "diasDeLicenca"
        },
        {
          "data": "saldo"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": 'dataConclusao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao) {
              if (row.status.toString().toLowerCase().includes('análise')) {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
              } else {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
              }
            }
            return '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao) {
              if (row.status.toString().toLowerCase().includes('análise')) {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
              } else {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
              }
            }
            return '';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[17]).on("click", function (e) {
          // aprovar
          if (!data.isConcessao) {
            if (data.status.toString().toLowerCase().includes('análise')) {
              self.aprovar(data);
            }
          }
        });
        $($(row).children()[18]).on("click", async function (e) {
          // reprovar
          if (!data.isConcessao) {
            if (data.status.toString().toLowerCase().includes('análise')) {
              self.reprovar(data);
            }
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": false,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          self.closeModal('modalColaborador');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.empresaSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
