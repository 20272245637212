import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";
import { UtilsService } from "../../../../@core/@service/utils/utils";
import { ImportacaoBancoDeHorasService } from "src/app/@core/@service/importacao/banco-de-horas/banco-de-horas.service";
import Swal from "sweetalert2";
import { CadastroColaboradorService } from "src/app/@core/@service/cadastro/colaborador/colaborador.service";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import {
  UsuarioColaboradorTipoEnum,
  ColaboradorTipoEnum,
} from "src/app/@core/@enum/enum";
import { CadastroColaboradorDadosPrincipaisService } from "src/app/@core/@service/cadastro/colaborador/dados-principais/dados-principais.service";
import { CadastroColaboradorDocumentacaoService } from "src/app/@core/@service/cadastro/colaborador/documentacao/documentacao.service";
import { CadastroColaboradorEmpregadorService } from "src/app/@core/@service/cadastro/colaborador/empregador/empregador.service";
import { CadastroColaboradorPagamentoService } from "src/app/@core/@service/cadastro/colaborador/pagamento/pagamento.service";
import { CadastroColaboradorEstrangeiroService } from "src/app/@core/@service/cadastro/colaborador/estrangeiro/estrangeiro.service";
import { CadastroTipoDeRegistroService } from "src/app/@core/@service/cadastro/tipo-de-registro/tipo-de-registro.service";

declare var $;

@Component({
  selector: "app-relatorio-funcionario",
  templateUrl: "./funcionario.component.html",
  styleUrls: ["./funcionario.component.scss"],
})
export class RelatorioFuncionarioComponent implements OnInit {
  gridData = [];

  modalRelatoriogridData: any = {};

  colaboradorList: any;
  colaboradorSelected = "";
  tipoRegistroList: any;
  tipoRegistro = "";
  statusList: Array<object> = [];
  statusSelected = [];
  selectStatus = "";
  dropdownSettings = {};

  modalRelatorioData: any = {
    colaborador: "",
    matricula: "",
    empresa: "",
  };

  colaboradorLogged = "";

  dataTable: any;
  userLogged: any;
  @ViewChild("grid", { static: true }) grid: ElementRef;
  @ViewChild("gridModalDadosPrincipais", { static: true })
  gridModalDadosPrincipais: ElementRef;
  @ViewChild("gridModalDocumentacao", { static: true })
  gridModalDocumentacao: ElementRef;
  @ViewChild("gridModalEmpregador", { static: true })
  gridModalEmpregador: ElementRef;
  @ViewChild("gridModalPagamento", { static: true })
  gridModalPagamento: ElementRef;
  @ViewChild("gridModalEstrangeiro", { static: true })
  gridModalEstrangeiro: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoBancoDeHorasService,
    private colaboradorService: CadastroColaboradorService,
    private colaboradorDadosPrincipaisService: CadastroColaboradorDadosPrincipaisService,
    private colaboradorDocumentacaoService: CadastroColaboradorDocumentacaoService,
    private colaboradorEmpregadorService: CadastroColaboradorEmpregadorService,
    private colaboradorPagamentoService: CadastroColaboradorPagamentoService,
    private colaboradorEstrangeiroService: CadastroColaboradorEstrangeiroService,
    private tipoDeRegistroService: CadastroTipoDeRegistroService
  ) {}

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem("currentUser"));
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
    }
    this.loadDataTable([]);
    this.getColaboradorList();
    this.getTipoDeServicoList();
    this.getSituacaoList();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Limpar todos',
      searchPlaceholderText: 'Pesquisar',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.statusSelected);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  changeSelectValues(event) {
    if (event.srcElement.id == "selColaborador") {
      this.colaboradorSelected = event.target.value;
    }
    if (event.srcElement.id == "tipoRegistro") {
      this.tipoRegistro = event.target.value;
      $("#tipoRegistro").removeClass("element-invalid");
    }
  }

  getColaboradorList() {
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService
      .buscarColaboradorPorGestorId(this.colaboradorLogged)
      .subscribe(
        (res) => {
          this.colaboradorList = res
            .map((val) => ({
              Id: val.Id,
              Description: val.Nome,
            }))
            .sort(this.utilService.dynamicSort("Description"));
        },
        (err) => {
          console.log(err);
        }
      );
    }else{
      this.colaboradorService
      .buscarColaboradorPorUsuarioId(this.userLogged.UsuarioColaboradorId)
      .subscribe(
        (res) => {
          this.colaboradorList = res
            .map((val) => ({
              Id: val.Id,
              Description: val.Nome,
            }))
            .sort(this.utilService.dynamicSort("Description"));
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getTipoDeServicoList() {
    this.tipoDeRegistroService.buscarTodos().subscribe(
      (res) => {
        this.tipoRegistroList = res.map((val) => ({
          Id: val.Id,
          Description: val.Nome,
        }));
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getSituacaoList(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
    this.colaboradorService.pesquisarColaboradorStatus().subscribe(
      res => {
        this.statusList = res.filter(val => val.Nome != null).map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
    })
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal("hide");
  }

  loadColaboradorAbasData() {
    //Dados Principais
    this.loadDataTableModalDadosPrincipais(this.gridData);

    //Documentação
    this.colaboradorDocumentacaoService
      .pesquisar({ ColaboradorId: this.colaboradorSelected })
      .subscribe(
        (res) => {
          const gridData = res
            .map((val) => ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              rg: val.RG,
              ric: val.RIC,
              cartaoNacionalSaude: val.CartaoNacionalSaude,
              reservista: val.NumeroReservista,
              tituloEleitor: val.TituloEleitor,
              habilitacao: val.CarteiraHabilitacao,
              ctps: val.NumeroCTPS,
              pis: val.PISNITNIS,
              dataCadastro: val.DataCadastro,
              ...val,
            }))
            .sort(this.utilService.dynamicSort("nomeColaborador"));
          this.loadDataTableModalDocumentacao(gridData);
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Ocorreu um erro ao buscar a Documentação do colaborador!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );

    //Empregador
    this.colaboradorEmpregadorService
      .pesquisar({
        ColaboradorId: this.colaboradorSelected,
        CargoUnidadeId: 0,
        CentroCustoUnidadeId: 0,
        LotacaoUnidadeId: 0,
        TipoRegistroId: 0,
        TipoMaoObraId: 0,
        UnidadeNegocioId: 0,
        NumeroCartaoPonto: "",
        Situacao: "",
        TurnoId: 0,
        CategoriaSalarialId: 0,
        Salario: 0,
        DataAdmissao: "",
        SindicatoId: 0,
      })
      .subscribe(
        (res) => {
          const gridData = res
            .map((val) => ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              sindicato: val.Sindicato.Nome,
              cargo: val.CargoUnidade.Nome,
              centroDeCusto: val.CentroCustoUnidade.Nome,
              lotacao: val.LotacaoUnidade.Nome,
              salario: val.Salario,
              dataAdmissao: val.DataAdmissao,
              dataCadastro: val.DataCadastro,
              ...val,
            }))
            .sort(this.utilService.dynamicSort("nomeColaborador"));
          this.loadDataTableModalEmpregador(gridData);
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title:
              "Ocorreu um erro ao buscar os dados Empregador do colaborador!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );

    //Pagamento
    this.colaboradorPagamentoService
      .pesquisar({
        ColaboradorId: this.colaboradorSelected,
      })
      .subscribe(
        (res) => {
          const gridData = res
            .map((val) => ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              banco: val.Banco.Nome,
              agencia: val.Agencia,
              conta: val.Conta,
              dataCadastro: val.DataCadastro,
              ...val,
            }))
            .sort(this.utilService.dynamicSort("nomeColaborador"));
          this.loadDataTableModalPagamento(gridData);
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title:
              "Ocorreu um erro ao buscar os dados de Pagamento do colaborador!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );

    //Estrangeiro
    this.colaboradorEstrangeiroService
      .pesquisar({ ColaboradorId: this.colaboradorSelected })
      .subscribe(
        (res) => {
          const gridData = res
            .map((val) => ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              origem: val.Origem,
              tipoVisto: val.ColaboradorEstrangeiroTipoVisto.Nome,
              passaporte: val.Passaporte,
              orgaoEmissor: val.OrgaoEmissorPassaporte,
              paisEmissor: val.PaisEmissorPassaporte,
              uf: val.UFPassaporte,
              dataEmissao: val.DataEmissaoPassaporte,
              portariaNat: val.PortariaNaturalizacao,
              indentidadeEstrangeira: val.IdentidadeEstrangeira,
              validadeIndentidadeEstrangeira: val.ValidadeIdentidadeEstrangeira,
              anoChegada: val.AnoChegada,
              dataCadastro: val.DataCadastro,
              ...val,
            }))
            .sort(this.utilService.dynamicSort("nomeColaborador"));
          this.loadDataTableModalEstrangeiro(gridData);
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title:
              "Ocorreu um erro ao buscar os dados de Estrangeiro do colaborador!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == "modalRelatorio") {
      this.colaboradorService
        .buscarColaboradorPorIdOuTipoRegistro(data.id, data.tipoRegistro)
        .subscribe(
          (res) => {
            this.modalRelatorioData = {
              cpf: res.CPF || "",
              colaborador: res.Nome || "",
              ...res,
            };
            this.loadColaboradorAbasData();
            ($(`#${idModal}`) as any).modal("show");
          },
          (err) => {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "Ocorreu um erro ao buscar a os dados da importação!",
              showConfirmButton: false,
              timer: 2000,
            });
            console.log(err);
          }
        );
    }
  }

  getDataGrid() {
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){

    this.colaboradorService
      .buscarColaboradorPorIdOuTipoRegistro(
        this.colaboradorSelected,
        this.tipoRegistro,
        this.statusSelected.map(val => val.item_id).join(",")
      )
      .subscribe(
        (res) => {
          if (res) {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map((val) => ({
                id: val.Id,
                nome: val.NomeColaborador,
                cpf: val.Cpf,
                empresaNome: val.NomeEmpresa,
                cargo: val.Cargo,
                centroCusto: val.CentroDeCusto,
                unidadeLotacao: val.UnidadeDeLotacao,
                unidadeNegocio: val.UnidadeDeNegocio,
                salario: val.Salario,
                turno: val.Turno,
                dataAdmissao: val.DataAdmissao,
                tipoRegistro: val.TipoRegistro,
                empresaTerceira: val.NomeEmpresaTerceira,
              status: val.Status,
              dataDemissao: val.DataDemissao,
                ...val,
              }));
              this.loadDataTable(this.gridData);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }else{
if (this.colaboradorSelected){
  this.colaboradorService
  .buscarColaboradorPorIdOuTipoRegistro(
    this.colaboradorSelected,
    this.tipoRegistro,
    this.statusSelected.map(val => val.item_id).join(",")
  )
  .subscribe(
    (res) => {
      if (res) {
        if (res.length == 1 && res[0].Erro) {
          this.loadDataTable([]);
        } else {
          this.gridData = res.map((val) => ({
            id: val.Id,
            nome: val.NomeColaborador,
            cpf: val.Cpf,
            empresaNome: val.NomeEmpresa,
            cargo: val.Cargo,
            centroCusto: val.CentroDeCusto,
            unidadeLotacao: val.UnidadeDeLotacao,
            unidadeNegocio: val.UnidadeDeNegocio,
            salario: val.Salario,
            turno: val.Turno,
            dataAdmissao: val.DataAdmissao,
            tipoRegistro: val.TipoRegistro,
            empresaTerceira: val.NomeEmpresaTerceira,
            status: val.Status,
            dataDemissao: val.DataDemissao,
            ...val,
          }));
          this.loadDataTable(this.gridData);
        }
      }
    },
    (err) => {
      console.log(err);
    }
  );
}else{
    this.colaboradorService
    .buscarColaboradorPorIdOuTipoRegistroPorUsuarioId(
      this.tipoRegistro,
      this.userLogged.UsuarioColaboradorId,
      this.statusSelected.map(val => val.item_id).join(",")
    )
    .subscribe(
      (res) => {
        if (res) {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            this.gridData = res.map((val) => ({
              id: val.Id,
              nome: val.NomeColaborador,
              cpf: val.Cpf,
              empresaNome: val.NomeEmpresa,
              cargo: val.Cargo,
              centroCusto: val.CentroDeCusto,
              unidadeLotacao: val.UnidadeDeLotacao,
              unidadeNegocio: val.UnidadeDeNegocio,
              salario: val.Salario,
              turno: val.Turno,
              dataAdmissao: val.DataAdmissao,
              tipoRegistro: val.TipoRegistro,
              empresaTerceira: val.NomeEmpresaTerceira,
              status: val.Status,
              dataDemissao: val.DataDemissao,
              ...val,
            }));
            this.loadDataTable(this.gridData);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    }}
  }

  pesquisar() {
    this.getDataGrid();
  }

  exportToExcel() {
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService
      .ExportarColaboradorPorIdOuTipoRegistro(
        this.colaboradorSelected,
        this.tipoRegistro
      )
      .subscribe(
        (res) => {
          if (res) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }else{
    this.colaboradorService
      .ExportarColaboradorPorIdOuTipoRegistroPorUsuarioId(
        this.colaboradorSelected,
        this.tipoRegistro,
        this.userLogged.UsuarioColaboradorId,
      )
      .subscribe(
        (res) => {
          if (res) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }}

  clearInputvalues(type = "") {
    this.colaboradorSelected = "";
    $("#txtDataInicial").val("");
    $("#txtDataFinal").val("");
    $("#txtNomeColaborador").val("");
    this.tipoRegistro = "";
    this.statusSelected = [];
    this.selectStatus =""
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cpf",
        },
        {
          data: "empresaNome",
        },
        {
          data: "cargo",
        },
        {
          data: "centroCusto",
        },
        {
          data: "unidadeLotacao",
        },
        {
          data: "unidadeNegocio",
        },
        {
          data: "salario",
        },
        {
          data: "turno",
        },
        {
          data: "dataAdmissao",
        },
        {
          data: "tipoRegistro",
        },
        {
          data: "empresaTerceira",
        },
        {
          data: "status",
        },
        {
          data: "dataDemissao",
        }
        // {
        //   "data": '', "orderable": false, "render": function (data, type, row) {
        //     return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
        //   }
        // },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()[11]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(
            data.DataCadastro
          ).toLocaleDateString();
          self.openModal("modalRelatorio", data);
        });
      },
      paging: false,
      bAutoWidth: false,
      lengthChange: true,
      searching: true,
      ordering: true,
      info: true,
      autoWidth: true,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalDadosPrincipais(data) {
    this.dataTable = $(this.gridModalDadosPrincipais.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cpf",
        },
        {
          data: "tipo",
        },
        {
          data: "empresaNome",
        },
        {
          data: "uf",
        },
        {
          data: "cidade",
        },
        {
          data: "telRes",
        },
        {
          data: "telCel",
        },
        {
          data: "telCom",
        },
        {
          data: "Email",
        },
        {
          data: "status",
        },
        {
          data: "dataCadastro",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
      ],
      createdRow: function (row, data, index) {},
      paging: false,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalDocumentacao(data) {
    this.dataTable = $(this.gridModalDocumentacao.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nomeColaborador",
        },
        {
          data: "nomeEmpresa",
        },
        {
          data: "rg",
        },
        {
          data: "ric",
        },
        {
          data: "cartaoNacionalSaude",
        },
        {
          data: "reservista",
        },
        {
          data: "tituloEleitor",
        },
        {
          data: "habilitacao",
        },
        {
          data: "ctps",
        },
        {
          data: "pis",
        },
        {
          data: "dataCadastro",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
      ],
      createdRow: function (row, data, index) {},
      paging: false,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }
  loadDataTableModalEmpregador(data) {
    this.dataTable = $(this.gridModalEmpregador.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nomeColaborador",
        },
        {
          data: "nomeEmpresa",
        },
        {
          data: "sindicato",
        },
        {
          data: "cargo",
        },
        {
          data: "centroDeCusto",
        },
        {
          data: "lotacao",
        },
        {
          data: "salario",
          orderable: true,
          render: function (data) {
            return (
              "R$ " + data.toLocaleString("pt-br", { minimumFractionDigits: 2 })
            );
          },
        },
        {
          data: "dataAdmissao",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
        {
          data: "dataCadastro",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
      ],
      createdRow: function (row, data, index) {},
      paging: false,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalPagamento(data) {
    this.dataTable = $(this.gridModalPagamento.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nomeColaborador",
        },
        {
          data: "nomeEmpresa",
        },
        {
          data: "banco",
        },
        {
          data: "agencia",
        },
        {
          data: "conta",
        },
        {
          data: "dataCadastro",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
      ],
      createdRow: function (row, data, index) {},
      paging: false,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }
  loadDataTableModalEstrangeiro(data) {
    this.dataTable = $(this.gridModalEstrangeiro.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nomeColaborador",
        },
        {
          data: "nomeEmpresa",
        },
        {
          data: "origem",
        },
        {
          data: "tipoVisto",
        },
        {
          data: "passaporte",
        },
        {
          data: "orgaoEmissor",
        },
        {
          data: "paisEmissor",
        },
        {
          data: "uf",
        },
        {
          data: "dataEmissao",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
        {
          data: "portariaNat",
        },
        {
          data: "indentidadeEstrangeira",
        },
        {
          data: "validadeIndentidadeEstrangeira",
        },
        {
          data: "anoChegada",
        },
        {
          data: "dataCadastro",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "-";
          },
        },
      ],
      createdRow: function (row, data, index) {},
      paging: false,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }
}
