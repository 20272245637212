import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { CommonModule  } from '@angular/common';
import { PreAdmissaoDadosPrincipaisComponent } from './dados-principais/dados-principais.component';
import { PreAdmissaoDocumentacaoComponent } from './documentacao/documentacao.component';
import { PreAdmissaoEmpregadorComponent } from './empregador/empregador.component';
import { PreAdmissaoPagamentoComponent } from './pagamento/pagamento.component';
import { PreAdmissaoEstrangeiroComponent } from './estrangeiro/estrangeiro.component';
import { PreAdmissaoAnexarDocumentoComponent } from './anexar-documento/anexar-documento.component';
import { ColaboradorStatusEnum, UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';
import { TestBed } from '@angular/core/testing';
declare var $;
@Component({
  selector: 'app-pre-admissao',
  templateUrl: './pre-admissao.component.html',
  styleUrls: ['./pre-admissao.component.scss']
})
export class PreAdmissaoComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private commonModule :CommonModule,
  ) {}

  ehPreAdmissao: boolean = false;
  ehPreAdmissaoOuRh: boolean = false;

  @ViewChild('tabRouterDadoPrincipal', { static: true, read: ViewContainerRef }) tabRouterDadoPrincipal: ViewContainerRef;
  @ViewChild('tabRouterDadoPrincipalDiv', { static: true }) tabRouterDadoPrincipalDiv: ElementRef;
  factoryDadoPrincipal: any;
  objDadoPrincipal: any;

  @ViewChild('tabRouterDocumentacao', { static: true, read: ViewContainerRef }) tabRouterDocumentacao: ViewContainerRef;
  @ViewChild('tabRouterDocumentacaoDiv', { static: true }) tabRouterDocumentacaoDiv: ElementRef;
  factoryDocumentacao: any;
  objDocumentacao: any;

  @ViewChild('tabRouterEmpregador', { static: true, read: ViewContainerRef }) tabRouterEmpregador: ViewContainerRef;
  @ViewChild('tabRouterEmpregadorDiv', { static: true }) tabRouterEmpregadorDiv: ElementRef;
  factoryEmpregador: any;
  objEmpregador: any;

  @ViewChild('tabRouterPagamento', { static: true, read: ViewContainerRef }) tabRouterPagamento: ViewContainerRef;
  @ViewChild('tabRouterPagamentoDiv', { static: true }) tabRouterPagamentoDiv: ElementRef;
  factoryPagamento: any;
  objPagamento: any;

  @ViewChild('tabRouterEstrangeiro', { static: true, read: ViewContainerRef }) tabRouterEstrangeiro: ViewContainerRef;
  @ViewChild('tabRouterEstrangeiroDiv', { static: true }) tabRouterEstrangeiroDiv: ElementRef;
  factoryEstrangeiro: any;
  objEstrangeiro: any;

  @ViewChild('tabRouterAnexarDocumentos', { static: true, read: ViewContainerRef }) tabRouterAnexarDocumentos: ViewContainerRef;
  @ViewChild('tabRouterAnexarDocumentosDiv', { static: true }) tabRouterAnexarDocumentosDiv: ElementRef;
  factoryAnexarDocumentos: any;
  objAnexarDocumentos: any;

  @ViewChild('someNameInput',{static: true}) someNameInput: ElementRef;

  ngOnInit() {
    this.changeTab('dadosPrincipais');

    const userLogged = JSON.parse(sessionStorage.getItem('currentUser'));

    this.ehPreAdmissao = userLogged && userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO;
    this.ehPreAdmissaoOuRh = this.ehPreAdmissao || userLogged.tipoUsuario == "RH";

  }

  changeTab(tab) {
    //console.log(this.someNameInput.nativeElement.value);
    let component;
    $('li').removeClass('active');
    $(`#${tab}`).addClass('active');
    switch (tab) {
      case 'dadosPrincipais':
        component = PreAdmissaoDadosPrincipaisComponent;
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'none';
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'none';
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'none';
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'none';
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objDadoPrincipal) {
            this.factoryDadoPrincipal = this.resolver.resolveComponentFactory(component);
            this.tabRouterDadoPrincipal.clear();
            this.objDadoPrincipal = this.tabRouterDadoPrincipal.createComponent(this.factoryDadoPrincipal);
          }
        }
        break;
      case 'documentacao':
        component = PreAdmissaoDocumentacaoComponent;
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'none';
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'none';
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'none';
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'none';
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objDocumentacao) {
            this.factoryDocumentacao = this.resolver.resolveComponentFactory(component);
            this.tabRouterDocumentacao.clear();
            this.objDocumentacao = this.tabRouterDocumentacao.createComponent(this.factoryDocumentacao);
          }
        }
        break;
      case 'empregador':
        component = PreAdmissaoEmpregadorComponent;
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'none';
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'none';
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'none';
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'none';
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objEmpregador) {
            this.factoryEmpregador = this.resolver.resolveComponentFactory(component);
            this.tabRouterEmpregador.clear();
            this.objEmpregador = this.tabRouterEmpregador.createComponent(this.factoryEmpregador);
          }
        }
        break;
      case 'pagamento':
        component = PreAdmissaoPagamentoComponent;
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'none';
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'none';
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'none';
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'none';
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objPagamento) {
            this.factoryPagamento = this.resolver.resolveComponentFactory(component);
            this.tabRouterPagamento.clear();
            this.objPagamento = this.tabRouterPagamento.createComponent(this.factoryPagamento);
          }
        }
        break;
      case 'estrangeiro':
        component = PreAdmissaoEstrangeiroComponent;
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'none';
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'none';
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'none';
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'none';
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objEstrangeiro) {
            this.factoryEstrangeiro = this.resolver.resolveComponentFactory(component);
            this.tabRouterEstrangeiro.clear();
            this.objEstrangeiro = this.tabRouterEstrangeiro.createComponent(this.factoryEstrangeiro);
          }
        }
        break;
      case 'anexarDocumentos':
        component = PreAdmissaoAnexarDocumentoComponent;
        this.tabRouterAnexarDocumentosDiv.nativeElement.style.display = 'inline';
        // Oculta outras abas
        this.tabRouterDadoPrincipalDiv.nativeElement.style.display = 'none';
        this.tabRouterDocumentacaoDiv.nativeElement.style.display = 'none';
        this.tabRouterEmpregadorDiv.nativeElement.style.display = 'none';
        this.tabRouterPagamentoDiv.nativeElement.style.display = 'none';
        this.tabRouterEstrangeiroDiv.nativeElement.style.display = 'none';
        if (typeof component == 'function') {
          if (!this.objAnexarDocumentos) {
            this.factoryAnexarDocumentos = this.resolver.resolveComponentFactory(component);
            this.tabRouterAnexarDocumentos.clear();
            this.objAnexarDocumentos = this.tabRouterAnexarDocumentos.createComponent(this.factoryAnexarDocumentos);
          }
        }
        break;
     }
  }

}
