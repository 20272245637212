import { Component, OnInit, ContentChild, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DataModel } from './model/data.model';

@Component({
  selector: 'select-component',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  constructor() {

  }

  @Output() changeValue = new EventEmitter<any>();
  value: string = '';

  isDisabled = false;

  @ViewChild('select', { static: true }) select: ElementRef;

  data = new Array<DataModel>();

  Id = '';

  @Input()
  set IdSelect(id: any) {
    this.Id = id;
  }

  @Input()
  set Disabled(isDisabled: any) {
    this.isDisabled = isDisabled;
    if (this.isDisabled) {
      this.select.nativeElement.classList.add('disabled-select');
    } else {
      this.select.nativeElement.classList.remove('disabled-select');
    }
  }

  @Input()
  set Data(data: any) {
    this.data = data;
  }

  @Input()
  set Value(value: any) {
    this.value = value;
  }

  @Input()
  set IsRequired(isRequired: any) {
    if (isRequired) {
      this.select.nativeElement.classList.add('required');
    } else {
      this.select.nativeElement.classList.remove('required');
    }
  }



  ngOnInit() {
  }

  changeSelectValue(event) {
    this.changeValue.emit(event)
  }


}
