<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Empresa
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Empresa</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">
    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome da Empresa">
              </div>
              <div class="form-group">
                <label>Endereço</label>
                <input type="text" class="form-control" id="txtEndereco" name="txtEndereco" placeholder="Endereço">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>CNPJ</label>
                <input type="text" class="form-control" mask="00.000.000/0000-00" id="txtCNPJ" name="txtCNPJ"
                  placeholder="CNPJ">
              </div>
              <div class="form-group">
                <label>Número</label>
                <input type="text" class="form-control" id="txtNumeroEnd" name="txtNumeroEnd" placeholder="Número">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Inscrição Estadual</label>
                <input type="text" class="form-control" id="txtInscEstadual" name="txtInscEstadual"
                  placeholder="Inscrição Estadual">
              </div>
              <div class="form-group">
                <label>Bairro</label>
                <input type="text" class="form-control" id="txtBairro" name="txtBairro" placeholder="Bairro">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Inscrição Municipal</label>
                <input type="text" class="form-control" id="txtInscMunicipal" name="txtInscMunicipal"
                  placeholder="Inscrição Municipal">
              </div>
              <div class="form-group">
                <label>CEP</label>
                <input type="text" mask="00000-000" class="form-control" id="txtCEP" name="txtCEP" placeholder="CEP">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>UF</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                  IdSelect="selectEstados" [Value]="empresaEstadoSelected">
                </select-component>
              </div>
              <div class="form-group">
                <label>2º Contato</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtSegundoContato"
                  name="txtSegundoContato" placeholder="(__) ____-____">
              </div>
              <div class="form-group">
                <label>Tipo</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="empresaTipoList" IdSelect="selTipo"
                  [Value]="empresaTipoSelected">
                </select-component>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Cidade</label>
                <select-component (changeValue)="changeSelectValues($event)"
                  [Disabled]="!empresaEstadoSelected ? true : false" [Data]="cidadesList" IdSelect="selectCidades"
                  [Value]="empresaCidadeSelected">
                </select-component>
              </div>
              <div class="form-group">
                <label>Email 2º Contato</label>
                <input type="text" class="form-control" id="txtEmailSegundo" name="txtEmailSegundo"
                  placeholder="Email 2º Contato">
              </div>
              <div #divMatriz class="form-group matrizDisable">
                <label>Nome da Matriz</label>
                <!-- BUSCAR OS DADOS DA TABELA EMPRESA  -->
                <div class="input-group">
                  <input #inputMatriz type="text" class="matrizDisableInput form-control" id="txtNomeEmpresa"
                    name="txtNomeEmpresa" placeholder="Nome da Empresa">
                  <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalMatriz')"
                      title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>1º Contato</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtPrimeiroContato"
                  name="txtPrimeiroContato" placeholder="(__) ____-____">
              </div>
              <div class="form-group">
                <label>3º Contato</label>
                <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTerceiroContato"
                  name="txtTerceiroContato" placeholder="(__) ____-____">
              </div>
              <div class="form-group">
                <label>Status</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="empresaStatusList"
                  IdSelect="selStatus" [Value]="empresaStatusSelected">
                </select-component>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Email 1º Contato</label>
                <input type="text" class="form-control" id="txtEmailPrimeiro" name="txtEmailPrimeiro"
                  placeholder="Email 1º Contato">
              </div>
              <div class="form-group">
                <label>Email 3º Contato</label>
                <input type="text" class="form-control" id="txtEmailTerceiro" name="txtEmailTerceiro"
                  placeholder="Email 3º Contato">
              </div>
              <div class="form-group">
                <label>Fornecedor Holerite</label>
                <select-component (changeValue)="changeSelectValues($event)" [Data]="fornecedoresHolerite"
                  IdSelect="selFornecedorHolerite" [Value]="fornecedoresHoleriteSelected">
                </select-component>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Link Site</label>
                <input type="text" class="form-control" id="txtLinkSite" name="txtLinkSite"
                  placeholder="Link Site">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Mostrar Link</label>
                <select-component (changeValue)="changeSelectValues($event)" IdSelect="mostrarLinkSite" [Value]="mostrarLinkSiteSelected" [Data]="mostrarLinkSiteList">
                </select-component>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Dias Validade do Aso</label>
                <input type="number" min="0" class="form-control" id="txtDiasValidadeAso" name="txtDiasValidadeAso"
                  placeholder="Dias Validade do Aso">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>1º Vencimento do Contrato</label>
                <input type="number" min="0" class="form-control" id="txtDiasVencimento1" name="txtDiasVencimento1"
                  placeholder="1º Vencimento do Contrato">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>2º Vencimento do Contrato</label>
                <input type="number" min="0" class="form-control" id="txtDiasVencimento2" name="txtDiasVencimento2"
                  placeholder="2º Vencimento do Contrato">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>3º Vencimento do Contrato</label>
                <input type="number" min="0" class="form-control" id="txtDiasVencimento3" name="txtDiasVencimento3"
                  placeholder="3º Vencimento do Contrato">
              </div>
            </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>
      </form>
    </div>
    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>I.E.</th>
                <th>I.M.</th>
                <th>Endereço</th>
                <th>Bairro</th>
                <th>CEP</th>
                <th>UF</th>
                <th>Cidade</th>
                <th>1º Contato</th>
                <th>Email 1º Contato</th>
                <th>2º Contato</th>
                <th>Email 2º Contato</th>
                <th>3º Contato</th>
                <th>Email 3º Contato</th>
                <th>Status</th>
                <th>Data de Cadastro</th>
                <th>Link Site</th>
                <th>Mostrar Link</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>

  <div class="control-sidebar-bg"></div>
</div>


<!-- Modal Funcionalidades -->
<div class="modal fade" id="modalFuncionalidade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" (click)="closeModal('modalFuncionalidade')" class="close pull-right" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title" style="padding-bottom: 10px;"><b>Selecionar Funcionalidade</b></h4>
        <h5 class="modal-title w-100" style="margin-bottom: 15px;"><b>Empresa:</b> {{dadosModal.nome || '--'}}</h5>
      </div>
      <div class="modal-body">
        <div class="modal-topic">
          <div class="modal-label-title"><b>Benefícios</b></div>
          <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px; padding-left: 20px;" *ngFor="let item of beneficiosList">
              <div class="modal-label">
                <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"(click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">{{item.nome}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-topic">
          <div class="modal-label-title"><b>Cadastro</b></div>
          <div class="row">
            <div class="col-md-6 checkbox-topic" style="padding-top: 5px; padding-left: 20px;" *ngFor="let item of cadastroList">
              <div>
                <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                  (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                {{item.nome}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-topic">
          <div class="modal-label-title" style="margin-bottom: 5px;"><b>Controle de frequência</b></div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Gestor</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of controleDeFrequencia.gestorList">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Funcionário</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of controleDeFrequencia.funcionarioList">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-topic">
          <div class="modal-label-title" style="margin-bottom: 5px;"><b>Férias</b></div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Gestor</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of ferias.gestorList">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Funcionário</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of ferias.funcionarioList">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-topic">
          <div class="modal-label-title" style="margin-bottom: 5px;"><b>Folha de pagamento</b></div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Funcionário</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of folha">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-topic">
          <div class="modal-label-title" style="margin-bottom: 5px;"><b>MOVIMENTAÇÕES CADASTRAIS</b></div>
          <div class="modal-sub-topic">
            <div class="modal-label-subtitle"><b>Gestor</b></div>
            <div class="row">
              <div class="col-md-6 checkbox-topic" style="padding-top: 5px" *ngFor="let item of movimentacoes">
                <div>
                  <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                    (click)="selectFuncionalidadeRadio('check-' + item.id, item.id)" type="checkbox">
                  {{item.nome}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <input name="Salvar" value="Salvar" readonly="readonly" (click)="salvarFuncionalidadeModal()"
          class="btn btn-info pull-right btn-margin">
        <button type="button" class="btn btn-default pull-left"
          (click)="closeModal('modalFuncionalidade')">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Documento -->
<div class="modal fade" id="modalDocumentos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" (click)="closeModal('modalDocumentos')" class="close pull-right" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" style="margin-bottom: 15px;">Funcionários (Documentos aceitos)</h4>
        <h5 class="modal-title w-100" style="margin-bottom: 15px;"><b>Empresa:</b> {{dadosModal.nome || '--'}}</h5>
      </div>
      <div class="modal-body">
        <div class="modal-topic">
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-md-6 checkbox-topic" style="padding-bottom: 10px" *ngFor="let item of documentosList">
              <div class="col-md-6">
                <input [checked]="item.isSelected.length > 0" id="check-{{item.id}}"
                  (click)="selectDocumentoRadio('check-' + item.id, item.id, 'obrigatorio-' + item.id)" type="checkbox">
                {{item.nome}}
              </div>
              <div class="col-md-6">
                <input [checked]="item.obrigatorio.length > 0" id="obrigatorio-{{item.id}}"
                  (click)="selectDocumentoRadio('check-' + item.id, item.id, 'obrigatorio-' + item.id)" type="checkbox">
                Obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <input name="Salvar" value="Salvar" readonly="readonly" (click)="salvarDocumentosModal()"
          class="btn btn-info pull-right btn-margin">
        <button type="button" class="btn btn-default pull-left" (click)="closeModal('modalDocumentos')">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalMatriz" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Matriz</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalMatriz style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CNPJ</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
