import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroTurnoService } from 'src/app/@core/@service/cadastro/turno/turno.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import Swal from 'sweetalert2';
declare var $;
@Component({
  selector: 'app-turno',
  templateUrl: './turno.component.html',
  styleUrls: ['./turno.component.scss']
})
export class TurnoComponent implements OnInit {


  gridData = [];

  diaSemanaSelected = '';

  diasSemana = [
    {
      Id: 'Segunda-Feira',
      Description: 'Segunda-Feira',
    },
    {
      Id: 'Terça-Feira',
      Description: 'Terça-Feira',
    },
    {
      Id: 'Quarta-Feira',
      Description: 'Quarta-Feira',
    },
    {
      Id: 'Quinta-Feira',
      Description: 'Quinta-Feira',
    },
    {
      Id: 'Sexta-Feira',
      Description: 'Sexta-Feira',
    },
    {
      Id: 'Sábado',
      Description: 'Sábado',
    },
    {
      Id: 'Domingo',
      Description: 'Domingo',
    },
  ]

  detalheGridData = [];

  isEdit = false;

  isEditDetalhe = false;

  turnoDetalheIdSelected = '';

  turnoIdSelected = '';

  dataTable: any;

  dataTableDetalhe: any;

  dadosModal: any = {
    descricao: '',
  };

  empresasList: Array<object> = [];
  empresasSelected = [];
  dropdownSettings = {};

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridDetalhe', { static: true }) gridDetalhe: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroTurnoService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Limpar todos',
      searchPlaceholderText: 'Pesquisar',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.empresasSelected);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  changeSelectValues(event) {
    this.diaSemanaSelected = event.target.value;
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async removerTurnoDetalhe(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.removerTurnoDetalhe(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.pesquisarDetalhe({ id: this.turnoIdSelected });
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtCodigo: {
          required: true
        },
        txtDescricao: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtCodigo: {
          required: "Informe o Código"
        },
        txtDescricao: {
          required: "Informe o Nome"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  async getIntitialsData() {
    this.loadDataTable([]);
    this.empresasList = await this.getEmpresas();
  }

  getEmpresas(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisar().subscribe(
      res => {
          this.empresasList = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  getEmpresasByTurnoId(turnoId): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this.empresaService.pesquisarPorTurnoId(turnoId).subscribe(
      res => {
          this.empresasSelected = res.map(val =>
          ({
            item_id: val.Id,
            item_text: val.Nome + ' - ' + val.CNPJ,
          })
        ).sort(this.utilService.dynamicSort('item_text'));
      },
      err => {
        console.log(err);
      }
    );
  })
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            codigo: val.Codigo,
            descricao: val.Descricao,
            dataCadastro: val.DataCadastro,
          })
        ).sort(this.utilService.dynamicSort('descricao'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  validarCamposTurnoDetalhe() {
    const self = this;
    ($("#formularioDetalhe") as any).validate({
      // Define as regras
      rules: {
        txtDia: {
          required: true
        },
        txtHoraInicial: {
          required: true,
          horaCheck: true,
        },
        txtHoraFinal: {
          required: true,
          horaCheck: true,
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtDia: {
          required: "Informe o dia da semana"
        },
        txtHoraInicial: {
          required: "Informe a hora inicial"
        },
        txtHoraFinal: {
          required: "Informe a hora final"
        },
      },
    });
    $.validator.addMethod("horaCheck", function (value, element) {
      return self.utilService.verificarHora(value);
    }, 'Hora inválida');
    ($("#formularioDetalhe") as any).on("submit", function () {
      return false;
    })
  }

  pesquisarDetalhe(data) {
    this.moduleService.pesquisarTurnoDetalhe(data.id).subscribe(
      res => {
        this.detalheGridData = res.TurnoDetalhe.map(val =>
          ({
            id: val.Id,
            idTurno: res.Id,
            diaSemana: val.DiaSemana,
            horaInicial: val.HorarioInicial,
            horaFinal: val.HorarioFinal,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTableDetalhe(this.detalheGridData)
      },
      err => {
        console.log(err);
      }
    );
  }

  openModal(idModal, data) {
    this.closeModal(idModal);
    this.dadosModal = data;
    this.pesquisarDetalhe(data);
    ($(`#${idModal}`) as any).modal('show');
    this.validarCamposTurnoDetalhe();
  }

  closeModal(idModal) {
    this.clearInputvaluesTurno();
    ($(`#${idModal}`) as any).modal('hide');
    this.empresasSelected = [];
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    $('#txtDescricao').val('');
    $('#txtCodigo').val('');
    this.turnoIdSelected = '';
  }

  clearInputvaluesTurno() {

    // Desabilita modo edição
    this.isEditDetalhe = false;

    // Zera os campos
    $('#txtDia').val('');
    $('#txtHoraInicial').val('');
    $('#txtHoraFinal').val('');
    this.turnoDetalheIdSelected = '';
  }

  getValuesFromInput() {
    return {
      Codigo: $('#txtCodigo').val().toString().trim(),
      Descricao: $('#txtDescricao').val().toString().trim(),
      EmpresasSelected: this.empresasSelected.map(val => val.item_id),
    }
  }

  getValuesFromInputDetalhe() {
    return {
      DiaSemana: $('#txtDia').val().toString().trim(),
      HorarioInicial: $('#txtHoraInicial').val().toString().trim(),
      HorarioFinal: $('#txtHoraFinal').val().toString().trim(),
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (($("#formulario") as any).valid()) {
      const obj: any = this.getValuesFromInput();
      if (this.isEdit) {
        obj.Id = this.turnoIdSelected;
        this.moduleService.editar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('edit-success');
            }

          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        this.moduleService.adicionar(obj).subscribe(
          res => {
            console.log
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  salvarDetalhe() {
    if (($("#formularioDetalhe") as any).valid()) {
      const obj: any = this.getValuesFromInputDetalhe();
      if (this.isEditDetalhe) {
        obj.Id = this.turnoDetalheIdSelected;
        this.moduleService.editarTurnoDetalhe(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvaluesTurno();
              this.pesquisarDetalhe({ id: this.turnoIdSelected });
              this.utilService.alertSwal('edit-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('edit-error');
          }
        );
      } else {
        obj.TurnoId = this.turnoIdSelected;
        this.moduleService.adicionarTurnoDetalhe(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvaluesTurno();
              this.pesquisarDetalhe({ id: this.turnoIdSelected });
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "codigo"
        },
        {
          "data": "descricao"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-gears" style="color:rgb(5, 90, 5); border: 0px; background-color: transparent;" title="Detalhe"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[3]).on("click", function (e) {
          self.turnoIdSelected = data.id;
          self.openModal('modalDetalhe', data)
        });
        $($(row).children()[4]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          $('#txtCodigo').val(data.codigo);
          $('#txtDescricao').val(data.descricao);
          self.turnoIdSelected = data.id;
          self.getEmpresasByTurnoId(data.id);
        });
        $($(row).children()[5]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableDetalhe(data) {

    let self = this;
    this.dataTableDetalhe = $(this.gridDetalhe.nativeElement);
    this.dataTableDetalhe.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "diaSemana"
        },
        {
          "data": "horaInicial"
        },
        {
          "data": "horaFinal"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[3]).on("click", function (e) {
          // Seta modo edição
          self.isEditDetalhe = true;

          $('#txtDia').val(data.diaSemana);
          $('#txtHoraInicial').val(data.horaInicial);
          $('#txtHoraFinal').val(data.horaFinal);
          self.turnoDetalheIdSelected = data.id;
        });
        $($(row).children()[4]).on("click", function (e) {
          self.removerTurnoDetalhe(data);
        });
      },
      "paging": false,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
