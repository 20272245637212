import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoCartaoPontoService } from 'src/app/@core/@service/importacao/cartao-de-ponto/cartao-de-ponto.service';
import Swal from 'sweetalert2';

declare var $;

@Component({
  selector: 'app-importacao-cartao-de-ponto',
  templateUrl: './cartao-de-ponto.component.html',
  styleUrls: ['./cartao-de-ponto.component.scss']
})

export class ImportacaoCartaoPontoComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  arquivoAnexoSelected = '';

  mesSelected = '';
  mesList = [];

  typeImagesAccepted = ['text/plain'];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoCartaoPontoService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.mesList = this.utilService.getMesList();
    this.validarCampos();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;
      $('#selMes').removeClass('element-invalid');
    }
  }

  validarCampos() {
    const self = this;
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtAno: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtAno: {
          required: "Informe a Data"
        },
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      if (this.typeImagesAccepted.includes(file.type)) {
        this.arquivoAnexoSelected = event.target.result;
        this.arquivoAnexoSelected = this.arquivoAnexoSelected.replace('data:text/plain;base64,', '');
        $('#txtArquivo').val(file.name);
        $('#txtArquivo').removeClass('element-invalid');
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  clearInputvalues(type = '') {
    if (type == 'importar') {
      ($("#formulario") as any).valid();
      $('#selMes').removeClass('element-invalid');
      $('#txtArquivo').removeClass('element-invalid');
    }
    this.arquivoAnexoSelected = '';
    this.mesSelected = '';
    $('#txtAno').val('');
    $('#tableResult').css("visibility", "hidden");
  }

  getValuesFromInput() {
    return {
      Mes: this.mesSelected,
      Ano: $('#txtAno').val().toString(),
      DocumentoBase64: this.arquivoAnexoSelected,
    }
  }

  importar() {
    if (!this.arquivoAnexoSelected || !this.mesSelected) {
      ($("#formulario") as any).valid()
      if (!this.mesSelected) {
        $('#selMes').addClass('element-invalid');
      }
      if (!this.arquivoAnexoSelected) {
        $('#txtArquivo').addClass('element-invalid');
      }
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.importar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              const inputBkp = {
                mes: this.mesSelected || '',
                ano: $('#txtAno').val().toString() || ''
              };
              this.clearInputvalues('importar');
              if (res.length > 0) {
                this.gridData = res.map(val =>
                  ({
                    id: val.Id,
                    matricula: val.Matricula || '',
                    mes: inputBkp.mes,
                    ano: inputBkp.ano,
                    erro: val.LogErro || '',
                    dataConclusao: val.DataCadastro,
                    ...val
                  })
                );
                this.loadDataTable(this.gridData);
                $('#tableResult').css("visibility", "unset");
              } else {
                this.loadDataTable([]);
                $('#tableResult').css("visibility", "hidden");
              }
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Arquivo importado!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          },
          err => {
            console.log(err);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Erro ao importar arquivo!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "matricula"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": 'erro', "orderable": false, "render": function (data, type, row) {
            return `<div style="color: red;">${data}</div>`;
          }
        },
        {
          "data": 'dataConclusao', "orderable": false, "render": function (data, type, row) {
             return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
