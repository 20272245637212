import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AprovacaoDesligamentoComponent } from './desligamento/desligamento.component';
import { CoreModule } from 'src/app/@core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AprovacaoRoutingModule } from './aprovacao-routing.module';
import { AprovacaoMovimentacaoContratualComponent } from './movimentacao-contratual/movimentacao-contratual.component';
import { AprovacaoAlteracaoCadastralComponent } from './alteracao-cadastral/alteracao-cadastral.component';
import { AprovacaoBeneficioComponent } from './beneficio/beneficio.component';
import { AprovacaoValeTransporteComponent } from './vale-transporte/vale-transporte.component';
import { AprovacaoAtestadoComponent } from './atestado/atestado.component';
import { AprovacaoCartaoPontoComponent } from './cartao-ponto/cartao-ponto.component';
import { AprovacaoBeneficioDependenteComponent } from './beneficio-dependente/beneficio-dependente.component';
import { AprovacaoFeriasComponent } from './ferias/ferias.component';
import { AprovacaoDivergenciaComponent } from './divergencia/divergencia.component';
import { AprovacaoHoraExtraComponent } from './hora-extra/hora-extra.component';

@NgModule({
  declarations: [
    AprovacaoDesligamentoComponent,
    AprovacaoMovimentacaoContratualComponent,
    AprovacaoAlteracaoCadastralComponent,
    AprovacaoBeneficioComponent,
    AprovacaoValeTransporteComponent,
    AprovacaoAtestadoComponent,
    AprovacaoCartaoPontoComponent,
    AprovacaoBeneficioDependenteComponent,
    AprovacaoFeriasComponent,
    AprovacaoDivergenciaComponent,
    AprovacaoHoraExtraComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    AprovacaoRoutingModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild()
  ],
  entryComponents: [
    AprovacaoDesligamentoComponent,
    AprovacaoMovimentacaoContratualComponent,
    AprovacaoAlteracaoCadastralComponent,
    AprovacaoBeneficioComponent,
    AprovacaoValeTransporteComponent,
    AprovacaoAtestadoComponent,
    AprovacaoCartaoPontoComponent,
    AprovacaoBeneficioDependenteComponent,
    AprovacaoFeriasComponent,
    AprovacaoDivergenciaComponent,
    AprovacaoHoraExtraComponent,
  ],
})
export class AprovacaoModule { }
