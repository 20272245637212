import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/*Custom Files*/
import { AlterarSenhaRoutingModule } from './alterar-senha-routing.module';
import { AlterarSenhaComponent } from './alterar-senha.component';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [AlterarSenhaComponent],
  imports: [
    CommonModule,
    AlterarSenhaRoutingModule,
    NgxMaskModule.forChild()
  ],
})
export class AlterarSenhaModule { }
