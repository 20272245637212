import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { AprovacaoCartaoPontoService } from 'src/app/@core/@service/aprovacao/cartao-ponto/cartao-ponto.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { ImportacaoCartaoPontoService } from 'src/app/@core/@service/importacao/cartao-de-ponto/cartao-de-ponto.service';



@Component({
  selector: 'app-aprovacao-cartao-ponto',
  templateUrl: './cartao-ponto.component.html',
  styleUrls: ['./cartao-ponto.component.scss']
})
export class AprovacaoCartaoPontoComponent implements OnInit {

  gridData = [];

  motivoReprovacao = '';

  dataTable: any;

  colaboradorLogged = ''

  userLogged: any;

  modalRelatorioData: any = {
    cpf: '',
    colaborador: '',
    empresa: '',
    ano: '',
    mes: '',
    dataInicio: '',
    dataFim: '',
  };

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalRelatorio', { static: true }) gridModalRelatorio: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: AprovacaoCartaoPontoService,
    private importacaoCartaoPontoService: ImportacaoCartaoPontoService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      this.getDataGrid();
    } else {
      this.loadDataTable([]);
    }
  }

  reprovar(data) {
    Swal.fire({
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        'aria-label': 'Informe o motivo da reprovação'
      },
      inputLabel: 'Motivo da Reprovação',
      inputPlaceholder: 'Informe o motivo da reprovação...',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.reprovar({ Id: data.id, MotivoReprovacao: result.value }).subscribe(
          res => {
            this.utilService.alertSwal('reprove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('reprove-error');
          }
        );
      }
    });
  }

  aprovar(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja aprovar essa solicitação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.aprovar(data.id).subscribe(
          res => {
            this.utilService.alertSwal('approve-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('approve-error');
          }
        );
      }
    })
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalRelatorio') {
      this.importacaoCartaoPontoService.pesquisarImportacaoPorId(data.id).subscribe(
        res => {
          this.modalRelatorioData = {
            matricula: res.Colaborador ? res.Colaborador.MatriculaInterna : '',
            colaborador: res.Colaborador ? res.Colaborador.Nome : '',
            empresa: data.Colaborador ? (data.Colaborador.Empresa ? data.Colaborador.Empresa.Nome : '') : '',
            ano: res.Ano || '',
            mes: res.Mes || '',
            dataInicio: res.CartaoPontoCabecalho.length > 0 ? res.CartaoPontoCabecalho[0].DataInicioPeriodo : '',
            dataFim: res.CartaoPontoCabecalho.length > 0 ? res.CartaoPontoCabecalho[0].DataTerminoPeriodo : '',
          };
          this.loadDataTableModalRelatorio(res.CartaoPontoDetalhe);
          ($(`#${idModal}`) as any).modal('show');
        },
        err => {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Ocorreu um erro ao buscar a os detalhes do cartão de ponto!',
            showConfirmButton: false,
            timer: 2000
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  getDataGrid() {
    this.moduleService.pesquisar(this.colaboradorLogged).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id || '',
            colaborador: val.Colaborador ? val.Colaborador.Nome : '-',
            matricula: val.Colaborador ? val.Colaborador.MatriculaInterna : '-',
            empresa: val.Colaborador ? (val.Colaborador.Empresa ? val.Colaborador.Empresa.Nome : '') : '-',
            mes: val.Mes || '-',
            ano: val.Ano || '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataCadastro,
            dataConclusao: val.DataConclusao,
            ...val,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  pesquisar() {
    this.getDataGrid();
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Aprovar"> </button></div>';
            }
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (row.SolicitacaoStatus && row.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="color: rgb(136, 68, 29); border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            } else {
              return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-times" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Reprovar"> </button></div>';
            }
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[8]).on("click", async function (e) {
          // detalhe
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(data.DataCadastro).toLocaleDateString();
          self.openModal('modalRelatorio', data);
        });
        $($(row).children()[9]).on("click", function (e) {
          // aprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.aprovar(data);
          }
        });
        $($(row).children()[10]).on("click", async function (e) {
          // reprovar
          if (data.SolicitacaoStatus && data.SolicitacaoStatus.Nome.toString().toLowerCase().includes('análise')) {
            self.reprovar(data);
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data) {
    let self = this;
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "DiaSemana"
        },
        {
          "data": "NumeroJornada"
        },
        {
          "data": "TipoDia"
        },
        {
          "data": "Marcacao1"
        },
        {
          "data": "TipoMarcacao1"
        },
        {
          "data": "Marcacao2"
        },
        {
          "data": "TipoMarcacao2"
        },
        {
          "data": "Marcacao3"
        },
        {
          "data": "TipoMarcacao3"
        },
        {
          "data": "Marcacao4"
        },
        {
          "data": "TipoMarcacao4"
        },
        {
          "data": "Marcacao5"
        },
        {
          "data": "TipoMarcacao5"
        },
        {
          "data": "Marcacao6"
        },
        {
          "data": "TipoMarcacao6"
        },
        {
          "data": "Marcacao7"
        },
        {
          "data": "TipoMarcacao7"
        },
        {
          "data": "Marcacao8"
        },
        {
          "data": "TipoMarcacao8"
        },
        {
          "data": "InicioJornada"
        },
        {
          "data": "TerminoJornada"
        },
        {
          "data": "QuantHoras"
        },
        {
          "data": "DescricaoOcorrencia"
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
