import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/@core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ImportacaoCartaoPontoComponent } from './cartao-de-ponto/cartao-de-ponto.component';
import { ImportacaoDivergenciaComponent } from './divergencia/divergencia.component';
import { ImportacaoFeriasAvisoComponent } from './ferias-aviso/ferias-aviso.component';
import { ImportacaoFeriasReciboComponent } from './ferias-recibo/ferias-recibo.component';
import { ImportacaoFeriasComponent } from './ferias/ferias.component';
import { ImportacaoHoleriteComponent } from './holerite/holerite.component';
import { ImportacaoInformeDeRendimentosComponent } from './informe-de-rendimentos/informe-de-rendimentos.component';
import { ImportacaoBancoDeHorasComponent } from './banco-de-horas/banco-de-horas.component';
import { ImportacaoAbsenteismoComponent } from './absenteismo/absenteismo.component';

@NgModule({
  declarations: [
    ImportacaoCartaoPontoComponent,
    ImportacaoDivergenciaComponent,
    ImportacaoFeriasAvisoComponent,
    ImportacaoFeriasReciboComponent,
    ImportacaoFeriasComponent,
    ImportacaoHoleriteComponent,
    ImportacaoInformeDeRendimentosComponent,
    ImportacaoBancoDeHorasComponent,
    ImportacaoAbsenteismoComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild()
  ],
  entryComponents: [
    ImportacaoCartaoPontoComponent,
    ImportacaoCartaoPontoComponent,
    ImportacaoDivergenciaComponent,
    ImportacaoFeriasAvisoComponent,
    ImportacaoFeriasReciboComponent,
    ImportacaoFeriasComponent,
    ImportacaoHoleriteComponent,
    ImportacaoInformeDeRendimentosComponent,
    ImportacaoBancoDeHorasComponent,
    ImportacaoAbsenteismoComponent
  ]
})
export class ImportacaoModule { }
