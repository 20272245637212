<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Turno
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Turno</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Código</label>
                <input type="text" class="form-control" id="txtCodigo" name="txtCodigo" placeholder="Código do turno">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Descrição</label>
                <input type="text" class="form-control" id="txtDescricao" name="txtDescricao"
                  placeholder="Descrição do turno">
              </div>
            </div>
            <div class="col-md-4">
            <div class="form-group">
              <label>Empresas</label>
              <ng-multiselect-dropdown [placeholder]="'Selecione uma ou mais empresas...'" [settings]="dropdownSettings"
                [data]="empresasList" [(ngModel)]="empresasSelected" (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>
          </div>
        </div>
        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>

      </form>
    </div>

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Data de Cadastro</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal Detalhe -->
    <div class="modal fade" id="modalDetalhe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><b>Detalhe</b></h4><br>
            <h5 class="modal-title">Turno: {{dadosModal.descricao || '-'}}</h5>
          </div>
          <div class="modal-body">
            <form id="formularioDetalhe" method="post">
              <div class="box-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Dia da Semana</label>
                      <select-component [Data]="diasSemana" IdSelect="txtDia"
                      (changeValue)="changeSelectValues($event)" [IsRequired]="true" [Value]="diaSemanaSelected">
                    </select-component>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Horário Inicial</label>
                      <input type="text" mask="00:00" class="form-control" id="txtHoraInicial" name="txtHoraInicial"
                        placeholder="__:__">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Horário Final</label>
                      <input type="text" mask="00:00" class="form-control" id="txtHoraFinal" name="txtHoraFinal"
                        placeholder="__:__">
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-footer">
                <table border=0 width="100%">
                  <tr width="100%">
                    <td align="left"><button type="reset" (click)="clearInputvaluesTurno()" class="btn btn-default">Limpar</button>
                    </td>
                    <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                    </td>
                    <td align="right">
                      <input id="SalvarDetalhe" name="SalvarDetalhe" value="Salvar" (click)="salvarDetalhe()"
                        class="btn btn-info pull-right btn-margin" />
                    </td>
                  </tr>
                </table>
              </div>
            </form>
          </div>

          <div class="box box-body">
            <div style="overflow-y: auto;">
              <div style="width: 99%; padding-left: 15px;">
                <table #gridDetalhe tyle="width: 100% !important;" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Dia da Semana</th>
                      <th>Horário Inicial</th>
                      <th>Horário Final</th>
                      <th style="width: 5% !important;"></th>
                      <th style="width: 5% !important;"></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" (click)="closeModal('modalDetalhe')" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="control-sidebar-bg"></div>
</div>
