import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  EventEmitter,
  Input,
} from "@angular/core";
import { UtilsService } from "../../../../@core/@service/utils/utils";
import { ImportacaoFeriasService } from "src/app/@core/@service/importacao/ferias/ferias.service";
import Swal from "sweetalert2";
import { CadastroColaboradorService } from "src/app/@core/@service/cadastro/colaborador/colaborador.service";
//import * as jsPDF from "jspdf";
import jsPDF from 'jspdf';
//import "jspdf-autotable";
import autoTable from 'jspdf-autotable'
import { CadastroEmpresaService } from "src/app/@core/@service/cadastro/empresa/empresa.service";
import {
  SolicitacaoStatusEnum,
  UsuarioColaboradorTipoEnum,
} from "src/app/@core/@enum/enum";

declare var $;

@Component({
  selector: "app-relatorio-ferias",
  templateUrl: "./ferias.component.html",
  styleUrls: ["./ferias.component.scss"],
})
export class RelatorioFeriasComponent implements OnInit {
  gridData = [];

  empresaGridData = [];

  colaboradorSelected = "";

  modalRelatoriogridData: any = {};

  modalRelatorioData: any = {
    colaborador: "",
    matricula: "",
    empresa: "",
  };
  currUser: any;
  empresaIdSelected = "";

  dataTable: any;

  @ViewChild("grid", { static: true }) grid: ElementRef;
  @ViewChild("gridModalEmpresa", { static: true }) gridModalEmpresa: ElementRef;
  @ViewChild("gridModalRelatorio", { static: true })
  gridModalRelatorio: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoFeriasService,
    private colaboradorService: CadastroColaboradorService,
    private empresaService: CadastroEmpresaService
  ) {}

  ngOnInit() {
    this.loadDataTable([]);
    this.currUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.colaboradorSelected = this.currUser
      ? this.currUser.UsuarioColaboradorId
      : "";
  }

  mapGrid(data) {
    const arrData = [];
    data.Periodo.forEach((element) => {
      if (element.Concessao.length > 0) {
        if (element.Concessao.length == 1) {
          arrData.push({
            colaborador: data.Colaborador ? data.Colaborador.Nome : "-",
            matricula: data.Colaborador
              ? data.Colaborador.MatriculaInterna
              : "-",
            inicioPeriodo: element.InicioPeriodo || "-",
            fimPeriodo: element.FimPeriodo || "-",
            situacao: element.Situacao || "-",
            diasDireito: element.DiasDireito || "-",
            diasConcedidos: element.DiasConcedidos || "-",
            diasProgramados: element.DiasProgramados || "-",
            inicioFerias: element.Concessao[0].InicioFerias || "-",
            diasGozo: element.Concessao[0].DiasGozo || "-",
            diasAbono: element.Concessao[0].DiasAbono || "-",
            diasDeLicenca: element.Concessao[0].DiasLicenca || "-",
            saldo: element.Saldo || "-",
            ...element,
          });
        } else {
          element.Concessao.forEach((element2, index) => {
            if (index == 0) {
              arrData.push({
                colaborador: data.Colaborador ? data.Colaborador.Nome : "-",
                matricula: data.Colaborador
                  ? data.Colaborador.MatriculaInterna
                  : "-",
                inicioPeriodo: element.InicioPeriodo || "-",
                fimPeriodo: element.FimPeriodo || "-",
                situacao: element.Situacao || "-",
                diasDireito: element.DiasDireito || "-",
                diasConcedidos: element.DiasConcedidos || "-",
                diasProgramados: element.DiasProgramados || "-",
                inicioFerias: element.Concessao[index].InicioFerias || "-",
                diasGozo: element.Concessao[index].DiasGozo || "-",
                diasAbono: element.Concessao[index].DiasAbono || "-",
                diasDeLicenca: element.Concessao[index].DiasLicenca || "-",
                saldo: element.Saldo || "-",
                ...element,
              });
            } else {
              arrData.push({
                colaborador: "",
                matricula: "",
                inicioPeriodo: "",
                fimPeriodo: "",
                situacao: "",
                diasDireito: "",
                diasConcedidos: "",
                diasProgramados: "",
                inicioFerias: element2.InicioFerias || "-",
                diasGozo: element2.DiasGozo || "-",
                diasAbono: element2.DiasAbono || "-",
                diasDeLicenca: element2.DiasLicenca || "-",
                saldo: "",
                ...element,
              });
            }
          });
        }
      } else {
        arrData.push({
          colaborador: data.Colaborador ? data.Colaborador.Nome : "-",
          matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : "-",
          inicioPeriodo: element.InicioPeriodo || "-",
          fimPeriodo: element.FimPeriodo || "-",
          situacao: element.Situacao || "-",
          diasDireito: element.DiasDireito || "-",
          diasConcedidos: element.DiasConcedidos || "-",
          diasProgramados: element.DiasProgramados || "-",
          inicioFerias: "-",
          diasGozo: "-",
          diasAbono: "-",
          diasDeLicenca: "-",
          saldo: element.Saldo || "-",
          ...element,
        });
      }
    });
    return arrData;
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return "Em Análise";
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return "Aprovado";
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return "Reprovado";
    } else {
      return "";
    }
  }

  openModal(idModal, data: any = {}) {
    this.closeModal(idModal);
    if (idModal == "modalEmpresa") {
      if (
        this.currUser.UsuarioColaboradorTipoId ==
        UsuarioColaboradorTipoEnum.COLABORADOR
      ) {
        if ($("#txtNomeEmpresa").val().toString().length > 0) {
          this.empresaService
            .buscarEmpresaPorNome($("#txtNomeEmpresa").val().toString())
            .subscribe(
              (res) => {
                this.empresaGridData = res
                  .map((val) => ({
                    id: val.Id,
                    nome: val.Nome,
                    cnpj: val.CNPJ,
                  }))
                  .sort(this.utilService.dynamicSort("nome"));
                this.loadDataTableModalEmpresa(this.empresaGridData);

                ($(`#${idModal}`) as any).modal("show");
              },
              (err) => {
                console.log(err);
              }
            );
        }
      } else {
        if ($("#txtNomeEmpresa").val().toString().length > 0) {
          this.empresaService
            .buscarEmpresaPorNomeUsuarioId($("#txtNomeEmpresa").val().toString(),this.currUser.UsuarioColaboradorId)
            .subscribe(
              (res) => {
                this.empresaGridData = res
                  .map((val) => ({
                    id: val.Id,
                    nome: val.Nome,
                    cnpj: val.CNPJ,
                  }))
                  .sort(this.utilService.dynamicSort("nome"));
                this.loadDataTableModalEmpresa(this.empresaGridData);

                ($(`#${idModal}`) as any).modal("show");
              },
              (err) => {
                console.log(err);
              }
            );
        }
      }
    } else if (idModal == "modalRelatorio") {
      this.moduleService.pesquisarImportacaoPorId(data.id).subscribe(
        (res) => {
          this.modalRelatoriogridData = this.mapGrid(res);
          this.loadDataTableModalRelatorio(this.modalRelatoriogridData);
          ($(`#${idModal}`) as any).modal("show");
        },
        (err) => {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Ocorreu um erro ao buscar a os dados da importação!",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      );
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal("hide");
  }

  exportarPDF() {
    const data = [];
    const doc = new jsPDF("p", "pt", "a4");

    this.modalRelatoriogridData.forEach((val, i) => {
      const arr = [];
      Object.keys(val).forEach((x) => {
        arr.push(val[x]);
      });
      data.push(arr);
    });
    doc.text(
      `Relatório de Férias - ${new Date().toLocaleDateString("pt-BR")}`,
      150,
      30
    );
    doc.setFontSize(11);
    doc.text(`Estabelecimento: ${this.modalRelatorioData.empresa}`, 40, 60);
    autoTable(doc,{
      margin: { top: 90 },
      didDrawPage: function (data) {
        data.settings.margin.top = 20;
      },
      head: [
        [
          "Nome do Colaborador",
          "Matrícula",
          "Início Período",
          "Fim Período",
          "Situação",
          "Dias Direito",
          "Dias Concedidos",
          "Início Férias",
          "Dias Programados",
          "Dias Abono",
          "Dias de Licença",
          "Saldo",
        ],
      ],
      body: data,
      styles: {
        valign: "middle",
        halign: "center",
        fontSize: 7,
      },
      headStyles: {
        valign: "middle",
        halign: "center",
      },
    });

    doc.save(`relatorio-ferias-${new Date().toLocaleDateString("pt-BR")}.pdf`);
  }

  getDataGrid() {
    if (this.empresaIdSelected) {
      this.moduleService.pesquisar(this.empresaIdSelected).subscribe(
        (res) => {
          if (res.length == 1 && res[0].Erro) {
            this.loadDataTable([]);
          } else {
            this.gridData = res.map((val) => ({
              id: val.Id,
              colaborador: val.Colaborador ? val.Colaborador.Nome : "",
              matricula: val.Colaborador
                ? val.Colaborador.MatriculaInterna
                : "",
              empresa: val.Empresa ? val.Empresa.Nome : "",
              dataConclusao: val.DataCadastro,
              ...val,
            }));
            this.loadDataTable(this.gridData);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.moduleService
        .pesquisarPorUsuarioId(this.currUser.UsuarioColaboradorId)
        .subscribe(
          (res) => {
            if (res.length == 1 && res[0].Erro) {
              this.loadDataTable([]);
            } else {
              this.gridData = res.map((val) => ({
                id: val.Id,
                colaborador: val.Colaborador ? val.Colaborador.Nome : "",
                matricula: val.Colaborador
                  ? val.Colaborador.MatriculaInterna
                  : "",
                empresa: val.Empresa ? val.Empresa.Nome : "",
                dataConclusao: val.DataCadastro,
                ...val,
              }));
              this.loadDataTable(this.gridData);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  pesquisar() {
    if (
      this.currUser.UsuarioColaboradorTipoId ==
      UsuarioColaboradorTipoEnum.COLABORADOR
    ) {
      if (this.empresaIdSelected) {
        this.getDataGrid();
      } else {
        $("#txtNomeEmpresa").addClass("element-invalid");
      }
    } else {
      this.getDataGrid();
    }
  }
  clearInputvalues(type = "") {
    this.empresaIdSelected = "";
    $("#txtNomeEmpresa").val("");
    $("#txtNomeEmpresa").addClass("element-invalid");
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "colaborador",
        },
        {
          data: "matricula",
        },
        {
          data: "empresa",
        },
        {
          data: "dataConclusao",
          orderable: true,
          render: function (data, type, row) {
            return data ? new Date(data).toLocaleDateString("pt-br") : "";
          },
        },
        {
          data: "",
          orderable: false,
          render: function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-search" style="color: #100F5C; border: 0px; background-color: transparent;" title="Visualizar"></button></div>';
          },
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()[4]).on("click", async function (e) {
          self.modalRelatorioData = data;
          self.modalRelatorioData.data = new Date(
            data.DataCadastro
          ).toLocaleDateString();
          self.openModal("modalRelatorio", data);
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: true,
      searching: true,
      ordering: true,
      info: true,
      autoWidth: true,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalRelatorio(data) {
    this.dataTable = $(this.gridModalRelatorio.nativeElement);
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "colaborador",
        },
        {
          data: "matricula",
        },
        {
          data: "inicioPeriodo",
        },
        {
          data: "fimPeriodo",
        },
        {
          data: "situacao",
        },
        {
          data: "diasDireito",
        },
        {
          data: "diasConcedidos",
        },
        {
          data: "diasProgramados",
        },
        {
          data: "inicioFerias",
        },
        {
          data: "diasAbono",
        },
        {
          data: "diasGozo",
        },
        {
          data: "diasDeLicenca",
        },
        {
          data: "saldo",
        },
      ],
      createdRow: function (row, data, index) {},
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      data: data,
      bDestroy: true,
      columns: [
        {
          data: "nome",
        },
        {
          data: "cnpj",
        },
      ],
      createdRow: function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          $("#txtNomeEmpresa").removeClass("element-invalid");
          self.empresaIdSelected = data.id;
          $("#txtNomeEmpresa").val(data.nome);
          self.closeModal("modalEmpresa");
        });
      },
      paging: true,
      bAutoWidth: false,
      lengthChange: false,
      searching: false,
      ordering: false,
      info: false,
      autoWidth: false,
      language: this.utilService.getDatatableTranslate(),
    });
  }
}
