<div style="margin-left: 0px" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>Funcionário</h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Funcionário</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">
    <form id="formulario">
      <div style="margin-left: 0px">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <select-component
                    (changeValue)="changeSelectValues($event)"
                    [Data]="colaboradorList"
                    IdSelect="selColaborador"
                    [Value]="colaboradorSelected"
                  >
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Tipo de Registro</label>
                  <select-component
                    (changeValue)="changeSelectValues($event)"
                    [Data]="tipoRegistroList"
                    IdSelect="tipoRegistro"
                    [Value]="tipoRegistro"
                  >
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Status</label>
                  <ng-multiselect-dropdown [placeholder]="'Selecione uma ou mais status...'" [settings]="dropdownSettings"
                  [data]="statusList" [(ngModel)]="statusSelected" (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)">
                </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border="0" width="100%">
              <tr width="100%">
                <td align="left">
                  <button
                    type="reset"
                    (click)="clearInputvalues()"
                    class="btn btn-default"
                  >
                    Limpar
                  </button>
                </td>
                <td align="center">
                  <span
                    id="spanErro"
                    name="spanErro"
                    class="error"
                    style="font-weight: bold"
                  ></span>
                </td>
                <td align="right">
                  <input
                    type="button"
                    value="Exportar para Excel"
                    (click)="exportToExcel()"
                    class="btn btn-primary"
                    *ngIf="gridData.length"
                  />
                  <input
                    type="button"
                    value="Pesquisar"
                    (click)="pesquisar()"
                    class="btn btn-primary btn-margin"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #grid
              style="width: 100% !important"
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Cpf</th>
                  <th>Empresa</th>
                  <th>Cargo</th>
                  <th>Centro De Custo</th>
                  <th>Unidade de Lotação</th>
                  <th>Unidade de Negócio</th>
                  <th>Salário</th>
                  <th>Turno</th>
                  <th>Data de Admissão</th>
                  <th>Tipo de Registro</th>
                  <th>Empresa Terceira</th>
                  <th>Status</th>
                  <th>Data de Demissao</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>
<div
  class="modal fade"
  id="modalRelatorio"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="width: 95%">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">
          <b>Relatório de Funcionário</b>
        </h4>
      </div>
      <div class="modal-header" style="padding-left: 40px">
        <div class="row">
          <div class="col-md-3"><b>CPF: </b> {{ modalRelatorioData.cpf }}</div>
          <div class="col-md-7">
            <b>Nome: </b>{{ modalRelatorioData.colaborador }}
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Dados Principais:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalDadosPrincipais
              style="width: 100% !important; cursor: pointer"
              class="hover tableOperadora table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Tipo</th>
                  <th>Empresa</th>
                  <th>UF</th>
                  <th>Cidade</th>
                  <th>Telefone Res.</th>
                  <th>Telefone Cel.</th>
                  <th>Telefone Com.</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Data de Cadastro</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Documentação:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalDocumentacao
              style="width: 100% !important; cursor: pointer"
              class="hover tableOperadora table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>RG</th>
                  <th>RIC</th>
                  <th>Cartão Nacional de Saúde</th>
                  <th>Reservista</th>
                  <th>Título Eleitor</th>
                  <th>Habilitação</th>
                  <th>CTPS</th>
                  <th>PIS</th>
                  <th>Data de Cadastro</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Empregador:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalEmpregador
              style="width: 100% !important; cursor: pointer"
              class="hover tableOperadora table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>Sindicato</th>
                  <th>Cargo</th>
                  <th>Centro de Custo</th>
                  <th>Lotação</th>
                  <th>Salário (R$)</th>
                  <th>Data de Admissão</th>
                  <th>Data de Cadastro</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Pagamento:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalPagamento
              style="width: 100% !important; cursor: pointer"
              class="hover tableOperadora table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>Banco</th>
                  <th>Agência</th>
                  <th>Conta</th>
                  <th>Data de Cadastro</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Estrangeiro:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalEstrangeiro
              style="width: 100% !important; cursor: pointer"
              class="hover tableOperadora table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>Origem</th>
                  <th>Tipo Visto</th>
                  <th>Passaporte</th>
                  <th>Orgão Emissor</th>
                  <th>País Emissor (Sigla)</th>
                  <th>UF</th>
                  <th>Data Emissão</th>
                  <th>Portaria Nat.</th>
                  <th>Identidade Est.</th>
                  <th>Valiade Ident Est.</th>
                  <th>Ano Chegada</th>
                  <th>Data de Cadastro</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default pull-right"
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>
