import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorEmpregadorService } from 'src/app/@core/@service/cadastro/colaborador/empregador/empregador.service';
import Swal from 'sweetalert2';
import { CadastroCargoUnidadeService } from 'src/app/@core/@service/cadastro/cargo/unidade/unidade.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from 'src/app/@core/@enum/enum';

declare var $;
@Component({
  selector: 'app-pre-admissao-empregador',
  templateUrl: './empregador.component.html',
  styleUrls: ['./empregador.component.scss']
})
export class PreAdmissaoEmpregadorComponent implements OnInit {

  gridData = [];

  gridModalUnidadeData = [];

  colaboradorGridData = [];

  isEdit = false;

  planoIdSelected = '';

  empresaIdSelected = '';

  empregadorIdSelected = '';

  colaboradorSelected = '';

  cargoList: Array<object> = [];
  cargoSelected = '';

  centroDeCustoList: Array<object> = [];
  centroDeCustoSelected = '';

  lotacaoList: Array<object> = [];
  lotacaoSelected = '';

  tipoDeRegistroList: Array<object> = [];
  tipoDeRegistroSelected = '';

  tipoDeMaoDeObraList: Array<object> = [];
  tipoDeMaoDeObraSelected = '';

  unidadeDeNegocioList: Array<object> = [];
  unidadeDeNegocioSelected = '';

  turnoList: Array<object> = [];
  turnoSelected = '';

  categoriaSalarialList: Array<object> = [];
  categoriaSalarialSelected = '';

  sindicatoList: Array<object> = [];
  sindicatoSelected = '';

  fotoColaborador: any = {
    nome: '',
    base64: '',
  };

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  colaboradorStatusIdSelected = '';

  grauInstrucaoSelected = '';

  estadoCivilSelected = '';

  sexoIdSelected = '';

  dataTable: any;

  dataTableModalUnidade: any;

  dataTableModalColaborador: any;

  cargoUnidades = [];
  userLogged: any;

  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };


  colaboradorType = '';

  colaboradorLogged = '';

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalUnidade', { static: true }) gridModalUnidade: ElementRef;

  @ViewChild('gridModalColaboradorEmpregador', { static: true }) gridModalColaboradorEmpregador: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorEmpregadorService,
    private unidadeService: CadastroCargoUnidadeService,
    private empresaService: CadastroEmpresaService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && this.userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO) {
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.colaboradorType = 'pre-admissao';
        this.getDataGrid();
      } else {
        this.colaboradorType = 'rh';
        this.loadDataTable([]);
        this.getSelectData();
      }
    }

    this.validarCampos();
    ($("#txtDataAdmissao") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataUltimoExame") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataValidadeAso") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataProximoAso") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataVencimentoContrato1") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataVencimentoContrato2") as any).datepicker({
      autoclose: true
    });
    ($("#txtDataVencimentoContrato3") as any).datepicker({
      autoclose: true
    });
    ($("#txtSalario") as any).maskMoney({ prefix: 'R$ ', thousands: '.', decimal: ',' });
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    if (this.empresaIdSelected) {
      this.colaboradorService.BuscarTudoCargo(this.empresaIdSelected).subscribe(
        res => {
          this.cargoList = res.filter(val => val.Nome != null).map(val =>
            ({
              Id: val.Id,
              Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
            })
          ).sort(this.utilService.dynamicSort('Description'));
        },
        err => {
          console.log(err);
        }
      );
      this.colaboradorService.BuscarTudoCentroCusto(this.empresaIdSelected).subscribe(
        res => {
          this.centroDeCustoList = res.filter(val => val.Nome != null).map(val =>
            ({
              Id: val.Id,
              Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
            })
          ).sort(this.utilService.dynamicSort('Description'));
        },
        err => {
          console.log(err);
        }
      );
      this.colaboradorService.BuscarTudoLotacao(this.empresaIdSelected).subscribe(
        res => {
          this.lotacaoList = res.filter(val => val.Nome != null).map(val =>
            ({
              Id: val.Id,
              Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
            })
          ).sort(this.utilService.dynamicSort('Description'));
        },
        err => {
          console.log(err);
        }
      );
    }
    this.colaboradorService.BuscarTudoTipoRegistro().subscribe(
      res => {
        this.tipoDeRegistroList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoTipoMaoDeObra().subscribe(
      res => {
        this.tipoDeMaoDeObraList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoUnidadeDeNegocio().subscribe(
      res => {
        this.unidadeDeNegocioList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    /*this.colaboradorService.BuscarTudoTurno().subscribe(
      res => {
        this.turnoList = res.filter(val => val.Descricao != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );*/
    this.colaboradorService.BuscarTudoCategoriaSalarial().subscribe(
      res => {
        this.categoriaSalarialList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoSindicato().subscribe(
      res => {
        this.sindicatoList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selCargo') {
      this.cargoSelected = event.target.value;
      $('#selCargo').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selCentro') {
      this.centroDeCustoSelected = event.target.value;
      $('#selCentro').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selLotacao') {
      this.lotacaoSelected = event.target.value;
      $('#selLotacao').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selTipoRegistro') {
      this.tipoDeRegistroSelected = event.target.value;
    } else if (event.srcElement.id == 'selTipoMaoObra') {
      this.tipoDeMaoDeObraSelected = event.target.value;
    } else if (event.srcElement.id == 'selUnidadeNegocio') {
      this.unidadeDeNegocioSelected = event.target.value;
    } else if (event.srcElement.id == 'selTurno') {
      this.turnoSelected = event.target.value;
    } else if (event.srcElement.id == 'selCategoriaSal') {
      this.categoriaSalarialSelected = event.target.value;
    } else if (event.srcElement.id == 'selSindicato') {
      this.sindicatoSelected = event.target.value;
    }
    $("#formularioEmpregador").valid();
  }

  validarCampos() {
    const self = this;
    ($("#formularioEmpregador") as any).validate({
      // Define as regras
      rules: {
        txtNomeColaboradorEmpregador: {
          required: true,
          colaboradorCheck: true,
        },
        txtEmpresaEmpregador: {
          required: true
        },
        selCargo: {
          required: true
        },
        selCentro: {
          required: true
        },
        selLotacao: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeColaboradorEmpregador: {
          required: "Informe o Nome do Colaborador"
        },
        txtEmpresaEmpregador: {
          required: "Informe a empresa"
        },
        selCargo: {
          required: "Informe o Cargo"
        },
        selCentro: {
          required: "Informe o Centro de Custo"
        },
        selLotacao: {
          required: "Informe a Lotação"
        }
      },
    });
    $.validator.addMethod("colaboradorCheck", function (value, element) {
      return self.colaboradorSelected ? true : false;
    }, 'Selecione o colaborador');
    ($("#formularioEmpregador") as any).on("submit", function () {
      return false;
    })
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    if (this.colaboradorType == 'pre-admissao') {
      this.colaboradorService.BuscarEmpregadorColaboradorPreAdmissaoPorId(this.colaboradorLogged).subscribe(
        async res => {
          // Seta modo edição
          this.isEdit = true;

          this.empregadorIdSelected = res.Id;
          this.empresaIdSelected = res.Colaborador.EmpresaId;
          this.buscaTurnoPorEmpresaId(res.Colaborador.EmpresaId);
          this.getSelectData();
          // Preencher campos, com a linha selecionada
          ($("#txtDataAdmissao") as any).datepicker('setDate', res.DataAdmissao ? new Date(res.DataAdmissao) : '');
          ($("#txtDataValidadeAso") as any).datepicker('setDate', res.DataValidadeAso ? new Date(res.DataValidadeAso) : '');
          ($("#txtDataProximoAso") as any).datepicker('setDate', res.DataProximoAso ? new Date(res.DataProximoAso) : '');
          ($("#txtDataVencimentoContrato1") as any).datepicker('setDate', res.DataVencimentoContrato1 ? new Date(res.DataVencimentoContrato1) : '');
          ($("#txtDataVencimentoContrato2") as any).datepicker('setDate', res.DataVencimentoContrato2 ? new Date(res.DataVencimentoContrato2) : '');
          ($("#txtDataVencimentoContrato3") as any).datepicker('setDate', res.DataVencimentoContrato3 ? new Date(res.DataVencimentoContrato3) : '');

          this.cargoSelected = res.CargoUnidadeId || '';
          this.centroDeCustoSelected = res.CentroCustoUnidadeId || '';
          this.lotacaoSelected = res.LotacaoUnidadeId || '';
          this.tipoDeRegistroSelected = res.TipoRegistroId || '';
          this.tipoDeMaoDeObraSelected = res.TipoMaoObraId || '';
          this.unidadeDeNegocioSelected = res.UnidadeNegocioId || '';
          this.turnoSelected = res.TurnoId || '';
          this.categoriaSalarialSelected = res.CategoriaSalarialId || '';
          this.sindicatoSelected = res.SindicatoId || '';
          this.colaboradorSelected = res.ColaboradorId || '';

          $('#txtEmpresaEmpregador').val(res.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorEmpregador').val(res.Colaborador.Nome);
          $('#txtCartaoPonto').val(res.NumeroCartaoPonto);
          $('#txtSituacao').val(res.Situacao);
          $('#txtSalario').val(this.utilService.numberToMaskMoney(res.Salario));

        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.moduleService.pesquisarPreAdmissaoPorUsuarioId(data, this.userLogged.UsuarioColaboradorId).subscribe(
        res => {
          this.gridData = res.map(val =>
            ({
              id: val.Id,
              nomeColaborador: val.Colaborador ? val.Colaborador.Nome : '',
              nomeEmpresa: val.Colaborador ? val.Colaborador.Empresa.Nome  : '',
              sindicato: val.Sindicato ? val.Sindicato.Nome : '',
              cargo: val.CargoUnidade ? val.CargoUnidade.Nome : '',
              centroDeCusto: val.CentroCustoUnidade ? val.CentroCustoUnidade.Nome : '',
              lotacao: val.LotacaoUnidade ? val.LotacaoUnidade.Nome : '',
              salario: val.Salario,
              dataAdmissao: val.DataAdmissao,
              dataCadastro: val.DataCadastro,
              ...val
            })
          ).sort(this.utilService.dynamicSort('nomeColaborador'));
          this.loadDataTable(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  clearInputvalues(type = '') {
    if (type == 'salvar') {
      $('#selCargo').removeClass('element-invalid');
      $('#selCentro').removeClass('element-invalid');
      $('#selLotacao').removeClass('element-invalid');
      $("#formularioEmpregador").valid();
    }
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos

    this.cargoSelected = '';
    this.centroDeCustoSelected = '';
    this.lotacaoSelected = '';
    this.tipoDeRegistroSelected = '';
    this.tipoDeMaoDeObraSelected = '';
    this.unidadeDeNegocioSelected = '';
    this.turnoSelected = '';
    this.categoriaSalarialSelected = '';
    this.sindicatoSelected = '';
    this.colaboradorSelected = '';
    this.empresaIdSelected = '';
    $('#txtEmpresaEmpregador').val('');
    $('#txtNomeColaboradorEmpregador').val('');

    $('#txtCartaoPonto').val('');
    $('#txtSituacao').val('');
    $('#txtSalario').val('');
    $('#txtDataAdmissao').val('');
    $('#txtDataUltimoExame').val('');
    $('#txtDataValidadeAso').val('');
    $("#txtDataProximoAso").val('');
    $('#txtDataVencimentoContrato1').val('');
    $('#txtDataVencimentoContrato2').val('');
    $('#txtDataVencimentoContrato3').val('');
    $('#cbxEscalaAlternada')[0].checked = false;
    $('#cbxProrrogaVencimento1')[0].checked = false;
    $('#cbxProrrogaVencimento2')[0].checked = false;
  }

  getValuesFromInput(type = '') {
    const dataAdmissao = $('#txtDataAdmissao').val().toString().trim().split('/');
    const dataUltimoExame = $('#txtDataUltimoExame').val().toString().trim().split('/');
    const dataValidadeAso = $('#txtDataValidadeAso').val().toString().trim().split('/');
    const dataProximoAso = $('#txtDataProximoAso').val().toString().trim().split('/');
    const dataVencimentoContrato1 = $('#txtDataVencimentoContrato1').val().toString().trim().split('/');
    const dataVencimentoContrato2 = $('#txtDataVencimentoContrato2').val().toString().trim().split('/');
    const dataVencimentoContrato3 = $('#txtDataVencimentoContrato3').val().toString().trim().split('/');

    if (type == 'pesquisar') {
      return {
        ColaboradorId: this.colaboradorSelected || 0,
        CargoUnidadeId: this.cargoSelected || 0,
        CentroCustoUnidadeId: this.centroDeCustoSelected || 0,
        LotacaoUnidadeId: this.lotacaoSelected || 0,
        TipoRegistroId: this.tipoDeRegistroSelected  || 0,
        TipoMaoObraId: this.tipoDeMaoDeObraSelected || 0,
        UnidadeNegocioId: this.unidadeDeNegocioSelected || 0,
        NumeroCartaoPonto: $('#txtCartaoPonto').val().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Situacao: $('#txtSituacao').val().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        TurnoId: this.turnoSelected || 0,
        CategoriaSalarialId: this.categoriaSalarialSelected || 0,
        Salario: this.utilService.maskMoneyToNumber($('#txtSalario').val().toString().trim()) || 0,
        DataAdmissao: dataAdmissao.length >= 3 ? `${dataAdmissao[2]}-${dataAdmissao[1]}-${dataAdmissao[0]}` : '',
        DataUltimoExame: dataUltimoExame.length >= 3 ? `${dataUltimoExame[2]}-${dataUltimoExame[1]}-${dataUltimoExame[0]}` : '',
        DataValidadeAso: dataValidadeAso.length >= 3 ? `${dataValidadeAso[2]}-${dataValidadeAso[1]}-${dataValidadeAso[0]}` : '',
        DataProximoAso: dataProximoAso.length >= 3 ? `${dataProximoAso[2]}-${dataProximoAso[1]}-${dataProximoAso[0]}` : '',
        DataVencimentoContrato1: dataVencimentoContrato1.length >= 3 ? `${dataVencimentoContrato1[2]}-${dataVencimentoContrato1[1]}-${dataVencimentoContrato1[0]}` : '',
        DataVencimentoContrato2: dataVencimentoContrato2.length >= 3 ? `${dataVencimentoContrato2[2]}-${dataVencimentoContrato2[1]}-${dataVencimentoContrato2[0]}` : '',
        DataVencimentoContrato3: dataVencimentoContrato3.length >= 3 ? `${dataVencimentoContrato3[2]}-${dataVencimentoContrato3[1]}-${dataVencimentoContrato3[0]}` : '',
        SindicatoId: this.sindicatoSelected || 0,
        EscalaAlternada: $('#cbxEscalaAlternada').is(":checked"),
        ProrrogaVencimento1: $('#cbxProrrogaVencimento1').is(":checked"),
        ProrrogaVencimento2: $('#cbxProrrogaVencimento2').is(":checked"),
      }
    } else {
      return {
        ColaboradorId: this.colaboradorSelected || '',
        CargoUnidadeId: this.cargoSelected || '',
        CentroCustoUnidadeId: this.centroDeCustoSelected || '',
        LotacaoUnidadeId: this.lotacaoSelected || '',
        TipoRegistroId: this.tipoDeRegistroSelected || '',
        TipoMaoObraId: this.tipoDeMaoDeObraSelected || '',
        UnidadeNegocioId: this.unidadeDeNegocioSelected || '',
        NumeroCartaoPonto: $('#txtCartaoPonto').val() || '',
        Situacao: $('#txtSituacao').val() || '',
        TurnoId: this.turnoSelected || '',
        CategoriaSalarialId: this.categoriaSalarialSelected || '',
        Salario: this.utilService.maskMoneyToNumber($('#txtSalario').val().toString().trim()) || 0,
        DataAdmissao: dataAdmissao.length >= 3 ? `${dataAdmissao[2]}-${dataAdmissao[1]}-${dataAdmissao[0]}` : '',
        DataUltimoExame: dataUltimoExame.length >= 3 ? `${dataUltimoExame[2]}-${dataUltimoExame[1]}-${dataUltimoExame[0]}` : '',
        DataValidadeAso: dataValidadeAso.length >= 3 ? `${dataValidadeAso[2]}-${dataValidadeAso[1]}-${dataValidadeAso[0]}` : '',
        DataProximoAso: dataProximoAso.length >= 3 ? `${dataProximoAso[2]}-${dataProximoAso[1]}-${dataProximoAso[0]}` : '',
        DataVencimentoContrato1: dataVencimentoContrato1.length >= 3 ? `${dataVencimentoContrato1[2]}-${dataVencimentoContrato1[1]}-${dataVencimentoContrato1[0]}` : '',
        DataVencimentoContrato2: dataVencimentoContrato2.length >= 3 ? `${dataVencimentoContrato2[2]}-${dataVencimentoContrato2[1]}-${dataVencimentoContrato2[0]}` : '',
        DataVencimentoContrato3: dataVencimentoContrato3.length >= 3 ? `${dataVencimentoContrato3[2]}-${dataVencimentoContrato3[1]}-${dataVencimentoContrato3[0]}` : '',
        SindicatoId: this.sindicatoSelected || '',
        EscalaAlternada: $('#cbxEscalaAlternada').is(":checked"),
        ProrrogaVencimento1: $('#cbxProrrogaVencimento1').is(":checked"),
        ProrrogaVencimento2: $('#cbxProrrogaVencimento2').is(":checked"),
      }
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;
    if (idModal == 'modalColaboradorEmpregador') {
      if ($('#txtNomeColaboradorEmpregador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPreAdmissaoPorNomePorUsuarioId($('#txtNomeColaboradorEmpregador').val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar(concluir: boolean) {
    if (!this.cargoSelected || !this.centroDeCustoSelected || !this.lotacaoSelected) {
      ($("#formularioEmpregador") as any).valid();
      if (!this.cargoSelected) {
        $('#selCargo').addClass('element-invalid');
      } else {
        $('#selCargo').removeClass('element-invalid');
      }
      if (!this.centroDeCustoSelected) {
        $('#selCentro').addClass('element-invalid');
      } else {
        $('#selCentro').removeClass('element-invalid');
      }
      if (!this.lotacaoSelected) {
        $('#selLotacao').addClass('element-invalid');
      } else {
        $('#selLotacao').removeClass('element-invalid');
      }
    } else {
      if (($("#formularioEmpregador") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.empregadorIdSelected;
          this.moduleService.editar(obj, concluir).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj, concluir).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    ;
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nomeColaborador"
        },
        {
          "data": "nomeEmpresa"
        },
        {
          "data": "sindicato"
        },
        {
          "data": "cargo"
        },
        {
          "data": "centroDeCusto"
        },
        {
          "data": "lotacao"
        },
        {
          "data": "salario"
        },
        {
          "data": 'dataAdmissao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[9]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;
          self.empregadorIdSelected = data.Id;
          self.empresaIdSelected = data.Colaborador.EmpresaId;
          self.buscaTurnoPorEmpresaId(data.Colaborador.EmpresaId);
          self.getSelectData();
          // Preencher campos, com a linha selecionada
          ($("#txtDataAdmissao") as any).datepicker('setDate', data.DataAdmissao ? new Date(data.DataAdmissao) : '');
          ($("#txtDataUltimoExame") as any).datepicker('setDate', data.DataUltimoExame ? new Date(data.DataUltimoExame) : '');
          ($("#txtDataValidadeAso") as any).datepicker('setDate', data.DataValidadeAso ? new Date(data.DataValidadeAso) : '');
          ($("#txtDataProximoAso") as any).datepicker('setDate', data.DataProximoAso ? new Date(data.DataProximoAso) : '');
          ($("#txtDataVencimentoContrato1") as any).datepicker('setDate', data.DataVencimentoContrato1 ? new Date(data.DataVencimentoContrato1) : '');
          ($("#txtDataVencimentoContrato2") as any).datepicker('setDate', data.DataVencimentoContrato2 ? new Date(data.DataVencimentoContrato2) : '');
          ($("#txtDataVencimentoContrato3") as any).datepicker('setDate', data.DataVencimentoContrato3 ? new Date(data.DataVencimentoContrato3) : '');

          self.cargoSelected = data.CargoUnidadeId || '';
          self.centroDeCustoSelected = data.CentroCustoUnidadeId || '';
          self.lotacaoSelected = data.LotacaoUnidadeId || '';
          self.tipoDeRegistroSelected = data.TipoRegistroId || '';
          self.tipoDeMaoDeObraSelected = data.TipoMaoObraId || '';
          self.unidadeDeNegocioSelected = data.UnidadeNegocioId || '';
          self.turnoSelected = data.TurnoId || '';
          self.categoriaSalarialSelected = data.CategoriaSalarialId || '';
          self.sindicatoSelected = data.SindicatoId || '';
          self.colaboradorSelected = data.ColaboradorId || '';

          $('#txtEmpresaEmpregador').val(data.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorEmpregador').val(data.Colaborador.Nome);
          $('#txtCartaoPonto').val(data.NumeroCartaoPonto);
          $('#txtSituacao').val(data.Situacao);
          $('#txtSalario').val(self.utilService.numberToMaskMoney(data.Salario));
          $('#cbxEscalaAlternada')[0].checked = data.EscalaAlternada;
          $('#cbxProrrogaVencimento1')[0].checked = data.ProrrogaVencimento1;
          $('#cbxProrrogaVencimento2')[0].checked = data.ProrrogaVencimento2;

          if (!self.cargoSelected) {
            $('#selCargo').addClass('element-invalid');
          } else {
            $('#selCargo').removeClass('element-invalid');
          }
          if (!self.centroDeCustoSelected) {
            $('#selCentro').addClass('element-invalid');
          } else {
            $('#selCentro').removeClass('element-invalid');
          }
          if (!self.lotacaoSelected) {
            $('#selLotacao').addClass('element-invalid');
          } else {
            $('#selLotacao').removeClass('element-invalid');
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaboradorEmpregador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          self.empresaIdSelected = data.Empresa.Id;
          self.buscaTurnoPorEmpresaId(data.Empresa.Id);
          $('#txtEmpresaEmpregador').val(data.Empresa.Nome);
          $('#txtNomeColaboradorEmpregador').val(data.nome);
          self.getSelectData();
          self.closeModal('modalColaboradorEmpregador');
          $("#formularioEmpregador").valid();
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }

  buscaTurnoPorEmpresaId(id:number){
  this.colaboradorService.BuscarTudoTurnoPorEmpresaId(id).subscribe(
    res => {
      this.turnoList = res.filter(val => val.Descricao != null).map(val =>
        ({
          Id: val.Id,
          Description: val.Descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        })
      ).sort(this.utilService.dynamicSort('Description'));
    },
    err => {
      console.log(err);
    }
  );

 }

}
