import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'app';

  constructor(private router: Router) { }

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!user) {
      this.router.navigate(['/Login']);
    }
  }
}
