import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoValeTransporteService {

    routePrefix: string = 'ValeTransporte'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarSolicitacao${this.routePrefix}PorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    solicitar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Solicitar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    editar(data = {}): Observable<any> {
      return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Editar${this.routePrefix}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    buscarFotoSolicitacaoAlteracao(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarFotoSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorColaboradorId(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarDadosPorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarTudoSituacao(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}Situacao`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarTudoUtilizacao(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}Utilizacao`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}
