import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorPagamentoService } from 'src/app/@core/@service/cadastro/colaborador/pagamento/pagamento.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from 'src/app/@core/@enum/enum';

declare var $;
@Component({
  selector: 'app-pre-admissao-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PreAdmissaoPagamentoComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  isEdit = false;

  empresaIdSelected = '';

  pagamentoIdSelected = '';

  colaboradorSelected = '';

  bancoList: Array<object> = [];
  bancoSelected = '';

  tipoContaList: Array<object> = [];
  tipoContaSelected = '';

  dataTable: any;

  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };
  userLogged: any;

  colaboradorType = '';

  colaboradorLogged = '';

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorPagamentoService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      /*if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO){
        ($("#btnSalvarPagamento") as any).hide();
      }*/
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && this.userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO) {
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.colaboradorType = 'pre-admissao';
        this.getDataGrid();
      } else
        this.colaboradorType = 'rh';
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtDataAdmissao") as any).datepicker({
      autoclose: true
    });
    ($("#txtSalario") as any).maskMoney({ prefix: 'R$ ', thousands: '.', decimal: ',' });
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    this.colaboradorService.BuscarTudoBanco().subscribe(
      res => {
        this.bancoList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoTipoConta().subscribe(
      res => {
        this.tipoContaList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selBanco') {
      this.bancoSelected = event.target.value;
      $('#selBanco').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selTipoConta') {
      this.tipoContaSelected = event.target.value;
      $('#selTipoConta').removeClass('element-invalid');
    }
  }

  validarCampos() {
    const self = this;
    ($("#formularioPagamento") as any).validate({
      // Define as regras
      rules: {
        txtNomeColaboradorPagamento: {
          required: true,
          colaboradorCheck: true,
        },
        selBanco: {
          required: true
        },
        txtAgencia: {
          required: true
        },
        txtConta: {
          required: true
        },
        selTipoConta: {
          required: true
        },
        txtChavePix: {
          required: true
        },
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeColaboradorPagamento: {
          required: "Informe o Nome do Colaborador"
        },
        selBanco: {
          required: "Selecione o Banco"
        },
        txtAgencia: {
          required: "Informe a Agência"
        },
        txtConta: {
          required: "Informe a Conta"
        },
        selTipoConta: {
          required: "Selecione o Tipo de Conta"
        },
        txtChavePix: {
          required: "Informe a Chave Pix"
        },
      },
    });
    $.validator.addMethod("colaboradorCheck", function (value, element) {
      return self.colaboradorSelected ? true : false;
    }, 'Selecione o colaborador');
    ($("#formularioPagamento") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    if (this.colaboradorType == 'pre-admissao') {
      this.colaboradorService.BuscarPagamentoColaboradorPreAdmissaoPorId(this.colaboradorLogged).subscribe(
        async res => {
          // Seta modo edição
          this.isEdit = true;

          this.pagamentoIdSelected = res.Id;
          this.bancoSelected = res.BancoId;
          this.tipoContaSelected = res.ContaBancariaTipoId;
          this.empresaIdSelected = res.Colaborador.EmpresaId;
          this.colaboradorSelected = res.ColaboradorId;
          $('#txtEmpresaPagamento').val(res.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorPagamento').val(res.Colaborador.Nome);
          $('#txtAgencia').val(res.Agencia);
          $('#txtConta').val(res.Conta);
          $('#txtChavePix').val(res.ChavePix);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.moduleService.pesquisarPreAdmissaoPorUsuarioId(data,this.userLogged.UsuarioColaboradorId).subscribe(
        res => {
          this.gridData = res.map(val =>
            ({
              id: val.Id,
              nomeColaborador: val.Colaborador.Nome,
              nomeEmpresa: val.Colaborador.Empresa.Nome,
              banco: val.Banco.Nome,
              agencia: val.Agencia,
              conta: val.Conta,
              dataCadastro: val.DataCadastro,
              chavePix: val.ChavePix,
              ...val
            })
          ).sort(this.utilService.dynamicSort('nomeColaborador'));
          this.loadDataTable(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  clearInputvalues(type = '') {
    if (type == 'salvar') {
      $('#selBanco').removeClass('element-invalid');
      $('#selTipoConta').removeClass('element-invalid');
      $("#formularioPagamento").valid();
    }
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos

    this.bancoSelected = '';
    this.tipoContaSelected = '';
    this.colaboradorSelected = '';
    this.empresaIdSelected = '';
    $('#txtEmpresaPagamento').val('');
    $('#txtNomeColaboradorPagamento').val('');

    $('#txtAgencia').val('');
    $('#txtConta').val('');
    $('#txtChavePix').val('');
  }

  getValuesFromInput() {
    return {
      ColaboradorId: this.colaboradorSelected,
      BancoId: this.bancoSelected,
      Agencia: $('#txtAgencia').val().toString().trim(),
      Conta: $('#txtConta').val().toString().trim(),
      ChavePix: $('#txtChavePix').val().toString(),
      ContaBancariaTipoId: this.tipoContaSelected,
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;
    if (idModal == 'modalColaboradorPagamento') {
      if ($('#txtNomeColaboradorPagamento').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPreAdmissaoPorNomePorUsuarioId($('#txtNomeColaboradorPagamento').val().toString(), this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar(concluir: boolean) {
    if (!this.bancoSelected || !this.tipoContaSelected) {
      ($("#formularioPagamento") as any).valid();
      if (!this.bancoSelected) {
        $('#selBanco').addClass('element-invalid');
      } else {
        $('#selBanco').removeClass('element-invalid');
      }
      if (!this.tipoContaSelected) {
        $('#selTipoConta').addClass('element-invalid');
      } else {
        $('#selTipoConta').removeClass('element-invalid');
      }
      if (!this.colaboradorSelected) {
        $('#txtNomeColaboradorPagamento').addClass('element-invalid');
      } else {
        $('#txtNomeColaboradorPagamento').removeClass('element-invalid');
      }
    } else {
      if (($("#formularioPagamento") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.pagamentoIdSelected;
          this.moduleService.editar(obj,concluir).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj,concluir).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }

  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    ;
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nomeColaborador"
        },
        {
          "data": "nomeEmpresa"
        },
        {
          "data": "banco"
        },
        {
          "data": "agencia"
        },
        {
          "data": "conta"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[6]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;
          self.pagamentoIdSelected = data.Id;

          self.bancoSelected = data.BancoId;
          self.tipoContaSelected = data.ContaBancariaTipoId;
          self.empresaIdSelected = data.Colaborador.EmpresaId;
          self.colaboradorSelected = data.ColaboradorId;
          $('#txtEmpresaPagamento').val(data.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorPagamento').val(data.Colaborador.Nome);
          $('#txtAgencia').val(data.Agencia);
          $('#txtConta').val(data.Conta);
          $('#txtChavePix').val(data.ChavePix);

          if (!self.bancoSelected) {
            $('#selBanco').addClass('element-invalid');
          } else {
            $('#selBanco').removeClass('element-invalid');
          }
          if (!self.tipoContaSelected) {
            $('#selTipoConta').addClass('element-invalid');
          } else {
            $('#selTipoConta').removeClass('element-invalid');
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          self.empresaIdSelected = data.Empresa.Id;
          $('#txtEmpresaPagamento').val(data.Empresa.Nome);
          $('#txtNomeColaboradorPagamento').val(data.nome);
          self.getSelectData();
          self.closeModal('modalColaboradorPagamento');
          ($("#formularioPagamento") as any).valid();
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });


  }

}
