import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthService } from './@core/@service/auth.service';
import { PerfilComponent } from './dashboard/body/perfil/perfil.component';

const routes: Routes = [
  // { path: '', redirectTo: 'Login', pathMatch: 'full' },
  { path: '', component: DashboardComponent, canActivate: [AuthService] },
  {
    path: 'Cadastro',
    component: DashboardComponent,
    canActivate: [AuthService],
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Aprovacao',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Importacao',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Lancamento',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Perfil',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Programacao',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Relatorio',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Solicitacao',
    component: DashboardComponent,
    children: [
      {
        path: '**', component: DashboardComponent,
        canActivate: [AuthService],
      }
    ]
  },
  {
    path: 'Home',
    component: DashboardComponent,
    canActivate: [AuthService],
  },
  {
    path: '**', redirectTo: 'Login', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
