import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificacaoService {

    routePrefix: string = 'Notificacao'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    buscarNotificacoes(userId = '', userType = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}?idUsuarioColaborador=${userId}&IdUsuarioColaboradorTipo=${userType}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    
    atualizarStatusNotificacao(idNotificacao = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AtualizarStatusLeituraAsync?idNotificacaoDetalhe=${idNotificacao}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    

}