import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { ImportacaoFeriasService } from 'src/app/@core/@service/importacao/ferias/ferias.service';
import Swal from 'sweetalert2';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';

declare var $;

@Component({
  selector: 'app-importacao-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.scss']
})

export class ImportacaoFeriasComponent implements OnInit {

  gridData = [];

  empresaGridData = [];

  colaboradorSelected = ''

  arquivoAnexoSelected = '';

  empresaIdSelected = '';

  mesSelected = '';
  mesList = [];

  typeImagesAccepted = ['text/plain'];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;


  constructor(
    private utilService: UtilsService,
    private moduleService: ImportacaoFeriasService,
    private empresaService: CadastroEmpresaService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
    this.mesList = this.utilService.getMesList();
    this.loadDataTable([]);
    //Date picker
    ($("#txtData") as any).datepicker({
      autoclose: true
    });
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;
      $('#selMes').removeClass('element-invalid');
    }
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      if (this.typeImagesAccepted.includes(file.type)) {
        this.arquivoAnexoSelected = event.target.result;
        this.arquivoAnexoSelected = this.arquivoAnexoSelected.replace('data:text/plain;base64,', '');
        $('#txtArquivo').val(file.name);
        $('#txtArquivo').removeClass('element-invalid');
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  clearInputvalues(type = '') {
    if (type == 'importar') {
      ($("#formulario") as any).valid();
      $('#selMes').removeClass('element-invalid');
      $('#txtArquivo').removeClass('element-invalid');
      $('#txtNomeEmpresa').removeClass('element-invalid');
    }
    this.arquivoAnexoSelected = '';
    this.empresaIdSelected = '';
    $('#txtNomeEmpresa').val('');
    $('#tableResult').css("visibility", "hidden");
  }

  getValuesFromInput() {
    return {
      EmpresaId: this.empresaIdSelected,
      DocumentoBase64: this.arquivoAnexoSelected,
    }
  }

  importar() {
    if (!this.arquivoAnexoSelected || !this.empresaIdSelected) {
      ($("#formulario") as any).valid()
      if (!this.empresaIdSelected) {
        $('#txtNomeEmpresa').addClass('element-invalid');
      }
      if (!this.arquivoAnexoSelected) {
        $('#txtArquivo').addClass('element-invalid');
      }
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.importar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              const inputBkp = {
                empresa: $('#txtNomeEmpresa').val().toString() || '',
              };
              this.clearInputvalues('importar');
              if (res.length > 0) {
                this.gridData = res.map(val =>
                  ({
                    id: val.Id,
                    matricula: val.Matricula || '',
                    empresa: inputBkp.empresa || '',
                    erro: val.LogErro || '',
                    dataConclusao: val.DataCadastro,
                    ...val
                  })
                );
                this.loadDataTable(this.gridData);
                $('#tableResult').css("visibility", "unset");
              } else {
                this.loadDataTable([]);
                $('#tableResult').css("visibility", "hidden");
              }
              Swal.fire({
                position: 'top',
                icon: 'success',
                title: 'Arquivo importado!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          },
          err => {
            console.log(err);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Erro ao importar arquivo!',
              showConfirmButton: false,
              timer: 2000
            });
          }
        );
      }
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);

    if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNome($('#txtNomeEmpresa').val().toString()).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cnpj: val.CNPJ,
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": 'erro', "orderable": false, "render": function (data, type, row) {
            return `<div style="color: red;">${data}</div>`;
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.empresaIdSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
