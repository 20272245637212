<form id="formularioAnexarDoc">
  <div style="margin-left: 0px;">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeColaboradorAnexarDocumento"
                  name="txtNomeColaboradorAnexarDocumento" placeholder="Nome do Colaborador">
                <span class="input-group-addon"><a style="cursor: pointer;"
                    (click)="openModal('modalColaboradorAnexarDocumento')" title="Pesquisar colaborador"><i
                      class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <input type="text" id="txtEmpresaAnexarDocumento" name="txtEmpresaAnexarDocumento" class="form-control"
                readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px">
    <div class="box box-primary">
      <div class="box-body">
        <div class="document-list">
          <div *ngFor="let item of documentosList" class="document-tipo" style="padding-bottom: 50px">
            <div class="form-group">
              <label>{{ item.nome }}</label>
              <div class="input-group date">
                <input disabled style="background-color: white" type="text" class="form-control pull-right"
                  id="txtNome-{{ item.id }}" name="txtNome-{{ item.id }}" placeholder="Escolher Arquivo" />
                <div class="input-group-addon" style="padding: 0px">
                  <div [ngClass]="{ 'input-disable': !colaboradorSelected }" class="fa fa-upload btn btn-file">
                    <input class="ignore" type="file" name="inputFoto" #imageInput type="file"
                      (change)="processFile(imageInput, item.Id)" />
                  </div>
                </div>
              </div>
              <ngb-accordion *ngIf="item.documents.length">
                <ngb-panel title="Documentos">
                  <ng-template ngbPanelC ontent>
                    <div *ngFor="let document of item.documents" class="document-item">
                      <div *ngIf="(document.DocumentoAdmissional.PermiteVisualizar) || (colaboradorType == 'rh')">
                        <span class="document-tittle">{{
                          document.DocumentoNome
                          }}</span>
                        <button *ngIf="document.Id" type="button" (click)="downloadDocumento(document)">
                          <a class="fa fa-cloud-download" title="Download Documento"></a>
                        </button>
                        <button *ngIf="document.Id && colaboradorType == 'rh'" type="button" class="remove-icon"
                          (click)="abrirDocumento(document)">
                          <a class="fa fa-eye" title="Visualizar Documento"></a>
                        </button>
                        <button *ngIf="document.Id && colaboradorType == 'rh' && usuarioType != 'user'" type="button"
                          class="remove-icon" (click)="removerDocumento(document)">
                          <a class="fa fa-trash" title="Remover Documento"></a>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input name="Salvar" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar()"
                class="btn btn-info pull-right btn-margin" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</form>

<div class="modal fade" id="modalColaboradorAnexarDocumento" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Colaborador</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Status</th>
              <th>Data Admissão</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
