import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class AprovacaoAlteracaoCadastralService {

    routePrefix: string = 'AlteracaoCadastral'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarSolicitacaoAlteracao`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovar(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/ReprovarSolicitacaoAlteracao?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovar(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AprovarSolicitacaoAlteracao?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarStatus(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}Solicitacao/BuscarTudoStatusSolicitacao`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoSolicitacaoAlteracao(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarFotoSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoCarteiraHabilitacao(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarCarteiraHabilitacaoSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoComprovanteResidencia(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarComprovanteResidenciaSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}