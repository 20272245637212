<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Informe de Rendimentos
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Informe de Rendimentos</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                      placeholder="Nome do Colaborador">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Ano</label>
                  <input type="text" mask="0000" class="form-control" id="txtAno" name="txtAno" placeholder="yyyy">
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                  <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Nome do Colaborador</th>
                  <th style="width: 15% !important">Matrícula do Colaborador</th>
                  <th style="width: 15% !important">Empresa</th>
                  <th style="width: 15% !important">Ano</th>
                  <th style="width: 15% !important">Data da Importação</th>
                  <th style="width: 5% !important;"></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 95%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Relatório de Informe de Rendimentos</b></h4>
      </div>
      <div class="modal-header" style="padding-left: 30px;">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-4">
            <b>Estabelecimento:</b> {{modalRelatorioData.empresa}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Ministério:</b> {{modalRelatorioData.Ministerio}}
          </div>
          <div class="col-md-3">
            <b>Documento:</b> {{modalRelatorioData.Documento}}
          </div>
          <div class="col-md-3">
            <b>Secretária:</b> {{modalRelatorioData.Secretaria}}
          </div>
          <div class="col-md-3">
            <b>Exercício:</b> {{modalRelatorioData.Exercicio}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Ano Calendário:</b> {{modalRelatorioData.AnoCalendario}}
          </div>
          <div class="col-md-3">
            <b>Fonte Pagadora:</b> {{modalRelatorioData.FontePagadora}}
          </div>
          <div class="col-md-3">
            <b>Empresa:</b> {{modalRelatorioData.NomeEmpresa}}
          </div>
          <div class="col-md-3">
            <b>CNPJ:</b> {{modalRelatorioData.CNPJ}}
          </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-3">
            <b>Dados Pessoa Física:</b> {{modalRelatorioData.DadosPessoaFisica}}
          </div>
          <div class="col-md-3">
            <b>CPF:</b> {{modalRelatorioData.CPF}}
          </div>
          <div class="col-md-3">
            <b>Nome:</b> {{modalRelatorioData.Nome}}
          </div>
          <div class="col-md-3">
            <b>Natureza Rendimento:</b> {{modalRelatorioData.Natureza}}
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
          <b>Tipo de Rendimento:</b> {{modalRelatorioData.TipoRendimento1}}
          <b style="padding-left: 5%;">Moeda: </b>{{modalRelatorioData.MoedaRendimento1}}
        </div>
        <div style="overflow-y: auto;">
          <div style="overflow-y: auto;">
            <div style="width: 99%; padding-left: 15px;">
              <table #gridModalRelatorio1 style="width: 100% !important; cursor: pointer;"
                class="hover table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Valor</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
          <b>Tipo de Rendimento:</b> {{modalRelatorioData.TipoRendimento2}}
          <b style="padding-left: 5%;">Moeda: </b>{{modalRelatorioData.MoedaRendimento2}}
        </div>
        <div style="overflow-y: auto;">
          <div style="overflow-y: auto;">
            <div style="width: 99%; padding-left: 15px;">
              <table #gridModalRelatorio2 style="width: 100% !important; cursor: pointer;"
                class="hover table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Valor</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
          <b>Tipo de Rendimento:</b> {{modalRelatorioData.TipoRendimento3}}
          <b style="padding-left: 5%;">Moeda: </b>{{modalRelatorioData.MoedaRendimento3}}
        </div>
        <div style="overflow-y: auto;">
          <div style="overflow-y: auto;">
            <div style="width: 99%; padding-left: 15px;">
              <table #gridModalRelatorio3 style="width: 100% !important; cursor: pointer;"
                class="hover table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Valor</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-header" style="padding-left: 30px;">
        <div class="row" style="margin-bottom: 10px;">
            <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
              <b>Tipo de Rendimento:</b> {{modalRelatorioData.TipoRendimento4}}
              <b style="padding-left: 5%;">Moeda: </b>{{modalRelatorioData.MoedaRendimento4}}
            </div>
        </div>
        <div class="row" style="margin-bottom: 10px;">
          <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
            <b>Tipo de Rendimento:</b> {{modalRelatorioData.InformacoesComplementares}}
          </div>
      </div>
      <div class="row" style="margin-bottom: 10px;">
        <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
          <b>Tipo de Rendimento:</b> {{modalRelatorioData.DadosResponsavel}}
        </div>
    </div>
        <div class="row" style="margin-bottom: 10px;">
          <div style="font-size: 17px; padding-left: 15px; padding-top: 20px; padding-bottom: 10px;">
            <b>Processo:</b> {{modalRelatorioData.Processo}}
            <b style="padding-left: 5%;">Natureza Rendimento: </b>{{modalRelatorioData.NaturezaRendimento}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>