import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError, take } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImportacaoDivergenciaService {

    routePrefix: string = 'Divergencia'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(colaboradorId = '', periodoInicial = '', periodoFinal = '', empresaId = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorColaboradorIdData?colaboradorId=${colaboradorId}&dataInicio=${periodoInicial}&dataFim=${periodoFinal}&empresaId=${empresaId}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    Exportar(colaboradorId = '', periodoInicial = '', periodoFinal = '', empresaId = ''): Observable<any> {
      return this.httpClient
      .get<Array<any>>(
        `${environment.urlBase}${this.routePrefix}/Exportar${this.routePrefix}PorColaboradorIdData?colaboradorId=${colaboradorId}&dataInicio=${periodoInicial}&dataFim=${periodoFinal}&empresaId=${empresaId}`,
        {
          //@ts-ignore
          responseType: "arraybuffer",
        })
       .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    pesquisarImportacaoPorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarDivergenciaNaoJustificada(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}NaoJustificadaPorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    importar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Importar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Aprovar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarDivergenciaPorColaboradorId(colaboradorId = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorColaboradorId?id=${colaboradorId}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    salvar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/SalvarJustificativaDivergencia`, data)
            .pipe(
                take(1),
                retry(1),
                catchError(this.utilsService.handleError));
    }
    remover(data = {}): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/Remover${this.routePrefix}`, data)
          .pipe(
              take(1),
              retry(1),
              catchError(this.utilsService.handleError));
  }
  excluirPorMultiplosIds(ids = []): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.urlBase}${this.routePrefix}/ExcluirDivergenciasSelecionadas`,
        ids
      )
      .pipe(retry(1), catchError(this.utilsService.handleError));
  }
}
