import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoFeriasService } from 'src/app/@core/@service/solicitacao/ferias/ferias.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import { ImportacaoFeriasService } from 'src/app/@core/@service/importacao/ferias/ferias.service';
import { SolicitacaoStatusEnum, UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

declare var $;

@Component({
  selector: 'app-solicitacao-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.scss']
})

export class SolicitacaoFeriasComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  colaboradorList: Array<any> = [];

  gestorSelected = '';

  gestorId = '';

  operadoraVTSelected = ''
  operadoraVTList: Array<any> = [];
  linhaVTSelected = ''
  linhaVTList: Array<any> = [];
  utilizacaoSelected = ''
  utilizacaoList: Array<any> = [];
  situacaoSelected = ''
  situacaoList: Array<any> = [];

  dataTable: any;
  currUser: any;
  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoFeriasService,
    private colaboradorService: CadastroColaboradorService,
    private feriasService: ImportacaoFeriasService,
  ) { }

  ngOnInit() {
    this.currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
      this.gestorId = this.currUser ? this.currUser.UsuarioColaboradorId : '';
    }
    this.getInitialData();
  }

  getInitialData() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService.buscarColaboradorPorGestorId(this.gestorSelected).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
        ({
          Id: val.Id,
          Description: val.Nome
        })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );

        }else{
          this.colaboradorService.buscarColaboradorPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
            res => {
              this.colaboradorList = res.map(val =>
              ({
                Id: val.Id,
                Description: val.Nome
              })
              ).sort(this.utilService.dynamicSort('Description'));
              this.colaboradorList = this.colaboradorList.filter(a => {
                return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
              }, Object.create(null));
            },
            err => {
              console.log(err);
            }
          );

        }    this.loadDataTable([]);
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectColaborador') {
      this.colaboradorSelected = event.target.value;
      $('#selectColaborador').removeClass('element-invalid');
    }
  }

  getStatusDescricao(status) {
    if (status == SolicitacaoStatusEnum.EM_ANALISE) {
      return 'Em Análise';
    } else if (status == SolicitacaoStatusEnum.APROVADO) {
      return 'Aprovado';
    } else if (status == SolicitacaoStatusEnum.REPROVADO) {
      return 'Reprovado';
    } else {
      return '';
    }
  }

  mapGrid(data) {
    const arrData = [];
    data.Periodo.forEach(element => {
      if (element.Concessao.length > 0) {
        if (element.Concessao.length == 1) {
          arrData.push(
            {
              id: element.Id,
              colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
              matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
              empresa: data.Empresa ? data.Empresa.Nome : '-',
              inicioPeriodo: element.InicioPeriodo || '-',
              fimPeriodo: element.FimPeriodo || '-',
              situacao: element.Situacao || '-',
              diasDireito: element.DiasDireito || '-',
              diasConcedidos: element.DiasConcedidos || '-',
              diasProgramados: element.DiasProgramados || '-',
              inicioFerias: element.Concessao[0].InicioFerias || '-',
              diasGozo: element.Concessao[0].DiasGozo || '-',
              diasAbono: element.Concessao[0].DiasAbono || '-',
              diasDeLicenca: element.Concessao[0].DiasLicenca || '-',
              saldo: element.Saldo || '-',
              status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
              dataSolicitacao: element.DataSolicitacao,
              dataConclusao: element.DataConclusao,
              isConcessao: false,
              ...element
            });
        } else {
          element.Concessao.forEach((element2, index) => {
            if (index == 0) {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
                  matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
                  empresa: data.Empresa ? data.Empresa.Nome : '-',
                  inicioPeriodo: element.InicioPeriodo || '-',
                  fimPeriodo: element.FimPeriodo || '-',
                  situacao: element.Situacao || '-',
                  diasDireito: element.DiasDireito || '-',
                  diasConcedidos: element.DiasConcedidos || '-',
                  diasProgramados: element.DiasProgramados || '-',
                  inicioFerias: element.Concessao[index].InicioFerias || '-',
                  diasGozo: element.Concessao[index].DiasGozo || '-',
                  diasAbono: element.Concessao[index].DiasAbono || '-',
                  diasDeLicenca: element.Concessao[index].DiasLicenca || '-',
                  saldo: element.Saldo || '-',
                  status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
                  dataSolicitacao: element.DataSolicitacao,
                  dataConclusao: element.DataConclusao,
                  isConcessao: false,
                  ...element
                });
            } else {
              arrData.push(
                {
                  id: element.Id,
                  colaborador: '',
                  matricula: '',
                  empresa: '',
                  inicioPeriodo: '',
                  fimPeriodo: '',
                  situacao: '',
                  diasDireito: '',
                  diasConcedidos: '',
                  diasProgramados: '',
                  inicioFerias: element2.InicioFerias || '-',
                  diasGozo: element2.DiasGozo || '-',
                  diasAbono: element2.DiasAbono || '-',
                  diasDeLicenca: element2.DiasLicenca || '-',
                  saldo: '',
                  status: '',
                  dataSolicitacao: '',
                  dataConclusao: '',
                  isConcessao: true,
                  ...element
                });
            }
          });
        }
      } else {
        arrData.push(
          {
            id: element.Id,
            colaborador: data.Colaborador ? data.Colaborador.Nome : '-',
            matricula: data.Colaborador ? data.Colaborador.MatriculaInterna : '-',
            empresa: data.Empresa ? data.Empresa.Nome : '-',
            inicioPeriodo: element.InicioPeriodo || '-',
            fimPeriodo: element.FimPeriodo || '-',
            situacao: element.Situacao || '-',
            diasDireito: element.DiasDireito || '-',
            diasConcedidos: element.DiasConcedidos || '-',
            diasProgramados: element.DiasProgramados || '-',
            inicioFerias: '-',
            diasGozo: '-',
            diasAbono: '-',
            diasDeLicenca: '-',
            saldo: element.Saldo || '-',
            status: element.SolicitacaoStatus ? element.SolicitacaoStatus.Nome : this.getStatusDescricao(element.SolicitacaoStatusId),
            dataSolicitacao: element.DataSolicitacao,
            dataConclusao: element.DataConclusao,
            isConcessao: false,
            ...element
          });
      }
    });
    return arrData;
  }

  pesquisar() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    if (this.colaboradorSelected)
      this.getDataGrid();
    else
      $('#selectColaborador').addClass('element-invalid');
    }else{
      this.getDataGrid();
    }
    }

  clearInputvalues() {
    this.colaboradorSelected = '';
  }

  getDataGrid() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.feriasService.pesquisarFeriasPorColaboradorId(this.colaboradorSelected ? this.colaboradorSelected : this.gestorSelected).subscribe(
      res => {
        let grid = [];
        res.forEach(element => {
          grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
        });
        this.loadDataTable(grid);
      },
      err => {
        console.log(err);
      }
    );
    }else{
      if(this.colaboradorSelected){
      this.feriasService.pesquisarFeriasPorColaboradorId(this.colaboradorSelected).subscribe(
        res => {
          let grid = [];
          res.forEach(element => {
            grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
          });
          this.loadDataTable(grid);
        },
        err => {
          console.log(err);
        }
      );
  }else{
    this.feriasService.pesquisarFeriasPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
      res => {
        let grid = [];
        res.forEach(element => {
          grid = grid.length > 0 ? grid.concat(this.mapGrid(element)) : this.mapGrid(element);
        });
        this.loadDataTable(grid);
      },
      err => {
        console.log(err);
      }
    );
  }
}
}

  solicitar(data) {
    Swal.fire({
      title: 'Solicitação de Férias',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off',
        'aria-label': 'Informe o Saldo',
      },
      inputLabel: 'Saldo',
      inputPlaceholder: 'Informe o saldo...',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.solicitar(
          {
            Id: data.id,
            Saldo: result.value,
          }).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.getDataGrid();
                Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Férias solicitada!',
                  showConfirmButton: false,
                  timer: 2000
                });
              }
            },
            err => {
              console.log(err);
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Ocorreu um erro ao solicitar férias!',
                showConfirmButton: false,
                timer: 2000
              });
            }
          );
      }
    });
  }


  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "colaborador"
        },
        {
          "data": "matricula"
        },
        {
          "data": "empresa"
        },
        {
          "data": "inicioPeriodo"
        },
        {
          "data": "fimPeriodo"
        },
        {
          "data": "situacao"
        },
        {
          "data": "diasDireito"
        },
        {
          "data": "diasConcedidos"
        },
        {
          "data": "diasProgramados"
        },
        {
          "data": "inicioFerias"
        },
        {
          "data": "diasAbono"
        },
        {
          "data": "diasGozo"
        },
        {
          "data": "diasDeLicenca"
        },
        {
          "data": "saldo"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": 'dataConclusao', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao)
              return data ? new Date(data).toLocaleDateString('pt-br') : '-';
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            if (!row.isConcessao) {
              if (!row.SolicitacaoStatusId || row.SolicitacaoStatusId == 0) {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-plus" style="color: rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Solicitar"> </button></div>';
              } else {
                return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-plus" style="pointer-events: none; color: #9c9b9b; border: 0px; background-color: transparent;" title="Solicitar"> </button></div>';
              }
            }
            return '';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[17]).on("click", function (e) {
          if (!data.isConcessao) {
            if (!data.SolicitacaoStatusId || data.SolicitacaoStatusId == 0) {
              //Solicitar
              self.solicitar(data);
            }
          }
        });
      },
      "aaSorting": [],
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": false,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
