import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class CadastroEmpresaService {

    routePrefix: string = 'Empresa'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorUsuarioId(usuarioId): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorUsuarioId?usuarioId=${usuarioId}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    pesquisarPorLinhaVTId(linhaVTId): Observable<any> {
      return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorLinhaVTId?linhaVTId=${linhaVTId}`)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }
    pesquisarPorTurnoId(turnoId): Observable<any> {
      return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorTurnoId?turnoId=${turnoId}`)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    pesquisarPorId(id): Observable<any> {
      return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorId?id=${id}`)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

   adicionar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Adicionar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Editar${this.routePrefix}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    remover(data): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}?id=${data.id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFuncionalidades(): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}Funcionalidade/Buscar${this.routePrefix}Funcionalidade`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarEmpresaPorNome(nome = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorNome?Nome=${nome}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    buscarEmpresaPorNomeUsuarioId(nome = '',usuarioId): Observable<any> {
      return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}PorNomeUsuarioId?Nome=${nome}&usuarioId=${usuarioId}`)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    adicionarEmpresaFuncionalidade(data = []): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}Funcionalidade/Adicionar${this.routePrefix}Funcionalidade`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editarEmpresaFuncionalidade(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}Funcionalidade/Editar${this.routePrefix}Funcionalidade`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    adicionarEmpresaDocumento(data = []): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}DocumentoAdmissional/Adicionar${this.routePrefix}DocumentoAdmissional`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarEmpresaMatrizPorNome(nome = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/Buscar${this.routePrefix}MatrizPorNome?nomeEmpresa=${nome}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarEmpresaMatrizFilialPorEmpresaId(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarMatrizFilialPorEmpresaId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}
