<form id="formularioDocumentacao">
  <!-- <div class="" style="text-align: right; margin-bottom: 10px;">
    <input name="Concluir cadastro" value="Concluir cadastro" (click)="salvar(true)"  readonly="readonly" class="btn btn-info" />
</div> -->
  <div style="margin-left: 0px;" [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'block'">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeColaboradorDocumentacao" name="txtNomeColaboradorDocumentacao"
                  placeholder="Nome do Colaborador">
                <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaboradorDocumentacao')"
                    title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <input type="text" value="" id="txtEmpresaDocumentacao" name="txtEmpresaDocumentacao" class="form-control" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px;">
    <div class="box box-primary">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>RG</label>
              <input type="text" class="form-control semAcentos" id="txtRG" name="txtRG" placeholder="RG">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Orgão Emissor RG</label>
              <input type="text" id="txtOrgaoEmissorRG" name="txtOrgaoEmissorRG" class="form-control"
                placeholder="Orgão Emissor RG">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF Emissão RG</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                IdSelect="selectEstadoEmissaoRG" [Value]="estadoEmissaoRGSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data de Emissão RG</label>
              <div class="input-group date">
                <input type="text" class="form-control pull-right" mask="00/00/0000" id="txtDataEmissaoRG"
                  name="txtDataEmissaoRG" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>RIC</label>
              <input type="text" class="form-control" id="txtRIC" name="txtRIC" placeholder="RIC">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF Emissão RIC</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                IdSelect="selectEstadoEmissaoRIC" [Value]="estadoEmissaoRICSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Cidade Emissão RIC</label>
              <select-component (changeValue)="changeSelectValues($event)"
                [Disabled]="!estadoEmissaoRICSelected ? true : false" [Data]="cidadesEmissaoRICList"
                IdSelect="selectCidadeEmissaoRIC" [Value]="cidadeEmissaoRICSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Orgão Emissor RIC</label>
              <input type="text" id="txtOrgaoEmissorRIC" name="txtOrgaoEmissorRIC" class="form-control pull-right"
                placeholder="Orgão Emissor RIC">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Data Expedição RIC</label>
              <div class="input-group date">
                <input type="text" class="form-control pull-right" mask="00/00/0000" id="txtDataExpRIC"
                  name="txtDataExpRIC" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Cartão Nacional de Saúde</label>
              <input type="text" id="txtCartaoSaude" name="txtCartaoSaude" class="form-control pull-right"
                placeholder="Cartão Nacional de Saúde">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Número Reservista</label>
              <input type="text" id="txtNumeroReservista" name="txtNumeroReservista" class="form-control pull-right"
                placeholder="Número Reservista">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Titulo Eleitor</label>
              <input type="text" id="txtTituloEleitor" name="txtTituloEleitor" class="form-control pull-right"
                placeholder="Titulo Eleitor">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Zona Eleitoral</label>
              <input type="text" id="txtZonaEleitoral" name="txtZonaEleitoral" class="form-control pull-right"
                placeholder="Zona Eleitoral">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Seção Eleitoral</label>
              <input type="text" id="txtSecaoEleitoral" name="txtSecaoEleitoral" class="form-control pull-right"
                placeholder="Seção Eleitoral">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF Eleitoral</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                IdSelect="selectEstadoEleitoral" [Value]="estadoEleitoralSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Cidade Eleitoral</label>
              <select-component (changeValue)="changeSelectValues($event)"
                [Disabled]="!estadoEleitoralSelected ? true : false" [Data]="cidadesEleitoralList"
                IdSelect="selectCidadeEleitoral" [Value]="cidadeEleitoralSelected">
              </select-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Carteira Habilitação</label>
              <input type="text" id="txtCarteiraHabilitacao" name="txtCarteiraHabilitacao"
                class="form-control pull-right" placeholder="Carteira Habilitação">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Categoria</label>
              <input type="text" id="txtCategoria" name="txtCategoria" class="form-control pull-right"
                placeholder="Categoria">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Vencimento Habilitação</label>
              <div class="input-group date">
                <input type="text" class="form-control pull-right" mask="00/00/0000" id="txtVencimentoHabilitacao"
                  name="txtVencimentoHabilitacao" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Número CTPS</label>
              <input type="text" id="txtNumeroCTPS" name="txtNumeroCTPS" class="form-control pull-right"
                placeholder="Número CTPS">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Série CTPS</label>
              <input type="text" id="txtSerieCTPS" name="txtSerieCTPS" class="form-control pull-right"
                placeholder="Série CTPS">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF CTPS</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList"
                IdSelect="selectEstadoCTPS" [Value]="estadoCTPSSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data Emissão CTPS</label>
              <div class="input-group date">
                <input type="text" class="form-control pull-right" mask="00/00/0000" id="txtDataEmissaoCTPS"
                  name="txtDataEmissaoCTPS" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>PIS NIT NIS</label>
              <input type="text" id="txtPIS" name="txtPIS" class="form-control pull-right" placeholder="PIS NIT NIS">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Data Emissão PIS NIT NIS</label>
              <div class="input-group date">
                <input type="text" class="form-control pull-right" mask="00/00/0000" id="txtDataEmissaoPIS"
                  name="txtDataEmissaoPIS" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
             <td align="left"><button  [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'inline-flex'" type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button"  [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'inline-flex'" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                class="btn btn-primary btn-margin" />
              <input name="Salvar" id="btnSalvarDocumentacao" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar(false)"
                class="btn btn-info pull-right btn-margin" />
              <td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</form>
<div class="box box-body" [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'block'" >
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid tyle="width: 100%; !important" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome do Colaborador</th>
            <th>Nome da Empresa</th>
            <th>RG</th>
            <th>RIC</th>
            <th>Cartão Nacional de Saúde</th>
            <th>Reservista</th>
            <th>Título Eleitor</th>
            <th>Habilitação</th>
            <th>CTPS</th>
            <th>PIS</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalColaboradorDocumentacao" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Colaborador</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
