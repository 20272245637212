<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Dependente
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Cadastro</li>
      <li class="active">Dependente</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <div class="box box-primary">
      <form id="formulario">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome do Dependente">
              </div>
              <div class="form-group">
                <label>Nome da Mãe</label>
                <input type="text" class="form-control" id="txtNomeMae" name="txtNomeMae" placeholder="Nome da Mãe">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>CPF</label>
                <input type="text" mask="000.000.000-00" class="form-control" id="txtCPFDependente"
                  name="txtCPFDependente" placeholder="CPF">
              </div>
              <div class="form-group">
                <label>Grau de Dependência</label>
                <select-component [Data]="grausData" IdSelect="selGrau" (changeValue)="changeSelectValues($event)"
                  [IsRequired]="true" [Value]="grauSelected">
                </select-component>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Sexo</label>
                <select-component [Data]="sexosData" IdSelect="selSexo" (changeValue)="changeSelectValues($event)"
                  [IsRequired]="true" [Value]="sexoSelected">
                </select-component>

              </div>
              <div class="form-group">
                <label>Anexar Documento</label>
                <div class="input-group date">
                  <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                    id="txtFoto" name="txtFoto" placeholder="Escolher Arquivo">
                  <div class="input-group-addon" style="padding: 0px;">
                    <div class="fa fa-upload btn btn-file">
                      <input class="ignore" type="file" name="inputFoto" #imageInput type="file"
                        (change)="processFile(imageInput)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Data de Nascimento</label>
                <div class="input-group date">
                  <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right" id="txtData"
                    name="txtData" placeholder="dd/mm/yyyy">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="colaboradorType == 'rh'">
                <label>Nome do Titular</label>
                <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                <div class="input-group">
                  <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                    placeholder="Nome do Colaborador">
                  <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalTitular')"
                      title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-footer">
          <table border=0 width="100%">
            <tr width="100%">
              <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
              </td>
              <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
              </td>
              <td align="right">
                <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                  class="btn btn-primary btn-margin" />
                <input id="Salvar" name="Salvar" value="Salvar" readonly="readonly" (click)="salvar()"
                  class="btn btn-info pull-right btn-margin" />
              </td>
            </tr>
          </table>
        </div>

      </form>
    </div>

    <div class="box box-body">
      <div style="overflow-y: auto;">
        <div style="width: 99%; padding-left: 15px;">
          <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th style="width: 15%">CPF</th>
                <th>Sexo</th>
                <th style="width: 17%">Data de nascimento</th>
                <th style="width: 13%">Nome da mãe</th>
                <th style="width: 17%">Grau de dependência</th>
                <th style="width: 15%">Nome do titular</th>
                <th>Data de Cadastro</th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
                <th style="width: 5% !important;"></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </section>
  <div class="control-sidebar-bg"></div>
</div>
<div class="modal fade" id="modalTitular" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalTitular style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>