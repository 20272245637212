import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../../utils/utils';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CadastroColaboradorEstrangeiroService {

    routePrefix: string = 'ColaboradorEstrangeiro'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(data = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarEstrangeiroColaborador`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
    pesquisarPorUsuarioId(data = {}, usuarioId): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarEstrangeiroColaboradorPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    pesquisarPreAdmissao(data: any = {}): Observable<any> {
        return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarEstrangeiroColaboradorPreAdmissao`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPreAdmissaoPorUsuarioId(data: any = {}, usuarioId: number): Observable<any> {
      return this.httpClient.post<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarEstrangeiroColaboradorPreAdmissaoPorUsuarioId?usuarioId=${usuarioId}`, data)
          .pipe(
              retry(1),
              catchError(this.utilsService.handleError));
  }

    adicionar(data = {},concluir?:boolean): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AdicionarEstrangeiroColaborador?concluir=${concluir}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    editar(data = {},concluir?:boolean): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/EditarEstrangeiroColaborador?concluir=${concluir}`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    remover(data): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Excluir${this.routePrefix}?id=${data.id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarColaboradorEstrangeiro(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarTudoEstrangeiroColaborador`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }
}
