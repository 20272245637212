<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Divergência
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Divergência</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                      placeholder="Nome do Colaborador">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nome da Empresa</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeEmpresa" name="txtNomeEmpresa"
                      placeholder="Nome da Empresa">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalEmpresa')"
                        title="Pesquisar Empresa"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Período Inicial (Importação)</label>
                  <div class="input-group date">
                    <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                      id="txtDataInicial" name="txtDataInicial" placeholder="dd/mm/yyyy">
                    <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Período Final (Importação)</label>
                  <div class="input-group date">
                    <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                      id="txtDataFinal" name="txtDataFinal" placeholder="dd/mm/yyyy">
                    <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border=0 width="100%">
              <tr width="100%">
                <td align="left"><button type="reset" (click)="clearInputvalues()"
                    class="btn btn-default">Limpar</button>
                </td>
                <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
                </td>
                <td align="right">
                    <input
                      type="button"
                      value="Excluir"
                      (click)="exluir()"
                      class="btn btn-primary btn-margin"
                      style="margin-right: 20px"
                      *ngIf="itensSelecionadosParaExclusao.length > 0"
                    />
                  <input
                    type="button"
                    value="Exportar para Excel"
                    (click)="exportToExcel()"
                    class="btn btn-primary"
                    *ngIf="gridData.length"
                  />
                  <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th style="width: 15% !important">Nome do Colaborador</th>
                  <th style="width: 5% !important">Matrícula</th>
                  <th style="width: 15% !important">Empresa</th>
                  <th style="width: 15% !important">Motivo</th>
                  <th style="width: 10% !important">Data da Importação</th>
                  <th style="width: 10% !important">Tipo de Importação</th>
                  <th style="width: 10% !important">Ferramenta</th>
                  <th style="width: 10% !important">Data Ocorrência</th>
                  <th style="width: 15% !important">Ação</th>
                  <th style="width: 5% !important;"></th>
                  <th style="width: 5% !important">
                    <input
                      type="checkbox"
                      title="Selecionar Todos"
                      data-select-all=""
                      (change)="selecionarTodosParaExclusao($event)"
                    />
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 95%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Relatório de Divergência</b></h4>
      </div>
      <div class="modal-header">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-4">
            <b>Estabelecimento:</b> {{modalRelatorioData.empresa}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b>Matrícula:</b> {{modalRelatorioData.matricula}}
          </div>
          <div class="col-md-4">
            <b>Nome:</b> {{modalRelatorioData.colaborador}}
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalRelatorio style="width: 100% !important; cursor: pointer;"
            class="hover tableOperadora table table-bordered table-striped">
            <thead>
              <tr>
                <th>Dia da Semana</th>
                <th>Dia</th>
                <th>Horário 1</th>
                <th>Horário 2</th>
                <th>Horário 3</th>
                <th>Horário 4</th>
                <th>Início Jornada</th>
                <th>Termino Jornada</th>
                <th>Diferença Horas</th>
                <th>Descrição Divergência</th>
                <th>Justificativa Divergência</th>
                <th>Observações</th>
                <th>Ação Pendente</th>
                <th>Verificado</th>
              </tr>
            </thead>
          </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>
<div class="modal fade" id="modalEmpresa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Empresa</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalEmpresa style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cnpj</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
