import { Injectable, EventEmitter } from '@angular/core';
import { BeneficioComponent } from 'src/app/dashboard/body/cadastro/beneficio/beneficio.component';
import { EmpresaComponent } from 'src/app/dashboard/body/cadastro/empresa/empresa.component';
import { PerfilComponent } from 'src/app/dashboard/body/perfil/perfil.component';
import { TipoMaoDeObraComponent } from 'src/app/dashboard/body/cadastro/tipo-mao-de-obra/tipo-mao-de-obra.component';
import { MarmitexComponent } from 'src/app/dashboard/body/cadastro/marmitex/marmitex.component';
import { TipoDeRegistroComponent } from 'src/app/dashboard/body/cadastro/tipo-de-registro/tipo-de-registro.component';
import { MotivoHoraExtraComponent } from 'src/app/dashboard/body/cadastro/motivo-hora-extra/motivo-hora-extra.component';
import { JustificativaDeDivergenciaComponent } from 'src/app/dashboard/body/cadastro/justificativa-de-divergencia/justificativa-de-divergencia.component';
import { CategoriaSalarialComponent } from 'src/app/dashboard/body/cadastro/categoria-salarial/categoria-salarial.component';
import { UnidadeDeNegocioComponent } from 'src/app/dashboard/body/cadastro/unidade-de-negocio/unidade-de-negocio.component';
import { CentroDeCustoComponent } from 'src/app/dashboard/body/cadastro/centro-de-custo/centro-de-custo.component';
import { DocumentoAdmissionalComponent } from 'src/app/dashboard/body/cadastro/documento-admissional/documento-admissional.component';
import { DependenteComponent } from 'src/app/dashboard/body/cadastro/dependente/dependente.component';
import { TurnoComponent } from 'src/app/dashboard/body/cadastro/turno/turno.component';
import { OperadoraVTComponent } from 'src/app/dashboard/body/cadastro/operadora-vt/operadora-vt.component';
import { LinhaVTComponent } from 'src/app/dashboard/body/cadastro/linha-vt/linha-vt.component';
import { SindicatoComponent } from 'src/app/dashboard/body/cadastro/sindicato/sindicato.component';
import { UsuarioComponent } from 'src/app/dashboard/body/cadastro/usuario/usuario.component';
import { LotacaoComponent } from 'src/app/dashboard/body/cadastro/lotacao/lotacao.component';
import { CargoComponent } from 'src/app/dashboard/body/cadastro/cargo/cargo.component';
import { ColaboradorComponent } from 'src/app/dashboard/body/cadastro/colaborador/colaborador.component';
import { AprovacaoDesligamentoComponent } from 'src/app/dashboard/body/aprovacao/desligamento/desligamento.component';
import { PreAdmissaoComponent } from 'src/app/dashboard/body/cadastro/pre-admissao/pre-admissao.component';
import { SolicitacaoDesligamentoComponent } from 'src/app/dashboard/body/solicitacao/desligamento/desligamento.component';
import { SolicitacaoMovimentacaoContratualComponent } from 'src/app/dashboard/body/solicitacao/movimentacao-contratual/movimentacao-contratual.component';
import { SolicitacaoAlteracaoCadastralComponent } from 'src/app/dashboard/body/solicitacao/alteracao-cadastral/alteracao-cadastral.component';
import { SolicitacaoValeTransporteComponent } from 'src/app/dashboard/body/solicitacao/vale-transporte/vale-transporte.component';
import { SolicitacaoBeneficioComponent } from 'src/app/dashboard/body/solicitacao/beneficio/beneficio.component';
import { AprovacaoMovimentacaoContratualComponent } from 'src/app/dashboard/body/aprovacao/movimentacao-contratual/movimentacao-contratual.component';
import { AprovacaoAlteracaoCadastralComponent } from 'src/app/dashboard/body/aprovacao/alteracao-cadastral/alteracao-cadastral.component';
import { AprovacaoBeneficioComponent } from 'src/app/dashboard/body/aprovacao/beneficio/beneficio.component';
import { AprovacaoValeTransporteComponent } from 'src/app/dashboard/body/aprovacao/vale-transporte/vale-transporte.component';
import { AprovacaoAtestadoComponent } from 'src/app/dashboard/body/aprovacao/atestado/atestado.component';
import { LancamentoAtestadoComponent } from 'src/app/dashboard/body/lancamento/atestado/atestado.component';
import { ImportacaoCartaoPontoComponent } from 'src/app/dashboard/body/importacao/cartao-de-ponto/cartao-de-ponto.component';
import { RelatorioCartaoPontoComponent } from 'src/app/dashboard/body/relatorio/cartao-de-ponto/cartao-de-ponto.component';
import { ImportacaoDivergenciaComponent } from 'src/app/dashboard/body/importacao/divergencia/divergencia.component';
import { ImportacaoHoleriteComponent } from 'src/app/dashboard/body/importacao/holerite/holerite.component';
import { ImportacaoInformeDeRendimentosComponent } from 'src/app/dashboard/body/importacao/informe-de-rendimentos/informe-de-rendimentos.component';
import { ImportacaoFeriasComponent } from 'src/app/dashboard/body/importacao/ferias/ferias.component';
import { ImportacaoFeriasAvisoComponent } from 'src/app/dashboard/body/importacao/ferias-aviso/ferias-aviso.component';
import { ImportacaoFeriasReciboComponent } from 'src/app/dashboard/body/importacao/ferias-recibo/ferias-recibo.component';
import { ImportacaoBancoDeHorasComponent } from 'src/app/dashboard/body/importacao/banco-de-horas/banco-de-horas.component';
import { RelatorioDivergenciaComponent } from 'src/app/dashboard/body/relatorio/divergencia/divergencia.component';
import { RelatorioFeriasComponent } from 'src/app/dashboard/body/relatorio/ferias/ferias.component';
import { RelatorioBancoDeHorasComponent } from 'src/app/dashboard/body/relatorio/banco-de-horas/banco-de-horas.component';
import { BandeiraCartaoVTComponent } from 'src/app/dashboard/body/cadastro/bandeira-cartao-vt/bandeira-cartao-vt.component';
import { ImportacaoAbsenteismoComponent } from 'src/app/dashboard/body/importacao/absenteismo/absenteismo.component';
import { RelatorioAvisoFeriasComponent } from 'src/app/dashboard/body/relatorio/ferias-aviso/ferias-aviso.component';
import { RelatorioReciboFeriasComponent } from 'src/app/dashboard/body/relatorio/ferias-recibo/ferias-recibo.component';
import { RelatorioAbsenteismoComponent } from 'src/app/dashboard/body/relatorio/absenteismo/absenteismo.component';
import { AprovacaoCartaoPontoComponent } from 'src/app/dashboard/body/aprovacao/cartao-ponto/cartao-ponto.component';
import { RelatorioHoleriteComponent } from 'src/app/dashboard/body/relatorio/holerite/holerite.component';
import { RelatorioInformeRendimentosComponent } from 'src/app/dashboard/body/relatorio/informe-rendimentos/informe-rendimentos.component';
import { RelatorioFuncionarioComponent } from 'src/app/dashboard/body/relatorio/funcionario/funcionario.component';
import { SolicitacaoBeneficioDependenteComponent } from 'src/app/dashboard/body/solicitacao/beneficio-dependente/beneficio-dependente.component';
import { AprovacaoBeneficioDependenteComponent } from 'src/app/dashboard/body/aprovacao/beneficio-dependente/beneficio-dependente.component';
import { ProgramacaoFeriasComponent } from 'src/app/dashboard/body/programacao/ferias/ferias.component';
import { SolicitacaoFeriasComponent } from 'src/app/dashboard/body/solicitacao/ferias/ferias.component';
import { AprovacaoFeriasComponent } from 'src/app/dashboard/body/aprovacao/ferias/ferias.component';
import { SolicitacaoHoraExtraComponent } from 'src/app/dashboard/body/solicitacao/hora-extra/hora-extra.component';
import { AprovacaoDivergenciaComponent } from 'src/app/dashboard/body/aprovacao/divergencia/divergencia.component';
import { RelatorioHoraExtraComponent } from 'src/app/dashboard/body/relatorio/hora-extra/hora-extra.component';
import { AprovacaoHoraExtraComponent } from 'src/app/dashboard/body/aprovacao/hora-extra/hora-extra.component';

@Injectable()
export class ComponentsService {

    constructor() { }
    public componentes: object = {
        perfil:  {
            id: '',
            componente: PerfilComponent,
            prop: 'perfil',
        },
        cadastro: [
            {
                id: '1',
                componente: UsuarioComponent,
                prop: 'usuario',
            },
            {
                id: '2',
                componente: EmpresaComponent,
                prop: 'empresa',
            },
            {
                id: '3',
                componente: PreAdmissaoComponent,
                prop: 'preAdmissao',
            },
            {
                id: '4',
                componente: ColaboradorComponent,
                prop: 'colaborador',
            },
            {
                id: '5',
                componente: BeneficioComponent,
                prop: 'beneficio',
            },
            {
                id: '6',
                componente: OperadoraVTComponent,
                prop: 'operadoraVT',
            },
            {
                id: '7',
                componente: LinhaVTComponent,
                prop: 'linhaVT',
            },
            {
                id: '8',
                componente: LotacaoComponent,
                prop: 'lotacao',
            },
            {
                id: '9',
                componente: CentroDeCustoComponent,
                prop: 'centroDeCusto',
            },
            {
                id: '10',
                componente: CargoComponent,
                prop: 'cargo',
            },
            {
                id: '11',
                componente: CategoriaSalarialComponent,
                prop: 'categoriaSalarial',
            },
            {
                id: '12',
                componente: DependenteComponent,
                prop: 'dependente',
            },
            {
                id: '13',
                componente: DocumentoAdmissionalComponent,
                prop: 'documentoAdmissional',
            },
            {
                id: '14',
                componente: MotivoHoraExtraComponent,
                prop: 'motivoHoraExtra',
            },
            {
                id: '15',
                componente: SindicatoComponent,
                prop: 'sindicato',
            },
            {
                id: '16',
                componente: MarmitexComponent,
                prop: 'marmitex',
            },
            {
                id: '17',
                componente: TipoMaoDeObraComponent,
                prop: 'tipoMaoDeObra',
            },
            {
                id: '18',
                componente: TipoDeRegistroComponent,
                prop: 'tipoRegistro',
            },
            {
                id: '19',
                componente: TurnoComponent,
                prop: 'turno',
            },
            {
                id: '20',
                componente: UnidadeDeNegocioComponent,
                prop: 'unidadeDeNegocio',
            },
            {
                id: '21',
                componente: JustificativaDeDivergenciaComponent,
                prop: 'justificativaDeDivergencia',
            },
            {
                id: '22',
                componente: BandeiraCartaoVTComponent,
                prop: 'bandeiraCartaoVT',
                
            }
        ],
        importacao: [
            {
                id: '80',
                componente: ImportacaoCartaoPontoComponent,
                prop: 'cartaoDePonto',
            },
            {
                id: '81',
                componente: ImportacaoDivergenciaComponent,
                prop: 'divergencia',
            },
            {
                id: '82',
                componente: ImportacaoHoleriteComponent,
                prop: 'holerite',
            },
            {
                id: '83',
                componente: ImportacaoInformeDeRendimentosComponent,
                prop: 'informeDeRendimento',
            },
            {
                id: '85',
                componente: ImportacaoFeriasComponent,
                prop: 'ferias',
            },
            {
                id: '86',
                componente: ImportacaoFeriasAvisoComponent,
                prop: 'feriasAviso',
            },
            {
                id: '87',
                componente: ImportacaoFeriasReciboComponent,
                prop: 'feriasRecibo',
            },
            {
                id: '88',
                componente: ImportacaoBancoDeHorasComponent,
                prop: 'bancoDeHoras',
            },
            {
                id: '89',
                componente: ImportacaoAbsenteismoComponent,
                prop: 'absenteismo',
            },
            
        ],
        relatorio: [
            
            {
                id: '100',
                componente: RelatorioCartaoPontoComponent,
                prop: 'cartaoDePonto',
            },
            {
                id: '101',
                componente: RelatorioDivergenciaComponent,
                prop: 'divergencia',
            },
            
            {
                id: '102',
                componente: RelatorioHoleriteComponent,
                prop: 'holerite',
            },
            
            {
                id: '103',
                componente: RelatorioInformeRendimentosComponent,
                prop: 'informeRendimentos',
            },
            {
                id: '105',
                componente: RelatorioFeriasComponent,
                prop: 'ferias',
            },
            {
                id: '106',
                componente: RelatorioAvisoFeriasComponent,
                prop: 'feriasAviso',
            },
            {
                id: '107',
                componente: RelatorioReciboFeriasComponent,
                prop: 'feriasRecibo',
            },
            {
                id: '',
                componente: null,
                prop: 'saldoFerias',
            },
            
            {
                id: '108',
                componente: RelatorioBancoDeHorasComponent,
                prop: 'bancoDeHoras',
            },
            {
                id: '109',
                componente: RelatorioFuncionarioComponent,
                prop: 'funcionario',
            },
            {
                id: '110',
                componente: RelatorioAbsenteismoComponent,
                prop: 'absenteismoSetor',
            },
            {
                id: '111',
                componente: RelatorioHoraExtraComponent,
                prop: 'horaExtra',
            },          
        ],
        solicitacao: [
            {
                id: '40',
                componente: SolicitacaoDesligamentoComponent,
                prop: 'desligamento',
            },
            {
                id: '41',
                componente: SolicitacaoMovimentacaoContratualComponent,
                prop: 'movimentacaoContratual',
            },
            {
                id: '42',
                componente: SolicitacaoAlteracaoCadastralComponent,
                prop: 'alteracaoCadastral',
            },
            {
                id: '43',
                componente: SolicitacaoBeneficioComponent,
                prop: 'beneficio',
            },
            {
                id: '44',
                componente: SolicitacaoValeTransporteComponent,
                prop: 'valeTransporte',
            },
            {
                id: '45',
                componente: SolicitacaoFeriasComponent,
                prop: 'ferias',
            },
            {
                id: '46',
                componente: SolicitacaoHoraExtraComponent,
                prop: 'horaExtra',
            },
            {
                id: '47',
                componente: SolicitacaoBeneficioDependenteComponent,
                prop: 'beneficioDependente',
            },
        ],
        programacao: [
            {
                id: '141',
                componente: null,
                prop: 'horaExtra',
            },
            {
                id: '140',
                componente: ProgramacaoFeriasComponent,
                prop: 'ferias',
            },
        ],
        lancamento: [
            {
                id: '',
                componente: null,
                prop: 'divergencia',
            },
            {
                id: '120',
                componente: LancamentoAtestadoComponent,
                prop: 'atestado',
            },
        ],
        aprovacao: [
            {
                id: '60',
                componente: AprovacaoDesligamentoComponent,
                prop: 'desligamento',
            },
            {
                id: '61',
                componente: AprovacaoMovimentacaoContratualComponent,
                prop: 'movimentacaoContratual',
            },
            {
                id: '62',
                componente: AprovacaoAlteracaoCadastralComponent,
                prop: 'alteracaoCadastral',
            },
            {
                id: '63',
                componente: AprovacaoBeneficioComponent,
                prop: 'beneficio',
            },
            {
                id: '64',
                componente: AprovacaoValeTransporteComponent,
                prop: 'valeTransporte',
            },
            {
                id: '65',
                componente: AprovacaoAtestadoComponent,
                prop: 'atestado',
            },
            {
                id: '66',
                componente: AprovacaoCartaoPontoComponent,
                prop: 'cartaoPonto',
            },
            {
                id: '67',
                componente: AprovacaoDivergenciaComponent,
                prop: 'divergencia',
            },
            {
                id: '68',
                componente: AprovacaoFeriasComponent,
                prop: 'ferias',
            },
            {
                id: '69',
                componente: AprovacaoHoraExtraComponent,
                prop: 'horaExtra',
            },
            {
                id: '70',
                componente: AprovacaoBeneficioDependenteComponent,
                prop: 'beneficioDependente',
            },
        ],
    };


}