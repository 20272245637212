import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PerfilService } from 'src/app/@core/@service/perfil/perfil.service';
import { LoginService } from 'src/app/@core/@service/login/login.service';
import { Subscription } from 'rxjs';
import { NotificacaoService } from 'src/app/@core/@service/notificacao/notificacao.service';
import { ComponentsService } from 'src/app/@core/@service/utils/components.service';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('profileImageBig', { static: true }) profileImageBig: ElementRef;
  @ViewChild('profileImageSmall', { static: true }) profileImageSmall: ElementRef;

  perfilDataSubscription: Subscription;
  perfilData = this.perfilService.perfilData;
  userLoggedId = '';
  userLoggedType = ''
  notificacaoList: any = [];
  intervalId: any;
  linkSite = '';

  constructor(
    private perfilService: PerfilService,
    private loginService: LoginService,
    private notificacaoService: NotificacaoService,
    private componentsService: ComponentsService,
    private colaboradorService: CadastroColaboradorService,
  ) {
    this.perfilDataSubscription = this.perfilService.getPerfilData().subscribe(res => {
      if (res) {
        this.perfilData = res;
        const nome = this.perfilData.nome;
        let nomeFormatted = nome ? nome.split(' ').map(name => name[0].toUpperCase()) : '';
        if (nome) {
          if (nomeFormatted.length == 1) {
            nomeFormatted = nomeFormatted[0];
          } else {
            nomeFormatted = nomeFormatted[0] + nomeFormatted[nomeFormatted.length - 1];
          }
        }
        if (this.perfilData.fotoBase64) {
          // Foto big
          $('#profileImageBig').removeClass('profileText-circle');
          $('#profileImageBig').addClass('profileImage-circle');
          $('#profileImageBig').css('background-image', 'url(' + this.perfilData.fotoBase64 + ')');

          // Foto small
          $('#profileImageSmall').removeClass('profileText');
          $('#profileImageSmall').addClass('profileImage');
          $('#profileImageSmall').css('background-image', 'url(' + this.perfilData.fotoBase64 + ')');
        } else {
          // Foto big
          $('#profileImageBig').removeClass('profileImage-circle');
          $('#profileImageBig').addClass('profileText-circle');
          this.profileImageBig.nativeElement.innerHTML = nomeFormatted.includes('undefined') ? '--' : nomeFormatted;

          // Foto small
          $('#profileImageSmall').removeClass('profileImage');
          $('#profileImageSmall').addClass('profileText');
          this.profileImageSmall.nativeElement.innerHTML = nomeFormatted.includes('undefined') ? '--' : nomeFormatted;
        }
      } else {
        // clear perfilDatas when empty perfilData received
        this.perfilData = this.perfilService.perfilData;
      }
    });
  }

  ngOnInit() {
       setTimeout(() => {
      ($(".navbar .menu") as any).slimscroll({
        height: '200px',
        alwaysVisible: false,
        size: '3px'
      }).css("width", "100%");
    }, 1000);

    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userLoggedId = currUser ? currUser.UsuarioColaboradorId : '';
    this.userLoggedType = currUser ? currUser.UsuarioColaboradorTipoId : '';
    if(currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR || currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.ADMINISTRADOR){
      this.getNotificacoes();
      this.intervalId = setInterval(() => {
        this.getNotificacoes();
      }, 15000);
    }else{
      ($(".notifications-menu")as any).hide();
    }

    if (currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && currUser.ColaboradorStatus.Id == 1) {
      this.colaboradorService.BuscarDadoPrincipalColaboradorPreAdmissaoPorId(this.userLoggedId).subscribe(
        async res => {
          // Seta modo edição
          if(res.Empresa.LinkSite != "" && res.Empresa.MostrarLinkSite == "Sim"){
            $("#liLink").show();
            this.linkSite = res.Empresa.LinkSite;
          }else{
            $("#liLink").hide();
          }
        },
        err => {
          console.log(err);
        }
      );
    }else if(currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && currUser.ColaboradorStatus.Id == 2){
      this.colaboradorService.buscarColaboradorPorId(this.userLoggedId).subscribe(
        async res => {
          // Seta modo edição
          if(res.Empresa.LinkSite != "" && res.Empresa.MostrarLinkSite == "Sim"){
            $("#liLink").show();
            this.linkSite = res.Empresa.LinkSite;
          }else{
            $("#liLink").hide();
          }
        },
        err => {
          console.log(err);
        }
      );
    }else{
      $("#liLink").hide();
    }
  }

  menuCollapse() {
    if (window.innerWidth < 768) {
      if ($('body').hasClass('sidebar-open')) {
        $('body').removeClass('sidebar-open');
      } else {
        $('body').addClass('sidebar-open');
      }
    } else {
      if ($('body').hasClass('sidebar-collapse')) {
        $('body').removeClass('sidebar-collapse');
      } else {
        $('body').addClass('sidebar-collapse');
      }
    }
  }

  getNotificacoes() {
    this.notificacaoService.buscarNotificacoes(this.userLoggedId, this.userLoggedType).subscribe(
      res => {

        this.notificacaoList = res.length > 0 ? res : [];
      },
      err => {

        console.log(err);
      }
    );
  }

  openNotificacao(notificacao) {
    let componente;
    Object.keys(this.componentsService.componentes).forEach(pai => {
      if (Array.isArray(this.componentsService.componentes[pai])) {
        const filter = this.componentsService.componentes[pai].filter(val => val.id == notificacao.NotificacaoDetalhe.MenuId);
        if (filter.length > 0) {
          componente = filter[0];
          const menu = JSON.parse(sessionStorage.getItem('menu'));
          if (menu.filter(el => el.Id == componente.id).length > 0) {
            this.atualizarStatusLeitura(notificacao.NotificacaoDetalhe.Id, pai, componente);
            return false;
          }
        }
      }
    });
  }

  atualizarStatusLeitura(idNotificacao, pai, componente: any) {
    this.notificacaoService.atualizarStatusNotificacao(idNotificacao).subscribe(
      res => {
        const url = location.href.split("/")[0] + "//" + location.href.split("/")[2]
        history.pushState({
          id: ''
        }, '', `${url}/${pai.charAt(0).toUpperCase() + pai.slice(1)}/${componente.prop.charAt(0).toUpperCase() + componente.prop.slice(1)}`);
        location.reload();
      },
      err => {
        console.log(err);
      }
    );
  }

  logout() {
    this.loginService.logout();
  }

  ngOnDestroy() {
    this.perfilDataSubscription.unsubscribe();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
