<div style="margin-left: 0px" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>Holerite</h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Holerite</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">
    <form id="formulario">
      <div style="margin-left: 0px">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div
                class="col-md-3"
                [style.display]="colaboradorType == 'rh' ? 'block' : 'none'"
              >
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="txtNomeColaborador"
                      name="txtNomeColaborador"
                      placeholder="Nome do Colaborador"
                    />
                    <span class="input-group-addon"
                      ><a
                        style="cursor: pointer"
                        (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"
                        ><i class="fa fa-search"></i></a
                    ></span>
                  </div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="colaboradorType == 'rh'">
                <div class="form-group">
                  <label>Nome da Empresa</label>
                  <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeEmpresa" name="txtNomeEmpresa"
                      placeholder="Nome da Empresa">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalEmpresa')"
                        title="Pesquisar Empresa"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Tipo</label>
                  <select-component
                  (changeValue)="changeSelectValues($event)"
                    [Data]="tipoList"
                    IdSelect="selTipo"
                    [Value]="tipoSelected"
                  >
                  </select-component>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Mês</label>
                  <select-component
                    (changeValue)="changeSelectValues($event)"
                    [Data]="mesList"
                    IdSelect="selMes"
                    [Value]="mesSelected"
                  >
                  </select-component>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Ano</label>
                  <input
                    type="text"
                    mask="0000"
                    class="form-control"
                    id="txtAno"
                    name="txtAno"
                    placeholder="yyyy"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="box-footer">
            <table border="0" width="100%">
              <tr width="100%">
                <td align="left">
                  <button
                    type="reset"
                    (click)="clearInputvalues()"
                    class="btn btn-default"
                  >
                    Limpar
                  </button>
                </td>
                <td align="center">
                  <span
                    id="spanErro"
                    name="spanErro"
                    class="error"
                    style="font-weight: bold"
                  ></span>
                </td>
                <td align="right">
                  <input
                    type="button"
                    value="Excluir"
                    (click)="exluir()"
                    class="btn btn-primary btn-margin"
                    style="margin-right: 20px"
                    *ngIf="itensSelecionadosParaExclusao.length > 0"
                  />
                  <input
                    type="button"
                    value="Pesquisar"
                    (click)="pesquisar()"
                    class="btn btn-primary btn-margin"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #grid
              style="width: 100% !important"
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th style="width: 5% !important">
                    <input
                      type="checkbox"
                      title="Selecionar Todos"
                      data-select-all=""
                      (change)="selecionarTodosParaExclusao($event)"
                    />
                  </th>
                  <th style="width: 15% !important">Nome do Colaborador</th>
                  <th style="width: 15% !important">
                    Matrícula do Colaborador
                  </th>
                  <th style="width: 15% !important">Empresa</th>
                  <th style="width: 12% !important">Mês</th>
                  <th style="width: 13% !important">Ano</th>
                  <th style="width: 13% !important">Tipo</th>
                  <th style="width: 15% !important">Data da Importação</th>
                  <th style="width: 5% !important"></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div style="position: absolute; left: 100%; top: 0; width: 1024px">
  <div
    id="holeriteRelatorio"
    style="
      padding: 2px;
      display: flex;
      flex-direction: column;
      line-height: 16px;
      background-color: white;
    "
  >
    <div style="display: flex; border: 1px solid black; flex-direction: column">
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 3px 10px 10px 10px;
        "
      >
        <div>
          <div>{{ modalRelatorioData.empresa | uppercase }}</div>
          <div>CNPJ: {{ modalRelatorioData.empresaCNPJ }}</div>
        </div>
        <div style="display: flex; align-items: flex-end">
          <div>
            {{ modalRelatorioData.mesNome }} de {{ modalRelatorioData.ano }}
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          padding: 0 10px 10px 10px;
          border-top: 1px solid black;
        "
      >
        <div style="display: flex; flex-direction: column">
          <div style="font-size: 10px; font-weight: bolder">Código</div>
          <div style="line-height: 12px">
            {{ modalRelatorioData.matricula }}
          </div>
        </div>
        <div style="display: flex; flex-direction: column; margin-left: 20px">
          <div style="font-size: 10px; font-weight: bolder">
            Nome do Funcionário
          </div>
          <div style="line-height: 12px">
            {{ modalRelatorioData.colaborador | uppercase }}
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; border: 1px solid black; margin-top: 4px">
      <table>
        <thead>
          <tr
            style="
              font-size: 12px;
              font-weight: bolder;
              border-bottom: 1px solid;
            "
          >
            <th style="text-align: center; width: 70px">Código</th>
            <th style="text-align: center; border-left: 1px solid">
              Descrição
            </th>
            <th
              style="text-align: center; border-left: 1px solid; width: 140px"
            >
              Referência
            </th>
            <th
              style="text-align: center; border-left: 1px solid; width: 150px"
            >
              Vencimentos
            </th>
            <th
              style="text-align: center; border-left: 1px solid; width: 150px"
            >
              Descontos
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let evento of modalRelatorioData.eventosPDF"
            style="height: 16px"
          >
            <td style="text-align: right; padding: 2px 5px">
              {{ evento.Evento }}
            </td>
            <td
              style="
                border-left: 1px solid black;
                text-align: left;
                padding: 2px 5px;
              "
            >
              {{ evento.Descricao | uppercase }}
            </td>
            <td
              style="
                border-left: 1px solid black;
                text-align: right;
                padding: 2px 5px;
              "
            >
              {{ evento.Quantidade }}
            </td>
            <td
              style="
                border-left: 1px solid black;
                text-align: right;
                padding: 2px 5px;
              "
            >
              {{ evento.ValoresPositivos }}
            </td>
            <td
              style="
                border-left: 1px solid black;
                text-align: right;
                padding: 2px 5px;
              "
            >
              {{ evento.ValoresNegativos }}
            </td>
          </tr>
          <tr style="border-top: 1px solid black">
            <td colspan="3"></td>
            <td style="border-left: 1px solid black; padding: 0 10px 10px 10px">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div
                  style="
                    font-size: 10px;
                    font-weight: bolder;
                    text-align: center;
                  "
                >
                  Total de Vencimentos
                </div>
                <div style="line-height: 12px; text-align: right">
                  {{ modalRelatorioData.totalProventos }}
                </div>
              </div>
            </td>
            <td style="border-left: 1px solid black; padding: 0 10px 10px 10px">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div
                  style="
                    font-size: 10px;
                    font-weight: bolder;
                    text-align: center;
                  "
                >
                  Total de Descontos
                </div>
                <div style="line-height: 12px; text-align: right">
                  {{ modalRelatorioData.totalDescontos }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td
              style="
                border-left: 1px solid black;
                border-top: 1px solid black;
                padding: 10px;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                "
              >
                <div
                  style="font-size: 10px; font-weight: bolder; text-align: left"
                >
                  Valor Líquido
                </div>
              </div>
            </td>
            <td
              style="
                border-left: 1px solid black;
                border-top: 1px solid black;
                padding: 10px;
              "
            >
              <div style="line-height: 12px; text-align: right">
                {{ modalRelatorioData.liquido }}
              </div>
            </td>
          </tr>
          <tr style="border-top: 1px solid">
            <td colspan="5" style="padding: 0px 10px 10px 10px">
              <div style="display: flex; justify-content: space-around">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    Salário Base
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ modalRelatorioData.salarioBase }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    Sal. Contr. INSS
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ modalRelatorioData.salarioInss }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    Base Cálc. FGTS
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ modalRelatorioData.baseCalcFgts }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    F.G.T.S do Mês
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ modalRelatorioData.fgtsMes }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    Base Cálc. IRRF
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ modalRelatorioData.baseCalcIRRF }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      font-size: 10px;
                      font-weight: bolder;
                      text-align: center;
                    "
                  >
                    Faixa IRRF
                  </div>
                  <div style="line-height: 12px; text-align: center">
                    {{ 0 | currency: "BRL":"" }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalColaborador"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <table
          #gridModalColaborador
          style="width: 100% !important; cursor: pointer"
          class="hover tableOperadora table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default pull-right"
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEmpresa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Empresa</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalEmpresa style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cnpj</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalRelatorio"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="width: 95%">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">
          <b>Relatório de Holerite</b>
        </h4>
      </div>
      <div class="modal-header" style="padding-left: 30px">
        <div class="row" style="margin-bottom: 10px">
          <div class="col-md-4">
            <b>Estabelecimento:</b> {{ modalRelatorioData.empresa }}
          </div>
        </div>
        <div class="row" style="padding-top: 10px">
          <div class="col-md-3">
            <b>Matrícula:</b> {{ modalRelatorioData.matricula }}
          </div>
          <div class="col-md-9">
            <b>Nome:</b> {{ modalRelatorioData.colaborador }}
          </div>
        </div>
        <div class="row" style="padding-top: 10px">
          <div class="col-md-3"><b>Mês:</b> {{ modalRelatorioData.mes }}</div>
          <div class="col-md-2"><b>Ano:</b> {{ modalRelatorioData.ano }}</div>
          <div class="col-md-2"><b>Tipo:</b> {{ modalRelatorioData.tipo }}</div>
        </div>
      </div>
      <div class="modal-body">
        <h4
          class="modal-title"
          id="exampleModalLabel"
          style="padding-left: 15px"
        >
          <b>Holerite:</b>
        </h4>
        <div style="overflow-y: auto">
          <div style="width: 99%; padding-left: 15px">
            <table
              #gridModalRelatorio
              style="width: 100% !important; cursor: pointer"
              class="hover table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>Descrição</th>
                  <th>Quantidade</th>
                  <th>Valores Positivos</th>
                  <th>Valores Negativos</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-header" style="padding-left: 30px">
        <div class="row" style="margin-bottom: 10px">
          <div class="col-md-3">
            <b>Total de Proventos:</b> {{ modalRelatorioData.totalProventos }}
          </div>
          <div class="col-md-3">
            <b>Total de Descontos:</b> {{ modalRelatorioData.totalDescontos }}
          </div>
          <div class="col-md-3">
            <b>Liquído:</b> {{ modalRelatorioData.liquido }}
          </div>
          <div class="col-md-3">
            <b>Salário Base:</b> {{ modalRelatorioData.salarioBase }}
          </div>
        </div>
        <div class="row" style="padding-top: 10px">
          <div class="col-md-3">
            <b>Salário INSS:</b> {{ modalRelatorioData.salarioInss }}
          </div>
          <div class="col-md-3">
            <b>Base Cálculo FGTS:</b> {{ modalRelatorioData.baseCalcFgts }}
          </div>
          <div class="col-md-3">
            <b>FGTS Mês:</b> {{ modalRelatorioData.fgtsMes }}
          </div>
          <div class="col-md-3">
            <b>Base Cálculo IRRF:</b> {{ modalRelatorioData.baseCalcIRRF }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default pull-right"
          data-dismiss="modal"
        >
          Fechar
        </button>
        <button
          type="button"
          class="btn btn-default pull-right"
          style="margin-right: 8px"
          (click)="exportarPDF()"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</div>
