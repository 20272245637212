import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
// import * as traducaoDatatable from '../../../../assets/plugins/datatable-translate/pt-BR.json';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { CadastroSindicatoService } from 'src/app/@core/@service/cadastro/sindicato/sindicato.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-sindicato',
  templateUrl: './sindicato.component.html',
  styleUrls: ['./sindicato.component.scss']
})
export class SindicatoComponent implements OnInit {

  gridData = [];

  isEdit = false;

  sindicatoIdSelected = '';


  mesSelected = '';
  mesList = [];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroSindicatoService,
  ) { }

  ngOnInit() {
    this.getIntitialsData();
    this.validarCampos();
    this.mesList = this.utilService.getMesList().map(val =>
      ({
        Id: (Number(val.Id) > 0 && Number(val.Id) < 10) ? '0' + val.Id : val.Id,
        Description: val.Description
      }));
    console.log(this.mesList);
  }

  changeSelectValues(event) {
    if (event.srcElement.id == 'selMes') {
      this.mesSelected = event.target.value;
      $('#selMes').removeClass('element-invalid');
    }
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }


  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true
        },
        txtCNPJ: {
          required: true, minlength: 18
        },
        txtAno: {
          required: true,
        },
        txtTelCom: {
          minlength: 14
        },
        txtTelCel: {
          minlength: 14
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtCNPJ: {
          required: "Informe o Cnpj",
          minlength: "Informe o Cnpj corretamente"
        },
        txtAno: {
          required: "Informe a Data Base",
        },
        txtTelCom: {
          minlength: "Informe o Telefone Comercial corretamente"
        },
        txtTelCel: {
          minlength: "Informe o Telefone Celular corretamente"
        }
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(data).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            cnpj: val.Cnpj,
            mes: val.DataBaseMes,
            ano: val.DataBaseAno,
            representante: val.Representante,
            telComercial: val.TelefoneComercial,
            celular: val.TelefoneCelular,
            dataCadastro: val.DataCadastro,
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }

  clearInputvalues() {

    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    this.mesSelected = '';
    $('#txtNome').val('');
    $('#txtCNPJ').val('');
    $('#txtAno').val('');
    $('#txtTelCom').val('');
    $('#txtTelCel').val('');
    $('#txtRepresentante').val('');
  }

  getValuesFromInput() {
    return {
      Nome: $('#txtNome').val().toString().trim(),
      Cnpj: $('#txtCNPJ').val().toString().trim(),
      DataBaseMes: this.mesSelected,
      DataBaseAno: $('#txtAno').val().toString().trim(),
      Representante: $('#txtRepresentante').val().toString().trim(),
      TelefoneComercial: $('#txtTelCom').val().toString().trim(),
      TelefoneCelular: $('#txtTelCel').val().toString().trim(),
    }
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (!this.mesSelected) {
      ($("#formulario") as any).valid();
      $('#selMes').addClass('element-invalid');
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.sindicatoIdSelected;
          this.moduleService.editar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues();
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
        {
          "data": "mes"
        },
        {
          "data": "ano"
        },
        {
          "data": "representante"
        },
        {
          "data": "telComercial"
        },
        {
          "data": "celular"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[8]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;

          // Preencher campos, com a linha selecionada
          self.mesSelected = data.mes;
          $('#txtNome').val(data.nome);
          $('#txtCNPJ').val(data.cnpj);
          $('#txtAno').val(data.ano);
          $('#txtRepresentante').val(data.representante);
          $('#txtTelCom').val(data.telComercial);
          $('#txtTelCel').val(data.celular);

          self.sindicatoIdSelected = data.id;
        });
        $($(row).children()[9]).on("click", function (e) {
          self.remover(data);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
