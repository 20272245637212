import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { CoreModule } from 'src/app/@core/core.module';
import { AprovacaoModule } from './body/aprovacao/aprovacao.module';
import { LoginModule } from './login/login.module';
import { RecuperarSenhaModule } from './recuperar-senha/recuperar-senha.module';
import { DashboardComponent } from './dashboard.component';
import { PerfilComponent } from './body/perfil/perfil.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { CadastroModule } from './body/cadastro/cadastro.module';
import { AlterarSenhaModule } from './alterar-senha/alterar-senha.module';
import { SolicitacaoModule } from './body/solicitacao/solicitacao.module';
import { LancamentoModule } from './body/lancamento/lancamento.module';
import { ImportacaoModule } from './body/importacao/importacao.module';
import { RelatorioModule } from './body/relatorio/relatorio.module';
import { ProgramacaoModule } from './body/programacao/programacao.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    AprovacaoModule,
    LoginModule,
    RecuperarSenhaModule,
    AlterarSenhaModule,
    CoreModule,
    CadastroModule,
    SolicitacaoModule,
    LancamentoModule,
    ImportacaoModule,
    RelatorioModule,
    ProgramacaoModule,
  ],
  declarations: [
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    PerfilComponent,
  ],
  entryComponents: [
    PerfilComponent,
  ]
})
export class DashboardModule { }
