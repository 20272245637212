import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './components/select/select.component';
import { UtilsService } from './@service/utils/utils';
import { ComponentsService } from './@service/utils/components.service';
import { CadastroBeneficioService } from './@service/cadastro/beneficio/beneficio.service';
import { CadastroEmpresaService } from './@service/cadastro/empresa/empresa.service';
import { PerfilService } from './@service/perfil/perfil.service';
import { LoginService } from './@service/login/login.service';
import { FormsModule } from '@angular/forms';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './components/alert/alert.component.service';
import { CadastroTipoMaoDeObraService } from './@service/cadastro/tipo-mao-de-obra/tipo-mao-de-obra.service';
import { CadastroMarmitexService } from './@service/cadastro/marmitex/marmitex.service';
import { CadastroTipoDeRegistroService } from './@service/cadastro/tipo-de-registro/tipo-de-registro.service';
import { CadastroMotivoHoraExtraService } from './@service/cadastro/motivo-hora-extra/motivo-hora-extra.service';
import { CadastroJustificativaDeDivergenciaService } from './@service/cadastro/justificativa-de-divergencia/justificativa-de-divergencia.service';
import { CadastroCategoriaSalarialService } from './@service/cadastro/categoria-salarial/categoria-salarial.service';
import { CadastroUnidadeDeNegocioService } from './@service/cadastro/unidade-de-negocio/unidade-de-negocio.service';
import { CadastroDocumentoAdmissionalService } from './@service/cadastro/documento-admissional/documento-admissional.service';
import { CadastroDependenteService } from './@service/cadastro/dependente/dependente.service';
import { CadastroTurnoService } from './@service/cadastro/turno/turno.service';
import { CadastroOperadoraVTService } from './@service/cadastro/operadora-vt/operadora-vt.service';
import { CadastroLinhaVTService } from './@service/cadastro/linha-vt/linha-vt.service';
import { CadastroSindicatoService } from './@service/cadastro/sindicato/sindicato.service';
import { CadastroUsuarioService } from './@service/cadastro/usuario/usuario.service';
import { CadastroLotacaoUnidadeService } from './@service/cadastro/lotacao/unidade/unidade.service';
import { CadastroLotacaoPlanoService } from './@service/cadastro/lotacao/plano/plano.service';
import { CadastroCentroDeCustoPlanoService } from './@service/cadastro/centro-de-custo/plano/plano.service';
import { CadastroCargoPlanoService } from './@service/cadastro/cargo/plano/plano.service';
import { CadastroCentroDeCustoUnidadeService } from './@service/cadastro/centro-de-custo/unidade/unidade.service';
import { CadastroCargoUnidadeService } from './@service/cadastro/cargo/unidade/unidade.service';
import { MenuService } from './@service/menu/menu.service';
import { LoaderService } from './@service/utils/loader-service';
import { CadastroColaboradorDadosPrincipaisService } from './@service/cadastro/colaborador/dados-principais/dados-principais.service';
import { CadastroColaboradorDocumentacaoService } from './@service/cadastro/colaborador/documentacao/documentacao.service';
import { CadastroColaboradorEmpregadorService } from './@service/cadastro/colaborador/empregador/empregador.service';
import { CadastroColaboradorEstrangeiroService } from './@service/cadastro/colaborador/estrangeiro/estrangeiro.service';
import { CadastroColaboradorPagamentoService } from './@service/cadastro/colaborador/pagamento/pagamento.service';
import { CadastroColaboradorService } from './@service/cadastro/colaborador/colaborador.service';
import { AprovacaoDesligamentoService } from './@service/aprovacao/desligamento/desligamento.service';
import { SolicitacaoDesligamentoService } from './@service/solicitacao/desligamento/desligamento.service';
import { SolicitacaoMovimentacaoContratualService } from './@service/solicitacao/movimentacao-contratual/movimentacao-contratual.service';
import { SolicitacaoAlteracaoCadastralService } from './@service/solicitacao/alteracao-cadastral/alteracao-cadastral.service';
import { SolicitacaoValeTransporteService } from './@service/solicitacao/vale-transporte/vale-transporte.service';
import { SolicitacaoBeneficioService } from './@service/solicitacao/beneficio/beneficio.service';
import { AprovacaoMovimentacaoContratualService } from './@service/aprovacao/movimentacao-contratual/movimentacao-contratual.service';
import { AprovacaoAlteracaoCadastralService } from './@service/aprovacao/alteracao-cadastral/alteracao-cadastral.service';
import { AprovacaoBeneficioService } from './@service/aprovacao/beneficio/beneficio.service';
import { AprovacaoValeTransporteService } from './@service/aprovacao/vale-transporte/vale-transporte.service';
import { AprovacaoAtestadoService } from './@service/aprovacao/atestado/atestado.service';
import { LancamentoAtestadoService } from './@service/lancamento/atestado/atestado.service';
import { ImportacaoCartaoPontoService } from './@service/importacao/cartao-de-ponto/cartao-de-ponto.service';
import { ImportacaoBancoDeHorasService } from './@service/importacao/banco-de-horas/banco-de-horas.service';
import { ImportacaoDivergenciaService } from './@service/importacao/divergencia/divergencia.service';
import { ImportacaoFeriasService } from './@service/importacao/ferias/ferias.service';
import { ImportacaoFeriasAvisoService } from './@service/importacao/ferias-aviso/ferias-aviso.service';
import { ImportacaoFeriasReciboService } from './@service/importacao/ferias-recibo/ferias-recibo.service';
import { ImportacaoHoleriteService } from './@service/importacao/holerite/holerite.service';
import { ImportacaoInformeRendimentoService } from './@service/importacao/informe-de-rendimentos/informe-de-rendimentos.service';
import { CadastroBandeiraCartaoVTService } from './@service/cadastro/bandeira-cartao-vt/bandeira-cartao-vt.service';
import { ImportacaoAbsenteismoService } from './@service/importacao/absenteismo/absenteismo.service';
import { AprovacaoCartaoPontoService } from './@service/aprovacao/cartao-ponto/cartao-ponto.service';
import { SolicitacaoBeneficioDependenteService } from './@service/solicitacao/beneficio-dependente/beneficio-dependente.service';
import { SolicitacaoFeriasService } from './@service/solicitacao/ferias/ferias.service';
import { SolicitacaoHoraExtraService } from './@service/solicitacao/hora-extra/hora-extra.service';
import { NotificacaoService } from './@service/notificacao/notificacao.service';

@NgModule({
  declarations: [
    SelectComponent,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    SelectComponent,
  ],
  providers: [
    UtilsService,
    CadastroBeneficioService,
    CadastroEmpresaService,
    ComponentsService,
    PerfilService,
    LoginService,
    AlertService,
    CadastroTipoMaoDeObraService,
    CadastroMarmitexService,
    CadastroTipoDeRegistroService,
    CadastroMotivoHoraExtraService,
    CadastroJustificativaDeDivergenciaService,
    CadastroCategoriaSalarialService,
    CadastroUnidadeDeNegocioService,
    CadastroDocumentoAdmissionalService,
    CadastroDependenteService,
    CadastroTurnoService,
    CadastroOperadoraVTService,
    CadastroLinhaVTService,
    CadastroSindicatoService,
    CadastroUsuarioService,
    CadastroLotacaoUnidadeService,
    CadastroLotacaoPlanoService,
    CadastroCentroDeCustoPlanoService,
    CadastroCentroDeCustoUnidadeService,
    CadastroCargoPlanoService,
    CadastroCargoUnidadeService,
    MenuService,
    LoaderService,
    CadastroColaboradorDadosPrincipaisService,
    CadastroColaboradorDocumentacaoService,
    CadastroColaboradorEmpregadorService,
    CadastroColaboradorEstrangeiroService,
    CadastroColaboradorPagamentoService,
    CadastroColaboradorService,
    CadastroBandeiraCartaoVTService,
    AprovacaoDesligamentoService,
    SolicitacaoDesligamentoService,
    SolicitacaoMovimentacaoContratualService,
    SolicitacaoAlteracaoCadastralService,
    SolicitacaoValeTransporteService,
    SolicitacaoBeneficioService,
    SolicitacaoBeneficioDependenteService,
    SolicitacaoFeriasService,
    SolicitacaoHoraExtraService,
    AprovacaoMovimentacaoContratualService,
    AprovacaoAlteracaoCadastralService,
    AprovacaoBeneficioService,
    AprovacaoValeTransporteService,
    AprovacaoAtestadoService,
    AprovacaoCartaoPontoService,
    LancamentoAtestadoService,
    ImportacaoCartaoPontoService,
    ImportacaoBancoDeHorasService,
    ImportacaoDivergenciaService,
    ImportacaoFeriasService,
    ImportacaoFeriasAvisoService,
    ImportacaoFeriasReciboService,
    ImportacaoHoleriteService,
    ImportacaoInformeRendimentoService,
    ImportacaoAbsenteismoService,
    NotificacaoService,
  ],
  entryComponents: [
    AlertComponent
  ]
})
export class CoreModule { }
