import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoDesligamentoService } from 'src/app/@core/@service/solicitacao/desligamento/desligamento.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

@Component({
  selector: 'app-solicitacao-desligamento',
  templateUrl: './desligamento.component.html',
  styleUrls: ['./desligamento.component.scss']
})
export class SolicitacaoDesligamentoComponent implements OnInit {

  gridData = [];

  colaboradorSelected = ''

  gestorSelected = '';

  gestorId = '';

  colaboradorList: Array<any> = [];

  tipoSelected = ''

  tipoList: Array<any> = [];

  desligamentoIdSelected = '';

  dataTable: any;
  currUser: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoDesligamentoService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.currUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
      this.gestorId = this.currUser ? this.currUser.UsuarioColaboradorId : '';
    }
    this.validarCampos();
    this.getDataGrid();
    this.getInitialData();
    //Date picker
    ($("#txtDataSugerida") as any).datepicker({
      autoclose: true
    });
  }

  getInitialData() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){
    this.colaboradorService.buscarColaboradorPorGestorId(this.gestorId).subscribe(
      res => {
        this.colaboradorList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.colaboradorList = this.colaboradorList.filter(a => {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      },
      err => {
        console.log(err);
      }
    );
    this.moduleService.buscarTudoDesligamentoTipo().subscribe(
      res => {
        this.tipoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
      }else{
          this.colaboradorService.buscarColaboradorPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
            res => {
              console.log(res);
              this.colaboradorList = res.map(val =>
                ({
                  Id: val.Id,
                  Description: val.Nome
                })
              ).sort(this.utilService.dynamicSort('Description'));
              this.colaboradorList = this.colaboradorList.filter(a => {
                return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
              }, Object.create(null));
            },
            err => {
              console.log(err);
            }
          );
          this.moduleService.buscarTudoDesligamentoTipo().subscribe(
            res => {
              this.tipoList = res.map(val =>
                ({
                  Id: val.Id,
                  Description: val.Nome
                })
              ).sort(this.utilService.dynamicSort('Description'));
            },
            err => {
              console.log(err);
            }
          );
        }
      }

  validarCampos() {
    ($("#formulario") as any).validate({
      // Define as regras
      rules: {
        selColaborador: {
          required: true
        },
        txtDataSugerida: {
          required: true
        },
        selTipo: {
          required: true
        },
        txtMotivo: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        selColaborador: {
          required: "Selecione o Nome do Colaborador"
        },
        txtDataSugerida: {
          required: "Informe a Data Sugerida"
        },
        selTipo: {
          required: "Selecione o Tipo"
        },
        txtMotivo: {
          required: "Informe o Motivo"
        }
      },
    });
    ($("#formulario") as any).on("submit", function () {
      return false;
    })
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectColaborador') {
      this.colaboradorSelected = event.target.value;
      $('#selectColaborador').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selTipo') {
      this.tipoSelected = event.target.value;
      $('#selTipo').removeClass('element-invalid');
    }
  }

  getDataGrid() {
    if(this.currUser.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR){

    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.gestorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Colaborador ? val.Colaborador.Nome : '-',
            dataSugerida: val.DataSugerida,
            tipo: val.DesligamentoTipo.Nome || '-',
            motivo: val.Motivo || '-',
            substituir: val.Substituir ? 'Sim' : 'Não',
            recontrataria: val.Recontrataria ? 'Sim' : 'Não',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            dataDesligamento: val.DataDesligamento,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }else{
    this.moduleService.pesquisarPorUsuarioId(this.currUser.UsuarioColaboradorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Colaborador ? val.Colaborador.Nome : '-',
            dataSugerida: val.DataSugerida,
            tipo: val.DesligamentoTipo.Nome || '-',
            motivo: val.Motivo || '-',
            substituir: val.Substituir ? 'Sim' : 'Não',
            recontrataria: val.Recontrataria ? 'Sim' : 'Não',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            dataDesligamento: val.DataDesligamento,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }
}
  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();
    if (type == 'solicitar') {
      $('#selCargo').removeClass('element-invalid');
      $('#selectColaborador').removeClass('element-invalid');
    }
    // Zera os campos
    this.gestorSelected = '';
    this.colaboradorSelected = '';
    this.tipoSelected = '';
    $('#txtDataSugerida').val('');
    $('#txtMotivo').val('');
    $("#substituirRadioSim").prop("checked", true);
    $("#recontratariaRadioSim").prop("checked", true);
  }

  getValuesFromInput() {
    const data = $('#txtDataSugerida').val().toString().trim().split('/');
    return {
      GestorId: this.gestorId,
      ColaboradorId: this.colaboradorSelected,
      DataSugerida: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      DesligamentoTipoId: this.tipoSelected,
      Motivo: $('#txtMotivo').val().toString().trim(),
      Substituir: $("#substituirRadioSim").prop("checked"),
      Recontrataria: $("#recontratariaRadioSim").prop("checked"),
    }
  }

  solicitar() {
    ($("#formulario") as any).valid();
    if (!this.colaboradorSelected || !this.tipoSelected) {
      if (!this.colaboradorSelected) {
        $('#selectColaborador').addClass('element-invalid');
      } else {
        $('#selectColaborador').removeClass('element-invalid');
      }
      if (!this.tipoSelected) {
        $('#selTipo').addClass('element-invalid');
      } else {
        $('#selTipo').removeClass('element-invalid');
      }
    } else {
      if (($("#formulario") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        this.moduleService.solicitar(obj).subscribe(
          res => {
            if (res.Codigo && res.Codigo == '600') {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: res.Descricao || 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
              });
            } else {
              this.clearInputvalues();
              this.getDataGrid();
              this.utilService.alertSwal('add-success');
            }
          },
          err => {
            console.log(err);
            this.utilService.alertSwal('add-error');
          }
        );
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": 'dataSugerida', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "tipo"
        },
        {
          "data": "motivo"
        },
        {
          "data": "substituir"
        },
        {
          "data": "recontrataria"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": 'dataDesligamento', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '';
          }
        },
      ],
      "createdRow": function (row, data, index) { },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
