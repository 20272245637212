import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { BeneficioComponent } from './body/cadastro/beneficio/beneficio.component';

const routes: Routes = [
  // {
  //   path: 'Home',
  //   component: DashboardComponent,
  //   children: [
  //     { path: 'Cadastro', component: BeneficioComponent, outlet: 'beneficio' }
  //   ]
  // }, 
  
  // {path: '404', component: DashboardComponent},
  // {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
