<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Absenteísmo
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Absenteísmo</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Mês</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="mesList" IdSelect="selMes"
                    [Value]="mesSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Ano</label>
                  <input type="text" mask="0000" class="form-control" id="txtAno" name="txtAno" placeholder="yyyy">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
            </td>
          </tr>
        </table>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>CPF do Colaborador</th>
                  <th>Horas Totais</th>
                  <th>Horas Não Trabalhadas</th>
                  <th>Absenteísmo</th>
                  <th>Mês</th>
                  <th>Ano</th>
                  <th>Data da Importação</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>