import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LotacaoUnidadeComponent } from './unidade/unidade.component';
import { LotacaoPlanoComponent } from './plano/plano.component';

@Component({
  selector: 'app-lotacao',
  templateUrl: './lotacao.component.html',
  styleUrls: ['./lotacao.component.scss']
})
export class LotacaoComponent implements OnInit {



  constructor(
    private location: Location,
    private router: Router,
    private resolver: ComponentFactoryResolver,
  ) { }

  @ViewChild('tabRouterUnidade', { static: true, read: ViewContainerRef }) tabRouterUnidade: ViewContainerRef;
  @ViewChild('tabRouterPlano', { static: true, read: ViewContainerRef }) tabRouterPlano: ViewContainerRef;
  @ViewChild('tabRouterUnidadeDiv', { static: true }) tabRouterUnidadeDiv: ElementRef;
  @ViewChild('tabRouterPlanoDiv', { static: true }) tabRouterPlanoDiv: ElementRef;

  obj: any;
  objUnidade: any;
  factoryUnidade: any;
  objPlano: any;
  factoryPlano: any;
  unidadeVisible = false;
  planoVisible = false;


  ngOnInit() {
    this.changeTab('unidade');

  }

  changeTab(tab) {
    let component;
    if (tab == 'unidade') {
      $('#liUnidade').addClass('active');
      $('#liPlano').removeClass('active');
      $('#tabRouterPlano').addClass('hidden');
      this.tabRouterPlanoDiv.nativeElement.style.display = 'none';
      this.tabRouterUnidadeDiv.nativeElement.style.display = 'inline';
      component = LotacaoUnidadeComponent;
      if (typeof component == 'function') {
        this.unidadeVisible = true;
        this.planoVisible = false;
        if (!this.objUnidade) {
          this.factoryUnidade = this.resolver.resolveComponentFactory(component);
          this.tabRouterUnidade.clear();
          this.objUnidade = this.tabRouterUnidade.createComponent(this.factoryUnidade);
        }

      }
    } else if (tab == 'plano') {
      $('#liPlano').addClass('active');
      $('#liUnidade').removeClass('active');
      this.tabRouterPlanoDiv.nativeElement.style.display = 'inline';
      this.tabRouterUnidadeDiv.nativeElement.style.display = 'none';
      component = LotacaoPlanoComponent;
      if (typeof component == 'function') {
        this.unidadeVisible = false;
        this.planoVisible = true;
        if (!this.objPlano) {
          this.factoryPlano = this.resolver.resolveComponentFactory(component);
          this.tabRouterPlano.clear();
          this.objPlano = this.tabRouterPlano.createComponent(this.factoryPlano);
        }

      }
    }
  }

}
