import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoBeneficioService {

    routePrefix: string = 'Beneficio'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    solicitar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/SolicitaBeneficio`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    cancelarSolicitacao(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/CancelarSolicitacaoBeneficio?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorColaboradorId(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarTudoBeneficioColaboradorPorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}