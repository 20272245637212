<aside class="main-sidebar">
  <section class="sidebar">
    <ul class="sidebar-menu">
      <li class="treeview" *ngIf="menuData.cadastro.length > 0">
        <a id="cadastro">
          <i class="fa fa-edit"></i> <span>Cadastro</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.cadastro">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'cadastro', item: item.Id, isMenu: true}); selectItemMenu($event, 'cadastro')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'cadastro', item: value.Id, isMenu: true}); selectItemMenu($event, 'cadastro')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="treeview" *ngIf="menuData.importacao.length > 0">
        <a id="importacao">
          <i class="fa fa-laptop"></i> <span>Importação</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.importacao">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'importacao', item: item.Id, isMenu: true}); selectItemMenu($event, 'importacao')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'importacao', item: value.Id, isMenu: true}); selectItemMenu($event, 'importacao')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>

      </li>
      <li class="treeview" *ngIf="menuData.relatorio.length > 0">
        <a id="relatorio">
          <i class="fa fa-pie-chart"></i> <span>Relatório</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.relatorio">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'relatorio', item: item.Id, isMenu: true}); selectItemMenu($event, 'relatorio')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'relatorio', item: value.Id, isMenu: true}); selectItemMenu($event, 'relatorio')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="treeview" *ngIf="menuData.solicitacao.length > 0">
        <a id="solicitacao">
          <i class="fa fa-fw fa-hand-paper-o"></i> <span>Solicitação</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.solicitacao">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'solicitacao', item: item.Id, isMenu: true}); selectItemMenu($event, 'solicitacao')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'solicitacao', item: value.Id, isMenu: true}); selectItemMenu($event, 'solicitacao')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="treeview" *ngIf="menuData.programacao.length > 0">
        <a id="programacao">
          <i class="fa fa-calendar"></i> <span>Programação</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.programacao">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'programacao', item: item.Id, isMenu: true}); selectItemMenu($event, 'programacao')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'programacao', item: value.Id, isMenu: true}); selectItemMenu($event, 'programacao')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="treeview" *ngIf="menuData.lancamento.length > 0">
        <a id="lancamento">
          <i class="fa fa-share"></i> <span>Lançamento</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.lancamento">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'lancamento', item: item.Id, isMenu: true}); selectItemMenu($event, 'lancamento')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'lancamento', item: value.Id, isMenu: true}); selectItemMenu($event, 'lancamento')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="treeview" *ngIf="menuData.aprovacao.length > 0">
        <a id="aprovacao">
          <i class="fa fa-check-square"></i> <span>Aprovação</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li style="padding-left: 20px;" *ngFor="let item of menuData.aprovacao">
            <a *ngIf="!item.subMenus"
              (click)="changePageMenu.emit({pai: 'aprovacao', item: item.Id, isMenu: true}); selectItemMenu($event, 'aprovacao')">
              {{item.SubTitulo}}
            </a>
            <a *ngIf="item.subMenus && item.subMenus.length > 0">
              {{item.SubTitulo}}
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul *ngIf="item.subMenus && item.subMenus.length > 0" class="treeview-menu">
              <li *ngFor="let value of item.subMenus">
                <a
                  (click)="changePageMenu.emit({pai: 'aprovacao', item: value.Id, isMenu: true}); selectItemMenu($event, 'aprovacao')">
                  {{value.SubTituloNivel1}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <!-- /.sidebar -->
</aside>