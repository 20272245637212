import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoBeneficioDependenteService {

    routePrefix: string = 'Beneficio'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}DependentePorDependenteId?id=${id}`,)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorColaboradorId(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarTudo${this.routePrefix}ColaboradorPorColaboradorId?id=${id}`,)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    

    solicitar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Solicita${this.routePrefix}Dependente`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    cancelar(data: any = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/Cancela${this.routePrefix}Dependente?id=${data.Id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovarSolicitacao(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AprovarSolicitacao${this.routePrefix}Dependente?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovarSolicitacao(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/ReprovarSolicitacao${this.routePrefix}Dependente?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    aprovarCancelamento(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/AprovarCancelamento${this.routePrefix}Dependente?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    reprovarCancelamento(id = ''): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/ReprovarCancelamento${this.routePrefix}Dependente?id=${id}`, {})
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }


    buscarStatus(): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}Solicitacao/BuscarTudoStatusSolicitacao`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }


}