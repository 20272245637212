import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "src/app/@core/core.module";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { RelatorioCartaoPontoComponent } from "./cartao-de-ponto/cartao-de-ponto.component";
import { RelatorioDivergenciaComponent } from "./divergencia/divergencia.component";
import { RelatorioFeriasComponent } from "./ferias/ferias.component";
import { RelatorioBancoDeHorasComponent } from "./banco-de-horas/banco-de-horas.component";
import { RelatorioAvisoFeriasComponent } from "./ferias-aviso/ferias-aviso.component";
import { RelatorioReciboFeriasComponent } from "./ferias-recibo/ferias-recibo.component";
import { RelatorioAbsenteismoComponent } from "./absenteismo/absenteismo.component";
import { RelatorioHoleriteComponent } from "./holerite/holerite.component";
import { RelatorioInformeRendimentosComponent } from "./informe-rendimentos/informe-rendimentos.component";
import { RelatorioFuncionarioComponent } from "./funcionario/funcionario.component";
import { RelatorioHoraExtraComponent } from "./hora-extra/hora-extra.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RelatorioCartaoPontoComponent,
    RelatorioDivergenciaComponent,
    RelatorioFeriasComponent,
    RelatorioBancoDeHorasComponent,
    RelatorioAvisoFeriasComponent,
    RelatorioReciboFeriasComponent,
    RelatorioAbsenteismoComponent,
    RelatorioHoleriteComponent,
    RelatorioInformeRendimentosComponent,
    RelatorioFuncionarioComponent,
    RelatorioHoraExtraComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    NgMultiSelectDropDownModule,
    FormsModule,
  ],
  entryComponents: [
    RelatorioCartaoPontoComponent,
    RelatorioDivergenciaComponent,
    RelatorioFeriasComponent,
    RelatorioBancoDeHorasComponent,
    RelatorioAvisoFeriasComponent,
    RelatorioReciboFeriasComponent,
    RelatorioAbsenteismoComponent,
    RelatorioHoleriteComponent,
    RelatorioInformeRendimentosComponent,
    RelatorioFuncionarioComponent,
    RelatorioHoraExtraComponent,
  ],
})
export class RelatorioModule {}
