import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/*Custom Files*/
import { LoginRoutingModule } from './login-routing.module';
import { RecuperarSenhaRoutingModule } from '../recuperar-senha/recuperar-senha-routing.module';
import { LoginComponent } from './login.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    RecuperarSenhaRoutingModule,
    NgxMaskModule.forChild()
  ],
})
export class LoginModule { }
