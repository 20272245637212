import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { UtilsService } from '../../utils/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoAlteracaoCadastralService {

    routePrefix: string = 'AlteracaoCadastral'
    constructor(
        private httpClient: HttpClient,
        private utilsService: UtilsService,
    ) { }

    pesquisar(id = ''): Observable<any> {
        return this.httpClient.get<Array<any>>(`${environment.urlBase}${this.routePrefix}/BuscarSolicitacaoAlteracaoPorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    solicitar(data = {}): Observable<any> {
        return this.httpClient.post<any>(`${environment.urlBase}${this.routePrefix}/SolicitarAlteracao`, data)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    pesquisarPorColaboradorId(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarDadosPorColaboradorId?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoSolicitacaoAlteracao(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarFotoSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoCarteiraHabilitacao(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarCarteiraHabilitacaoSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

    buscarFotoComprovanteResidencia(id = ''): Observable<any> {
        return this.httpClient.get<any>(`${environment.urlBase}${this.routePrefix}/BuscarComprovanteResidenciaSolicitacaoAlteracao?id=${id}`)
            .pipe(
                retry(1),
                catchError(this.utilsService.handleError));
    }

}