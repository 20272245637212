<form id="formularioEmpregador">
 <!--  <div class="" style="text-align: right; margin-bottom: 10px;">
    <input name="Concluir cadastro" value="Concluir cadastro" (click)="salvar(true)" readonly="readonly" class="btn btn-info" />
</div> -->
  <div style="margin-left: 0px;" [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'block'">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeColaboradorEmpregador" name="txtNomeColaboradorEmpregador"
                  placeholder="Nome do Colaborador">
                <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaboradorEmpregador')"
                    title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <input type="text" id="txtEmpresaEmpregador" name="txtEmpresaEmpregador" class="form-control" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px;">
    <div class="box box-primary">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Cargo</label>
              <select-component (changeValue)="changeSelectValues($event)"  [Disabled]="!empresaIdSelected ? true : false" [Data]="cargoList" IdSelect="selCargo"
                [Value]="cargoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Centro de Custo</label>
              <select-component (changeValue)="changeSelectValues($event)" [Disabled]="!empresaIdSelected ? true : false" [Data]="centroDeCustoList"
                IdSelect="selCentro" [Value]="centroDeCustoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Lotação</label>
              <!-- BUSCAR OS DADOS DA TABELA LOTAÇÃO UNIDADE/PLANO -->
              <select-component (changeValue)="changeSelectValues($event)" [Disabled]="!empresaIdSelected ? true : false" [Data]="lotacaoList" IdSelect="selLotacao"
                [Value]="lotacaoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Tipo de Registro</label>
              <!-- BUSCAR OS DADOS DA TABELA TIPO REGISTRO -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoDeRegistroList"
                IdSelect="selTipoRegistro" [Value]="tipoDeRegistroSelected">
              </select-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Tipo de Mão de Obra</label>
              <!-- BUSCAR OS DADOS DA TABELA TIPO DE MÃO DE OBRA -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoDeMaoDeObraList"
                IdSelect="selTipoMaoObra" [Value]="tipoDeMaoDeObraSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Unidade de Negócio</label>
              <!-- BUSCAR OS DADOS DA TABELA UNIDADE DE NEGÓCIO -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="unidadeDeNegocioList"
                IdSelect="selUnidadeNegocio" [Value]="unidadeDeNegocioSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Número do Cartão de Ponto</label>
              <input type="text" class="form-control" id="txtCartaoPonto" name="txtCartaoPonto"
                placeholder="Número do Cartão de Ponto">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Situação</label>
              <input type="text" class="form-control" id="txtSituacao" name="txtSituacao" placeholder="Situação">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Turno</label>
              <!-- BUSCAR OS DADOS DA TABELA TURNO -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="turnoList" IdSelect="selTurno"
                [Value]="turnoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Categoria Salarial</label>
              <!-- BUSCAR OS DADOS DA TABELA CATEGORIA SALARIAL -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="categoriaSalarialList"
                IdSelect="selCategoriaSal" [Value]="categoriaSalarialSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Salário (R$)</label>
              <input type="text" class="form-control" id="txtSalario" name="txtSalario" placeholder="0,00">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data de Admissão</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right" id="txtDataAdmissao"
                  name="txtDataAdmissao" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Sindicato</label>
              <!-- BUSCAR OS DADOS DA TABELA SINDICATO -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="sindicatoList"
                IdSelect="selSindicato" [Value]="sindicatoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label></label>
              <div class="modal-label">
                <input id="cbxEscalaAlternada" type="checkbox"> <label style="margin-left: 4px;">Escala Alternada</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data do Último Exame</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataUltimoExame" name="txtDataUltimoExame" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Data de Validade do Aso</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataValidadeAso" name="txtDataValidadeAso" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Data Próximo Aso</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataProximoAso" name="txtDataProximoAso" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>1º Vencimento do Contrato</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataVencimentoContrato1" name="txtDataVencimentoContrato1" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label></label>
              <div class="modal-label">
                <input id="cbxProrrogaVencimento1" type="checkbox"> <label style="margin-left: 4px;">Prorroga 1º Vencimento</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>2º Vencimento do Contrato</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataVencimentoContrato2" name="txtDataVencimentoContrato2" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label></label>
              <div class="modal-label">
                <input id="cbxProrrogaVencimento2" type="checkbox"> <label style="margin-left: 4px;">Prorroga 2º Vencimento</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>3º Vencimento do Contrato</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right"
                  id="txtDataVencimentoContrato3" name="txtDataVencimentoContrato3" placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
             <td align="left"><button  [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'inline-flex'" type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button"  [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'inline-flex'" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                class="btn btn-primary btn-margin" />
              <input name="Salvar" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar(false)"
                class="btn btn-info pull-right btn-margin" />
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</form>
<div class="box box-body" [style.display]="colaboradorType == 'pre-admissao' ? 'none' : 'block'" >
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome do Colaborador</th>
            <th>Nome da Empresa</th>
            <th>Sindicato</th>
            <th>Cargo</th>
            <th>Centro de Custo</th>
            <th>Lotação</th>
            <th>Salário (R$)</th>
            <th>Data de Admissão</th>
            <th>Data de Cadastro</th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalColaboradorEmpregador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Colaborador</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalColaboradorEmpregador style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
