import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/@core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { LancamentoAtestadoComponent } from './atestado/atestado.component';

@NgModule({
  declarations: [
    LancamentoAtestadoComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild()
  ],
  entryComponents: [
    LancamentoAtestadoComponent,
  ]
})
export class LancamentoModule { }
