import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from '../../../../@core/@service/utils/utils';
import { SolicitacaoValeTransporteService } from 'src/app/@core/@service/solicitacao/vale-transporte/vale-transporte.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroLinhaVTService } from 'src/app/@core/@service/cadastro/linha-vt/linha-vt.service';
import { CadastroOperadoraVTService } from 'src/app/@core/@service/cadastro/operadora-vt/operadora-vt.service';
import { ValeTransporteUtilizacaoEnum } from 'src/app/@core/@enum/enum';
import { UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from 'src/app/@core/@enum/enum';

declare var $;

@Component({
  selector: 'app-solicitacao-vale-transporte',
  templateUrl: './vale-transporte.component.html',
  styleUrls: ['./vale-transporte.component.scss']
})

export class SolicitacaoValeTransporteComponent implements OnInit {

  gridData = [];
  userLogged: any;
  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };
  colaboradorGridData = [];
  colaboradorSelected = ''
  empresaIdSelected = '';
  gestorSelected = '';
  colaboradorType = '';
  gestorId = '';
  isEdit = false;
  solicitacaoIdSelected = '';
  operadoraVTSelected = ''
  operadoraVTList: Array<any> = [];
  linhaVTSelected = '';
  valorLinha: any;
  valorTotal: any;
  linhaVTList: Array<any> = [];
  utilizacaoSelected = ''
  utilizacaoList: Array<any> = [];
  situacaoSelected = ''
  situacaoList: Array<any> = [];
  enviaEmail: any = false;
  utilziacaoEnum: any = ValeTransporteUtilizacaoEnum;

  diasSemana = [];

  dataTable: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;
  constructor(
    private utilService: UtilsService,
    private moduleService: SolicitacaoValeTransporteService,
    private colaboradorService: CadastroColaboradorService,
    private empresaServie: CadastroEmpresaService,
    private linhaVTService: CadastroLinhaVTService,
    private operadoraVTService: CadastroOperadoraVTService,
  ) { }

  ngOnInit() {
    const currUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorSelected = currUser ? currUser.UsuarioColaboradorId : '';
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR) {
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.colaboradorType = 'colaborador';
        this.enviaEmail = true;
        //this.getDataGrid();
        this.getInitialData(true);
      } else{
        this.colaboradorType = 'rh';
        this.getInitialData(false);
      }
    }

  }

  selectDiasSemana(event) {
    if (this.diasSemana.length > 0) {
      const filter = this.diasSemana.filter(val => val == event.target.value);
      if (filter.length > 0) {
        this.diasSemana.splice(this.diasSemana.findIndex(val => val == event.target.value), 1);
      } else {
        this.diasSemana.push(event.target.value);
      }
    } else {
      this.diasSemana.push(event.target.value);
    }
  }


  getInitialData(buscaDadosGrid) {
    this.moduleService.buscarTudoSituacao().subscribe(
      res => {
        this.situacaoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Nome
          })
        ).sort(this.utilService.dynamicSort('Description'));
        this.moduleService.buscarTudoUtilizacao().subscribe(
          res => {
            this.utilizacaoList = res.map(val =>
              ({
                Id: val.Id,
                Description: val.Nome
              })
            ).sort(this.utilService.dynamicSort('Description'));
            this.operadoraVTService.buscarTudoOperadoraVT().subscribe(
              res => {
                this.operadoraVTList = res.map(val =>
                  ({
                    Id: val.Id,
                    Description: val.Nome
                  })
                ).sort(this.utilService.dynamicSort('Description'));
              if(buscaDadosGrid)
              {this.getDataGrid();}
              },
              err => {
                console.log(err);
              }
            );
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selOperadoraVT') {
      $('#selOperadoraVT').removeClass('element-invalid');
      this.operadoraVTSelected = event.target.value;
      this.linhaVTSelected = '';
      if(this.colaboradorType == 'rh'){
        this.linhaVTService.buscarTudoLinhaVTPorOperadoraVTIdColaboradorId(event.target.value,this.colaboradorSelected).subscribe(
          res => {
            this.linhaVTList = res.map(val =>
              ({
                Id: val.Id,
                Description: val.NomeLinha
              })
            ).sort(this.utilService.dynamicSort('Description'));
          },
          err => {
            console.log(err);
          }
        );
      }else{
      this.linhaVTService.buscarTudoLinhaVTPorOperadoraVTIdColaboradorId(event.target.value,this.userLogged.colaboradorId).subscribe(
        res => {
          this.linhaVTList = res.map(val =>
            ({
              Id: val.Id,
              Description: val.NomeLinha
            })
          ).sort(this.utilService.dynamicSort('Description'));
        },
        err => {
          console.log(err);
        }
      );
    }
    } else if (event.srcElement.id == 'selLinhaVT') {
      this.linhaVTSelected = event.target.value;
      $('#selLinhaVT').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selUtilizacao') {
      this.utilizacaoSelected = event.target.value;
      $('#selUtilizacao').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selSituacao') {
      this.situacaoSelected = event.target.value;
      $('#selSituacao').removeClass('element-invalid');
    }
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisar(this.colaboradorSelected).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            operadoraVT: val.OperadoraVT ? val.OperadoraVT.Nome : '-',
            linhaVT: val.LinhaVT ? val.LinhaVT.NomeLinha : '-',
            valor: val.LinhaVT ? val.LinhaVT.ValorLinha : '-',
            utilizacao: val.ValeTransporteUtilizacao ? val.ValeTransporteUtilizacao.Nome : '-',
            situacao: val.ValeTransporteSituacao ? val.ValeTransporteSituacao.Nome : '-',
            status: val.SolicitacaoStatus ? val.SolicitacaoStatus.Nome : '-',
            dataSolicitacao: val.DataSolicitacao,
            dataConclusao: val.DataConclusao,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }
  clearInputvalues(type = '') {
    ($("#formulario") as any).valid();
    this.isEdit = false;
    if (type = 'solicitar') {
      $('#selOperadoraVT').removeClass('element-invalid');
      $('#selLinhaVT').removeClass('element-invalid');
      $('#selUtilizacao').removeClass('element-invalid');
      $('#selSituacao').removeClass('element-invalid');
    }
    // Zera os campos
    this.situacaoSelected = '';
    this.utilizacaoSelected = '';
    this.operadoraVTSelected = '';
    this.linhaVTSelected = '';
  }

  getValuesFromInput() {
    return {
      ColaboradorId: this.colaboradorSelected,
      OperadoraVTId: this.operadoraVTSelected,
      LinhaVTId: this.linhaVTSelected,
      ValeTransporteUtilizacaoId: this.utilizacaoSelected,
      ValeTransporteSituacaoId: this.situacaoSelected,
      DiasSemana: Number(this.utilizacaoSelected) == ValeTransporteUtilizacaoEnum.DIAS_DA_SEMANA ? this.diasSemana.sort().join(',') : '',
      enviaEmail: this.enviaEmail,
    }
  }

  confirmar() {
    const linhaVT: any = {
         Id: this.linhaVTSelected
        }
    this.linhaVTService.pesquisar(linhaVT).subscribe(
      res => {
        this.valorLinha = res[0].ValorLinha.toString();
        if (this.utilizacaoSelected == '3')
          this.valorTotal = this.valorLinha * 2;
        else
          this.valorTotal = this.valorLinha;

        this.linhaVTList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.NomeLinha
          })
        ).sort(this.utilService.dynamicSort('Description'));
        Swal.fire({
          position: 'top',
          width: 600,
          title: 'Verifique os dados antes de seguir',
          html: "<div align=\"left\"> "+
          "<b>Operadora VT:</b> " + $("#selOperadoraVT option:selected").text() + "</br>" +
          "<b>Linha VT:</b> " + $("#selLinhaVT option:selected").text()+ "</br>" +
          "<b>Valor da Linha (R$):</b> " + Number(this.valorLinha).toFixed(2).toString().replace(".",",") + "</br>" +
          "<b>Situação:</b> " + $("#selSituacao option:selected").text()+ "</br>" +
          "<b>Utilização:</b> " + $("#selUtilizacao option:selected").text() + "</br>" +
          "<b>Valor Total (R$):</b> " + Number(this.valorTotal).toFixed(2).toString().replace(".",",") +  "</br>" +"</div>",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3c8dbc',
          cancelButtonColor: '#DD4B39',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Solicitar'
        }).then((result) => {
          if (result.isConfirmed) {
            const obj: any = this.getValuesFromInput();
            if (this.isEdit) {
              console.log(obj);
              obj.Id = this.solicitacaoIdSelected;
              this.moduleService.editar(obj).subscribe(
                res => {
                  if (res.Codigo && res.Codigo == '600') {
                    Swal.fire({
                      position: 'top',
                      icon: 'error',
                      title: res.Descricao || 'Ocorreu um erro',
                      showConfirmButton: false,
                      timer: 2000
                    });
                  } else {
                    this.clearInputvalues();
                    this.getDataGrid();
                    this.utilService.alertSwal('edit-success');
                  }
                },
                err => {
                  console.log(err);
                  this.utilService.alertSwal('edit-error');
                }
              );
            } else {
            this.moduleService.solicitar(obj).subscribe(
              res => {
                if (res.Codigo && res.Codigo == '600') {
                  Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: res.Descricao || 'Ocorreu um erro',
                    showConfirmButton: false,
                    timer: 2000
                  });
                } else {
                  this.clearInputvalues('solicitar');
                  this.getDataGrid();
                  this.utilService.alertSwal('add-success');
                }
              },
              err => {
                console.log(err);
                this.utilService.alertSwal('add-error');
              }
            );
          }
          }
        })
      },
      err => {
        console.log(err);
      }
    );
  }

  solicitar() {
    if (!this.operadoraVTSelected || !this.linhaVTSelected || !this.situacaoSelected || !this.utilizacaoSelected) {
      ($("#formulario") as any).valid();
      if (!this.operadoraVTSelected) {
        $('#selOperadoraVT').addClass('element-invalid');
      } else {
        $('#selOperadoraVT').removeClass('element-invalid');
      }
      if (!this.linhaVTSelected) {
        $('#selLinhaVT').addClass('element-invalid');
      } else {
        $('#selLinhaVT').removeClass('element-invalid');
      }
      if (!this.situacaoSelected) {
        $('#selSituacao').addClass('element-invalid');
      } else {
        $('#selSituacao').removeClass('element-invalid');
      }
      if (!this.utilizacaoSelected) {
        $('#selUtilizacao').addClass('element-invalid');
      } else {
        $('#selUtilizacao').removeClass('element-invalid');
      }
    } else {
      if (($("#formulario") as any).valid()) {
        this.confirmar();
      }
    }
  }

  loadDataTable(data) {
    const self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "operadoraVT"
        },
        {
          "data": "linhaVT"
        },
        {
          "data": "LinhaVT.ValorLinha", "orderable": true, "render": function (data) {
            return data ? 'R$ ' + data.toLocaleString('pt-br', { minimumFractionDigits: 2 }): '-';
          }
        },
        {
          "data": "utilizacao"
        },
        {
          "data": "situacao"
        },
        {
          "data": "status"
        },
        {
          "data": 'dataSolicitacao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": 'dataConclusao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : 'Não Concluído';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[8]).on("click", function (e) {
        // Seta modo edição
        self.isEdit = true;

        // Preencher campos, com a linha selecionada
        ;
        self.solicitacaoIdSelected = data.Id;
        self.operadoraVTSelected = data.OperadoraVTId;
        self.linhaVTSelected = '';
        self.linhaVTService.buscarTudoLinhaVTPorOperadoraVTId(data.OperadoraVTId).subscribe(
          res => {
            self.linhaVTList = res.map(val =>
              ({
                Id: val.Id,
                Description: val.NomeLinha
              })
            ).sort(self.utilService.dynamicSort('Description'));
          },
          err => {
            console.log(err);
          }
        );
        self.linhaVTSelected = data.LinhaVT.Id;
        self.situacaoSelected = data.ValeTransporteSituacao.Id;
        self.utilizacaoSelected = data.ValeTransporteUtilizacaoId;
      });
     },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    if (idModal == 'modalColaborador') {
      if ($('#txtNomeColaborador').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNomeAtivo($('#txtNomeColaborador').val().toString()).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          $('#txtNomeColaborador').val(data.nome);
          self.closeModal('modalColaborador');
          self.getDataGrid();
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }
}
