import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorEstrangeiroService } from 'src/app/@core/@service/cadastro/colaborador/estrangeiro/estrangeiro.service';
import Swal from 'sweetalert2';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum } from 'src/app/@core/@enum/enum';

declare var $;
@Component({
  selector: 'app-colaborador-estrangeiro',
  templateUrl: './estrangeiro.component.html',
  styleUrls: ['./estrangeiro.component.scss']
})
export class ColaboradorEstrangeiroComponent implements OnInit {

  gridData = [];

  colaboradorGridData = [];

  isEdit = false;

  empresaIdSelected = '';

  estrangeiroIdSelected = '';

  colaboradorSelected = '';

  tipoVistoEstrangeiroList: Array<object> = [];
  tipoVistoEstrangeiroSelected = '';

  dataTable: any;
  userLogged: any;
  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };

  @ViewChild('grid', { static: true }) grid: ElementRef;

  @ViewChild('gridModalColaborador', { static: true }) gridModalColaborador: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorEstrangeiroService,
    private colaboradorService: CadastroColaboradorService,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if(this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO){
      ($("#btnSalvarEstrangeiro") as any).hide();
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtDataEmissaoPassaporte") as any).datepicker({
      autoclose: true
    });
    ($("#txtSalario") as any).maskMoney({ prefix: 'R$ ', thousands: '.', decimal: ',' });
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    this.colaboradorService.BuscarTudoTipoVisto().subscribe(
      res => {
        this.tipoVistoEstrangeiroList = res.filter(val => val.Nome != null).map(val =>
          ({
            Id: val.Id,
            Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
          })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selVistoEstrangeiro') {
      this.tipoVistoEstrangeiroSelected = event.target.value;
      $('#selVistoEstrangeiro').removeClass('element-invalid');
    }
  }

  validarCampos() {
    const self = this;
    ($("#formularioEstrangeiro") as any).validate({
      // Define as regras
      rules: {
        txtNomeColaboradorEstrangeiro: {
          required: true,
          colaboradorCheck: true,
        },
        txtOrigem: {
          required: true
        },
        selVistoEstrangeiro: {
          required: true
        },
        txtPassaporte: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNomeColaboradorEstrangeiro: {
          required: "Informe o Nome do Colaborador"
        },
        txtOrigem: {
          required: "Informe a Origem"
        },
        selVistoEstrangeiro: {
          required: "Selecione o Tipo Visto Estrangeiro"
        },
        txtPassaporte: {
          required: "Informe o Passaporte"
        }
      },
    });
    $.validator.addMethod("colaboradorCheck", function (value, element) {
      return self.colaboradorSelected ? true : false;
    }, 'Selecione o colaborador');
    ($("#formularioEstrangeiro") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    this.moduleService.pesquisarPorUsuarioId(data,this.userLogged.UsuarioColaboradorId).subscribe(
      res => {
        this.gridData = res.map(val =>
          ({
            id: val.Id,
            nomeColaborador: val.Colaborador.Nome,
            nomeEmpresa: val.Colaborador.Empresa.Nome,
            origem: val.Origem,
            tipoVisto: val.ColaboradorEstrangeiroTipoVisto.Nome,
            passaporte: val.Passaporte,
            orgaoEmissor: val.OrgaoEmissorPassaporte,
            paisEmissor: val.PaisEmissorPassaporte,
            uf: val.UFPassaporte,
            dataEmissao: val.DataEmissaoPassaporte,
            portariaNat: val.PortariaNaturalizacao,
            indentidadeEstrangeira: val.IdentidadeEstrangeira,
            validadeIndentidadeEstrangeira: val.ValidadeIdentidadeEstrangeira,
            anoChegada: val.AnoChegada,
            dataCadastro: val.DataCadastro,
            ...val
          })
        ).sort(this.utilService.dynamicSort('nomeColaborador'));
        this.loadDataTable(this.gridData);
      },
      err => {
        console.log(err);
      }
    );
  }
  clearInputvalues(type = '') {
    if (type == 'salvar') {
      $('#selVistoEstrangeiro').removeClass('element-invalid');
      $("#formularioEstrangeiro").valid();
    }
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos

    this.tipoVistoEstrangeiroSelected = '';
    this.colaboradorSelected = '';
    this.empresaIdSelected = '';
    $('#txtEmpresaEstrangeiro').val('');
    $('#txtNomeColaboradorEstrangeiro').val('');

    $('#txtOrigem').val('');
    $('#txtPassaporte').val('');
    $('#txtOrgaoPassaporte').val('');
    $('#txtPaisPassaporte').val('');
    $('#txtUFPassaporte').val('');
    $('#txtDataEmissaoPassaporte').val('');
    $('#txtPortariaNaturalizazao').val('');
    $('#txtIdentEstrangeira').val('');
    $('#txtValidadeIdentEstrangeira').val('');
    $('#txtAnoChegada').val('');
  }

  getValuesFromInput() {
    const dataEmissao = $('#txtDataEmissaoPassaporte').val().toString().trim().split('/');
    return {
      ColaboradorId: this.colaboradorSelected,
      Origem: $('#txtOrigem').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      ColaboradorEstrangeiroTipoVistoId: this.tipoVistoEstrangeiroSelected,
      Passaporte: $('#txtPassaporte').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      OrgaoEmissorPassaporte: $('#txtOrgaoPassaporte').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      PaisEmissorPassaporte: $('#txtPaisPassaporte').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      UFPassaporte: $('#txtUFPassaporte').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      DataEmissaoPassaporte: dataEmissao.length >= 3 ? `${dataEmissao[2]}-${dataEmissao[1]}-${dataEmissao[0]}` : '',
      PortariaNaturalizacao: $('#txtPortariaNaturalizazao').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      IdentidadeEstrangeira: $('#txtIdentEstrangeira').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      ValidadeIdentidadeEstrangeira: $('#txtValidadeIdentEstrangeira').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
      AnoChegada: $('#txtAnoChegada').val().toString().trim(),
    }
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;
    if (idModal == 'modalColaboradorEstrangeiro') {
      if ($('#txtNomeColaboradorEstrangeiro').val().toString().length > 0) {
        this.colaboradorService.pesquisarColaboradorPorNomePorUsuarioId($('#txtNomeColaboradorEstrangeiro').val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.colaboradorGridData = res.map(val =>
              ({
                id: val.Id,
                nome: val.Nome,
                cpf: val.CPF,
                status: val.ColaboradorStatus.Nome,
                dataAdmissao: val.DataAdmissao,
                ...val
              })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalColaborador(this.colaboradorGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Ocorreu um erro ao buscar a lista de \n colaboradores!',
              showConfirmButton: false,
              timer: 2000
            });
            console.log(err);
          }
        );
      }
    }
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar() {
    if (!this.tipoVistoEstrangeiroSelected) {
      ($("#formularioEstrangeiro") as any).valid();
      if (!this.tipoVistoEstrangeiroSelected) {
        $('#selVistoEstrangeiro').addClass('element-invalid');
      } else {
        $('#selVistoEstrangeiro').removeClass('element-invalid');
      }
    } else {
      if (($("#formularioEstrangeiro") as any).valid()) {
        const obj: any = this.getValuesFromInput();
        if (this.isEdit) {
          obj.Id = this.estrangeiroIdSelected;
          this.moduleService.editar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionar(obj).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {
    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    ;
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nomeColaborador"
        },
        {
          "data": "nomeEmpresa"
        },
        {
          "data": "origem"
        },
        {
          "data": "tipoVisto"
        },
        {
          "data": "passaporte"
        },
        {
          "data": "orgaoEmissor"
        },
        {
          "data": "paisEmissor"
        },
        {
          "data": "uf"
        },
        {
          "data": 'dataEmissao', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": "portariaNat"
        },
        {
          "data": "indentidadeEstrangeira"
        },
        {
          "data": "validadeIndentidadeEstrangeira"
        },
        {
          "data": "anoChegada"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[14]).on("click", function (e) {
          // Seta modo edição
          self.isEdit = true;
          self.estrangeiroIdSelected = data.Id;

          ($("#txtDataEmissaoPassaporte") as any).datepicker('setDate', data.DataEmissaoPassaporte ? new Date(data.DataEmissaoPassaporte) : '');
          self.tipoVistoEstrangeiroSelected = data.ColaboradorEstrangeiroTipoVistoId;
          self.colaboradorSelected = data.ColaboradorId;
          self.empresaIdSelected = data.Colaborador.Empresa.Id;
          $('#txtEmpresaEstrangeiro').val(data.Colaborador.Empresa.Nome);
          $('#txtNomeColaboradorEstrangeiro').val(data.Colaborador.Nome);
          $('#txtOrigem').val(data.Origem);
          $('#txtPassaporte').val(data.Passaporte);
          $('#txtOrgaoPassaporte').val(data.OrgaoEmissorPassaporte);
          $('#txtPaisPassaporte').val(data.PaisEmissorPassaporte);
          $('#txtUFPassaporte').val(data.UFPassaporte);
          $('#txtPortariaNaturalizazao').val(data.PortariaNaturalizacao);
          $('#txtIdentEstrangeira').val(data.IdentidadeEstrangeira);
          $('#txtValidadeIdentEstrangeira').val(data.ValidadeIdentidadeEstrangeira);
          $('#txtAnoChegada').val(data.AnoChegada);
          if (!self.tipoVistoEstrangeiroSelected) {
            $('#selVistoEstrangeiro').addClass('element-invalid');
          } else {
            $('#selVistoEstrangeiro').removeClass('element-invalid');
          }
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalColaborador(data) {
    let self = this;
    this.dataTable = $(this.gridModalColaborador.nativeElement);
    var table = this.dataTable.DataTable();
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
        {
          "data": "status"
        },
        {
          "data": "dataAdmissao"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.colaboradorSelected = data.id;
          self.empresaIdSelected = data.Empresa.Id;
          $('#txtEmpresaEstrangeiro').val(data.Empresa.Nome);
          $('#txtNomeColaboradorEstrangeiro').val(data.nome);
          self.getSelectData();
          self.closeModal('modalColaboradorEstrangeiro');
          $("#formularioEstrangeiro").valid();
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });

  }

}
