import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { UtilsService } from 'src/app/@core/@service/utils/utils';
import { CadastroColaboradorDadosPrincipaisService } from 'src/app/@core/@service/cadastro/colaborador/dados-principais/dados-principais.service';
import Swal from 'sweetalert2';
import { CadastroCargoUnidadeService } from 'src/app/@core/@service/cadastro/cargo/unidade/unidade.service';
import { CadastroEmpresaService } from 'src/app/@core/@service/cadastro/empresa/empresa.service';
import { CadastroColaboradorService } from 'src/app/@core/@service/cadastro/colaborador/colaborador.service';
import { UsuarioColaboradorTipoEnum, ColaboradorStatusEnum } from 'src/app/@core/@enum/enum';
import { PreAdmissaoComponent } from '../pre-admissao.component';
import {  Router } from '@angular/router';

declare var $;
@Component({
  providers:[PreAdmissaoComponent ],
  selector: 'app-pre-admissao-dados-principais',
  templateUrl: './dados-principais.component.html',
  styleUrls: ['./dados-principais.component.scss']
})
export class PreAdmissaoDadosPrincipaisComponent implements OnInit {
  prazoEntrega: Date = new Date();

  time = { hour: 0, minute: 0 };

  gridData = [];

  gridModalUnidadeData = [];

  empresaGridData = [];

  equipeGridData = [];

  desabilitarCamposPreAdmissao = false;

  ocultaCampoDataLimite = false;

  isEdit = false;

  planoIdSelected = '';

  empresaIdSelected = '';

  colaboradorSelected = '';

  nomeColaborador ='';

  cpfColaborador ='';

  emailColaborador ='';

  colaboradorStatusId = '';

  mensagemEmail ='';

  dadoPrincipalIdSelected = '';

  colaboradorPreAdmissaoOriginal: any;

  dadoPrincipalEstadoSelected = '';
  dadoPrincipalCidadeSelected = '';
  cidadesList: Array<object> = [];
  estadosList: Array<object> = [];
  colaboradorTipoList: Array<object> = [];
  grauInstrucaoList: Array<object> = [];
  estadoCivilList: Array<object> = [];
  etiniaList: Array<object> = [];
  tipoList: Array<object> = [];
  sexoList: Array<object> = [];
  modalEquipeLotacaoList: Array<object> = [];
  modalEquipeCodigoInicialIdSelected = '';
  modalEquipeCodigoFinalIdSelected = '';

  fotoColaborador: any = {
    nome: '',
    base64: '',
  };

  typeImagesAccepted = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'];

  grauInstrucaoSelected = '';

  estadoCivilSelected = '';

  etiniaSelected = '';
  colaboradorTipoSelected = '';

  sexoIdSelected = '';

  colaboradorSenha= '';

  dataTable: any;

  dataTableModalUnidade: any;

  dataTableModalEmpresa: any;

  cargoUnidades = [];

  dadosModal: any = {
    nome: '',
    empresaNome: '',
  };

  colaboradorType = '';

  colaboradorLogged = '';
  userLogged: any;

  @ViewChild('grid', { static: true }) grid: ElementRef;
  @ViewChild('gridModalUnidade', { static: true }) gridModalUnidade: ElementRef;

  @ViewChild('gridModalEmpresa', { static: true }) gridModalEmpresa: ElementRef;

  @ViewChild('gridModalEquipe', { static: true }) gridModalEquipe: ElementRef;

  constructor(
    private utilService: UtilsService,
    private moduleService: CadastroColaboradorDadosPrincipaisService,
    private unidadeService: CadastroCargoUnidadeService,
    private empresaService: CadastroEmpresaService,
    private colaboradorService: CadastroColaboradorService,
    private preAdmissaoComponent: PreAdmissaoComponent,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userLogged = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.userLogged) {
      this.colaboradorLogged = this.userLogged.UsuarioColaboradorId;
      if (this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.COLABORADOR && this.userLogged.ColaboradorStatus.Id == ColaboradorStatusEnum.PRE_ADMISSAO) {
        this.ocultaCampoDataLimite = true;
        this.colaboradorSelected = this.userLogged.UsuarioColaboradorId;
        this.colaboradorType = 'pre-admissao';
        this.desabilitarCamposPreAdmissao = true;
        this.getDataGrid();
      } else{
        this.colaboradorType = 'rh';
      }
    }
    this.getIntitialsData();
    this.validarCampos();
    ($("#txtDataNasc") as any).datepicker({
      autoclose: true
    });
    ($("#txtPrazo") as any).datepicker({
      autoclose: true
    });
    ($("#formularioDadosPrincipaisDetalhe") as any).on("submit", function () {
      return false;
    })
  }

  async remover(data) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.moduleService.remover(data).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async removerEquipe(id) {
    Swal.fire({
      position: 'top',
      title: 'Você tem certeza que deseja remover esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3c8dbc',
      cancelButtonColor: '#DD4B39',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.isConfirmed) {
        this.colaboradorService.removerEquipeGestor(id).subscribe(
          res => {
            this.utilService.alertSwal('remove-success');
            this.getEquipeGestorDataGrid();
          },
          err => {
            this.utilService.alertSwal('remove-error');
          }
        );
      }
    })
  }

  async getSelectData() {
    this.estadosList = await this.utilService.getEstados();
    this.etiniaList = await this.utilService.getEtiniaList();
    this.cidadesList = [];
    this.sexoList = [
      {
        Id: 'Masculino',
        Description: 'MASCULINO'
      },
      {
        Id: 'Feminino',
        Description: 'FEMININO'
      },
    ];
    this.colaboradorService.BuscarTudoColaboradorTipo().subscribe(
      res => {
        this.colaboradorTipoList = res.filter(val => val.Nome != null).map(val =>
        ({
          Id: val.Id,
          Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoGrauInstrucao().subscribe(
      res => {
        this.grauInstrucaoList = res.filter(val => val.Nome != null).map(val =>
        ({
          Id: val.Id,
          Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoEstadoCivil().subscribe(
      res => {
        this.estadoCivilList = res.filter(val => val.Nome != null).map(val =>
        ({
          Id: val.Id,
          Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
    this.colaboradorService.BuscarTudoColaboradorTipo().subscribe(
      res => {
        this.tipoList = res.filter(val => val.Nome != null).map(val =>
        ({
          Id: val.Id,
          Description: val.Nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        })
        ).sort(this.utilService.dynamicSort('Description'));
      },
      err => {
        console.log(err);
      }
    );
  }

  async changeSelectValues(event: any) {
    if (event.srcElement.id == 'selectEstados') {
      this.dadoPrincipalCidadeSelected = '';
      this.dadoPrincipalEstadoSelected = event.target.value;
      this.cidadesList = await this.utilService.getCidades(event.target.value);
    } else if (event.srcElement.id == 'selectCidades') {
      this.dadoPrincipalCidadeSelected = event.target.value;
    } else if (event.srcElement.id == 'selectGrauInstrucao') {
      this.grauInstrucaoSelected = event.target.value;
    } else if (event.srcElement.id == 'selectEstadoCivil') {
      this.estadoCivilSelected = event.target.value;
    } else if (event.srcElement.id == 'selectTipo') {
      this.colaboradorTipoSelected = event.target.value;
      $('#selectTipo').removeClass('element-invalid');
    } else if (event.srcElement.id == 'selectSexo') {
      this.sexoIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selCodigoInicial') {
      this.modalEquipeCodigoInicialIdSelected = event.target.value;
    } else if (event.srcElement.id == 'selCodigoFinal') {
      this.modalEquipeCodigoFinalIdSelected = event.target.value;
    }else if (event.srcElement.id == 'selectEtinia') {
      this.etiniaSelected = event.target.value;
    }
  }

  validarCampos() {
    const self = this;
    ($("#formularioDadosPrincipais") as any).validate({
      // Define as regras
      rules: {
        txtNome: {
          required: true

        },
        txtCPF: {
          required: true, minlength: 14
        },
        txtEmail: {
          required: true, email: true
        },
        txtTel1: {
          minlength: 14
        },
        txtTel3: {
          minlength: 14
        },
        txtTel2: {
          minlength: 14
        },
        selectTipo: {
          required: true
        },
        selectEtinia: {
          required: true
        }
      },
      // Define as mensagens de erro para cada regra
      messages: {
        txtNome: {
          required: "Informe o Nome"
        },
        txtCPF: {
          required: "Informe o Cpf",
          minlength: "Informe o Cpf corretamente"
        },
        txtEmail: {
          required: "Informe o Email",
          email: "Informme o Email corretamente"
        },
        txtTel1: {
          minlength: "Informe o Telefone Residencial corretamente"
        },
        txtTel3: {
          minlength: "Informe o Telefone Celular corretamente"
        },
        txtTel2: {
          minlength: "Informe o Telefone Comercial corretamente"
        },
        selectTipo: {
          required: "Selecione o Tipo"
        },
        selectEtinia: {
          required: "Declare a Etinia"
        }
      },
    });
    ($("#formularioDadosPrincipais") as any).on("submit", function () {
      return false;
    })
  }

  getIntitialsData() {
    this.loadDataTable([]);
    this.getSelectData();
  }

  getDataGrid() {
    const data = this.getValuesFromInput();
    if (this.colaboradorType == 'pre-admissao') {
      this.colaboradorService.BuscarDadoPrincipalColaboradorPreAdmissaoPorId(this.colaboradorLogged).subscribe(
        async res => {
          //res.DataNascimento = null;
          // Seta modo edição
          this.isEdit = true;
          this.colaboradorStatusId = res.ColaboradorStatusId;
          this.colaboradorPreAdmissaoOriginal = res;

          // Preencher campos, com a linha selecionada
          ($("#txtDataNasc") as any).datepicker('setDate', res.DataNascimento ? new Date(res.DataNascimento) : '');
          ($("#txtPrazo") as any).datepicker('setDate', res.DataPrazo ? new Date(res.DataPrazo) : '');
          this.time = { hour: 0, minute: 0 };

          this.dadoPrincipalIdSelected = res.Id;
          this.empresaIdSelected = res.EmpresaId;
          this.sexoIdSelected = res.Sexo || '';
          this.colaboradorTipoSelected = res.ColaboradorTipoId || '';
          this.fotoColaborador = {
            nome: '',
            base64: '',
          };
          this.grauInstrucaoSelected = res.GrauInstrucaoId || '';
          this.estadoCivilSelected = res.EstadoCivilId || '';
          this.etiniaSelected = res.Etinia || '';
          this.dadoPrincipalEstadoSelected = res.UF || '';
          this.cidadesList = res.UF ? await this.utilService.getCidades(res.UF) : [];
          this.dadoPrincipalCidadeSelected = res.Cidade || '';
          $('#txtNomeEmpresa').val(res.Empresa.Nome);
          $('#txtNome').val(res.Nome);
          $('#txtCPF').val(res.CPF);
          $('#txtEnd').val(res.Endereco);
          $('#txtEndComp').val(res.Complemento);
          $('#txtBairro').val(res.Bairro);
          $('#txtNumeroEnd').val(res.Numero);
          $('#txtCep').val(res.CEP);
          $('#txtTel1').val(res.Telefone1);
          $('#txtTel2').val(res.Telefone2);
          $('#txtTel3').val(res.Telefone3);
          $('#txtEmail').val(res.Email);
          $('#txtNomePai').val(res.NomePai);
          $('#txtNomeMae').val(res.NomeMae);
          $('#txtMatriculaInterna').val(res.MatriculaInterna);
          $('#txtMatriculaESocial').val(res.MatriculaeSocial);
          $('#txtPaisNasc').val(res.PaisNascimento);
          $('#txtUFNasc').val(res.UFNascimento);
          $('#txtCidadeNasc').val(res.CidadeNascimento);
          $("#formularioDadosPrincipais").valid();
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.moduleService.pesquisarPreAdmissaoPorUsuarioId(data, this.userLogged.UsuarioColaboradorId).subscribe(
        res => {
          this.gridData = res.map(val =>
          ({
            id: val.Id,
            nome: val.Nome,
            cpf: val.CPF,
            tipo: val.ColaboradorTipo.Nome,
            empresaId: val.EmpresaId,
            empresaNome: val.Empresa ? val.Empresa.Nome : '',
            uf: val.UF,
            cidade: val.Cidade,
            telRes: val.Telefone1,
            telCel: val.Telefone2,
            telCom: val.Telefone3,
            Email: val.Email,
            statusId: val.ColaboradorStatusId,
            status: val.ColaboradorStatus ? val.ColaboradorStatus.Nome : '',
            senha: this.userLogged.UsuarioColaboradorTipoId == UsuarioColaboradorTipoEnum.USUARIO? '-' : val.Senha || '-',
            dataCadastro: val.DataCadastro,
            etinia: val.Etinia,
            ...val
          })
          ).sort(this.utilService.dynamicSort('nome'));
          this.loadDataTable(this.gridData);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  clearModalEquipeInputvalues() {
    this.modalEquipeCodigoInicialIdSelected = '';
    this.modalEquipeCodigoFinalIdSelected = '';
  }

  clearInputvalues(type = '') {

    if (type == 'salvar') {
      $('#selectTipo').removeClass('element-invalid');
      $("#formularioDadosPrincipais").valid();
    }
    // Desabilita modo edição
    this.isEdit = false;

    // Zera os campos
    this.dadoPrincipalIdSelected = '';
    this.empresaIdSelected = '';
    this.colaboradorTipoSelected = '';
    this.sexoIdSelected = '';
    this.fotoColaborador = {
      nome: '',
      base64: '',
    };
    this.grauInstrucaoSelected = ''; //ver depois
    this.estadoCivilSelected = ''; //ver depois
    this.dadoPrincipalCidadeSelected = '';
    this.dadoPrincipalEstadoSelected = '';
    $('#txtNome').val('');
    $('#txtCPF').val('');
    $('#txtEnd').val('');
    $('#txtEndComp').val('');
    $('#txtBairro').val('');
    $('#txtCep').val('');
    $('#txtDataNasc').val('');
    $('#txtTel1').val('');
    $('#txtTel2').val('');
    $('#txtTel3').val('');
    $('#txtEmail').val('');
    $('#txtNomePai').val('');
    $('#txtNomeMae').val('');
    $('#txtMatriculaInterna').val('');
    $('#txtMatriculaESocial').val('');
    $('#txtPaisNasc').val('');
    $('#txtUFNasc').val('');
    $('#txtCidadeNasc').val('');
    $('#txtNumeroEnd').val('');
    $('#txtNomeEmpresa').val('');
    $('#txtPrazo').val('');
    this.time = { hour: 0, minute: 0 };
    $('#selectEtinia').val('');
  }

  getValuesFromInput(type = '') {
    const data = $('#txtDataNasc').val().toString().trim().split('/');
    if ($('#txtPrazo').val() != undefined){
      const prazo = $('#txtPrazo').val().toString().trim().split('/');
      this.prazoEntrega =  new Date(prazo[2],prazo[1]-1,prazo[0]);
      this.prazoEntrega.setUTCHours(this.time.hour, this.time.minute);
    }

    if (type == 'salvar') {
      return {
        Nome: $('#txtNome').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CPF: $('#txtCPF').val().toString().trim(),
        EmpresaId: this.empresaIdSelected || '',
        ColaboradorTipoId: this.colaboradorTipoSelected || '',
        Sexo: this.sexoIdSelected || '',
        Endereco: $('#txtEnd').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Complemento: $('#txtEndComp').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Bairro: $('#txtBairro').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CEP: $('#txtCep').val().toString().trim(),
        UF: this.dadoPrincipalEstadoSelected || '',
        Cidade: this.dadoPrincipalCidadeSelected || '',
        FotoBase64: this.fotoColaborador.base64,
        DataNascimento: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
        DataPrazo: this.prazoEntrega.toUTCString(),//prazo.length >= 3 ? `${prazo[2]}-${prazo[1]}-${prazo[0]}` : '',
        Telefone1: $('#txtTel1').val().toString().trim(),
        Telefone2: $('#txtTel2').val().toString().trim(),
        Telefone3: $('#txtTel3').val().toString().trim(),
        Email: $('#txtEmail').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        NomePai: $('#txtNomePai').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        NomeMae: $('#txtNomeMae').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        Numero: $('#txtNumeroEnd').val().toString().trim(),
        MatriculaInterna: $('#txtMatriculaInterna').val().toString().trim(),
        MatriculaeSocial: $('#txtMatriculaESocial').val().toString().trim(),
        PaisNascimento: $('#txtPaisNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        UFNascimento: $('#txtUFNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        CidadeNascimento: $('#txtCidadeNasc').val().toString().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
        GrauInstrucaoId: this.grauInstrucaoSelected || '',
        EstadoCivilId: this.estadoCivilSelected || '',
        ColaboradorStatusId: this.colaboradorStatusId ? this.colaboradorStatusId : '',
        Etinia: this.etiniaSelected || '',
      }
    }
    return {
      Nome: $('#txtNome').val().toString().trim(),
      CPF: $('#txtCPF').val().toString().trim(),
      EmpresaId: this.empresaIdSelected || 0,
      ColaboradorTipoId: this.colaboradorTipoSelected || 0,
      Sexo: this.sexoIdSelected || '',
      Endereco: $('#txtEnd').val().toString().trim(),
      Complemento: $('#txtEndComp').val().toString().trim(),
      Bairro: $('#txtBairro').val().toString().trim(),
      CEP: $('#txtCep').val().toString().trim(),
      UF: this.dadoPrincipalEstadoSelected || '',
      Cidade: this.dadoPrincipalCidadeSelected || '',
      FotoBase64: this.fotoColaborador.base64,
      DataNascimento: data.length >= 3 ? `${data[2]}-${data[1]}-${data[0]}` : '',
      DataPrazo: this.prazoEntrega.toUTCString(),//prazo.length >= 3 ? `${prazo[2]}-${prazo[1]}-${prazo[0]}` : '',
      Telefone1: $('#txtTel1').val().toString().trim(),
      Telefone2: $('#txtTel2').val().toString().trim(),
      Telefone3: $('#txtTel3').val().toString().trim(),
      Email: $('#txtEmail').val().toString().trim(),
      NomePai: $('#txtNomePai').val().toString().trim(),
      NomeMae: $('#txtNomeMae').val().toString().trim(),
      Numero: $('#txtNumeroEnd').val().toString().trim(),
      MatriculaInterna: $('#txtMatriculaInterna').val().toString().trim(),
      MatriculaeSocial: $('#txtMatriculaESocial').val().toString().trim(),
      PaisNascimento: $('#txtPaisNasc').val().toString().trim(),
      UFNascimento: $('#txtUFNasc').val().toString().trim(),
      CidadeNascimento: $('#txtCidadeNasc').val().toString().trim(),
      GrauInstrucaoId: this.grauInstrucaoSelected || 0,
      EstadoCivilId: this.estadoCivilSelected || 0,
      Etinia: this.etiniaSelected || '',
    }
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      if (this.typeImagesAccepted.includes(file.type)) {
        this.fotoColaborador.base64 = event.target.result;
        this.fotoColaborador.nome = file.name;
        $('#txtFoto').val(file.name);
      } else {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Formato de arquivo inválido!',
          showConfirmButton: false,
          timer: 2000
        });
      }
    });

    reader.readAsDataURL(file);
  }

  openModal(idModal, data = {}) {
    this.closeModal(idModal);
    this.dadosModal = data;

    if (idModal == 'modalEmpresa') {
      if ($('#txtNomeEmpresa').val().toString().length > 0) {
        this.empresaService.buscarEmpresaPorNomeUsuarioId($('#txtNomeEmpresa').val().toString(),this.userLogged.UsuarioColaboradorId).subscribe(
          res => {
            this.empresaGridData = res.map(val =>
            ({
              id: val.Id,
              nome: val.Nome,
              cnpj: val.CNPJ,
            })
            ).sort(this.utilService.dynamicSort('nome'));
            this.loadDataTableModalEmpresa(this.empresaGridData);

            ($(`#${idModal}`) as any).modal('show');
          },
          err => {
            console.log(err);
          }
        );
      }
    } else if (idModal == 'modalEquipe') {
      this.getEquipeGestorDataGrid(idModal);
      this.colaboradorService.BuscarTudoLotacao(this.empresaIdSelected).subscribe(
        res => {
          this.modalEquipeLotacaoList = res.map(val =>
          ({
            Id: val.Id,
            Description: val.Codigo,
          })
          );
        },
        err => {
          console.log(err);
        }
      );
    }
    else if(idModal == 'modalEnvioDeEmail')
    {
      $("#mensagemEmail").val('');
      ($(`#${idModal}`) as any).modal('show');
    }
  }

  getEquipeGestorDataGrid(idModal = '') {
    this.colaboradorService.buscarEquipeGestor({ ColaboradorId: this.colaboradorSelected }).subscribe(
      res => {
        this.equipeGridData = res.map(val =>
        ({
          id: val.Id,
          codigoInicial: val.LotacaoUnidadeInicial.Codigo,
          codigoFinal: val.LotacaoUnidadeFinal.Codigo,
          ...val,
        })
        ).sort(this.utilService.dynamicSort('nome'));
        this.loadDataTableModalEquipe(this.equipeGridData);
        if (!($(`#${idModal}`) as any).hasClass('in')) {
          ($(`#${idModal}`) as any).modal('show');
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  closeModal(idModal) {
    ($(`#${idModal}`) as any).modal('hide');
  }

  pesquisar() {
    this.getDataGrid();
  }

  salvar(concluir: boolean) {

    if (!this.colaboradorTipoSelected || !this.empresaIdSelected || $("#txtPrazo").val()=='' || !this.etiniaSelected) {
      if (!this.empresaIdSelected)
        $('#txtNomeEmpresa').addClass('element-invalid');
      if (!this.etiniaSelected)
        $('#selectEtinia').addClass('element-invalid');
      if (!this.colaboradorTipoSelected)
        $('#selectTipo').addClass('element-invalid');
      ($("#formularioDadosPrincipais") as any).valid();
      if ($("#txtPrazo").val()=='')
        $("#txtPrazo").addClass('element-invalid');
      else
        $('#txtPrazo').removeClass('element-invalid');
    } else {
      $('#selectTipo').removeClass('element-invalid');
      $('#txtNomeEmpresa').removeClass('element-invalid');
      $("#txtPrazo").removeClass('element-invalid');
      $("#selectEtinia").removeClass('element-invalid');
      if (($("#formularioDadosPrincipais") as any).valid()) {
        const obj: any = this.getValuesFromInput('salvar');
        if (this.isEdit) {
           obj.Id = this.dadoPrincipalIdSelected.toString();
           this.colaboradorSelected = obj.Id;
          this.moduleService.editar(obj, concluir,this.userLogged.UsuarioColaboradorId).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else if (res == 'Registro não encontrado') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res,
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {

                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('edit-success');
                ///this.router.navigate(['/Cadastro/PreAdmissao']);
                //this.preAdmissaoComponent.changeTab('documentacao');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('edit-error');
            }
          );
        } else {
          this.moduleService.adicionarPreAdmissao(obj,this.userLogged.UsuarioColaboradorId).subscribe(
            res => {
              if (res.Codigo && res.Codigo == '600') {
                Swal.fire({
                  position: 'top',
                  icon: 'error',
                  title: res.Descricao || 'Ocorreu um erro',
                  showConfirmButton: false,
                  timer: 2000
                });
              } else {
                this.clearInputvalues('salvar');
                this.getDataGrid();
                this.utilService.alertSwal('add-success');
              }
            },
            err => {
              console.log(err);
              this.utilService.alertSwal('add-error');
            }
          );
        }
      }
    }
  }

  salvarEquipe() {
    if (this.modalEquipeCodigoInicialIdSelected && this.modalEquipeCodigoFinalIdSelected) {
      const obj = {
        ColaboradorId: this.colaboradorSelected,
        LotacaoUnidadeInicialId: this.modalEquipeCodigoInicialIdSelected,
        LotacaoUnidadeFinalId: this.modalEquipeCodigoFinalIdSelected,
      }
      this.colaboradorService.adicionarEquipeGestor(obj).subscribe(
        res => {
          if (res.Codigo && res.Codigo == '600') {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: res.Descricao || 'Ocorreu um erro',
              showConfirmButton: false,
              timer: 2000
            });
          } else {
            this.clearModalEquipeInputvalues();
            this.getEquipeGestorDataGrid();
            this.utilService.alertSwal('add-success');
          }
        },
        err => {
          console.log(err);
          this.utilService.alertSwal('add-error');
        }
      );
    }
  }

  sendMail(){
    var data = {
      nome: this.nomeColaborador,
      email: this.emailColaborador,
      cpf: this.cpfColaborador,
      mensagem: $("#mensagemEmail").val(),
    };
    this.colaboradorService.EnviarEmail(data).subscribe(
      res => {
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: "Email enviado com sucesso!",
          showConfirmButton: false,
          timer: 3000
        });
      },
      err => {
        console.log(err);
        this.utilService.alertSwal('add-error');
      }
    );
  }

  destroiTable() {
    this.dataTable = $(this.grid.nativeElement);
    if (this.dataTable.hasClass('dataTable')) {
      var tableFound = this.dataTable.DataTable();
      tableFound.destroy();
    }
  }

  loadDataTable(data) {

    let self = this;
    this.dataTable = $(this.grid.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cpf"
        },
        {
          "data": "tipo"
        },
        {
          "data": "empresaNome"
        },
        {
          "data": "uf"
        },
        {
          "data": "cidade"
        },
        {
          "data": "telRes"
        },
        {
          "data": "telCel"
        },
        {
          "data": "telCom"
        },
        {
          "data": "Email"
        },
        {
          "data": "status"
        },
        {
          "data": "senha"
        },
        {
          "data": 'dataCadastro', "orderable": true, "render": function (data, type, row) {
            return data ? new Date(data).toLocaleDateString('pt-br') : '-';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-edit" style="color:rgb(136, 134, 29); border: 0px; background-color: transparent;" title="Editar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-check" style="rgb(29, 136, 136); border: 0px; background-color: transparent;" title="Aprovar"></button></div>';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-envelope" style = "color: rgb(60,179,113); border: 0px; background-color: transparent;" title = "Enviar E-mail" > </button></div > ';
          }
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()[13]).on("click", async function (e) {
          const datetime = new Date(data.DataPrazo);
          self.time = data.DataPrazo ? {hour:datetime.getHours(), minute:datetime.getMinutes()} : {hour:0, minute:0};
          // Seta modo edição
          self.isEdit = true;
          self.colaboradorStatusId = data.ColaboradorStatusId;
          // Preencher campos, com a linha selecionada
          ($("#txtDataNasc") as any).datepicker('setDate', data.DataNascimento ? new Date(data.DataNascimento) : '');
          ($("#txtPrazo") as any).datepicker('setDate', data.DataPrazo ? new Date(data.DataPrazo) : '');
          self.dadoPrincipalIdSelected = data.id;
          self.empresaIdSelected = data.empresaId;
          self.colaboradorTipoSelected = data.ColaboradorTipoId;
          self.sexoIdSelected = data.Sexo;
          self.fotoColaborador = {
            nome: '',
            base64: '',
          };

          self.colaboradorTipoSelected = data.ColaboradorTipoId;
          self.grauInstrucaoSelected = data.GrauInstrucaoId;
          self.estadoCivilSelected = data.EstadoCivilId;
          self.etiniaSelected = data.Etinia;
          self.dadoPrincipalEstadoSelected = data.UF;
          self.cidadesList = data.UF ? await self.utilService.getCidades(data.UF) : [];
          self.dadoPrincipalCidadeSelected = data.Cidade;
          $('#txtNomeEmpresa').val(data.Empresa.Nome);
          $('#txtNome').val(data.Nome);
          $('#txtCPF').val(data.CPF);
          $('#txtEnd').val(data.Endereco);
          $('#txtEndComp').val(data.Complemento);
          $('#txtBairro').val(data.Bairro);
          $('#txtNumeroEnd').val(data.Numero);
          $('#txtCep').val(data.CEP);
          $('#txtTel1').val(data.Telefone1);
          $('#txtTel2').val(data.Telefone2);
          $('#txtTel3').val(data.Telefone3);
          $('#txtEmail').val(data.Email);
          $('#txtNomePai').val(data.NomePai);
          $('#txtNomeMae').val(data.NomeMae);
          $('#txtMatriculaInterna').val(data.MatriculaInterna);
          $('#txtMatriculaESocial').val(data.MatriculaeSocial);
          $('#txtPaisNasc').val(data.PaisNascimento);
          $('#txtUFNasc').val(data.UFNascimento);
          $('#txtCidadeNasc').val(data.CidadeNascimento);
          $("#formularioDadosPrincipais").valid();
          if (!self.colaboradorTipoSelected) {
            $('#selectTipo').addClass('element-invalid');
          } else {
            $('#selectTipo').removeClass('element-invalid');
          }
          if (!self.empresaIdSelected) {
            $('#txtNomeEmpresa').addClass('element-invalid');
          } else {
            $('#txtNomeEmpresa').removeClass('element-invalid');
          }
        });
        $($(row).children()[16]).on("click", function (e) {
          self.remover(data);
        });
        $($(row).children()[15]).on("click", function (e) {
          self.nomeColaborador = data.Nome;
          self.emailColaborador = data.Email;
          self.cpfColaborador = data.CPF;
          self.openModal("modalEnvioDeEmail");
        });
        $($(row).children()[14]).on("click", function (e) {
          Swal.fire({
            position: 'top',
            title: `Você tem certeza que deseja aprovar o colaborador '${data.Nome}'?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3c8dbc',
            cancelButtonColor: '#DD4B39',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.isConfirmed) {
              const newData = data;
              newData.ColaboradorStatusId = 2;
              self.moduleService.aprovarColaboradorPreAdmissao(data).subscribe(
                res => {
                  console.log(res);
                  if(res.Codigo == 500){
                    Swal.fire({
                      position: 'top',
                      icon: 'error',
                      title: res.Descricao,
                      showConfirmButton: true,
                      timer: 10000
                    });
                  }else{
                    Swal.fire({
                      position: 'top',
                      icon: 'success',
                      title: `Colaborador '${data.Nome}' aprovado!`,
                      showConfirmButton: false,
                      timer: 3000
                    });
                  }

                  self.getDataGrid();
                },
                err => {
                 Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: `Erro ao aprovar o colaborador '${data.Nome}'!`,
                    showConfirmButton: false,
                    timer: 3000
                  });
                }
              );
            }
          })
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": true,
      "searching": true,
      "ordering": true,
      "info": true,
      "autoWidth": true,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEquipe(data) {
    let self = this;
    this.dataTable = $(this.gridModalEquipe.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "codigoInicial"
        },
        {
          "data": "codigoFinal"
        },
        {
          "data": '', "orderable": false, "render": function (data, type, row) {
            return '<div style="vertical-align: middle; text-align:center;"><button type="button" class="fa fa-trash-o" style = "color: rgb(206, 14, 14); border: 0px; background-color: transparent;" title = "Excluir" > </button></div > ';
          }
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.removerEquipe(data.id);
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

  loadDataTableModalEmpresa(data) {
    let self = this;
    this.dataTable = $(this.gridModalEmpresa.nativeElement);
    this.dataTable.DataTable({
      "data": data,
      "bDestroy": true,
      "columns": [
        {
          "data": "nome"
        },
        {
          "data": "cnpj"
        },
      ],
      "createdRow": function (row, data, index) {
        $($(row).children()).on("click", function (e) {
          self.empresaIdSelected = data.id;
          $('#txtNomeEmpresa').val(data.nome);
          $('#txtNomeEmpresa').removeClass('element-invalid');
          self.closeModal('modalEmpresa');
        });
      },
      "paging": true,
      "bAutoWidth": false,
      "lengthChange": false,
      "searching": false,
      "ordering": false,
      "info": false,
      "autoWidth": false,
      "language": this.utilService.getDatatableTranslate(),
    });
  }

}
