<form id="formularioDadosPrincipais">
  <div style="margin-left: 0px;">
    <div class="box box-info">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Colaborador</label>
              <input type="text" class="form-control" id="txtNome" name="txtNome" placeholder="Nome do Colaborador">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-left: 0px;">
    <div class="box box-primary">
      <div class="box-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Empresa</label>
              <!-- BUSCAR OS DADOS DA TABELA Empresa  -->
              <div class="input-group">
                <input type="text" class="form-control" id="txtNomeEmpresa" name="txtNomeEmpresa"
                  placeholder="Nome da Empresa">
                <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalEmpresa')"
                    title="Pesquisar Empresa"><i class="fa fa-search"></i></a></span>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Tipo</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="tipoList" IdSelect="selectTipo"
                [Value]="colaboradorTipoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>CPF</label>
              <input type="text" mask="000.000.000-00" class="form-control" id="txtCPF" name="txtCPF" placeholder="CPF">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Sexo</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="sexoList" IdSelect="selectSexo"
                [Value]="sexoIdSelected">
              </select-component>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Endereço</label>
              <input type="text" class="form-control" id="txtEnd" name="txtEnd" placeholder="Endereço">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Número</label>
              <input type="text" class="form-control" id="txtNumeroEnd" name="txtNumeroEnd" placeholder="Número">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Complemento</label>
              <input type="text" class="form-control" id="txtEndComp" name="txtEndComp"
                placeholder="Endereço Complemento">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Bairro</label>
              <input type="text" class="form-control" id="txtBairro" name="txtBairro" placeholder="Bairro">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>CEP</label>
              <input type="text" mask="00000-000" class="form-control" id="txtCep" name="txtCep" placeholder="CEP">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadosList" IdSelect="selectEstados"
                [Value]="dadoPrincipalEstadoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Cidade</label>
              <select-component (changeValue)="changeSelectValues($event)"
                [Disabled]="!dadoPrincipalEstadoSelected ? true : false" [Data]="cidadesList" IdSelect="selectCidades"
                [Value]="dadoPrincipalCidadeSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Foto</label>
              <div class="input-group date">
                <input disabled style="background-color: white;" type="text" class="form-control pull-right"
                  id="txtFoto" name="txtFoto" placeholder="Escolher Arquivo">
                <div class="input-group-addon" style="padding: 0px;">
                  <div class="fa fa-upload btn btn-file">
                    <input class="ignore" type="file" name="inputFoto" #imageInput type="file"
                      (change)="processFile(imageInput)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Data de Nascimento</label>
              <div class="input-group date">
                <input type="text" mask="00/00/0000" autocomplete="off" class="form-control pull-right" id="txtDataNasc" name="txtDataNasc"
                  placeholder="dd/mm/yyyy">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Telefone 1</label>
              <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTel1" name="txtTel1"
                placeholder="(__) ____-____">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Telefone 2</label>
              <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTel2" name="txtTel2"
                placeholder="(__) ____-____">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Telefone 3</label>
              <input type="text" mask="(00) 0000-00009" class="form-control" id="txtTel3" name="txtTel3"
                placeholder="(__) ____-____">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control" id="txtEmail" name="txtEmail" placeholder="Email">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome do Pai</label>
              <input type="text" class="form-control" id="txtNomePai" name="txtNomePai" placeholder="Nome do Pai">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Nome da Mãe</label>
              <input type="text" class="form-control" id="txtNomeMae" name="txtNomeMae" placeholder="Nome da Mãe">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Matrícula Interna</label>
              <input type="text" class="form-control" id="txtMatriculaInterna" name="txtMatriculaInterna"
                placeholder="Matrícula Interna">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Matrícula eSocial</label>
              <input type="text" class="form-control" id="txtMatriculaESocial" name="txtMatriculaESocial"
                placeholder="Matrícula eSocial">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <!-- USAR UM COMPONENTE PARA TRAZER OS PAISES NO FRONT -->
              <label>País de Nascimento</label>
              <input type="text" class="form-control" id="txtPaisNasc" name="txtPaisNasc"
                placeholder="País de Nascimento">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UF de Nascimento</label>
              <input type="text" class="form-control" id="txtUFNasc" name="txtUFNasc" placeholder="UF de Nascimento">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Cidade de Nascimento</label>
              <input type="text" class="form-control" id="txtCidadeNasc" name="txtCidadeNasc"
                placeholder="Cidade de Nascimento">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <!-- CRIAR UM ENUMERATOR NO BACK -->
              <label>Grau de Instrução</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="grauInstrucaoList"
                IdSelect="selectGrauInstrucao" [Value]="grauInstrucaoSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Estado Civil</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="estadoCivilList"
                IdSelect="selectEstadoCivil" [Value]="estadoCivilSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Status</label>
              <!-- BUSCAR OS DADOS DA TABELA COLABORADOR STATUS  -->
              <select-component (changeValue)="changeSelectValues($event)" [Data]="statusList" IdSelect="selectStatus"
                [Value]="colaboradorStatusIdSelected">
              </select-component>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
            <label>Empresas Terceiras</label>
            <ng-multiselect-dropdown class="custom-font-size" [placeholder]="'Selecione uma empresa...'" [settings]="empresasTerceiraSettings"
              [data]="empresasTerceiraList" [(ngModel)]="empresaTerceiraSelected" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Declaração de Etinia</label>
              <select-component (changeValue)="changeSelectValues($event)" [Data]="etiniaList"
                IdSelect="selectEtinia" [Value]="etiniaSelected">
              </select-component>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button" id="Pesquisar" name="Pesquisar" value="Pesquisar" (click)="pesquisar()"
                class="btn btn-primary btn-margin" />
              <input name="Salvar" id="btnSalvar" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvar()"
                class="btn btn-info pull-right btn-margin" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</form>
<div class="box box-body">
  <div style="overflow-y: auto;">
    <div style="width: 99%; padding-left: 15px;">
      <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Tipo</th>
            <th>Empresa</th>
            <th>UF</th>
            <th>Cidade</th>
            <th>Telefone Res.</th>
            <th>Telefone Cel.</th>
            <th>Telefone Com.</th>
            <th>Email</th>
            <th>Status</th>
            <th>Senha</th>
            <th>Data de Cadastro</th>
            <th>Empresa Terceira</th>
            <th style="width: 5% !important;"></th>
            <th style="width: 5% !important;"></th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<div class="modal fade" id="modalEmpresa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Empresa</b></h4>
      </div>
      <div class="modal-body">
        <table #gridModalEmpresa style="width: 100% !important; cursor: pointer;"
          class="hover tableOperadora table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cnpj</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL EQUIPE GESTOR -->
<div class="modal fade" id="modalEquipe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><b>Equipe</b></h4><br>
        <h5 class="modal-title">Gestor: Trident</h5>
      </div>
      <div class="modal-body">
        <form id="formularioDetalhe">
          <div class="box-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Código Inicial</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="modalEquipeLotacaoList"
                    IdSelect="selCodigoInicial" [Value]="modalEquipeCodigoInicialIdSelected">
                  </select-component>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Código Final</label>
                  <select-component (changeValue)="changeSelectValues($event)" [Data]="modalEquipeLotacaoList"
                    IdSelect="selCodigoFinal" [Value]="modalEquipeCodigoFinalIdSelected">
                  </select-component>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <input name="Salvar" value="Salvar" readonly="readonly" readonly="readonly" (click)="salvarEquipe()" class="btn btn-info pull-right btn-margin">
            <button type="button" class="btn btn-default pull-left"
              (click)="clearModalEquipeInputvalues()">Limpar</button>
          </div>
        </form>
      </div>

      <div>
        <div style="overflow-y: auto; padding-left: 10px; padding-right: 10px;">
          <div style="width: 99%;">
            <table #gridModalEquipe style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Código Inicial</th>
                  <th>Código Final</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
