<div style="margin-left: 0px;" class="content-wrapper" id="content-wrapper">
  <section class="content-header">
    <h1>
      Recibo de Férias
    </h1>
    <ol class="breadcrumb">
      <li><i class="fa fa-dashboard"></i>&nbsp;&nbsp;&nbsp;Home</li>
      <li>Relatório</li>
      <li class="active">Recibo de Férias</li>
    </ol>
  </section>

  <!-- modalp popup ends -->
  <section class="content">

    <form id="formulario">
      <div style="margin-left: 0px;">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row" *ngIf="colaboradorType == 'rh'; else funcionario">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Nome do Colaborador</label>
                  <!-- BUSCAR OS DADOS DA TABELA COLABORADOR -->
                  <div class="input-group">
                    <input type="text" class="form-control" id="txtNomeColaborador" name="txtNomeColaborador"
                      placeholder="Nome do Colaborador">
                    <span class="input-group-addon"><a style="cursor: pointer;" (click)="openModal('modalColaborador')"
                        title="Pesquisar colaborador"><i class="fa fa-search"></i></a></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Ano</label>
                  <input type="text" class="form-control" mask="0000" id="txtAno" name="txtAno" placeholder="yyyy">
                </div>
              </div>
            </div>
            <ng-template #funcionario>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Ano</label>
                    <input type="text" class="form-control" mask="0000" id="txtAno" name="txtAno" placeholder="yyyy">
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="box-footer">
        <table border=0 width="100%">
          <tr width="100%">
            <td align="left"><button type="reset" (click)="clearInputvalues()" class="btn btn-default">Limpar</button>
            </td>
            <td align="center"><span id="spanErro" name="spanErro" class="error" style="font-weight: bold;"></span>
            </td>
            <td align="right">
              <input type="button" value="Pesquisar" (click)="pesquisar()" class="btn btn-primary btn-margin" />
            </td>
          </tr>
        </table>
      </div>
      <div class="box box-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #grid style="width: 100% !important;" class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome do Colaborador</th>
                  <th>CPF do Colaborador</th>
                  <th>Nome da Empresa</th>
                  <th>Ano</th>
                  <th>Data da Importação</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  </section>

  <div class="control-sidebar-bg"></div>
</div>

<div class="modal fade" id="modalColaborador" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Titular</b></h4>
      </div>
      <div class="modal-body">
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalColaborador style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalRelatorio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 95%;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Relatório de Recibo de Férias</b></h4>
      </div>
      <div class="modal-header" style="padding-left: 15px;">
        <div class="row" style="margin-bottom: 10px;">
          <div class="col-md-12"><b>Estabelecimento: </b> {{ modalRelatorioData.empresa }} </div>
        </div>
        <div class="row">
          <div class="col-md-3"><b>CPF: </b> {{ modalRelatorioData.cpf }} </div>
          <div class="col-md-7"><b>Nome: </b>{{ modalRelatorioData.colaborador }} </div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-12"><b>Ano: </b>{{ modalRelatorioData.ano }}</div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-5"><b>Início Período Aquisitivo: </b> {{ modalRelatorioData.inicioPeriodoAquisitivo }}
          </div>
          <div class="col-md-5"><b>Fim Período Aquisitivo: </b> {{ modalRelatorioData.fimPeriodoAquisitivo }}</div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-5"><b>Início Período Gozo: </b> {{ modalRelatorioData.inicioPeriodoGozo }}</div>
          <div class="col-md-5"><b>Fim Período Gozo: </b> {{ modalRelatorioData.fimPeriodoGozo }}</div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-5"><b>Início Período Abono: </b> {{ modalRelatorioData.inicioPeriodoAbono }}</div>
          <div class="col-md-5"><b>Fim Período Abono: </b> {{ modalRelatorioData.fimPeriodoAbono }}</div>
        </div>
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-5"><b>Início Licença Remunerada: </b> {{ modalRelatorioData.inicioLicenca }}</div>
          <div class="col-md-5"><b>Fim Licença Remunerada: </b> {{ modalRelatorioData.fimLicenca }}</div>
        </div>
      </div>
      <div class="modal-body">
        <h4 class="modal-title" id="exampleModalLabel" style="padding-left: 15px;"><b>Vencimentos:</b></h4>
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalRelatorioVencimentos style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>Descrição Evento</th>
                  <th>Base de Cálculo</th>
                  <th>Valor</th>
                  <th>Total Vencimentos</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <h4 class="modal-title" id="exampleModalLabel" style="padding-left: 15px;"><b>Descontos:</b></h4>
        <div style="overflow-y: auto;">
          <div style="width: 99%; padding-left: 15px;">
            <table #gridModalRelatorioDescontos style="width: 100% !important; cursor: pointer;"
              class="hover tableOperadora table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>Descrição Evento</th>
                  <th>Base de Cálculo</th>
                  <th>Valor</th>
                  <th>Total Descontos</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-header" style="padding-left: 15px;">
        <div class="row" style="padding-top: 10px;">
          <div class="col-md-12" style="text-align: justify;"><b>Texto: </b>{{modalRelatorioData.texto}}</div>
        </div>
        <div class="row" style="padding-top: 15px;">
          <div class="col-md-12"><b>Local e Data: </b>{{modalRelatorioData.localData}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Fechar</button>
      </div>
      <!-- <div class="modal-footer">
        <input type="submit" value="Exportar PDF" (click)="exportarPDF()" class="btn btn-info pull-right" />
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Fechar</button>
      </div> -->
    </div>
  </div>
</div>